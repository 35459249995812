import { Select, Typography, CircularProgress, } from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import ImportLocations from './ImportLocations';
import { Grid, MenuItem, makeStyles, Button, Switch, Divider  } from '@material-ui/core';
import stylesTransition from "assets/jss/material-dashboard-react/layouts/transactionalStyle";
import stylesIntegration from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import {useGlobalState} from "../../hooks/useGlobalState";
import axios from 'axios';
import UseAuthorized from 'hooks/useAuthorized';
import AdminTransactionalHeader from "components/AdminTransactionalHeader/AdminTransactionalHeader";
import Alert from '@material-ui/lab/Alert';

const locationsHeaders = ['Name', 'Friendly site name', 'Latitude', 'Longitude'];

export default (props) => {
  const classesTransition = useStylesTransition();
  const classesLocal = useLocalStyles();
  const classes = useStylesIntegration();

  const dispatchState = useContext(useGlobalState.Dispatch);
  const state = useContext(useGlobalState.State);
  //const [isLocationEnabled, setIsLocationEnabled] = useState(state.signedInUser?.data?.user?.isLocationEnabled);
  const [isLocationEnabled, setIsLocationEnabled] = useState(true);
  const [geoMethod, setGeoMethod] = useState(state.geoMethod);
  const [rows, setRows] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [notification, setNotification] = useState(null);

  UseAuthorized(state);

  useEffect(() => {
    if (state.signedInUser?.data?.user?.locations?.length) {
      const arrayLocations = (state.signedInUser?.data?.user?.locations || []).map((location) => Object.values(location));
      setRows([locationsHeaders, ...arrayLocations]);
    }

  }, [
    state.signedInUser?.data?.user?.locations
  ]);

  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        setNotification(null);
      }, 3000);
    }
  }, [
    notification
  ]);

  const save = async (newIsLocationEnabled) => {
    let locations = state.signedInUser?.data?.user?.locations || [];
    if (rows && rows.length > 1) {
      locations = [];
      let onlyLocations = [...rows];
      onlyLocations.splice(0, 1);
      onlyLocations.forEach((row, index) => {
        let name = `${row[0]}`.replace(',', '.');
        let url = `${row[1]}`.replace(',', '.');
        let friendlySiteName = row[2] ? `${row[2]}`.replace(',', '.') : '';
        let latitude = `${row[3]}`.replace(',', '.');
        let longitude = `${row[4]}`.replace(',', '.');
        let id = `${row[5]}`.replace(',', '.');
        if (isNaN(latitude) || isNaN(longitude) || !row[0] || !id) {
          return
        }
        let location = {
          name,
          url,
          friendlySiteName,
          latitude,
          longitude,
          id,
        }
        locations.push(location);
      });
    }

    let updatedUserData = {
      token: state.signedInUser.token, 
      userData: {
        ...state.signedInUser.data.user,
        geoMethod,
        isLocationEnabled: newIsLocationEnabled,
        locations,
      },
    };

    setUpdating(true);
    try {
      const response = await axios.put(
        process.env.REACT_APP_API_URL + 'user/' + state.signedInUser.data.user.id,
        updatedUserData,
        { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.signedInUser.token } }
      );
      if (response.data.status) {
        dispatchState({
          type: 'root-state',
          payload:
            {
              geoMethod,
              signedInUser: {
                ...state.signedInUser,
                data: {
                  ...state.signedInUser.data,
                  user: {
                    ...state.signedInUser.data.user,
                    locations,
                    geoMethod,
                    isLocationEnabled
                  }
                }
              }
            }
          });
        setUpdating(false);
        setNotification({
          type: 'success',
          message: 'Successfully saved'
        });
        return;
      }
      setNotification({
        type: 'error',
        message: 'Saving failed'
      });
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Saving failed'
      });
    }

  }

  const updateIsLocationEnabled = async () => {
    const newIsLocationEnabled = !isLocationEnabled;
    setIsLocationEnabled(!isLocationEnabled)
    await save(newIsLocationEnabled);
  }

  return (
    <Grid container spacing={0} justify="center" className={classesTransition.cFormContaner} style={{paddingTop: 30}}>
      <AdminTransactionalHeader
        homeBtn={false}
        backBtn={false}
        logo={false}
        closeBtn={() => props.history.push("/admin/my-account")}
      />

      <Grid item xs={12} sm={12} md={12} className={classesTransition.cWidthLimiter}>
        <Alert style={{visibility: notification?.type ? 'visible' : 'hidden'}} onClick={() => setNotification(null)} severity={notification?.type}>{notification?.message}</Alert>
        <Typography variant="h1" component="h2" style={{fontWeight: 'normal', paddingBottom: 20}}>Location settings</Typography>
        {/* </Grid><Grid container justifyContent='space-between' alignItems='center'> */}
          {/* <Grid item xs={10}><Typography>Enable/Disable Locations Pop-up for ALL Calculators</Typography></Grid>
          <Grid item xs={2} style={{display: 'flex'}}>
            <Switch
              className={classesLocal.enableLocations}
              checked={isLocationEnabled}
              onChange={updateIsLocationEnabled}
              color="primary"
              name="selectedItemsIntoURL"
              inputProps={{ 'aria-label': 'Selected Items Into URL' }}
            />
          </Grid>
        </Grid> */}
        <br />
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item xs={10}>
            <Typography>Geo location method</Typography>
          </Grid>
          <Grid item xs={2}> 
            <Select
              onChange={(event) => setGeoMethod(event.target.value)}
              value={geoMethod}
              className={{[classes.disabled]: updating}}
            >
              <MenuItem value={'ip'}>IP-address</MenuItem>
              <MenuItem value={'device'}>Device geolocation</MenuItem>
              <MenuItem value={'manual'}>Manual</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <br />
        <br />
        <Divider className={classes.customDividerOne} />
        <Grid item xs={12} className={classesLocal.importLocationsWrap}>
          <ImportLocations
            rows={rows}
            setRows={setRows}
            dispatchState={dispatchState}
            state={state}
            updating={updating}
          />
        </Grid>
        <br/>
        <Grid item>
          <Button
            variant="contained"
            className={classes.buttonSpacing}
            style={{marginRight: 'auto'}}
            color="primary"
            size="large"
            fullWidth
            disabled={updating}
            onClick={() => save(isLocationEnabled)}
          >{updating ? <CircularProgress color='inherit' size={15} /> : 'SAVE'}</Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

// ============================================================================
// Styles
// ============================================================================

const styles = theme => ({
  mainHeader: {
    marginBottom: 15
  },
  enableLocations: {
    marginLeft: 'auto'
  },
  importLocationsWrap: {
    '& > div': {
      paddingLeft: 0,
      paddingRight: 0
    }
  }
});

const useStylesTransition = makeStyles(stylesTransition);
const useStylesIntegration = makeStyles(stylesIntegration);
const useLocalStyles = makeStyles(styles);