/*eslint-disable*/
import React, {useEffect, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";
import stylesHome from "assets/jss/material-dashboard-react/views/HomePageStyle";
import {Grid, Typography} from "@material-ui/core";
import saaLogo from "assets/img/icon-saa@2x.webp";
import NpsaLogo from "assets/img/npsa-logo.png";
import SSAUKLogo from "assets/img/SSA-UK-logo.png";
import SSALogo from "assets/img/SSA-logo.svg";
import cmLogo from "assets/img/cm-isotype@2x.png"
import { Link } from "react-router-dom";
import { whiteColor } from "assets/jss/material-dashboard-react";
import classNames from "classnames";
import G5 from "../../assets/img/g5.svg";
import WordpressS from "../../assets/img/wordpress-s.svg";
import EasyStorageSolution from "../../assets/img/easystoragesolution.svg";
import VixS from "../../assets/img/wix-s.svg";
import Vix from "../../assets/img/vix.svg";
import EasyStorageSolutionS from "../../assets/img/easystoragesolution-s.svg";
import Wordpress from "../../assets/img/wordpress.svg";
import Storable from "../../assets/img/Storable-D@2x-desktop.png";
import StorableM from "../../assets/img/Storable-M@2x.png";
import StoreganiseWhite from '../../assets/img/Storeganise-White.png';
import StoreganiseWhiteMobile from '../../assets/img/Storeganise-White-Mobile.png';
import SSAALogo from '../../assets/img/SSAA-Logo.png';
import StoragelyWhitelogo from "../../assets/img/storagely-white-logo.png";
import StoragelyWhitelogoMobile from "../../assets/img/storagely-white-logo-mobile.png";

const useStyles = makeStyles(styles);
const useStylesHome = makeStyles(stylesHome);

const Footer = props => {
  const classes = useStyles();
  const homeClasses = useStylesHome();
  const {hideCompanies, removals, pageData} = props;
  const [mobile, setMobile] = useState(true);

  useEffect(() => {
    const handleScroll = () => window.innerWidth >= 600 ? setMobile(false) : setMobile( true)
    handleScroll()
    window.addEventListener('resize', handleScroll);
    return () => window.removeEventListener('resize', handleScroll)
  },[]);

  return (
    <footer className={classes.footer}>
      {
        !hideCompanies && (
          <Grid container className={classes.footerContainerOne} spacing={3} direction="row" justify="space-between" alignItems="center">
              <img data-src={NpsaLogo} alt={'Npsa logo'} className="lazyload"/>
              <img data-src={SSALogo} alt={'Ssa logo'} className="lazyload"/>
              <img data-src={saaLogo} alt={'Self storage association of Australia logo'} className="lazyload"/>
              <img data-src={SSAUKLogo} alt={'SSAUK logo'} className="lazyload"/>
              <img data-src={SSAALogo} alt={'SSAA logo'} className="lazyload"/>
          </Grid>
        )
      }
      <Grid container className={classes.footerContainerTwo} direction="row" justify="space-between" alignItems="center">
        {
          removals && (
            <Grid item xs={12} md={12} className={classNames(homeClasses.whyItWorksHead, homeClasses.whyItWorksFooterWrap)} style={{margin: 'auto'}}>
              <Typography className={homeClasses.homeSubHeaderWhite} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Designed to be easily embedded onto any page of your website.</Typography>
              <Grid className={homeClasses.whyItWorksFooterImages}>
                <img data-src={mobile ? StoragelyWhitelogoMobile : StoragelyWhitelogo} alt={'Storagely logo'} className="lazyload" />
                <img data-src={G5} alt={'G5 logo'} className="lazyload" />
                <img data-src={mobile ? EasyStorageSolutionS : EasyStorageSolution} alt={'EasyStorage logo'} className="lazyload" />
                <img data-src={mobile ? VixS : Vix} alt={'Wix logo'} className="lazyload" />
                <img data-src={mobile ? WordpressS : Wordpress} alt={'Wordpress logo'} className="lazyload" />
                <img data-src={mobile ? StorableM : Storable} alt={'Storable logo'} className="lazyload" />
                <img data-src={mobile ? StoreganiseWhiteMobile : StoreganiseWhite} alt={'Storeganise logo'} className="lazyload" />
              </Grid>
            </Grid>)
        }
        <Grid item>
          <img src={cmLogo} alt="Calcumate logo" />
          <Typography variant="subtitle2" component="span" className={classes.startFreeTxtWhite}>©{new Date().getFullYear()} Calcumate</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" component="span" className={classes.startFreeTxtWhite}><Link to="/privacy-policy#" style={{color:whiteColor}}>Privacy policy</Link> |
            <Link to="/terms-of-service#" style={{color:whiteColor}}>Terms of service</Link> |
            <Link to="/sitemap.xml" style={{color:whiteColor}} target={'_blank'}>Sitemap</Link>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" component="span" className={classes.startFreeTxtWhite}>Made with &hearts; in Australia</Typography>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer
