/*eslint-disable*/
import React, { useContext, useState, useEffect, useMemo } from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { Grid, TextField, Button, Typography, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core"
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements
} from '@stripe/react-stripe-js';
import * as Sentry from "@sentry/react";

import StripeCard from 'components/Stripe/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
// core components
import { useGlobalState } from "hooks/useGlobalState"
import {useHistory, useLocation} from "react-router-dom";
import {exist, notExist, serverError} from "../../variables/messages"
import { couponRegexp, emailRegex } from '../../helpers/validation';
import useCouponValidation from '../../hooks/useCouponValidation';
// Other components

import axios from 'axios';

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles)

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const couponMessages = {
  success: exist,
  no: notExist,
  error: serverError
}

const NewPaymentComponent = props => {
  let history = useHistory();
  const classes = useStyles()
  const dispatchState = useContext(useGlobalState.Dispatch)
  let state = useContext(useGlobalState.State);

  //Local state
  const [firstName, setFirstName] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [company, setCompany] = useState('');
  const [country, setCountry] = useState('');
  const [coupon, setCoupon] = useState('');
  const [billingEmail, setBillingEmail] = useState('');
  const [savingCard, setSavingCard] = useState(false);
  const [validCard, setValidCard] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [updateSuccess, setUpdateSuccess] = useState('initial');
  const [updateError, setUpdateError] = useState('');
  const [fieldValidations, setFieldValidations] = useState({});
  const [
    setCheckCoupon,
    message,
    setMessage,
    couponIsValid, 
    updating
  ] = useCouponValidation(coupon, state.signedInUser.token);

  let [values, setValues] = useState({
    paymentMod: false
  });
  
  const isFormDisabled = useMemo(() => {
    return !!(
     savingCard || 
     !validCard || 
     firstName === '' || 
     lastName === '' || 
     address === '' || 
     //company === '' || 
     (
       coupon && 
       !couponIsValid
     )
   )
  }, [
    savingCard,
    validCard,
    firstName,
    lastName,
    address,
    company,
    coupon,
    couponIsValid,
  ]);
  
  const isStripedDisabled = useMemo(() => {
    return !country || 
      !city || 
      !postalCode || 
      !firstName || 
      !lastName || 
      !address;
  }, [
    country,
    city,
    postalCode,
    firstName,
    lastName,
    address,
  ]);
  
  const paymentDetails = useMemo(() => ({
    country,
    firstName,
    address,
    city,
    postal_code: postalCode,
    lastName,
    email: state.signedInUser.data.user.email,
    phone: state.signedInUser.data.user.phone && state.signedInUser.data.user.phone !== '-' ? 
      state.signedInUser.data.user.phone : 
      null,
  }), [
    country,
    firstName,
    address,
    city,
    postalCode,
    lastName,
  ])


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage(false);
  };

  /* eslint-disable */
  useEffect(() => {

    if (state.isLoggedIn && state.signedInUser.token !== '') {
      // set global state values to the local state
      // firstName (and other details) exist in data.user.billing and data.user -- it would be better if these weren't duplicated
      setFirstName(state.signedInUser.data.user?.billing?.firstName || '');
      setLastName(state.signedInUser.data.user?.billing?.lastName || '');
      setCompany(state.signedInUser.data.user?.billing?.company || '');
      setAddress(state.signedInUser.data.user?.billing?.address || '');
      setCountry(state.signedInUser.data.user?.billing?.country || '');
      setCity(state.signedInUser.data.user?.billing?.city || '');
      setPostalCode(state.signedInUser.data.user?.billing?.postal_code || '');
      setBillingEmail(state.signedInUser.data.user?.billing?.billingEmail || '');
      //setCoupon(state.signedInUser.data.user?.billing?.coupon || '');
    } else {
      history.replace('/login');
      window.location.reload();
    }

  }, []);

  const handleNewPaymentMethod = id => {
    setPaymentMethodId(id);
  }

  const handleChange = e => {
    const fields = {
      ...fieldValidations,
      [e.target.name]: !e.target.value
    };

    switch (e.target.name) {
      case 'cm-edit-payment-country':
        setCountry(e.target.value);
        break;
      case 'cm-edit-payment-fname':
        setFirstName(e.target.value);
        break;
      case 'cm-edit-payment-lname':
        setLastName(e.target.value);
        break;
      case 'cm-edit-payment-address':
        setAddress(e.target.value);
        break;
      case 'cm-edit-payment-company':
        setCompany(e.target.value);
        break;
      case 'cm-edit-coupon':
        setCheckCoupon(true);
        setCoupon(e.target.value);
        break;
      case 'cm-edit-payment-city':
        setCheckCoupon(true);
        setCity(e.target.value);
        break;
      case 'cm-edit-payment-postal-code':
        setCheckCoupon(true);
        setPostalCode(e.target.value);
        break;
      case 'cm-edit-payment-billing-email':    
        fields['cm-edit-payment-billing-email'] = e.target.value && !emailRegex.test(e.target.value);
        setBillingEmail(e.target.value);
        break;
    }
    setFieldValidations(fields);
  }

  const handleCardDetailsChange = (e) => {

    if (e.error) {
      setValidCard(false);
    }
    if (e.complete) {
      setValidCard(true);
    }
  }
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  // A custom hook that builds on useLocation to parse
// the query string for you.

  const getUpgradingPlan = () => {
    let selectedPlan = state.calcumatePlans.filter(plan => {
      const planName = query.get("plan") ? query.get("plan") : state.currentPlan.toLowerCase();
      return plan.thePlan.toLowerCase() === planName;
    });
    return selectedPlan?.[0];
  };

  const handleCardDetailsChangeSubmit = async () => {
    // save only if the payment method id is different from the value in the state
    if (paymentMethodId !== '' && paymentMethodId !== state.signedInUser.data.user?.billing?.paymentMethodId && validCard) {
      setSavingCard(true);
      const newPlan = getUpgradingPlan();
      // send new card details along with the user data to the API
      const sids = newPlan?.[`sid${state.currency}`] ? newPlan[`sid${state.currency}`] : newPlan?.sid
      const newSelectedPlan = sids?.[state.cmPlanPayRec ? 0 : 1];
      const updatedUserData = {
        ...state.signedInUser.data.user,
        billing: {
          firstName,
          lastName,
          address,
          country,
          company,
          coupon,
          paymentMethodId,
          city,
          postal_code: postalCode,
          billingEmail,
        },
      };
      if (newSelectedPlan) {
        updatedUserData.lastChosenSubscription = newSelectedPlan;
      }

      await axios.put(
        process.env.REACT_APP_API_URL + 'paymentdetails',
        updatedUserData,
        { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.signedInUser.token } }
      ).then(function (response) {
        if (response.status && response.status === 200 && response.data) {
          setUpdateSuccess(response.data.status ? 'true' : 'false');
          setUpdateError(!response.data.status ? response.data.error : '');
          if (response.data.status) {
            setSavingCard(false);
            // if the response was a success, update the user details in the state
            let newPlanDetails = {};
            if (newPlan) {
              let selectedPlan = [...state.calcumatePlans, ...state.calcumatePlansOld].filter(plan => {
                const sids = [...plan.sidUSD, ...plan.sidAUD || [], ...plan.sidGBP || [], ...plan.sidEUR || []]
                return sids.includes(newSelectedPlan);
              });
              const thePrices = selectedPlan[0][state.currency];
              const sids = selectedPlan[0][`sid${state.currency}`];
              newPlanDetails = {
                currentPlan: selectedPlan[0] ? selectedPlan[0].thePlan : 'cancelled',
                currentPlanPrice: selectedPlan[0] ?
                  thePrices[sids.indexOf(newSelectedPlan)] :
                  0,
                currentPlanPriceRec: sids?.indexOf(newSelectedPlan) === 1 ? "/mo" : "/yr",
              }
            }
            dispatchState({ type: 'root-state', payload: {
              ...newPlanDetails,
              showInactiveMessage: false,
              signedInUser: {
                ...state.signedInUser,
                data: {
                  ...state.signedInUser.data,
                  user: {...updatedUserData, stripeAccount: response.data.stripeAccount, active: true},
                  card: response.data.card,
                  lastChosenSubscription: newSelectedPlan,
                }
              }
            }});
            if (props.update) {
              props.setToModCard(false);
            }
            // if (newPlan && (newSelectedPlan !== state?.signedInUser?.data?.user?.lastChosenSubscription)) {
            //   history.push("/admin/my-account/my-subscription")
            // }
          } else {
            setSavingCard(false);
            setUpdateSuccess('false');
          }
        } else {
          setSavingCard(false);
          setUpdateSuccess('false');
          Sentry.captureMessage(`Saving payment error: ${JSON.stringify(response)}`);
        }

      }).catch(function (error) {
        setUpdateSuccess('false');
        setSavingCard(false);
        console.error(error);
        Sentry.captureException(error);
      });
    }
  }

  return (
    <form noValidate autoComplete="off" className={classes.standardForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {updateSuccess === 'true' ?
            <div className={classes.notification}>
              <br />
              <Alert severity="success">Update succesful!</Alert>

            </div>
            : ''}
          {updateSuccess === 'false' ?
            <div className={classes.notification}>
              <br />
              <Alert severity="error"> Something went wrong. Please try again.</Alert>

            </div>
            : ''}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2">New billing details</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel error={fieldValidations['cm-edit-payment-country']} htmlFor="cm-signup-country-label">Country *</InputLabel>
            <Select
              name="cm-edit-payment-country"
              value={country}
              variant="outlined"
              label="Country *"
              onChange={handleChange}
            >
              {state.accountCurrentCountryList.map((label, key) => (
                <MenuItem key={key} value={label.value}>{label.country}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField 
            required 
            name="cm-edit-payment-city" 
            label="City" 
            variant="outlined" 
            type="text" 
            fullWidth 
            onChange={handleChange} 
            value={city} 
            error={fieldValidations['cm-edit-payment-city']}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField 
            required 
            name="cm-edit-payment-postal-code" 
            label="Postal code" 
            variant="outlined" 
            type="text" 
            fullWidth 
            onChange={handleChange} 
            value={postalCode} 
            error={fieldValidations['cm-edit-payment-postal-code']}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField 
            required 
            name="cm-edit-payment-fname" 
            label="First name" 
            variant="outlined" 
            type="text" 
            fullWidth 
            onChange={handleChange} 
            value={firstName} 
            error={fieldValidations['cm-edit-payment-fname']}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField 
            required 
            name="cm-edit-payment-lname" 
            label="Last name" 
            variant="outlined" 
            type="text" 
            fullWidth 
            onChange={handleChange} 
            value={lastName} 
            error={fieldValidations['cm-edit-payment-lname']}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required 
            name="cm-edit-payment-billing-email" 
            label="Billing email" 
            variant="outlined" 
            type="text" 
            fullWidth 
            onChange={handleChange} 
            value={billingEmail} 
            error={fieldValidations['cm-edit-payment-billing-email']}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required 
            name="cm-edit-payment-address" 
            label="Address" 
            variant="outlined" 
            type="text" 
            fullWidth 
            onChange={handleChange} 
            value={address} 
            error={fieldValidations['cm-edit-payment-address']}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputProps={{ maxLength: 30 }}
            helperText={ `${(company || '').length} / 30 character limit` }
            name="cm-edit-payment-company" 
            label="Company name" 
            variant="outlined" 
            type="text" 
            fullWidth 
            onChange={handleChange} 
            value={company} 
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="cm-edit-coupon"
            name="cm-edit-coupon"
            label="Promo code"
            variant="outlined"
            type="text"
            fullWidth
            onChange={handleChange}
            value={coupon}
            error={!updating && (coupon && (!(coupon || '').match(couponRegexp)?.length === 1 || !couponIsValid)) || false}
            InputProps={{
              endAdornment: updating && (<CircularProgress color='inherit' size={20} />),
            }}
            helperText={message?.type === 'success' ?
              <span style={{color: '#4caf50'}}>{!updating && couponMessages?.[message?.msg]}</span> :
              !updating && couponMessages?.[message?.msg]}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2">New payment details</Typography>
        </Grid>
        <Grid item xs={12} className={{[classes.disabled]: isStripedDisabled}}>
          <Elements stripe={stripePromise}>
            <StripeCard
              handleNewPaymentMethod={id => handleNewPaymentMethod(id)} 
              handleCardDetailsChange={handleCardDetailsChange} 
              paymentDetails={paymentDetails}
            />
          </Elements>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Button
            onClick={props.update ? () => props.setToModCard(false) : () => { history.push("/admin/my-account") }}
            variant="outlined"
            className={classes.buttonSpacing}
            color="primary"
            size="large"
            fullWidth
          >
            Cancel
        </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Button
            onClick={handleCardDetailsChangeSubmit}
            variant="contained"
            className={classes.buttonSpacing}
            color="primary"
            size="large"
            fullWidth
            disabled={isFormDisabled}
          >
            {savingCard || updating ? <CircularProgress color='inherit' size={15} /> : 'Save details'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default NewPaymentComponent
