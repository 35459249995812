import React, {useContext, useEffect, useMemo, useState} from "react";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import InfoIcon from '@material-ui/icons/InfoRounded';
import ContactForm from "components/ContactForm/ContactForm";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Tooltip,
  Divider
} from "@material-ui/core";
import { emailRegex, isFreePlan } from '../../../helpers/validation';
// Core components

// Other components
import { useGlobalState } from "hooks/useGlobalState";

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
import stylesIntegrations from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import StripeCard from 'components/Stripe/Card';
import AdminPanels from "../../../components/AdminPanels/AdminPanels";
import { exist, notExist, serverError } from "../../../variables/messages"
import useCouponValidation from "../../../hooks/useCouponValidation";
import CircularProgress from "@material-ui/core/CircularProgress";
import {couponRegexp} from "../../../helpers/validation";
import CustomPricingWidget from "../../../components/CustomPricing/CustomPricingWidget";
import AgreementBlock from "components/AgreementBlock/AgreementBlock";
import classNames from 'classnames';
const useStyles = makeStyles(styles);
const useStylesIntegrations = makeStyles(stylesIntegrations);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const couponMessages = {
  success: exist,
  no: notExist,
  error: serverError
}

const PaymentStep = (props) => {
  const classes = useStyles();
  const classesInts = useStylesIntegrations();
  const dispatchState = useContext(useGlobalState.Dispatch);
  const [fieldValidations, setFieldValidations] = useState({});
  const [showPaymentDetails, setShowPaymentDetails] = useState(true);
  const [showCountactForms, setShowContactForm] = useState(false);
  const state = useContext(useGlobalState.State);
  const isSubscriptionFree = isFreePlan(state);
  const { theRef } = props
  const [
    setCheckCoupon,
    message,
    setMessage,
    couponIsValid,
    updating
  ] = useCouponValidation(state.userData.billing.coupon, state.signedInUser.token, props.setUpdating);

  /* eslint-disable */
  useEffect(() => {
    dispatchState({ type: 'integration-data', payload: { integrationStepperStep: 5 } }) // Set stepper step number 5
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
  }, []);
  /* eslint-enable */

  useMemo(() => {
    switch (state.currentPlan) {
      case 'Contact-us':
        setShowContactForm(true);
        setShowPaymentDetails(false);
        break;
      default:
        setShowContactForm(false);
        setShowPaymentDetails(true);
    }
  }, [
    state.currentPlan,
  ]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage(false);
  };
  
  const isStripeDisabled = useMemo(() => {
    return !state.userData.billing.city || 
      !state.userData.billing.country ||
      !state.userData.billing.postal_code ||
      !state.userData.billing.firstName ||
      !state.userData.billing.lastName || 
      !state.userData.billing.address
  }, [
    state.userData.billing.city,
    state.userData.billing.country,
    state.userData.billing.postal_code,
    state.userData.billing.firstName,
    state.userData.billing.lastName,
    state.userData.billing.address,
  ]);

  const handleNewPaymentMethod = id => {
    const obj = { billing: {...state.userData.billing} };
    obj.billing.paymentMethodId = id;
    dispatchState({ type: 'reg-state', payload: { userData: obj } });
  }

  const handleCardDetailsChange = (e) => {
    let obj = {
      billing: {
        creditCard: {
          valid: false
        }
      }
    }
    if (e.error) {
      obj.billing.creditCard.valid = false;
    }
    if (e.complete) {
      obj.billing.creditCard.valid = true;
    }
    dispatchState({ type: 'reg-state', payload: { userData: obj } });
  }

  const highlightedPlan = useMemo(() => {
    const index = state.calcumatePlans.findIndex(plan => plan.thePlan === state.currentPlan);
    return index === -1 ? 3 : index
  }, [state.currentPlan])

  const handleChange = e => {
    const obj = { billing: {} };
    if (e.target.name === 'coupon') {
      setCheckCoupon(true);
    }
    obj.billing[e.target.name] = e.target.value;
    dispatchState({ type: 'reg-state', payload: { userData: obj } });
    
    // if (e.target.name === 'company') {
    //   setFieldValidations({
    //     ...fieldValidations,
    //     [e.target.name]: !isValidPhoneNumber(e.target.value)
    //   });
    //   return true
    // }
    if (e.target.name === 'billingEmail') {
      setFieldValidations({
        ...fieldValidations,
        [e.target.name]: e.target.value && !emailRegex.test(e.target.value)
      });
      return true
    }
    
    setFieldValidations({
      ...fieldValidations,
      [e.target.name]: !e.target.value
    });
  }

  /* eslint-disable */
  useEffect(() => {
    dispatchState({ type: 'root-state', payload: { signUpStepperStep: 1 } }) // Set stepper step number 2
  }, []);
  /* eslint-enable */
  return (
    <form noValidate autoComplete="off" className={classes.standardForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AdminPanels
            theHeader={false}
            headerAction={false}
            fabCodeAction={false}
            fabCloneAction={false}
            fabEditAction={false}
            fabDeleteAction={false}
            panelTheme="dark"
          >
            <Typography variant="h5">Start your 30 day FREE trial</Typography>
            <Typography variant="body1">Your card won’t be charged during these first 30 days and you can cancel at any time prior.</Typography>
          </AdminPanels>
          <Grid item xs={12} style={{marginTop: 20}} className={classesInts.pricingPlans}>
            <CustomPricingWidget save setUpdating={props.setUpdating} highlightedPlan={highlightedPlan} />
          </Grid>
        </Grid>
        {
          showCountactForms && (
            <Grid item xs={12}>
              <ContactForm />
            </Grid>
          )
        }
        {
          (showPaymentDetails && !isSubscriptionFree) && (
            <>
              <Grid item xs={12}>
                <Typography variant="h5" component="h2">Payment details</Typography>
              </Grid>
              <Grid container spacing={3} className={{[classesInts.disabled]: state.currentPlan === 'Essential'}}>
                <Grid item xs={12}>
                  <Divider className={classes.customDividerOne} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h2">Billing Details</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth error={!state.userData.billing.country}>
                    <InputLabel htmlFor="cm-payment-country-label">Country *</InputLabel>
                    <Select
                      id="cm-payment-country"
                      value={state.userData.billing.country}
                      variant="outlined"
                      label="Country *"
                      name="country"
                      onChange={handleChange}
                    >
                      {state.accountCurrentCountryList.map((label, key) => (
                        <MenuItem key={key} value={label.value}>{label.country}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    id="p-payment-city"
                    name="city"
                    label="City *"
                    variant="outlined"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    value={state.userData.billing.city}
                    error={fieldValidations.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    id="p-payment-postal-code"
                    name="postal_code"
                    label="Postal Code *"
                    variant="outlined"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    value={state.userData.billing.postal_code}
                    error={fieldValidations.postal_code}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    id="p-payment-fname"
                    name="firstName"
                    label="First name *"
                    variant="outlined"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    value={state.userData.billing.firstName}
                    error={fieldValidations.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField 
                    id="p-payment-lname" 
                    name="lastName" 
                    label="Last name *" 
                    variant="outlined" 
                    type="text" 
                    fullWidth 
                    onChange={handleChange} 
                    value={state.userData.billing.lastName} 
                    error={fieldValidations.lastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField 
                    id="p-payment-billing-email-address" 
                    name="billingEmail" 
                    label="Billing email" 
                    variant="outlined" 
                    type="text" 
                    fullWidth 
                    onChange={handleChange} 
                    value={state.userData.billing.billingEmail}
                    error={fieldValidations.billingEmail}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField 
                    id="p-payment-address" 
                    name="address" 
                    label="Address *" 
                    variant="outlined" 
                    type="text" 
                    fullWidth 
                    onChange={handleChange} 
                    value={state.userData.billing.address} 
                    error={fieldValidations.address}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={11}>
                      <TextField 
                        id="p-payment-company" 
                        name="company" 
                        label="Business ID/Tax number *" 
                        variant="outlined" 
                        type="text" 
                        fullWidth 
                        onChange={handleChange} 
                        value={state.userData.billing.company} 
                        //error={fieldValidations.company}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip title="Your Business ID/Tax number will appear on your invoice for tax and accounting purposes">
                        <IconButton aria-label="delete">
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <TextField
                        id="p-coupon"
                        name="coupon"
                        error={!updating && (state.userData.billing.coupon && ((!state.userData.billing.coupon || '').match(couponRegexp)?.length === 1 || !couponIsValid))}
                        label="Promo code"
                        variant="outlined" type="text" fullWidth onChange={handleChange} value={state.userData.billing.coupon}
                        InputProps={{
                          endAdornment: updating && (<CircularProgress color='inherit' size={20} />),
                        }}
                        helperText={message?.type === 'success' ?
                          <span style={{color: '#4caf50'}}>{!updating && couponMessages?.[message?.msg]}</span> :
                          !updating && couponMessages?.[message?.msg]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.customDividerOne} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h2">Card Details</Typography>
                </Grid>
                <Grid item xs={12} className={{[classes.disabled]: isStripeDisabled}}>
                  {/* <StripeCardsSelection /> */}
                  <Elements stripe={stripePromise}>
                    <StripeCard 
                      handleNewPaymentMethod={id => handleNewPaymentMethod(id)} 
                      handleCardDetailsChange={handleCardDetailsChange} 
                      paymentDetails={state.userData.billing}              
                    />
                  </Elements>
                </Grid>
                <Grid item xs={12}>
                  <AgreementBlock />
                </Grid>
              </Grid>
            </>
          )
        }

      </Grid>
    </form>
  )
}

export default PaymentStep
