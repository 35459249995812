import React, {useContext, useState} from "react"
import classNames from "classnames"

import axios from "axios";

// @material-ui/core components
import { Fab, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Poppers from "@material-ui/core/Popper"
import Divider from "@material-ui/core/Divider"

// @material-ui/icons
import Person from "@material-ui/icons/PersonRounded"
import ContactSupport from "@material-ui/icons/ContactSupportRounded"

// core components
import { useGlobalState } from "hooks/useGlobalState"
import { Link } from "react-router-dom";

// styles
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle"

const useStyles = makeStyles(styles);

const AdminNavbarLinks = () => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const [openProfile, setOpenProfile] = React.useState(null);
  const state = useContext(useGlobalState.State);
  const isAdmin = state?.signedInUser?.data?.user?.isAdmin;
  const [fetching, setFetching] = useState(false);
  const exportReport = async event => {
    event.preventDefault();
    try {
      try {
        setFetching(true);
        const data = await axios.post(
          process.env.REACT_APP_API_URL + 'report',
          { "cukey": state.signedInUser.cukey },
          { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.signedInUser.token } });
        if (data?.data?.url) {
          window.open(process.env.REACT_APP_REPORTS_BUCKET + data?.data?.url,'_blank');
        }
      } catch (e) {
        console.log('Error:', e)
      }
    } catch (e) {
      console.log('Error: ', e)
    }
    setFetching(false);
  }

  const exportUserLoadDetails = async event => {
    event.preventDefault();
    setFetching(true);
    try {
      const data = await axios.post(
        process.env.REACT_APP_API_URL + 'userActivity',
        { "cukey": state.signedInUser.cukey },
        { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.signedInUser.token } });
      const fileName = `User activity-${new Date().toUTCString()}`
      import("export-from-json").then(({default: exportFromJSON}) => {
        const exportType =  exportFromJSON.types.csv
        exportFromJSON({
          data: (data?.data?.data || []),
          fileName,
          exportType
        });
      })
    } catch (e) {
      console.log('Error: ', e)
    }
    setFetching(false);
  }

  const exportUserBookmarkedResults = async event => {
    event.preventDefault();
    setFetching(true);
    try {
      const data = await axios.post(
        process.env.REACT_APP_API_URL + 'userActivity',
        { "cukey": state.signedInUser.cukey, loadingPlan: true },
        { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.signedInUser.token } });
      const fileName = `User activity-${new Date().toUTCString()}`
      import("export-from-json").then(({default: exportFromJSON}) => {
        const exportType =  exportFromJSON.types.csv
        exportFromJSON({
          data: (data?.data?.data || []),
          fileName,
          exportType
        });
      })
    } catch (e) {
      console.log('Error: ', e)
    }
    setFetching(false);
  }

  const exportPromoCodes = async event => {
    event.preventDefault();
    setFetching(true);
    try {
      try {
        const data = await axios.post(
          process.env.REACT_APP_API_URL + 'promoCodes',
          { "cukey": state.signedInUser.cukey },
          { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.signedInUser.token } });
        const fileName = `Report-${state.signedInUser.data.user.email}`
        import("export-from-json").then(({default: exportFromJSON}) => {
          const exportType =  exportFromJSON.types.csv
          exportFromJSON({
            data: (data?.data?.data || []),
            fileName,
            exportType
          });
        })
      } catch (e) {
        console.log('Error:', e)
      }
    } catch (e) {
      console.log('Error: ', e)
    }
    setFetching(false);
  }

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  return (
    <Grid
      container
      direction="row"
      justify="flex-end"
      alignItems="center"
    >
      <Grid item>
        <Fab
          color="primary"
          aria-label="Help"
          aria-haspopup="true"
          size="medium"
          className={classes.buttonLink}
          component={Link}
          to="/admin/support"
        >
          <ContactSupport className={classes.icons} />
        </Fab>
      </Grid>
      <Grid item>
        <Fab
          color="primary"
          aria-label="User profile"
          aria-haspopup="true"
          size="medium"
          onClick={handleClickProfile}
          className={classes.buttonLink}
          data-cy={'header-menu--admin-panel'}
        >
          <Person className={classes.icons} />
        </Fab>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                      component={Link}
                      to="/admin/my-account"
                      data-cy={'menu__link--my-account'}
                    >
                      My account
                    </MenuItem>
                    <Divider light />
                    {
                      isAdmin &&
                        <>
                          <MenuItem
                            onClick={exportReport}
                            className={classes.dropdownItem}
                            component={Link}
                            disabled={fetching}
                            to="/"
                          >
                            Report
                          </MenuItem>
                          <MenuItem
                            onClick={exportUserLoadDetails}
                            className={classes.dropdownItem}
                            component={Link}
                            disabled={fetching}
                            to="/"
                          >
                            Requested lead details
                          </MenuItem>
                          <MenuItem
                            onClick={exportUserBookmarkedResults}
                            className={classes.dropdownItem}
                            component={Link}
                            disabled={fetching}
                            to="/"
                          >
                            Bookmarked results
                          </MenuItem>
                          <MenuItem
                            onClick={exportPromoCodes}
                            className={classes.dropdownItem}
                            component={Link}
                            disabled={fetching}
                            to="/"
                          >
                            Promo codes
                          </MenuItem>
                        </>
                    }
                    {
                      isAdmin && <Divider light />
                    }
                    <MenuItem
                      onClick={() => { dispatchState({ type: 'root-state', payload: { isLoggedIn: false } }) }}
                      className={classes.dropdownItem}
                      component={Link}
                      to="/"
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </Grid>
    </Grid>
  );
}

export default AdminNavbarLinks
