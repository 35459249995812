// Self-storage Prices

const usdPriceRangeM = new Map(); 
usdPriceRangeM.set([0,1], 39);
usdPriceRangeM.set([2,2], 30);
usdPriceRangeM.set([3,10], 20);
usdPriceRangeM.set([11,100], 15);
usdPriceRangeM.set([101, Infinity], 10);

const audPriceRangeM = new Map(); 
audPriceRangeM.set([0,1], 59);
audPriceRangeM.set([2,2], 50);
audPriceRangeM.set([3,10], 35);
audPriceRangeM.set([11,100], 25);
audPriceRangeM.set([101, Infinity], 20);

const eurPriceRangeM = new Map(); 
eurPriceRangeM.set([0,1], 39);
eurPriceRangeM.set([2,2], 30);
eurPriceRangeM.set([3,10], 20);
eurPriceRangeM.set([11,100], 15);
eurPriceRangeM.set([101, Infinity], 10);

const gbpPriceRangeM = new Map(); 
gbpPriceRangeM.set([0,1], 34);
gbpPriceRangeM.set([2,2], 25);
gbpPriceRangeM.set([3,10], 20);
gbpPriceRangeM.set([11,100], 15);
gbpPriceRangeM.set([101, Infinity], 10);

const usdPriceRangeY = new Map(); 
usdPriceRangeY.set([0,1], 421.20);
usdPriceRangeY.set([2,2], 324.00);
usdPriceRangeY.set([3,10], 216.00);
usdPriceRangeY.set([11,100], 162.00);
usdPriceRangeY.set([101, Infinity], 108.00);

const audPriceRangeY = new Map(); 
audPriceRangeY.set([0,1], 637.20);
audPriceRangeY.set([2,2], 540.00);
audPriceRangeY.set([3,10], 378.00);
audPriceRangeY.set([11,100], 270.00);
audPriceRangeY.set([101, Infinity], 216.00);

const eurPriceRangeY = new Map(); 
eurPriceRangeY.set([0,1], 421.20);
eurPriceRangeY.set([2,2], 324.00);
eurPriceRangeY.set([3,10], 216.00);
eurPriceRangeY.set([11,100], 162.00);
eurPriceRangeY.set([101, Infinity], 108.00);

const gbpPriceRangeY = new Map(); 
gbpPriceRangeY.set([0,1], 367.20);
gbpPriceRangeY.set([2,2], 270.00);
gbpPriceRangeY.set([3,10], 216.00);
gbpPriceRangeY.set([11,100], 162.00);
gbpPriceRangeY.set([101, Infinity], 108.00);

// Container-storage Prices

const usdContainerPriceRangeM = new Map(); 
usdContainerPriceRangeM.set([0,1], 39);
usdContainerPriceRangeM.set([2,2], 30);
usdContainerPriceRangeM.set([3,10], 20);
usdContainerPriceRangeM.set([11,100], 15);
usdContainerPriceRangeM.set([101, Infinity], 10);

const audContainerPriceRangeM = new Map(); 
audContainerPriceRangeM.set([0,1], 59);
audContainerPriceRangeM.set([2,2], 50);
audContainerPriceRangeM.set([3,10], 35);
audContainerPriceRangeM.set([11,100], 25);
audContainerPriceRangeM.set([101, Infinity], 20);

const eurContainerPriceRangeM = new Map(); 
eurContainerPriceRangeM.set([0,1], 39);
eurContainerPriceRangeM.set([2,2], 30);
eurContainerPriceRangeM.set([3,10], 20);
eurContainerPriceRangeM.set([11,100], 15);
eurContainerPriceRangeM.set([101, Infinity], 10);

const gbpContainerPriceRangeM = new Map(); 
gbpContainerPriceRangeM.set([0,1], 34);
gbpContainerPriceRangeM.set([2,2], 25);
gbpContainerPriceRangeM.set([3,10], 20);
gbpContainerPriceRangeM.set([11,100], 15);
gbpContainerPriceRangeM.set([101, Infinity], 10);

const usdContainerPriceRangeY = new Map(); 
usdContainerPriceRangeY.set([0,1], 421.20);
usdContainerPriceRangeY.set([2,2], 324.00);
usdContainerPriceRangeY.set([3,10], 216.00);
usdContainerPriceRangeY.set([11,100], 162.00);
usdContainerPriceRangeY.set([101, Infinity], 108.00);

const audContainerPriceRangeY = new Map(); 
audContainerPriceRangeY.set([0,1], 637.20);
audContainerPriceRangeY.set([2,2], 540.00);
audContainerPriceRangeY.set([3,10], 378.00);
audContainerPriceRangeY.set([11,100], 270.00);
audContainerPriceRangeY.set([101, Infinity], 216.00);

const eurContainerPriceRangeY = new Map(); 
eurContainerPriceRangeY.set([0,1], 421.20);
eurContainerPriceRangeY.set([2,2], 324.00);
eurContainerPriceRangeY.set([3,10], 216.00);
eurContainerPriceRangeY.set([11,100], 162.00);
eurContainerPriceRangeY.set([101, Infinity], 108.00);

const gbpContainerPriceRangeY = new Map(); 
gbpContainerPriceRangeY.set([0,1], 367.20);
gbpContainerPriceRangeY.set([2,2], 270.00);
gbpContainerPriceRangeY.set([3,10], 216.00);
gbpContainerPriceRangeY.set([11,100], 162.00);
gbpContainerPriceRangeY.set([101, Infinity], 108.00);

// Movers

const usdMoversPriceRangeM = new Map(); 
usdMoversPriceRangeM.set([0,1], 89.00);
usdMoversPriceRangeM.set([2,2], 30);
usdMoversPriceRangeM.set([3,10], 20);
usdMoversPriceRangeM.set([11,100], 15);
usdMoversPriceRangeM.set([101, Infinity], 10);

const audMoversPriceRangeM = new Map(); 
audMoversPriceRangeM.set([0,1], 139.00);
audMoversPriceRangeM.set([2,2], 50);
audMoversPriceRangeM.set([3,10], 35);
audMoversPriceRangeM.set([11,100], 25);
audMoversPriceRangeM.set([101, Infinity], 20);

const eurMoversPriceRangeM = new Map(); 
eurMoversPriceRangeM.set([0,1], 79.00);
eurMoversPriceRangeM.set([2,2], 30);
eurMoversPriceRangeM.set([3,10], 20);
eurMoversPriceRangeM.set([11,100], 15);
eurMoversPriceRangeM.set([101, Infinity], 10);

const gbpMoversPriceRangeM = new Map(); 
gbpMoversPriceRangeM.set([0,1], 69.00);
gbpMoversPriceRangeM.set([2,2], 25);
gbpMoversPriceRangeM.set([3,10], 20);
gbpMoversPriceRangeM.set([11,100], 15);
gbpMoversPriceRangeM.set([101, Infinity], 10);

const usdMoversPriceRangeY = new Map(); 
usdMoversPriceRangeY.set([0,1], 961.00);
usdMoversPriceRangeY.set([2,2], 324.00);
usdMoversPriceRangeY.set([3,10], 216.00);
usdMoversPriceRangeY.set([11,100], 162.00);
usdMoversPriceRangeY.set([101, Infinity], 108.00);

const audMoversPriceRangeY = new Map(); 
audMoversPriceRangeY.set([0,1], 1501.00);
audMoversPriceRangeY.set([2,2], 540.00);
audMoversPriceRangeY.set([3,10], 378.00);
audMoversPriceRangeY.set([11,100], 270.00);
audMoversPriceRangeY.set([101, Infinity], 216.00);

const eurMoversPriceRangeY = new Map(); 
eurMoversPriceRangeY.set([0,1], 853.00);
eurMoversPriceRangeY.set([2,2], 324.00);
eurMoversPriceRangeY.set([3,10], 216.00);
eurMoversPriceRangeY.set([11,100], 162.00);
eurMoversPriceRangeY.set([101, Infinity], 108.00);

const gbpMoversPriceRangeY = new Map(); 
gbpMoversPriceRangeY.set([0,1], 745.00);
gbpMoversPriceRangeY.set([2,2], 270.00);
gbpMoversPriceRangeY.set([3,10], 216.00);
gbpMoversPriceRangeY.set([11,100], 162.00);
gbpMoversPriceRangeY.set([101, Infinity], 108.00);

// Portable

const usdPortablePriceRangeM = new Map(); 
usdPortablePriceRangeM.set([0,1], 89.00);
usdPortablePriceRangeM.set([2,2], 30);
usdPortablePriceRangeM.set([3,10], 20);
usdPortablePriceRangeM.set([11,100], 15);
usdPortablePriceRangeM.set([101, Infinity], 10);

const audPortablePriceRangeM = new Map(); 
audPortablePriceRangeM.set([0,1], 139.00);
audPortablePriceRangeM.set([2,2], 50);
audPortablePriceRangeM.set([3,10], 35);
audPortablePriceRangeM.set([11,100], 25);
audPortablePriceRangeM.set([101, Infinity], 20);

const eurPortablePriceRangeM = new Map(); 
eurPortablePriceRangeM.set([0,1], 79.00);
eurPortablePriceRangeM.set([2,2], 30);
eurPortablePriceRangeM.set([3,10], 20);
eurPortablePriceRangeM.set([11,100], 15);
eurPortablePriceRangeM.set([101, Infinity], 10);

const gbpPortablePriceRangeM = new Map(); 
gbpPortablePriceRangeM.set([0,1], 69.00);
gbpPortablePriceRangeM.set([2,2], 25);
gbpPortablePriceRangeM.set([3,10], 20);
gbpPortablePriceRangeM.set([11,100], 15);
gbpPortablePriceRangeM.set([101, Infinity], 10);

const usdPortablePriceRangeY = new Map(); 
usdPortablePriceRangeY.set([0,1], 961.00);
usdPortablePriceRangeY.set([2,2], 324.00);
usdPortablePriceRangeY.set([3,10], 216.00);
usdPortablePriceRangeY.set([11,100], 162.00);
usdPortablePriceRangeY.set([101, Infinity], 108.00);

const audPortablePriceRangeY = new Map(); 
audPortablePriceRangeY.set([0,1], 1501.00);
audPortablePriceRangeY.set([2,2], 540.00);
audPortablePriceRangeY.set([3,10], 378.00);
audPortablePriceRangeY.set([11,100], 270.00);
audPortablePriceRangeY.set([101, Infinity], 216.00);

const eurPortablePriceRangeY = new Map(); 
eurPortablePriceRangeY.set([0,1], 853.00);
eurPortablePriceRangeY.set([2,2], 324.00);
eurPortablePriceRangeY.set([3,10], 216.00);
eurPortablePriceRangeY.set([11,100], 162.00);
eurPortablePriceRangeY.set([101, Infinity], 108.00);

const gbpPortablePriceRangeY = new Map(); 
gbpPortablePriceRangeY.set([0,1], 745.00);
gbpPortablePriceRangeY.set([2,2], 270.00);
gbpPortablePriceRangeY.set([3,10], 216.00);
gbpPortablePriceRangeY.set([11,100], 162.00);
gbpPortablePriceRangeY.set([101, Infinity], 108.00);

// Kiosks

const usdKiosksPriceRangeM = new Map(); 
usdKiosksPriceRangeM.set([0,1], 39);
usdKiosksPriceRangeM.set([2,2], 30);
usdKiosksPriceRangeM.set([3,10], 20);
usdKiosksPriceRangeM.set([11,100], 15);
usdKiosksPriceRangeM.set([101, Infinity], 10);

const audKiosksPriceRangeM = new Map(); 
audKiosksPriceRangeM.set([0,1], 59);
audKiosksPriceRangeM.set([2,2], 50);
audKiosksPriceRangeM.set([3,10], 35);
audKiosksPriceRangeM.set([11,100], 25);
audKiosksPriceRangeM.set([101, Infinity], 20);

const eurKiosksPriceRangeM = new Map(); 
eurKiosksPriceRangeM.set([0,1], 39);
eurKiosksPriceRangeM.set([2,2], 30);
eurKiosksPriceRangeM.set([3,10], 20);
eurKiosksPriceRangeM.set([11,100], 15);
eurKiosksPriceRangeM.set([101, Infinity], 10);

const gbpKiosksPriceRangeM = new Map(); 
gbpKiosksPriceRangeM.set([0,1], 34);
gbpKiosksPriceRangeM.set([2,2], 25);
gbpKiosksPriceRangeM.set([3,10], 20);
gbpKiosksPriceRangeM.set([11,100], 15);
gbpKiosksPriceRangeM.set([101, Infinity], 10);

const usdKiosksPriceRangeY = new Map(); 
usdKiosksPriceRangeY.set([0,1], 421.00);
usdKiosksPriceRangeY.set([2,2], 324.00);
usdKiosksPriceRangeY.set([3,10], 216.00);
usdKiosksPriceRangeY.set([11,100], 162.00);
usdKiosksPriceRangeY.set([101, Infinity], 108.00);

const audKiosksPriceRangeY = new Map(); 
audKiosksPriceRangeY.set([0,1], 637.00);
audKiosksPriceRangeY.set([2,2], 540.00);
audKiosksPriceRangeY.set([3,10], 378.00);
audKiosksPriceRangeY.set([11,100], 270.00);
audKiosksPriceRangeY.set([101, Infinity], 216.00);

const eurKiosksPriceRangeY = new Map(); 
eurKiosksPriceRangeY.set([0,1], 421.00);
eurKiosksPriceRangeY.set([2,2], 324.00);
eurKiosksPriceRangeY.set([3,10], 216.00);
eurKiosksPriceRangeY.set([11,100], 162.00);
eurKiosksPriceRangeY.set([101, Infinity], 108.00);

const gbpKiosksPriceRangeY = new Map(); 
gbpKiosksPriceRangeY.set([0,1], 367.00);
gbpKiosksPriceRangeY.set([2,2], 270.00);
gbpKiosksPriceRangeY.set([3,10], 216.00);
gbpKiosksPriceRangeY.set([11,100], 162.00);
gbpKiosksPriceRangeY.set([101, Infinity], 108.00);

export {
  usdPriceRangeM,
  audPriceRangeM,
  eurPriceRangeM,
  gbpPriceRangeM,
  usdPriceRangeY,
  audPriceRangeY,
  eurPriceRangeY,
  gbpPriceRangeY,
  usdContainerPriceRangeM,
  audContainerPriceRangeM,
  eurContainerPriceRangeM,
  gbpContainerPriceRangeM,
  usdContainerPriceRangeY,
  audContainerPriceRangeY,
  eurContainerPriceRangeY,
  gbpContainerPriceRangeY,
  usdMoversPriceRangeM,
  audMoversPriceRangeM,
  eurMoversPriceRangeM,
  gbpMoversPriceRangeM,
  usdMoversPriceRangeY,
  audMoversPriceRangeY,
  eurMoversPriceRangeY,
  gbpMoversPriceRangeY,
  usdPortablePriceRangeM,
  audPortablePriceRangeM,
  eurPortablePriceRangeM,
  gbpPortablePriceRangeM,
  usdPortablePriceRangeY,
  audPortablePriceRangeY,
  eurPortablePriceRangeY,
  gbpPortablePriceRangeY,
  usdKiosksPriceRangeM,
  audKiosksPriceRangeM,
  eurKiosksPriceRangeM,
  gbpKiosksPriceRangeM,
  usdKiosksPriceRangeY,
  audKiosksPriceRangeY,
  eurKiosksPriceRangeY,
  gbpKiosksPriceRangeY,
}