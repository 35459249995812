import { primaryColour } from "assets/jss/material-dashboard-react"

const muiFabStyle = {
  MuiFab: {
    primary: {
      color: primaryColour[900],
      backgroundColor: primaryColour[50],
      boxShadow: "none",
      "&:hover": {
        backgroundColor: primaryColour[100],
        boxShadow: "none"
      }
    }
  }
}

export default muiFabStyle
