import React from 'react';
// @material-ui/core
import { Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle";
const useStyles = makeStyles(styles);

export default () => {
  const classes = useStyles();
  return (
    <Typography component="h3" className={classes.mb1}>
      By proceeding, you agree to the <a href={window.location.origin + '/terms-of-service'} target={'_blank'}>Calcumate Terms & Conditions</a> and  <a href={window.location.origin + '/privacy-policy'} target={'_blank'}>Privacy Policy</a> and to receiving marketing communications from Calcumate.
    </Typography>
  );
}