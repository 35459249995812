import React, {useMemo, useState} from "react";

// @material-ui/core components
import {
  Dialog,
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
  Fab,
  TextField,
  CircularProgress,
  makeStyles,
  Select,Grid,Typography,Switch
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseRounded from "@material-ui/icons/CloseRounded";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { addUnitOnlyUniq } from '../../helpers/SaveIntegration';
// styles
import classNames from 'classnames';
import styles from "assets/jss/material-dashboard-react/components/adminIntegrationItemStyle";
import stylesIntegration from "assets/jss/material-dashboard-react/layouts/integrationStyle"

import { saveIntegration } from "../../helpers/SaveIntegration";
import {loadAll} from "../../helpers/loadAll";
import {mapPlansLimitations} from "../../variables/InitState";
import DisabledFieldPlaceholder from "../../views/FreeVersion/DisabledFieldPlaceholder";

const useStyles = makeStyles(styles);
const useStylesIn = makeStyles(stylesIntegration);

const CustomNameDialog = ({
  isOpen,
  defaultCustomName,
  itemThumbnail,
  itemLibraryEntryIndex,
  state,
  onClose,
  dispatchState,
  defaultSize,
  weight,
  defaultWeight,
  elem
}) => {
  const classes = useStyles();
  const classesInt = useStylesIn();

  const [isSaving, setIsSaving] = useState(false);
  const [hasSavingError, setHasSavingError] = useState(false);
  const [customName, setCustomName] = useState(defaultCustomName);
  const restrictions = useMemo(() => mapPlansLimitations?.[state.currentPlan] || [], []);
  const [scale, setScale] = useState(defaultSize);
  const [scaleWeight, setScaleWeight] = useState(defaultWeight);

  const handleSave = async () => {
    const update = (await import("immutability-helper")).default;
    const newItemLibrary = update(state.integrationData.itemLibrary, {
      [itemLibraryEntryIndex]: { customName: { $set: customName }, scale:  { $set: scale }, scaleWeight: {$set: scaleWeight} },
    });
    dispatchState({
      type: "integration-data",
      payload: { itemLibrary: newItemLibrary },
    });
    setIsSaving(true);
    const response = await saveIntegration({
      ...state,
      integrationData: {
        ...state.integrationData,
        itemLibrary: newItemLibrary,
      },
    }, false, dispatchState);
    await loadAll(state, dispatchState, false);
    setIsSaving(false);

    if (response?.error) {
      setHasSavingError(true);
    } else {
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} className={classes.customDialog}>
      <Fab
        color="primary"
        aria-label="Edit"
        size="small"
        className={classes.closeFab}
        onClick={onClose}
      >
        <CloseRounded />
      </Fab>

      <DialogContent className={classes.customDialogContent}>
        <img
          src={itemThumbnail}
          className={classes.customDialogImage}
          alt=""
          role="presentation"
        />

        {hasSavingError && (
          <Alert
            severity="error"
            onClose={() => setHasSavingError(false)}
            style={{ marginBottom: "1rem" }}
          >
            There was an issue saving, please try again later.
          </Alert>
        )}

        <DialogContentText id="alert-dialog-description" component="div">
          <TextField
            id="p-integration-item-new-name"
            label="Custom item name"
            variant="outlined"
            type="text"
            helperText="Empty field will reset to default name"
            value={customName}
            onChange={(e) => {
              setCustomName(e.target.value);
            }}
            fullWidth
            disabled={!restrictions.includes('itemLibraryCustomNames')}
          />
          <FormControl variant="outlined" className={classNames(classes.formControl, {[classesInt.disabledField]: !restrictions.includes('itemLibrarySizes')})} style={{width: '100%', marginTop: 20, minWidth: 86}} >
            <InputLabel id="size-item-select-outlined-label">Size  </InputLabel>
            <Select
              labelId="size-item-select-outlined-label"
              id="size-item-select-outlined-label"
              value={!restrictions.includes('itemLibrarySizes') ? ' ' : scale || ''}
              onChange={event => setScale(event.target.value)}
              label="Size  "
              name={'size  '}
              style={{textAlign: 'left'}}
              disabled={!restrictions.includes('itemLibrarySizes')}
            >
              <MenuItem value={-30}>-30%</MenuItem>
              <MenuItem value={-20}>-20%</MenuItem>
              <MenuItem value={-10}>-10%</MenuItem>
              <MenuItem value="">
                <em>Default size</em>
              </MenuItem>
              <MenuItem value={10}>+10%</MenuItem>
              <MenuItem value={20}>+20%</MenuItem>
              <MenuItem value={30}>+30%</MenuItem>
            </Select>
            { !restrictions.includes('itemLibrarySizes') && <DisabledFieldPlaceholder feature={'itemLibrarySizes'} updating={false} /> }
          </FormControl>
          {
            weight && (
              <FormControl variant="outlined" className={classNames(classes.formControl, {[classesInt.disabledField]: !restrictions.includes('integrationShowItemWeight')})} style={{width: '100%', marginTop: 20, minWidth: 86}} >
                <InputLabel id="size-item-select-outlined-label">Weight</InputLabel>
                <Select
                  labelId="weight-item-select-outlined-label"
                  id="weight-item-select-outlined-label"
                  value={!restrictions.includes('integrationShowItemWeight') ? ' ' : scaleWeight || ''}
                  onChange={event => setScaleWeight(event.target.value)}
                  label="Weight"
                  name={'weight'}
                  style={{textAlign: 'left'}}
                  disabled={!restrictions.includes('integrationShowItemWeight')}
                >
                  <MenuItem value={-30}>-30%</MenuItem>
                  <MenuItem value={-20}>-20%</MenuItem>
                  <MenuItem value="">
                    <em>Default weight</em>
                  </MenuItem>
                  <MenuItem value={20}>+20%</MenuItem>
                  <MenuItem value={30}>+30%</MenuItem>
                </Select>
                {!restrictions.includes('integrationShowItemWeight') && <DisabledFieldPlaceholder feature={'integrationShowItemWeight'} updating={false} /> }
              </FormControl>
            )
          }
        </DialogContentText>
          <FormControl 
            variant="outlined" 
            className={
              classNames(
                classes.formControl, 
                {
                  [classesInt.disabledField]: !restrictions.includes('integrationSelectedItemsIntoURL')}
                  )
            } 
            style={{width: '100%', marginTop: 20, minWidth: 86, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}} 
          >
          <Grid item>
            <Typography variant="subtitle2">Include items qty in URL parameters?</Typography>
          </Grid>
          <Grid item>
            {
              <Switch
                checked={(state.integrationData.integrationSelectedItemsIntoURL || []).includes(elem.product)}
                onChange={() => dispatchState({ type: 'integration-data', payload: { integrationSelectedItemsIntoURL: addUnitOnlyUniq(elem.product, state.integrationData.integrationSelectedItemsIntoURL || [])} })}
                color="primary"
                name="selectedItemsIntoURL"
                inputProps={{ 'aria-label': 'Selected Items Into URL' }}
              />
            }
          </Grid>
          { !restrictions.includes('integrationSelectedItemsIntoURL') && <DisabledFieldPlaceholder feature={'integrationSelectedItemsIntoURL'} updating={false} /> }
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleSave}
          size="medium"
          variant="contained"
          color="primary"
          fullWidth
          disabled={isSaving}
        >
          {isSaving ? (
            <CircularProgress color="inherit" size={21} />
          ) : (
            "Save item name"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomNameDialog;
