import React from 'react';
import Alert from '@material-ui/lab/Alert';
import {Grid} from "@material-ui/core";
import {useHistory} from "react-router-dom";

export default () => {
  let history = useHistory();
  const redirectToSubscription = e => {
    e.preventDefault();
    history.push("/admin/my-account/my-subscription", {builder: true});
  }
  return (
    <Grid item xs={12}>
      <Alert severity="info">This feature is disabled for you pricing plan. You can update your subscription by this link:
        <a onClick={redirectToSubscription} to="/admin/my-account/my-subscription" style={{pointerEvents: 'auto', cursor: 'pointer'}} variant="contained" size="small" color="primary"> UPGRADE NOW</a>
      </Alert>
    </Grid>
  )
}
