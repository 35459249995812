import React, {memo} from "react";
import classNames from "classnames";

// Other components
import AnchorLinkComponent from "./AnchorLinkComponent";
import elipseShine from "assets/img/cm-logotype.svg";

//Styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/homeHeaderStyle";
const useStyles = makeStyles(styles);

export default memo(({
  headerOn,
}) => {
  const classes = useStyles();
  return (
    <AnchorLinkComponent href="#main-section">
      <img
        src={elipseShine}
        className={classNames({ [classes.whiteLogo]: !headerOn })}
        alt="Calcumate"
        data-cy={'logo'}
      />
    </AnchorLinkComponent>
  );
})