import React, {useContext, useEffect, useMemo, useState} from "react"

import FormSettings from "./FormSettings";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import {
  Grid,
  TextField,
  Button,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  Popover,
  Tooltip, Fab
} from "@material-ui/core"
import HelpRounded from "@material-ui/icons/HelpRounded"
import {emailRegex, regexp} from '../../../helpers/validation'
// Core components
import { useGlobalState } from "hooks/useGlobalState"

// Other components
import { saveIntegration } from '../../../helpers/SaveIntegration';
// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import CircularProgress from "@material-ui/core/CircularProgress";
import EditRounded from "@material-ui/icons/EditRounded";
import {mapPlansLimitations} from "../../../variables/InitState";
import PayButton from "../../FreeVersion/Button";
import AddRounded from "@material-ui/icons/AddRounded";
import ClearRounded from "@material-ui/icons/ClearRounded";
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles(styles)

const OptionStepAdvanced = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const { theRef, updating, setUpdating } = props
  const [ctaUrlPopAdv, setCtaUrlPopAdv] = useState(null)
  const [ctaParamsPop, setCtaParamsPop] = useState(null)
  const [ctaIdPop, setCtaIdPop] = useState(null);
  const [ctaQuantityPop, setCtaQuantityPop] = useState(null)
  const [ctaQuantityMultiplePop, setCtaQuantityMultiplePop] = useState(null)
  const [loadDetailsPop, setLoadDetailsPop] = useState(null)
  const [loadingPlanPop, setLoadingPlanPop] = useState(null)
  const [showLoadingButtonToolTip, setShowLoadingButtonToolTip] = useState(null)
  const restrictions = useMemo(() => {
    let restrictionList = mapPlansLimitations?.[state.currentPlan] || [];
    //enable some features for some users
    if ([
      'accounts@cheshire-storage.co.uk', 
    ].includes(state?.signedInUser?.data?.user?.email)) {
      if (!restrictionList.includes('integrationIncQty')) {
        restrictionList.push('integrationIncQty');
      }
      if (!restrictionList.includes('integrationCtaShouldIncludeUnitQuantity')) {
        restrictionList.push('integrationCtaShouldIncludeUnitQuantity');
      }
    }
    //state.signedInUser.data.user.email
    return restrictionList;
  }, [state?.signedInUser?.data?.user?.email]);
  const isLoadingPlanEmailValid = useMemo(() => emailRegex.test(state.integrationData.integrationLoadingCustomerEmail), [state.integrationData.integrationLoadingCustomerEmail])
  const isLoadGenEmailValid = useMemo(() => emailRegex.test(state.integrationData.integrationLoadDetailsEmail), [state.integrationData.integrationLoadDetailsEmail])


  /* eslint-disable */
  useEffect(() => {
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
  }, []);
  /* eslint-enable */

  const isDisabledButton = useMemo(()=> (
    state?.integrationDataOptions?.updatingItems ||
    state?.integrationData?.integrationActiveLanguage === "other" ||
    !state?.integrationData?.integrationName ||
    state?.submittingUserData ||
    state?.integrationData?.integrationCtaURL === "" ||
    state?.integrationData?.integrationDomain === "" ||
    (state?.integrationData?.integrationCtaURL !== "" && !state?.integrationData?.ctaUrlValid) ||
    (state?.integrationData?.integrationDomain !== "" && !state?.integrationData?.domainUrlValid) ||
    state?.integrationData?.integrationMeasurement === "" || state?.integrationData?.integrationStorageType === ""
  ), [state.integrationData])

  const qtyURL = () => { return state?.integrationData?.integrationIncQty ? "cm-qty={qty}" : "" }
  const idURL = () => { return state?.integrationData?.integrationIncID ? "cm-id={id}" : "" }
  const volumeURL = () => {
    return state?.integrationData?.integrationIncVolume ? `${(state?.integrationData?.integrationIncID ||
      state?.integrationData?.integrationIncQty) ? '&' : ''}cm-volume={totalVolume}` : ""
  }
  const usedVolumeURL = () => {
    return state?.integrationData?.integrationIncUsedVolume ? `${
      (state?.integrationData?.integrationIncID ||
      state?.integrationData?.integrationIncQty || state?.integrationData?.integrationIncVolume) ? '&' : ''
    }cm-used-volume={usedVolume}` : ""
  }
  const qryURL = () => {
    return state?.integrationData?.integrationIncID ||
    state?.integrationData?.integrationIncQty ||
    state?.integrationData?.integrationIncUsedVolume ||
    state?.integrationData?.integrationIncVolume ? "?" : ""
  }
  const midURL = () => { return state?.integrationData?.integrationIncID && state?.integrationData?.integrationIncQty ? "&" : "" }

  const handleSave = async () => {
    addLoadDetailsEmail();
    addLoadingPlanEmail();
    setUpdating(true);
    try {
      await saveIntegration(state, false, dispatchState);
      dispatchState({ type: "integration-data", payload: { integrationSecPanel: false } });
    } catch (e) {
      console.log('Advanced option saving error: ', e);
    }
    setUpdating(false);
  };

  if (state.integrationData.integrationThirdPanel && state.integrationData.integrationFormType) {
    return (
      <FormSettings
        type={state.integrationData.integrationFormType}
        updating={updating}
        setUpdating={setUpdating}
        closePanel={() => dispatchState({ type: 'integration-data', payload: {integrationThirdPanel: false, integrationFormType: null}})}
      />
    )
  }

  const validateURL = url => {

    // check whether the user is adding a url and not a phone number
    if (!state.integrationData.integrationCTAType) {
      if (regexp.test(url)) {
        dispatchState({ type: 'integration-data', payload: { ctaUrlValid: true } });
      } else {
        dispatchState({ type: 'integration-data', payload: { ctaUrlValid: false } });
      }
    } else {
      dispatchState({ type: 'integration-data', payload: { ctaUrlValid: true } });
    }
    dispatchState({ type: 'integration-data', payload: { integrationCtaURL: url } })
  }

  const addLoadingPlanEmail = event => {

    if (
      (state.integrationData.integrationLoadingCustomerEmails || []).includes(state.integrationData.integrationLoadingCustomerEmail) ||
      !emailRegex.test(state.integrationData.integrationLoadingCustomerEmail))
      return

    dispatchState({
      type: 'integration-data',
      payload: {
        integrationLoadingCustomerEmails: [
          ...(state.integrationData.integrationLoadingCustomerEmails || []),
          state.integrationData.integrationLoadingCustomerEmail
        ],
        integrationLoadingCustomerEmail: ''
      }
    })
  }

  const addLoadDetailsEmail = event => {
    if (
      (state.integrationData.integrationLoadDetailsEmails || []).includes(state.integrationData.integrationLoadDetailsEmail) ||
      !emailRegex.test(state.integrationData.integrationLoadDetailsEmail)
    )
      return

    dispatchState({
      type: 'integration-data',
      payload: {
        integrationLoadDetailsEmails: [
          ...(state.integrationData.integrationLoadDetailsEmails || []),
          state.integrationData.integrationLoadDetailsEmail
        ],
        integrationLoadDetailsEmail: ''
      }
    })
  }

  const removeItem = (index, property) => {
    const array = (state.integrationData[property] || []);
    if (array[index]) {
      array.splice(index, 1);
      dispatchState({
        type: 'integration-data',
        payload: {
          integrationLoadingCustomerEmails: array,
        }
      })
    }
  }
  //emailRegex.test(state.integrationData.integrationLoadDetailsEmail)
  return (
    <Grid spacing={3} container>
      <Grid item xs={12}>
        <Typography variant="h3" component="h2">Call to action</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.customDividerOne} />
        <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Call to action URL <HelpRounded style={{ verticalAlign: "sub", fontSize: "1.3rem", marginLeft: 3 }} onClick={(event) => { setCtaUrlPopAdv(event.currentTarget) }} /></Typography>
        <Popover
          id="ctaPopover"
          open={Boolean(ctaUrlPopAdv)}
          anchorEl={ctaUrlPopAdv}
          onClose={() => { setCtaUrlPopAdv(false) }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography variant="body2" style={{zIndex: 10}} className={classes.blueText}>Once the calculator has returned the result, what would you like them to do next? Here you can send them to a booking or inquiry form, or have them call you for more information.</Typography>
        </Popover>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Typography variant="subtitle2">Use phone number instead of a URL</Typography>
          </Grid>
          <Grid item>
            <Switch
              checked={state.integrationData.integrationCTAType}
              onChange={() => { dispatchState({ type: 'integration-data', payload: { integrationCTAType: !state.integrationData.integrationCTAType } }) }}
              color="primary"
              name="cta-type"
              inputProps={{ 'aria-label': 'CTA type' }}
              disabled={!restrictions.includes('integrationCTAType')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="p-integration-cta-url-2"
              label={state.integrationData.integrationCTAType ? "Phone number" : "URL"}
              variant="outlined"
              type="text"
              helperText={state.integrationData.integrationCTAType ? "Example: 1234 567 899" : "Example: https://yourwebsite.com/book-now"}
              onChange={(e) => { validateURL(e.target.value) }}
              value={state.integrationData.integrationCtaURL}
              fullWidth
              disabled={!restrictions.includes('integrationCtaURL')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Typography variant="subtitle2">Disabled CTA redirection</Typography>
          <Switch
            checked={!!state.integrationData.integrationCTARedirection}
            onChange={() => { dispatchState({ type: 'integration-data', payload: { integrationCTARedirection: !state.integrationData.integrationCTARedirection } }) }}
            color="primary"
            name="cta-redirection"
            inputProps={{ 'aria-label': 'CTA Redirection' }}
            disabled={!restrictions.includes('integrationCTARedirection')}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Typography variant="subtitle2">Enable auto detection as "nearest facility"</Typography>
          <Switch
            checked={!!state.integrationData.isEnabledNearestFacility}
            onChange={() => {
              dispatchState({
                type: 'integration-data',
                payload: {
                  isEnabledNearestFacility: !state.integrationData.isEnabledNearestFacility,
                }
              })
            }}
            color="primary"
            name="cta-redirection"
            inputProps={{ 'aria-label': 'CTA Redirection' }}
            disabled={!restrictions.includes('integrationCTARedirection')}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.customDividerOne} />
        <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Share result button</Typography>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Typography variant="subtitle2">Allow the user to bookmark their result <HelpRounded style={{ verticalAlign: "sub", fontSize: "1.3rem" }} onClick={(event) => { setLoadingPlanPop(event.currentTarget) }} /></Typography>
            <Popover
              id="loadingPlan"
              open={Boolean(loadingPlanPop)}
              anchorEl={loadingPlanPop}
              onClose={() => { setLoadingPlanPop(null) }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography variant="body2" className={classes.blueText}>
                This will ask for the user’s name, email and phone number, before providing them with a link to the calculator that has the items they added saved. Good for saving for later or sending to friends. The lead details we’ll email to you automatically.
                <br />Note: So we don’t ask for their lead details twice, turning on this option will disable ‘Lead details’.
              </Typography>
            </Popover>
          </Grid>
          <Grid item>
            <Popover
              id="ctaPopover"
              open={Boolean(showLoadingButtonToolTip)}
              anchorEl={showLoadingButtonToolTip}
              onClose={() => { setShowLoadingButtonToolTip(false) }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography variant="body2" className={classes.blueText}>Link on the button displayed on the calculation result.</Typography>
            </Popover>
            <Tooltip title="Edit form" aria-label="Edit form">
              <Fab
                color="primary"
                aria-label="Edit form"
                size="small"
                disabled={
                  updating ||
                  !state.integrationData.showLoadingPlanButton ||
                  isDisabledButton ||
                  !restrictions.includes('showLoadingPlanButton')
                }
                onClick={() => dispatchState({ type: 'integration-data', payload: {
                  integrationThirdPanel: true,
                  integrationFormType: 'integrationLoadingPlanFields'
                }})}
              >
                <EditRounded />
              </Fab>
            </Tooltip>
            {
              !restrictions.includes('showLoadingPlanButton') ?
                <PayButton setUpdating={setUpdating} feature={'showLoadingPlanButton'} updating={updating} /> :
                (
                <Switch
                  checked={!!state.integrationData.showLoadingPlanButton}
                  onChange={() => dispatchState({ type: 'integration-data', payload: {
                      showLoadingPlanButton: !state.integrationData.showLoadingPlanButton,
                      integrationLoadDetailsActive: false
                    } })}
                  color="primary"
                  name="cta-type"
                  inputProps={{ 'aria-label': 'loading plan button' }}
                  disabled={!restrictions.includes('showLoadingPlanButton')}
                />
              )
            }
          </Grid>
          { state.integrationData.showLoadingPlanButton && (
            <Grid item xs={12}>
              <Typography style={{marginBottom: 18, marginTop: 10}} variant="subtitle2">Which exact page will your calculator be on?</Typography>
              <TextField
                id="p-integration-cta-url"
                label={"URL"}
                variant="outlined"
                type="text"
                error={state.integrationData.integrationUrl && !regexp.test(state.integrationData.integrationUrl)}
                helperText={"Example: https://yourwebsite.com/space-calculator"}
                onChange={ e => dispatchState({ type: 'integration-data', payload: { integrationUrl: e.target.value } })}
                value={state.integrationData.integrationUrl}
                fullWidth
              />
              <TextField
                id="p-integration-customer-service-email"
                label="Where would you like their details emailed to?"
                variant="outlined"
                type="text"
                error={state.integrationData.integrationLoadingCustomerEmail && !isLoadingPlanEmailValid ? true : false}
                helperText="Example: hello@mystoragecompany.com"
                fullWidth
                style={{marginTop: 15}}
                onChange={ e => dispatchState({ type: 'integration-data', payload: { integrationLoadingCustomerEmail: e.target.value } })}
                value={state.integrationData.integrationLoadingCustomerEmail}
                onKeyPress={ event => { if (event.key === 'Enter') addLoadingPlanEmail()}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AddRounded style={{cursor: 'pointer'}} onClick={addLoadingPlanEmail} />
                    </InputAdornment>
                  ),
                }}
              />
              <Grid>
                {
                  (state.integrationData.integrationLoadingCustomerEmails || []).length ? (
                    state.integrationData.integrationLoadingCustomerEmails.map((email, index) => (
                      <Fab
                        variant="extended"
                        size="small"
                        color="primary"
                        aria-label="add"
                        style={{backgroundColor: 'rgba(0, 86, 149, 0.1)', margin: 3}}
                        key={index}
                      >
                        {email}
                        <ClearRounded onClick={() => removeItem(index, 'integrationLoadingCustomerEmails')} style={{fontSize: 15, marginLeft: 5}} />
                      </Fab>
                    ))
                  ) : null
                }
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.customDividerOne} />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item /*className={state.integrationData?.integrationLoadDetailsActive ? classes.disabled : classes.enabled}*/>
            <Typography variant="subtitle2">Request lead details from the user?<HelpRounded style={{ verticalAlign: "sub", fontSize: "1.3rem", marginLeft: 3 }} onClick={(event) => { setLoadDetailsPop(event.currentTarget) }} /></Typography>
            <Popover
              id="displayUnit"
              open={!!loadDetailsPop}
              anchorEl={loadDetailsPop}
              onClose={() => { setLoadDetailsPop(null) }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography variant="body2" className={classes.blueText}>
                Before sending them to your chosen call to action URL, we’ll ask for their name, email and phone number. This will be automatically emailed to you as they proceed to the next page.
                <br/>Note: This option is disabled if you switch on the ‘share results’ feature.
              </Typography>
            </Popover>
          </Grid>
          <Grid item /*className={state.integrationData?.showLoadingPlanButton ? classes.disabled : classes.enabled}*/>
            <Tooltip title="Edit form" aria-label="Edit form">
              <Fab
                color="primary"
                aria-label="Edit form"
                size="small"
                disabled={
                  updating ||
                  !state.integrationData.integrationLoadDetailsActive ||
                  isDisabledButton ||
                  !restrictions.includes('integrationLoadDetailsActive')
                }
                onClick={() => dispatchState({
                  type: 'integration-data',
                  payload: {
                    integrationThirdPanel: true,
                    integrationFormType: 'integrationLoadGenerationForm'
                  }})}
              >
                <EditRounded />
              </Fab>
            </Tooltip>
            {
              !restrictions.includes('integrationLoadDetailsActive') ?
                <PayButton setUpdating={setUpdating} feature={'integrationLoadDetailsActive'} updating={updating} /> : (
                <Switch
                  checked={!!state.integrationData.integrationLoadDetailsActive}
                  onChange={() => dispatchState({ type: 'integration-data', payload: {
                      integrationLoadDetailsActive: !state.integrationData.integrationLoadDetailsActive,
                      showLoadingPlanButton: false
                    }})}
                  color="primary"
                  name="load-details"
                  inputProps={{ 'aria-label': 'Load details' }}
                  disabled={!restrictions.includes('integrationLoadDetailsActive')}
                />
              )
            }
          </Grid>
        </Grid>
      </Grid>
      { state.integrationData.integrationLoadDetailsActive && (
        <Grid
          item
          xs={12}
        >
          <TextField
            id="p-integration-load-details-email"
            label="Where would you like their details emailed to?"
            variant="outlined"
            type="text"
            error={state.integrationData.integrationLoadDetailsEmail && !isLoadGenEmailValid ? true : false}
            helperText="E.g. sales@mycompany.com"
            fullWidth
            onChange={ e => dispatchState({ type: 'integration-data', payload: { integrationLoadDetailsEmail: e.target.value } })}
            value={state.integrationData.integrationLoadDetailsEmail}
            onKeyPress={ event => { if (event.key === 'Enter') addLoadDetailsEmail()}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AddRounded style={{cursor: 'pointer'}} onClick={addLoadDetailsEmail} />
                </InputAdornment>
              ),
            }}
          />
          <Grid>
            {
              (state.integrationData.integrationLoadDetailsEmails || []).length ? (
                state.integrationData.integrationLoadDetailsEmails.map((email, index) => (
                  <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    aria-label="add"
                    style={{backgroundColor: 'rgba(0, 86, 149, 0.1)', margin: 3}}
                    key={index}
                  >
                    {email}
                    <ClearRounded onClick={() => removeItem(index, 'integrationLoadDetailsEmails')} style={{fontSize: 15, marginLeft: 5}} />
                  </Fab>
                ))
              ) : null
            }
          </Grid>
        </Grid>)
      }
      <Grid item xs={12}>
        {/*<Divider className={classes.customDividerOne} />*/}
        <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>
          CTA URL Params
          <HelpRounded style={{ verticalAlign: "sub", fontSize: "1.3rem", marginLeft: 3 }} onClick={(event) => { setCtaParamsPop(event.currentTarget) }} />
        </Typography>
        <Popover
          id="ctaPopover"
          open={Boolean(ctaParamsPop)}
          anchorEl={ctaParamsPop}
          onClose={() => { setCtaParamsPop(false) }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography variant="body2" style={{zIndex: 10}} className={classes.blueText}>
            Add the calculator’s recommendation to the end of the URL and direct the user to the page of your website
            that’s relevant to their needs. You can also specify an entire URL for each of your units in the ‘Units’ step.
          </Typography>
        </Popover>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="subtitle2">
              Include unit quantity
              <HelpRounded style={{ verticalAlign: "sub", fontSize: "1.3rem", marginLeft: 3 }} onClick={(event) => { setCtaQuantityPop(event.currentTarget) }} />
            </Typography>
            <Popover
              id="ctaPopover"
              open={Boolean(ctaQuantityPop)}
              anchorEl={ctaQuantityPop}
              onClose={() => { setCtaQuantityPop(false) }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography variant="body2" style={{zIndex: 10}} className={classes.blueText}>This is for when you have one size of unit and the calculator recommends how many the user needs.</Typography>
            </Popover>
          </Grid>
          <Grid item>
            {
              !restrictions.includes('integrationIncQty') ?
                <PayButton setUpdating={setUpdating} feature={'integrationIncQty'} updating={updating} /> :
                (
                <Switch
                  checked={state.integrationData.integrationIncQty}
                  onChange={() => { dispatchState({ type: 'integration-data', payload: { integrationIncQty: !state.integrationData.integrationIncQty } }) }}
                  color="primary"
                  name="include-unit-quantity"
                  inputProps={{ 'aria-label': 'Include unit quantity' }}
                />
              )
            }
          </Grid>
        </Grid>
        <Divider className={classes.customDividerTwo} />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="subtitle2">
              Include unit ID
              <HelpRounded style={{ verticalAlign: "sub", fontSize: "1.3rem", marginLeft: 3 }} onClick={(event) => { setCtaIdPop(event.currentTarget) }} />
            </Typography>
            <Popover
              id="ctaPopover"
              open={Boolean(ctaIdPop)}
              anchorEl={ctaIdPop}
              onClose={() => { setCtaIdPop(false) }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography variant="body2" style={{zIndex: 10}} className={classes.blueText}>This is when you have multiple unit sizes and the calculator recommends one set of dimensions.</Typography>
            </Popover>
          </Grid>
          <Grid item>
            {
              !restrictions.includes('integrationIncID') ?
                <PayButton setUpdating={setUpdating} feature={'integrationIncID'} updating={updating} /> : (
                <Switch
                  checked={state.integrationData.integrationIncID}
                  onChange={() => { dispatchState({ type: 'integration-data', payload: { integrationIncID: !state.integrationData.integrationIncID } }) }}
                  color="primary"
                  name="include-unit-id"
                  inputProps={{ 'aria-label': 'Include unit ID' }}
                  disabled={!restrictions.includes('integrationIncID')}
                />
              )
            }
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{marginTop: 10}}
        >
          <Grid item>
            <Typography variant="subtitle2">
              Include unit volume
            </Typography>
          </Grid>
          <Grid item>
            {
              !restrictions.includes('integrationIncVolume') ?
                <PayButton setUpdating={setUpdating} feature={'integrationIncVolume'} updating={updating} /> : (
                  <Switch
                    checked={state.integrationData.integrationIncVolume}
                    onChange={() => { dispatchState({ type: 'integration-data', payload: { integrationIncVolume: !state.integrationData.integrationIncVolume } }) }}
                    color="primary"
                    name="include-unit-volume"
                    inputProps={{ 'aria-label': 'Include unit Volume' }}
                    disabled={!restrictions.includes('integrationIncVolume')}
                  />
                )
            }
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{marginTop: 10}}
        >
          <Grid item>
            <Typography variant="subtitle2">
              Include unit used space
            </Typography>
          </Grid>
          <Grid item>
            {
              !restrictions.includes('integrationIncUsedVolume') ?
                <PayButton setUpdating={setUpdating} feature={'integrationIncUsedVolume'} updating={updating} /> : (
                  <Switch
                    checked={state.integrationData.integrationIncUsedVolume}
                    onChange={() => { dispatchState({ type: 'integration-data', payload: { integrationIncUsedVolume: !state.integrationData.integrationIncUsedVolume } }) }}
                    color="primary"
                    name="include-unit-space"
                    inputProps={{ 'aria-label': 'Include unit Space' }}
                    disabled={!restrictions.includes('integrationIncUsedVolume')}
                  />
                )
            }
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{marginTop: 10}}
        >
          <Grid item>
            <Typography variant="subtitle2">
              Include unit quantity (Multiple)
              <HelpRounded 
                style={{ verticalAlign: "sub", fontSize: "1.3rem", marginLeft: 3 }} 
                onClick={(event) => { setCtaQuantityMultiplePop(event.currentTarget) }} 
              />
            </Typography>
            <Popover
              id="ctaPopover"
              open={Boolean(ctaQuantityMultiplePop)}
              anchorEl={ctaQuantityMultiplePop}
              onClose={() => { setCtaQuantityMultiplePop(false) }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography variant="body2" style={{zIndex: 10}} className={classes.blueText}>Allow to include unit ID and quantity into Booking URL. <br/>Pattern: {'{Unit ID}={Unit Quantity}'}.</Typography>
            </Popover>
          </Grid>
          <Grid item>
            {
              !restrictions.includes('integrationCtaShouldIncludeUnitQuantity') ?
                <PayButton setUpdating={setUpdating} feature={'integrationCtaShouldIncludeUnitQuantity'} updating={updating} /> : (
                  <Switch
                    checked={state.integrationData.integrationCtaShouldIncludeUnitQuantity}
                    onChange={() => { dispatchState({ type: 'integration-data', payload: { integrationCtaShouldIncludeUnitQuantity: !state.integrationData.integrationCtaShouldIncludeUnitQuantity } }) }}
                    color="primary"
                    name="include-unit-quantity-multiple"
                    inputProps={{ 'aria-label': 'Include unit quantity (Multiple)' }}
                    disabled={!restrictions.includes('integrationCtaShouldIncludeUnitQuantity')}
                  />
                )
            }
          </Grid>
        </Grid>
      </Grid>
      {
        restrictions.includes('integrationIncID') && (
          <Grid item xs={12}>
            <Divider className={classes.customDividerOne} />
            <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>CTA Link</Typography>
            <TextField
              id="p-integration-finalURL"
              label="Example URL"
              variant="outlined"
              type="text"
              disabled
              value={state.integrationData.integrationCtaURL + qryURL() + qtyURL() + midURL() + idURL() + volumeURL() + usedVolumeURL()}
              fullWidth
            />
          </Grid>
        )
      }
      <Grid item xs={12}>
        <TextField
          id="p-integration-CTA-label"
          label="CTA Label"
          variant="outlined"
          type="text"
          inputProps={{ maxLength: 12 }}
          value={state.integrationData.integrationCTALabel}
          onChange={(e) => { dispatchState({ type: 'integration-data', payload: { integrationCTALabel: e.target.value } }) }}
          fullWidth
          helperText={state.integrationData.integrationCTALabel ? `${state.integrationData.integrationCTALabel.length} / 12 character limit` : 'CTA Label is required'}
          error={!state.integrationData.integrationCTALabel}
          disabled={!restrictions.includes('integrationCTALabel')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel htmlFor="p-integration-wTarget">CTA Open</InputLabel>
          <Select
            id="p-integration-wTarget"
            value={state.integrationData.integrationCTATarget}
            variant="outlined"
            label="CTA Open"
            onChange={(e) => { dispatchState({ type: 'integration-data', payload: { integrationCTATarget: e.target.value } }) }}
          >

            <MenuItem key={0} value="_self">Same tab</MenuItem>
            <MenuItem key={1} value="_blank">New tab</MenuItem>
            {/*<MenuItem key={2} value="_top">New window</MenuItem>*/}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.buttonSpacing}
          color="primary"
          size="large"
          onClick={handleSave}
          fullWidth
          disabled={!state.integrationData.integrationCTALabel || updating || isDisabledButton}
        >{updating ? <CircularProgress color='inherit' size={15} /> : 'Save' }</Button>
      </Grid>
    </Grid>
  )
}

export default OptionStepAdvanced
