import AdminTransactionalHeader from "../../components/AdminTransactionalHeader/AdminTransactionalHeader";
import {Grid, InputAdornment, TextField, Typography} from "@material-ui/core";
import NumberFormat from 'react-number-format';
import React, {useContext, useMemo, useState} from "react";
import Calculator from '../../assets/img/calculator.svg';
import StartForFree from "../../components/StartForFree";
import {makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import styles from "../../assets/jss/material-dashboard-react/layouts/transactionalStyle";
import {useGlobalState} from "../../hooks/useGlobalState";
const useStyles = makeStyles(styles)

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

export default props => {
  const [marketingSpend, setMarketingSpend] = useState(null);
  const [onlineReservations, setOnlineReservations] = useState(null);
  const [averageRevenuePerCustomer, setAverageRevenuePerCustomer] = useState(null);
  let state = useContext(useGlobalState.State);
  const corePlanPrice = useMemo(() => state.calcumatePlans.find(plan => plan.thePlan === 'Core')?.thePrice?.[0], []);
  const classes = useStyles();

  const returnOnInvestment = useMemo(() => (
    Math.round(((((averageRevenuePerCustomer * onlineReservations) - marketingSpend) / marketingSpend) * 100))
  ), [marketingSpend, onlineReservations, averageRevenuePerCustomer])

  const marketingSpendWithCalculate = useMemo(() => {
    if (!marketingSpend) return ''
    return Math.round(Number(marketingSpend) + 421)
  }, [marketingSpend]);

  const onlineReservationsWithCalculate = useMemo(() => {
    if (!onlineReservations) return ''
    return Math.round(onlineReservations * 1.18);
  }, [onlineReservations]);

  const returnOnInvestmentWithCalculate = useMemo(() => (
    Math.round((((averageRevenuePerCustomer * onlineReservationsWithCalculate) - marketingSpendWithCalculate) / marketingSpendWithCalculate) * 100)
  ), [marketingSpendWithCalculate, onlineReservationsWithCalculate, averageRevenuePerCustomer]);

  const costPerCustomer = useMemo(() => Math.ceil(marketingSpend / onlineReservations), [marketingSpend, onlineReservations]);
  const costPerCustomerWithCalculate = useMemo(() => Math.ceil(marketingSpendWithCalculate / onlineReservationsWithCalculate), [marketingSpendWithCalculate, onlineReservationsWithCalculate]);
  const showInvestmentResult = useMemo(() => returnOnInvestment && averageRevenuePerCustomer && marketingSpend && onlineReservations && averageRevenuePerCustomer, [returnOnInvestment && averageRevenuePerCustomer && marketingSpend && onlineReservations && averageRevenuePerCustomer])
  const showInvestmentResultWithCalculate = useMemo(() => returnOnInvestmentWithCalculate && marketingSpend && onlineReservations && averageRevenuePerCustomer, [returnOnInvestmentWithCalculate && marketingSpend && onlineReservations && averageRevenuePerCustomer]);

  return (
    <Grid style={{
      minHeight: '100vh',
      paddingBottom: 30,
    }}>
      <AdminTransactionalHeader
        homeBtn={() => props.history.push("/")}
        backBtn={false}
        logo={true}
        closeBtn={false}
      />
      <Grid justify="center" className={classes.cFormContaner} style={{
        display: 'flex',
      }}>
        <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
          <Typography variant="h1" className={classes.headerOneLine}><img src={Calculator} alt={'Calculator icon'} /> ROI Calculator</Typography>
          <Typography variant="subtitle1" component="p" className={classes.subTitleTwo} align="center">See what adding Calcumate to your website can do to your bottom line…</Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.rioCalculatorWrap}>
        <Grid item xs={12} sm={6} className={classes.rioCalculatorLeftBlock}>
          <Typography className={classNames(classes.rioFormBlock, classes.rioTypography4)} variant="h4">Your current marketing</Typography>
          <Grid item xs={12} className={classes.rioFormBlock}>
            <Typography variant={'h5'} className={classes.rioTypographyFormItem}>Marketing spend<sup style={{fontSize: 12}}></sup></Typography>
            <TextField
              id="p-marketing-spend"
              label={'$ / year'}
              variant="outlined"
              onChange={e => setMarketingSpend(e.target.value)}
              value={marketingSpend}
              fullWidth
              InputProps={{
                startAdornment: marketingSpend && <InputAdornment position="end">$ </InputAdornment>,
                inputComponent: NumberFormatCustom
              }}
              className={classes.rioTextInput}
            />
          </Grid>
          <Grid item xs={12} className={classes.rioFormBlock}>
            <Typography variant={'h5'} className={classes.rioTypographyFormItem}>Online reservations<sup style={{fontSize: 12}}></sup></Typography>
            <TextField
              id="p-online-reservations"
              label={'/ year'}
              variant="outlined"
              onChange={e => setOnlineReservations(e.target.value)}
              value={onlineReservations}
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.rioFormBlock}>
            <Typography variant={'h5'} className={classes.rioTypographyFormItem}>Average revenue per customer</Typography>
            <TextField
              id="p-average-revenue-per-customer"
              label={'$'}
              variant="outlined"
              onChange={e => setAverageRevenuePerCustomer(e.target.value)}
              value={averageRevenuePerCustomer}
              fullWidth
              InputProps={{
                startAdornment: averageRevenuePerCustomer && <InputAdornment position="end">$ </InputAdornment>,
                inputComponent: NumberFormatCustom
              }}
              className={classes.rioTextInput}
            />
          </Grid>
          <Grid item xs={12} className={classNames(classes.rioFormBlock, classes.rioFormBlockLine)}>
            <Typography variant={'h5'} className={classes.rioTypographyFormItem} style={{color: 'rgba(0, 0, 0, 0.54)', fontSize: 16, minWidth: 145}}>Cost per customer</Typography>
            <Typography variant={'p'} className={classes.rioCalculationResult} style={{fontSize: 24}}>{
              (costPerCustomer && averageRevenuePerCustomer && marketingSpend && onlineReservations && averageRevenuePerCustomer) ?
                `$${(costPerCustomer).toLocaleString(undefined, { maximumFractionDigits: 2 })}` :
                <span style={{color: "transparent"}}>1</span>}</Typography>
          </Grid >
          <Grid item xs={12} className={classNames(classes.rioFormBlock, classes.rioFormBlockLine)}>
            <Typography variant={'h5'} className={classes.rioTypographyFormItem} style={{color: 'rgba(0, 0, 0, 0.54)', fontSize: 16, minWidth: 145}}>Return on investment</Typography>
            <Typography variant={'p'} className={classNames(classes.rioCalculationResult, {[classes.roiPercentSign]:showInvestmentResult})}>{
              showInvestmentResult ?
                `${(returnOnInvestment).toLocaleString(undefined, { maximumFractionDigits: 2 })}` :
                <span style={{color: "transparent"}}>1</span>}</Typography>
          </Grid >
        </Grid>
        <Grid item xs={12} sm={6} className={classes.rioCalculatorRightBlock}>
          <Typography variant="h4" className={classNames(classes.rioFormBlock, classes.rioTypography4, classes.rioWhiteColor)}>With Calcumate</Typography>
          <Grid item xs={12} className={classes.rioFormBlock}>
            <Typography variant={'h5'} className={classNames(classes.rioTypographyFormItem, classes.rioWhiteColor)}>Marketing spend<sup style={{fontSize: 12}}>1</sup></Typography>
            <TextField
              id="p-marketing-spend"
              variant="outlined"
              value={marketingSpendWithCalculate}
              fullWidth
              className={classNames(classes.whiteTextField, classes.rioTextInput)}
              disabled
              InputProps={{
                startAdornment: marketingSpendWithCalculate && <InputAdornment position="end">$ </InputAdornment>,
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.rioFormBlock}>
            <Typography variant={'h5'} className={classNames(classes.rioTypographyFormItem, classes.rioWhiteColor)}>Online reservations<sup style={{fontSize: 12}}>2</sup></Typography>
            <TextField
              id="p-online-reservations"
              variant="outlined"
              type="text"
              value={onlineReservationsWithCalculate}
              fullWidth
              className={classes.whiteTextField}
              disabled
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.rioFormBlock}>
            <Typography variant={'h5'} className={classNames(classes.rioTypographyFormItem, classes.rioWhiteColor)}>Average revenue per customer</Typography>
            <TextField
              id="p-average-revenue-per-customer"
              variant="outlined"
              value={averageRevenuePerCustomer}
              fullWidth
              className={classNames(classes.whiteTextField, classes.rioTextInput)}
              disabled
              InputProps={{
                startAdornment: averageRevenuePerCustomer && <InputAdornment position="end">$ </InputAdornment>,
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
          <Grid item xs={12} className={classNames(classes.rioFormBlock, classes.rioFormBlockLine)}>
            <Typography variant={'h5'} className={classNames(classes.rioTypographyFormItem, classes.rioWhiteColor)} style={{fontSize: 16, minWidth: 145}}>Cost per customer</Typography>
            <Typography variant={'p'} className={classNames(classes.rioCalculationResult, classes.rioLighterVersion)} style={{fontSize: 24}}>{
              (
                costPerCustomerWithCalculate && marketingSpend && onlineReservations && averageRevenuePerCustomer) ?
                `$${(costPerCustomerWithCalculate).toLocaleString(undefined, { maximumFractionDigits: 2 })}` :
                <span style={{color: "transparent"}}>1</span>}</Typography>
          </Grid>
          <Grid item xs={12} className={classNames(classes.rioFormBlock, classes.rioFormBlockLine)}>
            <Typography variant={'h5'} className={classNames(classes.rioTypographyFormItem, classes.rioWhiteColor)} style={{fontSize: 16, minWidth: 145}}>Return on investment</Typography>
            <Typography variant={'p'} className={classNames(classes.rioCalculationResult, classes.rioLighterVersion, {[classes.roiPercentSign]:showInvestmentResultWithCalculate})}>{
              showInvestmentResultWithCalculate ?
                `${(returnOnInvestmentWithCalculate).toLocaleString(undefined, { maximumFractionDigits: 2 })}` :
                <span style={{color: "transparent"}}>1</span>}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.rioFormBlock}>
            <Typography className={classes.rioExplanations}><sup style={{fontSize: 8}}>1</sup> Our Core subscription is just ${corePlanPrice} a year</Typography>
            <Typography className={classes.rioExplanations}><sup style={{fontSize: 8}}>2</sup> Forecast increase from self-storage operators</Typography>
          </Grid>
        </Grid>
      </Grid>
      <StartForFree resetOffsets={true} />
    </Grid>
  )
}
