import React, { useContext, useState, Fragment } from "react"
import { useHistory } from "react-router-dom";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

// Core components
import { Link } from "react-router-dom"

// Other components
import PasswordInput from "components/PasswordInput/PasswordInput";
import { useGlobalState } from "hooks/useGlobalState";
import AdminTransactionalHeader from "../../components/AdminTransactionalHeader/AdminTransactionalHeader";
import AgreementBlock from "../../components/AgreementBlock/AgreementBlock";

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
const useStyles = makeStyles(styles)

const Login = props => {
  let history = useHistory();
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [authenticationValid, setAuthenticationValid] = useState(true);
  const [submittingUserData, setSubmittingUserData] = useState(false);
  let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const handleEmailChange = e => {
    if (emailRegex.test(e.target.value)) {
      setEmailValid(true);
      setEmail(e.target.value);
    } else {
      setEmailValid(false);
    }

  };


  const handlePasswordChange = password => {
    setPassword(password);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  }

  const handleLogin = async () => {
    setSubmittingUserData(true);
    // send a request to the API and check whether ther credentials are correct.
    // send the request to create a new user account
    await axios.post(
      process.env.REACT_APP_API_URL + 'login',
      {
        email: email.toLowerCase(),
        password: password
      },
      { headers: { 'Content-Type': 'application/json' } }
    ).then(function (response) {
      if (response.status && response.status === 200 && response.data.token) {
        setAuthenticationValid(true);
        // set the token in the state and get user id from Cognito and save in the state.
        dispatchState({ type: 'root-state', payload: { isLoggedIn: true, signedInUser: { token: response.data.token, cukey: response.data.cuid, data: {} } } });
        // redirect to the admin page and refresh
        history.replace('/admin/my-calcumate');
        //window.location.reload();
      } else {
        setAuthenticationValid(false);
        setSubmittingUserData(false);
      }
    })
      .catch(function (error) {
        console.error(error);
        setAuthenticationValid(false);
        setSubmittingUserData(false);
      });

  }

  return (
    <Fragment>
      <AdminTransactionalHeader
        homeBtn={() => { props.history.push("/") }}
        backBtn={false}
        logo={true}
        closeBtn={false}
      />
      <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
        <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
          <Typography variant="h1" className={classes.headerOneLine}>Login</Typography>
          {window.location.search.indexOf('reg=1') > -1 ?
            <div className={classes.notification}>
              <br />
              <Alert severity="success">You have successfully created the account. Please login to create your first integration!</Alert>

            </div>
            : ''}
          {window.location.search.indexOf('recover=1') > -1 ?
            <div className={classes.notification}>
              <br />
              <Alert severity="success">You have successfully recovered your password. Please login to continue.</Alert>

            </div>
            : ''}
          {!authenticationValid ?
            <div className={classes.notification}>
              <br />
              <Alert severity="error">Incorrect email or password.</Alert>

            </div>
            : ''}
          <form noValidate autoComplete="off" className={classes.standardForm}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField 
                  id="p-login-email" 
                  label="Email" 
                  error={email !== '' && !emailValid} 
                  variant="outlined" 
                  type="email" 
                  onChange={handleEmailChange} 
                  fullWidth
                  data-cy={'p-login-email'} 
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordInput 
                  uniqueLabel="Password" 
                  setPassword={password => handlePasswordChange(password)}
                  handleEnterKeyPress={handleEnterKeyPress} 
                  keyPressEnabled={true} 
                />
              </Grid>
              <Grid item xs={12}>
                <AgreementBlock />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  className={classes.buttonSpacing}
                  color="primary"
                  size="large"
                  onClick={handleLogin}
                  disabled={!emailValid || password === '' || submittingUserData}
                  fullWidth
                  data-cy={'login-button'}
                >
                  {submittingUserData ? <CircularProgress color='inherit' size={15} /> : 'LOGIN'}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center">
                  <Link to="/password-request">Password Recovery</Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Login
