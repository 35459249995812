import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-react/views/HomePageStyle";
import classNames from "classnames";
import AppleExample from "../../../assets/img/Apple-Example.webp";
import AppleSelfStorage from "../../../assets/img/appleLogoSingle_vfkjjy.webp";
import TBExample2 from "../../../assets/img/ET-Example-slide-2.webp";
const useStyles = makeStyles(styles);

export default () => {
  const classes = useStyles();
  return (
    <div>
      <Grid container>
        <Grid xs={12} sm={6} md={6} item className={classNames(classes.whyItWorksSlide1Left, classes.whyItWorksSlide4Left)}>
          <Grid style={{background: `url(${AppleExample})`, height: '100%',
            width: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain'
          }}>
            <img
              style={{maxWidth: 580, width: '100%', height: 'auto', visibility: "hidden"}}
              className={classNames(classes.whyItWorksSlide1LeftImg,classes.whyItWorksSlide2LeftImg)}
              src={TBExample2}
              alt={'EasyTruck logo'}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6} className={classNames(classes.whyItWorksSlide1Right, classes.whyItWorksSlide2Right)}>
          <Grid style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}} className={classes.whyItWorksSlide2RightWrap}>
            <a href={'https://www.applestorage.com/?utm_source=calcumate&utm_medium=referral&utm_campaign=calcumate_testimonial'} target={'_blank'} rel="noopener">
              <img
                src={AppleSelfStorage}
                className={classNames(classes.whyItWorksSlide1RightLogo, classes.whyItWorksSlide12RightLogo)}
                alt={'AppleSelfStorage logo'}
              />
            </a>
            <Grid>
              <Typography className={classNames(classes.whyItWorksSlide1RightText, classes.whyItWorksSlide2RightText)}>
                Family owned and operated, Apple Self-Storage has used Calcumate since 2019 for their 30+ storage locations. Our calculator allows their customers to reserve units online with confidence.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
