import React, {useMemo} from "react";
import { Button, makeStyles, } from "@material-ui/core";
import { Link } from "react-router-dom"
//Styles
import styles from "assets/jss/material-dashboard-react/components/CustomPricingStyle";
import classNames from "classnames";

const useStyles = makeStyles(styles);

const PlanButton = ({
  plan,
  size = 'large',
  type = 'filled',
  state,
  defaultServiceNumber,
  defaultService,
}) => {

  const classes = useStyles();

  const {
    signUpPlanUrl,
    calcumatePlans,
    upDownGradePlanUrl,
    serviceNumber,
    service,
    originPricingPlan,
    cmPlanPayRec,
    currency,
    cancelPlanUrl,
    currentPlan,
  } = state;
  const subscriptionId = state?.signedInUser?.data?.user?.stripeAccount?.subscriptionId;

  const {thePlan, thePlanLabel} = plan;
  const url = useMemo(() => {
    if (state?.signedInUser?.token) {
      return thePlan?.toLowerCase() === "contact-us"
      ? "/contact-us"
      : upDownGradePlanUrl +
        "?plan=" +
        thePlan?.toLowerCase() +
        "&" +
        service +
        "=" +
            serviceNumber;
    }
    return state.signUpPlanUrl + "?plan=" + thePlan.toLowerCase() + '&' + service + '=' + serviceNumber;        
  }, [
    upDownGradePlanUrl,
    thePlan,
    service,
    serviceNumber,
    defaultServiceNumber,
    defaultService,
  ]);


  const originalPlan = originPricingPlan;  

  const sid = useMemo(() => {
    return plan?.[`sid${currency}`] || plan.sid;
  }, [
    plan,
    currency
  ]);

    if (
      thePlan === currentPlan &&
      originalPlan === sid?.[(cmPlanPayRec ? 0 : 1)] &&
      !!subscriptionId &&
      defaultServiceNumber === serviceNumber &&
      defaultService === service
    ) {
     
      return (
        <Button
          component={Link}
          to={cancelPlanUrl + "?plan=" + thePlan.toLowerCase()}
          variant="outlined"
          color="default"
          size="small"
          fullWidth
          disabled={!subscriptionId}
          className={classNames(classes.submitButton, classes.cancelBtn, {
            [classes.largeButton]: size === 'large',
            [classes.smallButton]: size === 'small',
            [classes.middleButton]: size === 'middle',
            [classes.submitButtonFilled]: type === 'filled',
            [classes.submitButtonPrimary]: type === 'outlined',
          })}
        >
          Cancel subscription
        </Button>
      );
    } else {
      return (
        <Button
          component={Link}
          to={url}
          variant="contained"
          color="primary"
          size="small"
          fullWidth
          className={classNames("submitPlanButton", classes.submitButton, {
            [classes.largeButton]: size === 'large',
            [classes.smallButton]: size === 'small',
            [classes.middleButton]: size === 'middle',
            [classes.submitButtonFilled]: type === 'filled',
            [classes.submitButtonOutlined]: type === 'outlined',
          })}
          data-cy={"button-get-plan"}
        >
          Get {thePlanLabel || thePlan}
        </Button>
      );
    }
}

export default PlanButton;