import React, { createContext, useReducer } from 'react'
import InitState from "variables/InitState"

// Context
const State = createContext()
const Dispatch = createContext()

// Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case 'root-state':
      return {
        ...state,
        ...action.payload
      }
    case 'integration-data':
      return {
        ...state,
        integrationData: { ...state.integrationData, ...action.payload }
      }
    case 'integration-data-options':
      return {
        ...state,
        integrationDataOptions: { ...state.integrationDataOptions, ...action.payload }
      }
    case 'signed-in-user-data':
      return {
        ...state,
        signedInUser: { ...state.signedInUser, data: { ...state.signedInUser.data, user: { ...action.payload } } }
      }

    case 'reg-state':
      if (action.payload.userData.billing) {
        let udata = {};
        if (action.payload.userData.billing.creditCard) {
          udata = { ...state.userData.billing, creditCard: { ...state.userData.billing.creditCard, ...action.payload.userData.billing.creditCard } }
        } else {
          udata = { ...state.userData.billing, ...action.payload.userData.billing }
        }
        return {
          ...state,
          userData: { ...state.userData, billing: udata }
        }
      } else {
        return {
          ...state,
          userData: { ...state.userData, ...action.payload.userData }
        }
      }

    default:
      return state
  }
}

// Provider
const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, InitState)

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>
  )
}

// Export
export const useGlobalState = {
  State,
  Dispatch,
  Provider,
}
