import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

//Styles
import styles from "assets/jss/material-dashboard-react/views/CmsPageStyles";
import {makeStyles} from "@material-ui/core/styles";
import 'react-accessible-accordion/dist/fancy-example.css';
const useStyles = makeStyles(styles);

export default function SimpleAccordion(props) {
  const classes = useStyles();

  return (
    <Accordion allowZeroExpanded={true} style={{maxWidth: 1200, margin: 'auto', marginTop: 15, border: 'unset'}}>
      {
        props?.elem?.props?.children?.props?.children?.props?.children?.props?.children && 
        ((props.elem.props.children.props.children.props.children.props.children || []).filter(item => typeof item !== 'string').map((item, index) => {
          return (
            <AccordionItem style={{border: 'unset'}} key={index}>
              <AccordionItemHeading className={classes.accordionHead}>
                <AccordionItemButton>
                  {item.props.children[0]}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className={classes.accordionContent}>
                  {item.props.children[1]}
              </AccordionItemPanel>
            </AccordionItem>
          )
        }))
      }
    </Accordion>
  );
}
