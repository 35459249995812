import React, {useMemo, useContext} from 'react';
import {
  Grid,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import comparePlans, { plans } from "variables/compare-plans";
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import classNames from 'classnames';
import PlanButton from './CustomPricingPanel/components/PlanButton';
import { useGlobalState } from 'hooks/useGlobalState'
import { blackColor, whiteColor } from 'assets/jss/material-dashboard-react';

const styles = {
  even: {
    backgroundColor: '#F0F0F0',
  },
  odd: {
    backgroundColor: whiteColor,
  },
  category: {
    backgroundColor: '#CCCCCC',
    marginTop: 61,
    height: 48,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 10px',
    color: blackColor,
    fontWeight: 'bold',
    fontSize: 20,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    zIndex: 2,
    position: 'relative',
  },
  firstCategory: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  row: {
    minHeight: 48,
    padding: 10,
    overflow: 'hidden',
    //zIndex: 1,
    //position: 'relative',
    // '&:hover': {
    //   backgroundColor: whiteColor + '!important',
    // }
    //display: 'flex',
    //justifyContent: 'center',
    //alignItems: 'center'
  },
  cell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    position: 'relative',
    fontWeight: 500,
    textDecoration: 'underline dashed',
    textUnderlineOffset: '6px',
    color: blackColor,
    '&.reset-underline': {
      textDecoration: 'unset'
    }
  },
  middleCell: {
    '&:after': {
      content: "' '",
      height: '200%',
      width: 2,
      backgroundColor: '#F0F0F0',
      position: 'absolute',
      right: 0
    },
    '&:before': {
      content: "' '",
      height: '200%',
      width: 2,
      backgroundColor: '#F0F0F0',
      position: 'absolute',
      left: 0
    }
  },
  firstPlanCell: {
    '&:after': {
      content: "' '",
      height: '100%',
      width: 2,
      backgroundColor: '#F0F0F0',
      position: 'absolute',
      right: 3
    },
    '&:before': {
      content: "' '",
      height: '100%',
      width: 2,
      backgroundColor: '#F0F0F0',
      position: 'absolute',
      left: 3
    }
  },
  secondPlanCell: {
    '&:after': {
      content: "' '",
      height: '100%',
      width: 2,
      backgroundColor: '#F0F0F0',
      position: 'absolute',
      right: 0
    },
  },
  planName: {
    fontSize: 20,
    fontWeight: 'bold',
    color: blackColor
  },
  tableHeadCell: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 110,
    gap: 9
  },
  tableHead: {
    marginTop:35,
    maxWidth: 1244,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: '1px 3px 4px 0px rgba(0, 0, 0, 0.25)',
    position: 'relative',
  }
}

const useStyles = makeStyles(styles);

const Included = () => {
  return (<CheckIcon style={{color: '#2BB0DE'}} />);
}

const Excluded = () => {
  return (<ClearIcon style={{color: '#B3B3B3'}} />);
}

const Feature = ({
  feature, 
  index
}) => {
  return (
    <span 
      style={{
        color: blackColor, 
        display: 'flex', 
        alignItems: 'center',
      }}
    >
      {
        feature.plans[index] ?
          feature.value[index] || <Included /> : 
          feature.value[index] || <Excluded />
      }
    </span>
  )
}

export default () => {

  const classes = useStyles();
  let state = useContext(useGlobalState.State);
  const features = useMemo(()=> {
    return comparePlans; //TODO sort by category
  }, [
    comparePlans
  ]);

  const Category = ({index}) => {

    if (index === 0) {
      return (
        <Grid xs={12} style={{marginTop: 0}} className={classNames(classes.category, classes.firstCategory)}>
          {features[0].category}
        </Grid>
      );
    }
    if (features[index]?.category !== features?.[index - 1]?.category) {
      return (
        <Grid xs={12} className={classes.category}>
          {features[index].category}
        </Grid>
      );
    }
    return '';
  }

  return (
    <Grid container style={{marginTop: 50, paddingLeft: 15, paddingRight: 15}}>
      <Grid item xs={12}>
        <Typography style={{
          color: blackColor, 
          textAlign: 'center', 
          fontSize: 36, 
          fontWeight: 'bold',
          letterSpacing: 1.1
        }} variant="h1" className={classNames(classes.headerOneLine, classes.pricingTitle)}>Compare all plans</Typography>
      </Grid>
      <Grid container className={classes.tableHead} style={{}}>
        <Grid xs={4}></Grid>
        {
          plans.map((plan, key) => {
            const pricingPlan = state.calcumatePlans.find(elem => {
              return elem.thePlan === plan || elem.thePlan === `${elem.thePlan}-${plan}`;
            });
            
            return (
              <Grid xs={4} className={classNames(classes.tableHeadCell, {
                [classes.firstPlanCell]: key === 0,
                [classes.secondPlanCell]: key === 1,
              })}>
                <Grid className={classes.planName}>{
                  plan === 'Limited' ? 
                    plan+' (Free)' : 
                    plan}
                </Grid>
                <Grid>
                  <PlanButton
                    plan={pricingPlan}
                    size='small'
                    state={state}
                    defaultServiceNumber={state.serviceNumber}
                    defaultService={state.service}
                  />
                </Grid>
              </Grid>
            );
          })
        }
      </Grid>
      <div style={{margin: 'auto', maxWidth: 1240, width: '100%'}}>
        {
          features.map((elem, index) => {
            return (
              <>
                <Category index={index} />
                <Grid container className={classNames(classes.row, {
                  [classes.even]: (index % 2 === 1 && index !== 0),
                  [classes.odd]: index % 2 === 0
                })}>
                  <Grid
                    style={{justifyContent: 'flex-start'}}
                    className={classNames(classes.cell)}
                    xs={4}>
                      <Tooltip
                        title={elem.tooltip}
                        disableHoverListener={!elem.tooltip}
                        disableFocusListener={!elem.tooltip}
                      >
                        <span style={{paddingRight: 5}}>
                          {elem.name}
                        </span>
                      </Tooltip>
                    </Grid>
                  <Grid 
                    className={classNames(
                      classes.cell, 
                      classes.middleCell, 
                      'middle-cell',
                      {
                        ['reset-underline']: elem?.noUnderline
                      })} xs={4}>
                    <Feature feature={elem} index={1} />
                  </Grid>
                  <Grid 
                    className={classNames(classes.cell, {
                      ['reset-underline']: elem?.noUnderline
                    })} 
                    xs={4}
                  >
                    <Feature feature={elem} index={0} />
                  </Grid>
                </Grid>
              </>
            )
          })
        }
      </div>
      <Grid container style={{maxWidth: 1240, width: '100%', marginLeft: 'auto', marginRight: 'auto'}}>
        <Grid xs={4}></Grid>
        {
          plans.map((plan, key) => {
            const pricingPlan = state.calcumatePlans.find(elem => {
              return elem.thePlan === plan || elem.thePlan === `${elem.thePlan}-${plan}`;
            });
            
            return (
              <Grid xs={4} className={classNames(classes.tableHeadCell, {
                [classes.firstPlanCell]: key === 0,
                [classes.secondPlanCell]: key === 1,
              })}>
                <Grid className={classes.planName}>{plan}</Grid>
                <Grid>
                  <PlanButton
                    plan={pricingPlan}
                    size='small'
                    state={state}
                    defaultServiceNumber={state.serviceNumber}
                    defaultService={state.service}
                  />
                </Grid>
              </Grid>
            );
          })
        }
      </Grid>
    </Grid>
  );
}