import React, { useState } from "react"
import { FormControl, InputLabel, InputAdornment, IconButton, OutlinedInput } from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Typography from '@material-ui/core/Typography';

const PasswordInput = props => {
  const { uniqueID, uniqueLabel, validationRequired } = props
  let [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false
  })
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
    props.setPassword(event.target.value);
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }
  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  return (
    <FormControl
      variant="outlined"
      fullWidth>
      <InputLabel htmlFor={uniqueID}>{uniqueLabel}</InputLabel>
      <OutlinedInput
        error={props.showError}
        id={uniqueID}
        data-cy={'password-field'} 
        label={uniqueLabel}
        type={values.showPassword ? 'text' : 'password'}
        value={values.password}
        onChange={handleChange('password')}
        onKeyPress={props.keyPressEnabled ? props.handleEnterKeyPress : undefined}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={70}
      />
      {validationRequired ? <Typography variant="caption" display="block" gutterBottom>Minimum: 8 characters, 1 lowercase, 1 uppercase, 1 number &amp; 1 special character</Typography> : ''}
    </FormControl>
  );
}

export default PasswordInput
