/*eslint-disable*/
import React, { useContext, useState, useEffect, Fragment } from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"

// core components
import { useGlobalState } from "hooks/useGlobalState"
import { useHistory } from "react-router-dom";
// Other components
import AdminTransactionalHeader from "components/AdminTransactionalHeader/AdminTransactionalHeader"
import PaymentSelected from "components/PaymentSelected/PaymentSelected"
import NewPaymentComponent from './NewPaymentComponent';

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles)

const AdminPaymentMethod = props => {
  let history = useHistory();
  const classes = useStyles()
  const dispatchState = useContext(useGlobalState.Dispatch)
  let state = useContext(useGlobalState.State)

  useEffect(() => {

    if (!state.isLoggedIn || state.signedInUser.token === '') {
      // redirect to the login page if the user is not logged in
      history.replace('/login');
      window.location.reload();
    }

  }, []);

  //Local state
  let [values, setValues] = useState({
    paymentMod: false
  })

  const setToModCard = mode => () => {
    setValues({ paymentMod: mode })
  }

  const triggerButtons = () => {
    if (values.paymentMod) {
      return (
        <NewPaymentComponent />
      )
    } else {
      return (
        <PaymentSelected
          cardIcon={state.signedInUser?.data?.card?.brand} // visa, mastercard, amex
          cardDigits={state.signedInUser?.data?.card?.last4digits}
          expDate={state.signedInUser?.data?.card?.expiry}
          marginTopBottom={20}
          editBtn={setToModCard(true)}
        />
      )
    }
  }

  return (
    <Fragment>
      <AdminTransactionalHeader
        homeBtn={false}
        backBtn={false}
        logo={false}
        closeBtn={() => props.history.push("/admin/my-account")}
      />
      <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
        <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
          <Typography variant="overline">Payment method</Typography>
          <Typography variant="h1" className={classes.headerTwoLine}>My credit card</Typography>

          {triggerButtons()}

        </Grid>
      </Grid>
    </Fragment>
  );
}

export default AdminPaymentMethod
