import React, {
  useEffect,
} from "react";

export default ({children}) => {

  useEffect(() => {
    document.querySelector('#root').classList.add('integration-layout');
    return () => document.querySelector('#root').classList.remove('integration-layout');
  }, []);

  return <div>{children}</div>;
}
