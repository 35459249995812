import React from 'react';
import styles from "assets/jss/material-dashboard-react/components/homeHeaderStyle";
import {makeStyles} from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
const useStyles = makeStyles(styles);

const UserIcon = props => {
  const classes = useStyles();
  return (
    <div
      data-cy={'login-button'}
      className={classes.userIconsWrap} 
      onClick={()=>props.history.push('/login')}
    >
      <svg
        id="prefix__Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 60 60"
        xmlSpace="preserve"
      >
        <style>{`.prefix__st1000{fill:${props.headerOn ? '#005695' : '#ffffff'}`}</style>
        <path
          d="M30 5h0c13.8 0 25 11.2 25 25h0c0 13.8-11.2 25-25 25h0C16.2 55 5 43.8 5 30h0C5 16.2 16.2 5 30 5z"
          fill="none"
          stroke={props.headerOn ? '#005695' : '#ffffff'}
          strokeWidth={2.941}
        />
        <path
          className="prefix__st1000"
          d="M30 27.7c3.9 0 7.1-3.2 7-7.1v-.3c-.1-3.8-3.2-6.9-7.1-6.8h-.3c-3.8.1-6.9 3.2-6.8 7.1.1 3.9 3.3 7.1 7.2 7.1zM30 32.1c-4.7 0-14.1 2.6-14.1 7.3 3.2 4.7 8.5 7.5 14.1 7.5 5.7 0 10.9-2.8 14.1-7.5 0-4.7-9.4-7.3-14.1-7.3z"
        />
      </svg>
    </div>
  );
}

export default withRouter(UserIcon);
