/*eslint-disable*/
import React, { useContext, useState, useEffect, Fragment, useMemo } from "react";
import { useGlobalState } from "hooks/useGlobalState";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {Grid, TextField, Button, Typography, Tooltip, Fab, Divider, Switch} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import PayButton from "../../views/FreeVersion/Button";
// core components
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';

// Other components
import PasswordInput from "components/PasswordInput/PasswordInput";
import AdminTransactionalHeader from "components/AdminTransactionalHeader/AdminTransactionalHeader";

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle";
import EditRounded from "@material-ui/icons/EditRounded";
import { emailRegex } from '../../helpers/validation';
import {mapPlansLimitations} from "../../variables/InitState";

const useStyles = makeStyles(styles);

const AdminEditAccount = props => {
  let history = useHistory();
  const classes = useStyles();
  const state = useContext(useGlobalState.State);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCurrent, setPasswordCurrent] = useState('');
  const [passwordValid, setPasswordValid] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState('initial');
  const [updateError, setUpdateError] = useState('');
  const [currentPasswordValid, setCurrentPasswordValid] = useState(true);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [emailExists, setEmailExists] = useState(false)
  const [editEmail, setEditEmail] = useState(false)
  let passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
  const dispatchState = useContext(useGlobalState.Dispatch);

  const restrictions = useMemo(() => {
    let restrictionList = mapPlansLimitations?.[state.currentPlan] || [];
    //enable some features for some users
    if ([
      'accounts@cheshire-storage.co.uk', 
    ].includes(state?.signedInUser?.data?.user?.email)) {
      if (!restrictionList.includes('integrationIncQty')) {
        restrictionList.push('integrationIncQty');
      }
      if (!restrictionList.includes('integrationCtaShouldIncludeUnitQuantity')) {
        restrictionList.push('integrationCtaShouldIncludeUnitQuantity');
      }
    }
    //state.signedInUser.data.user.email
    return restrictionList;
  }, [state?.signedInUser?.data?.user?.email]);

  /* eslint-disable */
  useEffect(() => {

    if (state.isLoggedIn && state.signedInUser.token !== '') {
      // set global state values to the local state
      setFirstName(state.signedInUser.data.user.firstName);
      setLastName(state.signedInUser.data.user.lastName);
    } else {
      history.replace('/login');
      window.location.reload();
    }

  }, []);

  useEffect(()=>{
    if (emailExists) setEmailExists(false)
  }, [newEmail])

  const checkValuesChanged = () => {
    return updating ||
      firstName === '' ||
      lastName === '' ||
      !(passwordMatch && passwordValid) ||
      (newEmail && (!emailRegex.test(newEmail) || newEmail !== confirmEmail || emailExists))
  };

  const handleInputChange = e => {
    if (e.target.id === "p-edit-fname") {
      setFirstName(e.target.value);
    }
    if (e.target.id === "p-edit-lname") {
      setLastName(e.target.value);
    }
  };

  const setCurrentPasswordValue = currentPassword => {
    if ((currentPassword !== '')) {
      setCurrentPasswordValid(true);
      setPasswordCurrent(currentPassword);
    } else {
      setCurrentPasswordValid(false);
    }
  };

  const setPasswordValue = passwordnew => {
    if (passwordnew === '' && passwordConfirm === '' && passwordCurrent === '') {
      setPasswordValid(true);
      setPasswordMatch(true);
    } else {
      if (passwordRegex.test(passwordnew)) {
        setPassword(passwordnew);
        setPasswordValid(true);
      } else {
        setPasswordValid(false);
      }
      setPasswordMatch(passwordnew === passwordConfirm);
    }

  };

  useEffect(() => {
    if (newEmail && emailRegex.test(newEmail) && newEmail === confirmEmail) {
      handleEmailCheck()
    }
  }, [newEmail, confirmEmail])

  const handleEmailCheck = async () => {
    setUpdating(true);
      await axios.post(
      process.env.REACT_APP_API_URL + 'checkemail',
      {
        email: newEmail
      },
      { headers: { 'Content-Type': 'application/json' } }
    ).then(function (response) {
      if (response.status && response.status === 200 && response.data && response.data !== '') {
        // set the error message
        setEmailExists(true);
      } else {
        setEmailExists(false);
      }
    })
      .catch(function (error) {
        console.error(error);
      });
    setUpdating(false);
  }

  const setPasswordConfirmValue = passwordconfirm => {
    setPasswordConfirm(passwordconfirm);
    setPasswordMatch(passwordconfirm === password);
  };

  /**
   * this function will submit user account change details to the API and display the response accordingly
   */
  const handleAccountEditSubmit = async () => {
    setUpdating(true);
    // prepare the object to send to the API
    let updatedUserData = {
      token: state.signedInUser.token, 
      userData: {
        ...state.signedInUser.data.user, 
        firstName: firstName, 
        lastName: lastName,
        email: state.signedInUser.data.user.email.toLowerCase(),
        reportConfig: state.reportConfig,
      },
    };

    // if the user has changed passwords, add that also
    if (passwordValid && passwordMatch) {
      updatedUserData.passwords = { current: passwordCurrent, new: password };
    }

    if (emailRegex.test(newEmail) && newEmail === confirmEmail && !emailExists) {
      updatedUserData.email = newEmail.toLowerCase();
    }

    // send the request to the API
    await axios.put(
      process.env.REACT_APP_API_URL + 'user/' + state.signedInUser.data.user.id,
      updatedUserData,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.signedInUser.token } }
    ).then(function (response) {
      setUpdating(false);

      if (response.status && response.status === 200 && response.data) {
        setUpdateSuccess(response.data.status ? 'true' : 'false');
        setUpdateError(!response.data.status ? response.data.error : '');
        if (response.data.status) {
          // if the response was a success, update the user details in the state
          const newEmail = updatedUserData.email ? {email: updatedUserData.email} : {}
          dispatchState({ type: 'root-state', payload: { signedInUser: { ...state.signedInUser, data: { ...state.signedInUser.data, user: { ...state.signedInUser.data.user, ...newEmail, firstName: firstName, lastName: lastName } } } } });
        }
      } else {
        setUpdateSuccess('false');
      }

    }).catch(function (error) {
      setUpdateSuccess('false');
      console.error(error);
    });


  };

  return (
    <Fragment>
      <AdminTransactionalHeader
        homeBtn={false}
        backBtn={false}
        logo={false}
        closeBtn={() => props.history.push("/admin/my-account")}
      />
      {state.isLoggedIn && state.signedInUser.token !== '' ?
        <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
          <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
            <Typography variant="overline">Account details</Typography>
              <Typography variant="h1" style={{position: 'relative'}} className={classes.headerTwoLine}>{state.signedInUser.data.user.email}<Tooltip title="Edit" aria-label="Edit">
                <Fab
                  color="primary"
                  aria-label={'Edit'}
                  size="medium"
                  onClick={() => setEditEmail(()=>{
                    if (editEmail) {
                      setNewEmail(null)
                      setConfirmEmail(null)
                      return !editEmail
                    }
                    return !editEmail
                  })}
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 3
                  }}
                >
                  <EditRounded />
                </Fab>
              </Tooltip>
              </Typography>
            {updateSuccess === 'true' ?
              <div className={classes.notification}>
                <br />
                <Alert severity="success">Update successful!</Alert>
              </div>
              : ''}
            {updateSuccess === 'false' ?
              <div className={classes.notification}>
                <br />
                <Alert severity="error">{updateError !== '' && typeof updateError === 'string' ? updateError : 'Something went wrong. Please try again.'}</Alert>
              </div>
              : ''}
            { editEmail && (
              <form noValidate autoComplete="off" className={{...classes.standardForm}} style={{marginBottom: 12, marginTop: 40}}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      helperText={emailExists ? "Email already used." : ''}
                      id="p-new-email"
                      label="New email"
                      variant="outlined"
                      type="text"
                      fullWidth
                      value={newEmail}
                      onChange={event => setNewEmail(event.target.value.trim())}
                      error={emailExists}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField id="p-confirm-email" label="Confirm email" variant="outlined" type="text" fullWidth value={confirmEmail} onChange={event => setConfirmEmail(event.target.value.trim())} />
                  </Grid>
                </Grid>
              </form>
            )}
            <form noValidate autoComplete="off" className={classes.standardForm} style={{marginTop: editEmail ? 0: 40}}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField id="p-edit-fname" label="First name" variant="outlined" type="text" fullWidth value={firstName} onChange={handleInputChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField id="p-edit-lname" label="Last name" variant="outlined" type="text" fullWidth value={lastName} onChange={handleInputChange} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    inputProps={{ maxLength: 30 }}
                    id="p-edit-company"
                    label="Company name (Optional)"
                    value={state.signedInUser.data.user.companyName}
                    variant="outlined"
                    helperText={ `${(state?.signedInUser?.data?.user?.companyName || '').length} / 30 character limit` }
                    type="text" disabled fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h2">Change password</Typography>
                </Grid>
                <Grid item xs={12}>
                  <PasswordInput
                    uniqueID="p-current-password"
                    validationRequired={false}
                    uniqueLabel="Current Password"
                    setPassword={password => setCurrentPasswordValue(password)}
                    keyPressEnabled={false}
                    showError={!currentPasswordValid}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PasswordInput
                    uniqueID="p-edit-password"
                    validationRequired={true}
                    uniqueLabel="New Password"
                    setPassword={password => setPasswordValue(password)}
                    keyPressEnabled={false}
                    showError={!passwordValid}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PasswordInput
                    uniqueID="p-edit-confirm-password"
                    uniqueLabel="Confirm New Password"
                    setPassword={password => setPasswordConfirmValue(password)}
                    showError={!passwordMatch}
                  />
                </Grid>
                <Grid item xs={12} fullWidth>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={1}
                    fullWidth
                    style={{gap: '15px'}}
                  >
                    <Typography variant="h5" component="h2">
                      Calculations Report Content
                    </Typography>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography variant="subtitle2">Include Date</Typography>
                      </Grid>
                      <Grid item>
                        {!restrictions.includes("includeDate") ? (
                          <PayButton
                            setUpdating={setUpdating}
                            feature={"includeDate"}
                            updating={updating}
                          />
                        ) : (
                          <Switch
                            checked={!!state.reportConfig?.includeDate}
                            onChange={() =>
                              dispatchState({
                                type: "root-state",
                                payload: {
                                  reportConfig: {
                                    ...state.reportConfig,
                                    includeDate: !state?.reportConfig?.includeDate,
                                  },
                                },
                              })
                            }
                            color="primary"
                            name="report-date"
                            inputProps={{ "aria-label": "Include Date into report" }}
                            disabled={!restrictions.includes("includeDate")}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography variant="subtitle2">
                          include Session ID
                        </Typography>
                      </Grid>
                      <Grid item>
                        {!restrictions.includes("includeSessionId") ? (
                          <PayButton
                            setUpdating={setUpdating}
                            feature={"includeSessionId"}
                            updating={updating}
                          />
                        ) : (
                          <Switch
                            checked={!!state?.reportConfig?.includeSessionId}
                            onChange={() =>
                              dispatchState({
                                type: "root-state",
                                payload: {
                                  reportConfig: {
                                    ...state.reportConfig,
                                    includeSessionId: !state?.reportConfig?.includeSessionId,
                                  },
                                },
                              })
                            }
                            color="primary"
                            name="report-session-id"
                            inputProps={{
                              "aria-label": "Include Session ID into report",
                            }}
                            disabled={!restrictions.includes("includeSessionId")}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography variant="subtitle2">
                          Include Recommended Unit
                        </Typography>
                      </Grid>
                      <Grid item>
                        {!restrictions.includes("includeRecommendedUnit") ? (
                          <PayButton
                            setUpdating={setUpdating}
                            feature={"includeRecommendedUnit"}
                            updating={updating}
                          />
                        ) : (
                          <Switch
                            checked={!!state?.reportConfig?.includeRecommendedUnit}
                            onChange={() =>
                              dispatchState({
                                type: "root-state",
                                payload: {
                                  reportConfig: {
                                    ...state.reportConfig,
                                    includeRecommendedUnit: !state?.reportConfig?.includeRecommendedUnit,
                                  },
                                },
                              })
                            }
                            color="primary"
                            name="report-unit"
                            inputProps={{
                              "aria-label": "Include Recommended Unit into report",
                            }}
                            disabled={
                              !restrictions.includes("includeRecommendedUnit")
                            }
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography variant="subtitle2">include Time</Typography>
                      </Grid>
                      <Grid item>
                        {!restrictions.includes("includeTime") ? (
                          <PayButton
                            setUpdating={setUpdating}
                            feature={"includeTime"}
                            updating={updating}
                          />
                        ) : (
                          <Switch
                            checked={!!state.reportConfig?.includeTime}
                            onChange={() =>
                              dispatchState({
                                type: "root-state",
                                payload: {
                                  reportConfig: {
                                    ...state.reportConfig,
                                    includeTime: !state?.reportConfig?.includeTime,
                                  },
                                },
                              })
                            }
                            color="primary"
                            name="report-time"
                            inputProps={{ "aria-label": "Include Time into report" }}
                            disabled={!restrictions.includes("includeTime")}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography variant="subtitle2">Include Items</Typography>
                      </Grid>
                      <Grid item>
                        {!restrictions.includes("includeItems") ? (
                          <PayButton
                            setUpdating={setUpdating}
                            feature={"includeItems"}
                            updating={updating}
                          />
                        ) : (
                          <Switch
                            checked={!!state.reportConfig?.includeItems}
                            onChange={() =>
                              dispatchState({
                                type: "root-state",
                                payload: {
                                  reportConfig: {
                                    ...state.reportConfig,
                                    includeItems: !state?.reportConfig?.includeItems,
                                  },
                                },
                              })
                            }
                            color="primary"
                            name="report-items"
                            inputProps={{ "aria-label": "Include items into report" }}
                            disabled={!restrictions.includes("includeItems")}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography variant="subtitle2">Include Url</Typography>
                      </Grid>
                      <Grid item>
                        {!restrictions.includes("includeUrl") ? (
                          <PayButton
                            setUpdating={setUpdating}
                            feature={"includeUrl"}
                            updating={updating}
                          />
                        ) : (
                          <Switch
                            checked={!!state.reportConfig?.includeUrl}
                            onChange={() =>
                              dispatchState({
                                type: "root-state",
                                payload: {
                                  reportConfig: {
                                    ...state.reportConfig,
                                    includeUrl: !state?.reportConfig?.includeUrl,
                                  },
                                },
                              })
                            }
                            color="primary"
                            name="report-url"
                            inputProps={{ "aria-label": "Include Url into report" }}
                            disabled={!restrictions.includes("includeUrl")}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography variant="subtitle2">
                          Include Calculator Name
                        </Typography>
                      </Grid>
                      <Grid item>
                        {!restrictions.includes("includeCalculator") ? (
                          <PayButton
                            setUpdating={setUpdating}
                            feature={"includeCalculator"}
                            updating={updating}
                          />
                        ) : (
                          <Switch
                            checked={!!state.reportConfig?.includeCalculator}
                            onChange={() =>
                              dispatchState({
                                type: "root-state",
                                payload: {
                                  reportConfig: {
                                    ...state.reportConfig,
                                    includeCalculator: !state?.reportConfig?.includeCalculator,
                                  },
                                },
                              })
                            }
                            color="primary"
                            name="report-calculator-name"
                            inputProps={{
                              "aria-label": "Include Calculator Name into report",
                            }}
                            disabled={!restrictions.includes("includeCalculator")}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography variant="subtitle2">
                          Include Dynamic Form data
                        </Typography>
                      </Grid>
                      <Grid item>
                        {!restrictions.includes("includeDynamicForm") ? (
                          <PayButton
                            setUpdating={setUpdating}
                            feature={"includeDynamicForm"}
                            updating={updating}
                          />
                        ) : (
                          <Switch
                            checked={!!state.reportConfig?.includeDynamicForm}
                            onChange={() =>
                              dispatchState({
                                type: "root-state",
                                payload: {
                                  reportConfig: {
                                    ...state.reportConfig,
                                    includeDynamicForm: !state?.reportConfig?.includeDynamicForm,
                                  },
                                },
                              })
                            }
                            color="primary"
                            name="report-dynamic-form"
                            inputProps={{
                              "aria-label": "Include Dynamic form into report",
                            }}
                            disabled={!restrictions.includes("includeDynamicForm")}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    component={Link}
                    className={classes.buttonSpacing}
                    color="primary"
                    size="large"
                    to={false}
                    fullWidth
                    disabled={checkValuesChanged()}
                    onClick={handleAccountEditSubmit}
                  >{updating ? <CircularProgress color='inherit' size={15} /> : 'Save account details'}</Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        :
        history.replace('/login')
      }
    </Fragment>
  );
}

export default AdminEditAccount