import React, {useEffect, useState} from 'react';

const Video = ({elem, index}) => {
  const [height, setHeight] = useState(453);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  if (!elem?.props?.children?.props?.children) return;
  const id = elem?.props?.children?.props?.children.split('/').pop();
  if (!id) return ;

  const onResize = (e) => {
    const wrap = document.querySelector('.wp-video-wrap');
    if (wrap) {
      setHeight(wrap.clientWidth * .56);
    }
  }

  return (
    <figure key={index} {...elem.props} className={'wp-video-wrap'}>
      <iframe key={index} width="100%" height="100%" style={{minHeight: height}} src={`https://www.youtube.com/embed/${id}?feature=oembed`}
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
    </figure>
  )
}

export default Video;
