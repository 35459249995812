import React, {useMemo} from "react";
import Slider from "react-slick";

//Styles
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import styles from "../../assets/jss/material-dashboard-react/views/HomePageStyle";
import "../../assets/css/custom.scss";
import classNames from 'classnames';

//Components
import SimpleHeading from "../SimpleHeading/SimpleHeading";
import EasyTruck from "./slides/EasyTruck";
import TaxiBox from "./slides/TaxiBox";
import Minilager from "./slides/Minilager";
import AppleSelfStorage from "./slides/AppleSelfStorage";
import Quraz from "./slides/Quraz";
import ClientsLogos from "./slides/ClientsLogos";
import G5 from '../../assets/img/g5.svg';
import Storable from '../../assets/img/Storable-M@2x.png';
import StorableDesktop from '../../assets/img/Storable-D@2x-desktop.png';
import Wordpress from '../../assets/img/wordpress.svg';
import WordpressS from '../../assets/img/wordpress-s.svg';
import EasyStorageSolution from '../../assets/img/easystoragesolution.svg';
import EasyStorageSolutionS from '../../assets/img/easystoragesolution-s.svg';
import StoreganiseWhite from '../../assets/img/Storeganise-White.png';
import StoreganiseWhiteMobile from '../../assets/img/Storeganise-White-Mobile.png';
import StoraLogo from '../../assets/img/stora-logo-white.png';
import StoraLogoM from '../../assets/img/stora-logo-white-m.png';
import PugDesktop from '../../assets/img/Pug-Desktop.png';
import PugMobile from '../../assets/img/Pug-Mobile.png';
import StoragelyWhitelogo from "../../assets/img/storagely-white-logo.png";
import StoragelyWhitelogoMobile from "../../assets/img/storagely-white-logo-mobile.png";
//Material UI
import {Grid, Typography, withWidth} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const WhoItWorkFor = props => {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 8000,
    adaptiveHeight: true,
    onInit: props => {
      document.querySelectorAll('.slick-slide').forEach(elem => elem.setAttribute('role', 'listitem'));
      document.querySelectorAll('.slick-track').forEach(elem => elem.setAttribute('role', 'list'));
    }
  };

  const mobile = useMemo(() => props.width === 'xs', [props.width])

  return (
    <section className={classes.whoItWorkFor} id={'who-it-work-for'}>
      <Grid
        container
        spacing={4}
        className={classes.whyItWorksWrap}
        alignItems="center"
      >
        <Grid item xs={12} md={12} className={classes.whyItWorksHead}>
          <SimpleHeading mb={10} weight={500} theme={'light'}>Who Calcumate works for</SimpleHeading>
          <Typography className={classes.homeSubHeaderWhite}>Over 500 calculators, across every continent, helping thousands of storage customers every day.</Typography>
        </Grid>
        <Slider {...settings} style={{width: '100%'}}>
          { !mobile && <ClientsLogos />}
          <AppleSelfStorage />
          <Quraz />
          <TaxiBox />
          <Minilager />
          <EasyTruck/>
        </Slider>
        <Grid item xs={12} md={12} className={classNames(classes.whyItWorksHead, classes.whyItWorksFooterWrap)}>
          <Typography className={classes.homeSubHeaderWhite}>Designed to be easily embedded onto any page of your website.</Typography>
          <Grid className={classes.whyItWorksFooterImages}>
            <img data-src={mobile ? StoragelyWhitelogoMobile : StoragelyWhitelogo} alt={'Storagely logo'} className="lazyload" />
            <img data-src={G5} alt={'G5 logo'} className="lazyload" />
            <img data-src={mobile ? EasyStorageSolutionS : EasyStorageSolution} alt={'EasyStorageSolution logo'} className="lazyload" />
            <img data-src={mobile ? WordpressS : Wordpress} alt={'Wordpress logo'} className="lazyload" />
            <img data-src={mobile ? Storable : StorableDesktop} alt={'Storable logo'} className="lazyload" />
            <img data-src={mobile ? StoreganiseWhiteMobile : StoreganiseWhite} style={{maxHeight: 35}} alt={'StoreganiseWhite logo'} className="lazyload" />
            <img data-src={mobile ? StoraLogoM : StoraLogo} style={{maxHeight: 35}} alt={'Stora logo'} className="lazyload" />
            <img data-src={mobile ? PugMobile : PugDesktop} style={{maxHeight: 35}} alt={'Pug Mobile'} className="lazyload" />
          </Grid>
        </Grid>
      </Grid>
    </section>
  )
}

export default withWidth()(WhoItWorkFor);
