import React, {useContext, useState, useMemo, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {
  Typography
} from "@material-ui/core";
import { Grid, Fab, makeStyles} from "@material-ui/core";
import CloseRounded from "@material-ui/icons/CloseRounded";
import axios from "axios";
import {useGlobalState} from "../../hooks/useGlobalState";
import CircularProgress from "@material-ui/core/CircularProgress";
import usePrice from "../../hooks/usePrice";
import { currentPricingPlan, round } from '../../helpers/common';
import styles from "assets/jss/material-dashboard-react/components/CustomPricingStyle";
import cloneDeep  from 'lodash.clonedeep';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(styles);
export default props => {
  const {showModal, setShowModal, updateIntegrations} = props;
  const [sending, setSending] = useState(false);
  const state = useContext(useGlobalState.State);
  const dispatchState = useContext(useGlobalState.Dispatch);
  const [message, setMessage] = useState('');

  const serviceNumber = useMemo(() => {
    return state.originServiceNumber + 1;
  }, []);

  useEffect(() => {
    setTimeout(() => setMessage(''), 2000);
  }, [message]);


  const plan = useMemo(() => currentPricingPlan(state), [
    state.signedInUser?.data?.user?.isCustomPricingPlan,
    state.signedInUser?.data?.user?.lastChosenSubscription
  ]);

  const submit = async () => {
    setSending(true);
    const key = state?.integrationDataOptions?.selectedIntegration;
    await axios.post(
     process.env.REACT_APP_API_URL + 'requestMoreCalculations',
     {
       email: state.signedInUser.data.user.email,
       serviceNumber,
       cukey: state.signedInUser.cukey
     },
     { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.signedInUser.token } })
     .then(result => {
        if (result.data?.status) {
          let integrations = cloneDeep(state.signedInUser.data.user.integrations);
          integrations[key] = {
            ...integrations[key],
            paused: !integrations[key].paused,
          };
          dispatchState({
            type: 'root-state',
            payload: {
              originServiceNumber: serviceNumber,
              serviceNumber,
              signedInUser: {
                ...state.signedInUser,
                data: {
                  ...state.signedInUser.data,
                  user: {
                    ...state.signedInUser.data.user,
                    integrations
                  }
                }
              }
            }
          });
          // setSending(false);
          // setShowModal(false);
          updateIntegrations(integrations, serviceNumber).then(() => {
            setSending(false);
            setShowModal(false);
            setMessage('Data saved!');
          });
        } else {
          setMessage('Saving Error');
          setSending(false);
        }
     })
     .catch(error => {
        console.log('error', error);
        setMessage('Saving Error');
        return setSending(false);
     });
  }

  const handleClose = () => {
    setShowModal(false);
  };

  const finalPrice = useMemo( () => {
    return usePrice({
      plan: plan, 
      currency: state.currency, 
      type: state.currentPlanPriceRec.indexOf('yr') > -1,
      number: serviceNumber,
    })
  }, [
    state.currency,
    state.cmPlanPayRec,
    serviceNumber,
    plan
  ]);

  const finalPricePretty = useMemo(() => {
    const currencyDetails = state.availableCurrencies.find(currency => currency.currency === state.currency);
    const sign = plan?.showSign ? currencyDetails?.sign : '';
    return `${sign}${round(finalPrice)}`
  }, [
    state.currency, 
    state.cmPlanPayRec, 
    serviceNumber,
    plan,
  ]);

  const splittedPrice = useMemo(() => {
    const currencyDetails = state.availableCurrencies.find(currency => currency.currency === state.currency);
    const sign = plan.showSign ? currencyDetails.sign : '';
    if (typeof(finalPrice) === 'number') {
      const pr = finalPrice / serviceNumber;
      const value = state.cmPlanPayRec ? ( pr / 12 ) : finalPrice / serviceNumber;
      return `${sign}${round(Number.isNaN(value) ? '0' : value)}/Facility/${state.cmPlanPayRec ? 'Month^' : 'Month'}`
    }
    return '';
  }, [
    state.currency,
    state.cmPlanPayRec,
    serviceNumber,
  ]);

  const finalPriceOrigin = useMemo( () => {
    return usePrice({
      plan: plan, 
      currency: state.currency,
      type: state.originPlanPriceRec.indexOf('yr') > -1,
      number: state.originServiceNumber,
    })
  }, [
    state.currency,
    state.cmPlanPayRec,
    state.originServiceNumber,
    plan
  ]);

  const finalPricePrettyOrigin = useMemo(() => {
    const currencyDetails = state.availableCurrencies.find(currency => currency.currency === state.currency);
    const sign = plan?.showSign ? currencyDetails?.sign : '';
    return `${sign}${round(finalPriceOrigin)}`
  }, [
    state.currency, 
    state.cmPlanPayRec, 
    state.originServiceNumber,
    plan,
  ]);

  const splittedPriceOrigin = useMemo(() => {
    const currencyDetails = state.availableCurrencies.find(currency => currency.currency === state.currency);
    const sign = plan.showSign ? currencyDetails.sign : '';
    if (typeof(finalPriceOrigin) === 'number') {
      const pr = finalPriceOrigin / state.originServiceNumber;
      const value = state.cmPlanPayRec ? ( pr / 12 ) : finalPriceOrigin / state.originServiceNumber;
      return `${sign}${round(Number.isNaN(value) ? '0' : value)}/Facility/${state.cmPlanPayRec ? 'Month^' : 'Month'}`
    }
    return '';
  }, [
    state.currency,
    state.cmPlanPayRec,
    state.originServiceNumber,
  ]);

  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{fontFamily: 'futura-pt, sans-serif'}}
    >
      <Grid style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', marginBottom: 15}}>
        <Fab
          color="primary"
          aria-label="Edit"
          size="small"
          onClick={handleClose}
          style={{border: '2px solid #00437C', borderRadius: 50}}
        >
          <CloseRounded />
        </Fab>
      </Grid>
      {
        message && (
          <Alert
            onClick={() => setMessage('')}
            severity="error">{message}</Alert>
        )
      }
      {/* <DialogTitle id="alert-dialog-title" style={{marginTop: 0}}><span style={{fontSize: 28}}>Request more units</span></DialogTitle> */}
      <DialogContent style={{display: 'flex', flexDirection: 'column', marginBottom: 15}}>
        <DialogContentText id="alert-dialog-description" style={{color: '#707070', fontSize: 20, marginBottom: 35}}>
          Putting this calculator live will increase your number of facilities to {serviceNumber}.
        </DialogContentText>
        <FormControl variant="outlined" style={{
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row'
        }} className={{width: '100%'}}>
          <Grid container>
            <Grid xs={12} item><Typography variant="subtitle2">Current plan:</Typography></Grid>
            <Grid xs={12} item>{splittedPriceOrigin}</Grid>
            <Grid xs={12} item>Total: {finalPricePrettyOrigin}</Grid>
          </Grid>
          <Grid container>
            <Grid xs={12} item><Typography variant="subtitle2">New plan:</Typography></Grid>
            <Grid xs={12} item>{splittedPrice}</Grid>
            <Grid xs={12} item>Total: {finalPricePretty}</Grid>
          </Grid>
        </FormControl>
      </DialogContent>
      <DialogActions style={{paddingRight: 24, paddingLeft: 24}}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={submit}
        >{
          sending ? (
            <CircularProgress color='inherit' size={15} />
          ) : 'Submit'
        }</Button>
      </DialogActions>
    </Dialog>
  )
}
