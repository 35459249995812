import React from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Card, CardContent, Typography, Fab, Tooltip } from "@material-ui/core"
import EditRounded from '@material-ui/icons/EditRounded'

// Other components
import classNames from "classnames"

// style
import styles from "assets/jss/material-dashboard-react/components/adminPanelsStyle"

const useStyles = makeStyles(styles)

const AdminPanels = props => {
  const classes = useStyles()
  const { theHeader, headerAction, panelTheme, children } = props

  const isDarkThemed = panelTheme === "dark" ? true : false
  return (
    <Card elevation={0} className={classNames(classes.customCard, {
      [classes.customCardDark]: isDarkThemed,
      [classes.disabled]: props.disabled,
    })}>
      <CardContent>
        {theHeader &&
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5" component="h2" className={classes.panelHeader}>{theHeader}</Typography>
            </Grid>
            {headerAction &&
              <Grid item>
                <Tooltip title="Edit" aria-label="Edit">
                  <Fab
                    color="primary"
                    aria-label="Edit"
                    size="medium"
                    onClick={headerAction}
                  >
                    <EditRounded />
                  </Fab>
                </Tooltip>
              </Grid>
            }
          </Grid>
        }
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default AdminPanels
