import React, { useContext, useEffect, useRef } from "react"
import throttle from 'lodash.throttle';
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"

// Core components
import { useGlobalState } from "hooks/useGlobalState";

// Other components
import GenericCalculatorLoader from 'components/GenericCalculatorLoader/GenericCalculatorLoader';
// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import { Typography } from "@material-ui/core";

import { getDomain } from '../../helpers/getDomain';

const useStyles = makeStyles(styles)

const IntegrationPreview = React.memo(() => {
  const classes = useStyles();
  const state = useContext(useGlobalState.State)

  const isReadyToDisplayCalculator =
    state.signedInUser && state.signedInUser.cukey !== "" &&
    state.integrationData && state.integrationData.integrationDomain !== "" &&
    state.signedInUser.data.user.integrations;

  useLoadExternalCalculator(isReadyToDisplayCalculator);
  const selectedIntegration = state.integrationDataOptions?.selectedIntegration ? state.integrationDataOptions.selectedIntegration : 0
  
  return (
    <div 
      className={state.integrationData.integrationStepperStep > 0 ? classes.integrationComponent2 : classes.integrationComponent} 
      style={state.integrationData.integrationActiveBgStyle === 'transparent' ? 
      {
        background: 'transparent'
      } : 
      { 
        backgroundImage: `linear-gradient(180deg, ${state.integrationData.integrationGradientOne} 40%, ${state.integrationData.integrationActiveBgStyle === "gradient" ? state.integrationData.integrationGradientTwo : state.integrationData.integrationGradientOne} 60%)` 
      }}>
      {isReadyToDisplayCalculator ?
        <div className={classes.fullContainer}>
          <div
            id="calcumate-root"
            style={{ opacity: 100, width: "100vw", maxHeight: 800, height: "100%", overflowX: "hidden", overflowY: "auto", boxSizing: "border-box" }}
            data-integration={getDomain(state.integrationData.integrationDomain)}
            data-integration-2={getDomain(state.integrationData.integrationDomain2 || '') || ''}
            data-ref={state?.adminMode?.cuid || state.signedInUser.cukey}
            data-customer={state.adminMode.cuemail}
            data-int={state.signedInUser?.data?.user?.integrations?.[selectedIntegration]?.id}>
          </div>
        </div>
        : <GenericCalculatorLoader>
          <Typography variant="h4" color="primary" component="p">Calculator is being built...</Typography>
          <Typography variant="subtitle1" color="primary" component="p">Please complete all inputs and click NEXT to generate your preview</Typography>
        </GenericCalculatorLoader>
      }
    </div>
  )
});

// ============================================================================
// Helpers
// ============================================================================

const loadExternalCalcumateScript = () => {
  const script = document.createElement("script");

  script.src = process.env.REACT_APP_CALCULATOR_SCRIPT_URL;
  script.async = false;

  // loop through existing children and delete the script if exists

  [...document.body.children]
    .filter((node) => node.src === process.env.REACT_APP_CALCULATOR_SCRIPT_URL)
    .forEach((node) => node.remove());

  [...document.head.children]
    .filter((node) =>
      node.innerHTML.includes('ResultsSection-module__itemInitial') ||  node.innerHTML.includes('data-simplebar')
    )
    .forEach((node) => node.remove());

  const scripts = [...document.head.children]
    .filter((node) => (node?.sheet?.type === 'text/css' && node?.dataset?.styled === 'active' && node?.dataset?.styledVersion))

  if (scripts.length > 1) {
    try {
      scripts[0].remove()
    } catch (e) {
      console.error('Item does not exist')
    }
  }
  document.body.appendChild(script);
};

const debouncedLoadExternalCalcumateScript = throttle(loadExternalCalcumateScript, 750);

const useLoadExternalCalculator = (isReadyToDisplayCalculator) => {
  const isMountedRef = useRef(true);
  const state = useContext(useGlobalState.State)
  // Track mounting status to prevent reloading calculator when unmounted.
  // The empty ([]) dependency array instructs this useEffect to run once on
  // mount. Subsequently, this means this will only clean up once on unmount

  useEffect(
    () =>
      function unmount() {
        isMountedRef.current = false;
        debouncedLoadExternalCalcumateScript.cancel();
      },
    []
  );

  // Reload calculator whenever parent rerenders (no dependency array)
  useEffect(() => {
    if (isReadyToDisplayCalculator && isMountedRef.current) {
      debouncedLoadExternalCalcumateScript();
    }
  }, [state?.signedInUser?.data?.user]);
};

export default IntegrationPreview
