import React, {useEffect, useMemo, useState} from 'react';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from "@material-ui/core/CircularProgress";

const Code = ({elem, index}) => {
  const wrapID = useMemo(() => `code-section-${index}`, []);
  const [parsedElements, setParsedElements] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(false);

  function addScript( src, item ) {
    setLoading(true)
    let s = document.createElement( 'script' );
    s.setAttribute( 'src', src );
    s.addEventListener('load', () => {
      setLoading(false);
    });
    s.addEventListener('error', () => {
      setLoading(false);
      setIsValid(false);
    });

    item.appendChild( s );
  }
  useEffect(() => {
    const item = document.getElementById('cms-content')
    if (elem?.props?.children?.props?.children && typeof elem?.props?.children?.props?.children === 'string') {
     import('html-react-parser').then(({default: parse}) => {
      const parser = parse(elem.props.children.props.children);
      if (parser && parser.forEach) {
        parser.forEach(node => {
          if (typeof node === 'object' && node.type !== 'script') {
            setParsedElements([...parsedElements, node]);
          } else if (node.type === 'script') {
            if (node?.props?.src)
              addScript(node.props.src, item)
          }
        });
      } else if (parser.type === "script") {
        if (parser?.src)
          addScript(parser.src, item)
      }
     }) 
    } else if (elem.type === 'script') {
      if (elem?.props?.src)
        addScript(elem.props.src, item);
    } else {
      setIsValid(false);
    }
    return () => {}
  }, [elem]);

  return (
    <div key={index} id={wrapID} style={{marginTop: 25, marginBottom: 25, minHeight: loading ? 600 : 'unset'/*, paddingLeft: 25, paddingRight: 25*/}}>
        <div style={{width: '100%', alignItems:"center", justifyContent: 'center', display: loading ? 'flex' : 'none' }}>
          <CircularProgress style={{margin: 'auto', marginTop: loading ? 240 : 0}} color={'primary'} />
        </div>
      {
        isValid ? parsedElements : (
          <Alert
            severity="error"
            style={{ marginBottom: "1rem" }}
          >
            Invalid code integration.
          </Alert>
        )
      }
    </div>
  )
};

export default Code;
