import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import capitalize from 'lodash.capitalize';
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { 
  Grid, 
  Button, 
  TextField,
} from "@material-ui/core"

// Core components
import { useHistory, Link } from "react-router-dom"
import PasswordInput from "components/PasswordInput/PasswordInput"

// Other components
import { useGlobalState } from "hooks/useGlobalState";

import { sendAdminProgressEmail } from '../../../helpers/SendEmail';
import { allowedStorageTypes, emailRegex } from '../../../helpers/validation'
// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
import CircularProgress from "@material-ui/core/CircularProgress";
import AgreementBlock from "components/AgreementBlock/AgreementBlock";

const useStyles = makeStyles(styles)

const AccountStep = () => {
  const classes = useStyles();
  let history = useHistory();
  const dispatchState = useContext(useGlobalState.Dispatch);
  let state = useContext(useGlobalState.State);
  let passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
  const [sending, setSending] = useState(false);
  const [emailExists, setEmailExists] = useState(false);

  useEffect(() => {
    // set the plan chosen in the state
    const urlParams = new URLSearchParams(window.location.search);
    let selectedPlan = "Complete";
    let selectedService = 'self-storage';
    let serviceNumber = 1;
  
    for (const key of urlParams.keys()) {
      if (key === 'plan') {
        selectedPlan = capitalize(urlParams.get("plan") || state.currentPlan || "Complete");
      }
      if (allowedStorageTypes.includes(key)) {
        selectedService = key;
        serviceNumber = urlParams.get(key) || 1;
      }
    }

    dispatchState({
      type: "root-state",
      payload: {
        highlightedPlan: getSelectedPlanIndex(selectedPlan),
        currentPlan: selectedPlan,
        // Set stepper step number 1
        signUpStepperStep: 0,
        service: selectedService,
        serviceNumber: serviceNumber,
      },
    });
  }, [state.currentPlan, dispatchState]);

  const handleChange = e => {
    let obj = {};
    obj[e.target.name] = e.target.value;
    let emailInvalid = false;
    if (e.target.name === 'email' && e.target.value !== '') {
      setEmailExists(false);
      // validate email
      if (emailRegex.test(e.target.value)) {
        emailInvalid = false;
        obj.emailValidated = true;
      } else {
        emailInvalid = true;
        obj.emailValidated = false;
      }

    }
    obj.emailInvalid = emailInvalid;
    dispatchState({ type: 'reg-state', payload: { userData: obj } });

  }

  const setPassword = password => {
    let obj = {};
    let passwordInvalid = false;
    // validate password
    if (passwordRegex.test(password)) {
      passwordInvalid = false;
      obj.passwordValidated = true;
      obj.password = password;
    } else {
      passwordInvalid = true;
      obj.passwordValidated = false;
    }
    obj.passwordInvalid = passwordInvalid;
    dispatchState({ type: 'reg-state', payload: { userData: obj } });
  }

  const handleEmailCheck = async () => {
    if (emailRegex.test(state.userData.email)) {
      // use the authentication endpoint and check whether the response returns an error with user doesn't exist message
      await axios.post(
        process.env.REACT_APP_API_URL + 'checkemail',
        {
          email: state.userData.email.toLowerCase()
        },
        { headers: { 'Content-Type': 'application/json' } }
      ).then(function (response) {
        if (response.status && response.status === 200 && response.data && response.data !== '') {
          // set the error message
          setEmailExists(true);
        } else {
          setEmailExists(false);
        }
      })
        .catch(function (error) {
          console.error(error);
        });
    }
    // if the email is valid, check whether it already exists in the record
  }
  const getStripeSubscriptionId = () => {
    // loop through the subscriptions and get the relevant one
    let selectedPlan = state.calcumatePlans.filter(plan => {
      return plan.thePlan.toLowerCase() === state.currentPlan.toLowerCase();
    });

    const sids = selectedPlan?.[0]?.[`sid${state.currency}`];
    return sids[(state.cmPlanPayRec ? 0 : 1)];
  };


  const handleNextStepClick = async () => {
    setSending(true);
    try {
      await sendAdminProgressEmail(1, {
        firstName: state.userData.firstName,
        lastName: state.userData.lastName,
        phone: state.userData.phone,
        email: state.userData.email,
        company: state.userData.company,
        plan: state.currentPlan,
        currency: state.currency,
        pricing: getStripeSubscriptionId(),
        serviceNumber: state.serviceNumber,
        service: state.service,
      });

      const response = await axios.post(
        process.env.REACT_APP_API_URL + 'confirmation',
        {
          email: state.userData.email,
          firstName: state.userData.firstName
        },
        { headers: { 'Content-Type': 'application/json' } }
      )

      if (response.data && response.data.status) {
        dispatchState({ type: 'reg-state', payload: { userData: { confirmationCode: response.data.code } } });
      }
      setSending(false);
      history.push('/sign-up/confirmation');
    } catch (e) {
      console.log('Error: ', e);
      setSending(false);
    }
  };

  return (
    <form noValidate autoComplete="off" className={classes.standardForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            id="p-signup-email"
            error={state.userData.emailInvalid || emailExists}
            helperText={emailExists ? "Email already used." : ''}
            name="email"
            label="Email *"
            variant="outlined"
            type="email"
            fullWidth
            onChange={handleChange}
            value={state.userData.email}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField id="p-signup-fname" name="firstName" onFocus={handleEmailCheck} label="First name *" variant="outlined" type="text" fullWidth onChange={handleChange} value={state.userData.firstName} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField id="p-signup-lname" name="lastName" onFocus={handleEmailCheck} label="Last name *" variant="outlined" type="text" fullWidth onChange={handleChange} value={state.userData.lastName} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputProps={{ maxLength: 30 }}
            id="p-signup-company"
            name="company"
            onFocus={handleEmailCheck}
            label="Company name *"
            variant="outlined"
            type="text"
            fullWidth
            onChange={handleChange}
            value={state.userData.company}
            helperText={ `${(state.userData.company || '').length} / 30 character limit` }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField id="p-signup-phone" name="phone" onFocus={handleEmailCheck} label="Phone number (Optional)" variant="outlined" type="phone" fullWidth onChange={handleChange} value={state.userData.phone} />
        </Grid>
        <Grid item xs={12}>
          <PasswordInput
            uniqueID="p-signup-password"
            validationRequired={true}
            uniqueLabel="Password"
            setPassword={password => setPassword(password)}
            keyPressEnabled={false}
            showError={state.userData.passwordInvalid}
          />
        </Grid>
        <Grid item xs={12}>
          <AgreementBlock />
        </Grid>
        <Grid item xs={6}>
          <Button
            component={Link}
            to="/pricing"
            variant="outlined"
            className={classes.buttonSpacing}
            color="primary"
            size="large"
            fullWidth
            disabled={state.submittingUserData}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            onClick={handleNextStepClick}
            className={classes.buttonSpacing}
            color="primary"
            size="large"
            fullWidth
            disabled={sending || state.userData.company === '' || !state.userData.passwordValidated || !state.userData.emailValidated || state.userData.firstName === '' || state.userData.lastName === '' || emailExists}
          >{ sending ? <CircularProgress color='inherit' size={20} /> : 'Next' }</Button>
        </Grid>
      </Grid>
    </form>
  )
}

// ============================================================================
// Helpers
// ============================================================================

const getSelectedPlanIndex = (selectedPlan) => {
  switch (selectedPlan) {
    case "Limited":
      return 0;
    case "Complete":
    case "Complete-container":
    case "Complete-movers":
    case "Complete-portable":
    case "Complete-kiosks":
      return 1;
    case "Contact-us":
      return 2;
    default:
      // If we catch errors, we should throw here as this is not a planned user flow
      // E.g. a user navigates to /sign-up?plan=fake or /sign-up
      // throw new Error();
      return 0;
  }
};

export default AccountStep
