import React from "react";
import Logos from "../../../assets/img/logos.png";
import Quraz from "./Quraz";
import {Grid} from "@material-ui/core";

export default () => {
  return (
    <Grid style={{
      background: `url(${Logos})`,
      height: '100%',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      backgroundSize: 'contain'
    }}>
      <div style={{visibility: "hidden"}}> {/*This is terrible solution, but easiest solution to make all slides the same height*/}
        <Quraz />
      </div>
    </Grid>
  )
}
