import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-react/views/HomePageStyle";
import classNames from "classnames";
import TBExample2 from "../../../assets/img/ET-Example-slide-2.webp";
import SlideLogo2 from "../../../assets/img/logo-slide-2.webp";
const useStyles = makeStyles(styles);

export default () => {
  const classes = useStyles();
  return (
    <div>
      <Grid container>
        <Grid xs={12} sm={6} md={6} item className={classNames(classes.whyItWorksSlide1Left, classes.whyItWorksSlide2Left)}>
          <img
            style={{maxWidth: 580, width: '100%', height: 'auto'}}
            className={classNames(classes.whyItWorksSlide1LeftImg,classes.whyItWorksSlide2LeftImg)}
            src={TBExample2}
            alt={'Easytruck logo'}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} className={classNames(classes.whyItWorksSlide1Right, classes.whyItWorksSlide2Right)}>
          <Grid style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}} className={classes.whyItWorksSlide2RightWrap}>
            <a href={'https://easytruck.ae/moving-calculator/'} target={'_blank'} rel="noopener">
              <img
                src={SlideLogo2}
                className={classNames(classes.whyItWorksSlide1RightLogo, classes.whyItWorksSlide12RightLogo)}
                alt={'Easytruck logo'}
              />
            </a>
            <Grid>
              <Typography className={classNames(classes.whyItWorksSlide1RightText, classes.whyItWorksSlide2RightText)}>Easytruck operates over 700 Pods and an award-winning moving team in the heart of Dubai. They use Calcumate on their website to help both movers and storers.</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
