import React, { Fragment } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"

// Core components
import classNames from "classnames"
// import {Router} from "react-router-dom"

// Other components
import AdminTransactionalHeader from "../../components/AdminTransactionalHeader/AdminTransactionalHeader"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
const useStyles = makeStyles(styles)

const PrivacyPolicy = props => {
  const classes = useStyles()

  return (
    <Fragment>
      <AdminTransactionalHeader
        homeBtn={() => { props.history.push("/") }}
        backBtn={false}
        logo={true}
        closeBtn={false}
      />
      <Grid container spacing={0} justify="center" className={classes.cFormContaner} style={{marginBottom:40}}>
        <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
          <Typography variant="h1" className={classNames(classes.headerOneLine, classes.textMarginBottom)}>Privacy policy</Typography>
          <Typography variant="body1" className={classes.textMarginBottom}><strong>Calcumate is committed to protecting the privacy of its customers. This document is a description of the policies that any subsidiary or related entities hereafter referred to as Calcumate have adopted to ensure that your personal information is secure and your privacy is protected. It governs the collection, use and disclosure of your personal information by us.</strong></Typography>

          <Typography variant="body1" className={classes.textMarginBottom}><strong>We reserve the right to modify or amend this document at any time and for any reason. You should check this page periodically for changes. Your continued use of Calcumate services including the website following any modifications or amendments to this document means that you accept those modifications or amendments.</strong></Typography>

          <Typography variant="body1" className={classes.textMarginBottom}><strong>This document is to be read in conjunction with the Calcumate Terms and Conditions of Service (“the Agreement”).</strong></Typography>
          <Typography variant="h5" component="h2" className={classes.textMarginBottom}>Privacy Policy</Typography>
          <Typography variant="body1" className={classes.textMarginBottom}>This Privacy Policy outlines the policy of Calcumate. and its related entities. This privacy policy outlines how and why we need to collect and retain your personal information, and how we handle, protect, use and disclose that personal information.</Typography>
          <Typography variant="h5" component="h2" className={classes.textMarginBottom}>1. Collection of information</Typography>
          <Typography variant="body1" className={classes.textMarginBottom}>We need to collect, retain and use information about you, including your personal information to do business with you. Generally, this information is collected directly from you but we may collect information about you that is publicly available, for example from public registers or social media, or made available by third parties.  Information we collect may include:
          <ul>
            <li>your name</li>
            <li>licence number and details</li>
            <li>date of birth</li>
            <li>passport number</li>
            <li>telephone number</li>
            <li>email address</li>
            <li>physical address</li>
            <li>CCTV footage and;</li>
            <li>any other information reasonably necessary.</li>
          </ul></Typography>
          <Typography variant="body1" className={classes.textMarginBottom}>We may also need to collect payment information from you, including credit card or direct debit information, and we collect and retain information about your ongoing usage and payment history.</Typography>
          <Typography variant="body1" className={classes.textMarginBottom}>When you use our website or mobile applications we may collect information about your physical location and/or web activity including your IP address, your telephone number(s) and whether you’ve accessed or been referred to us via a third party site. Some of this website information we collect using Cookies.</Typography>
          <Typography variant="body1" className={classes.textMarginBottom}>You may choose not to provide us with any of this information, but not doing so may affect our ability to do business with you.</Typography>

          <Typography variant="h5" component="h2" className={classes.textMarginBottom}>2.	Primary purposes of collection</Typography>
          <Typography variant="body1" className={classes.textMarginBottom}>We need to collect information from you for a variety of purposes whilst we do business with you. These primary purposes include:
          <ul>
            <li>To establish your identity</li>
            <li>To be able to contact you during the business relationship</li>
            <li>To provide you with information about your account, including billing and provision of notices</li>
            <li>To provide you with information about our business and related entities, including promotions</li>
            <li>To be able to answer questions from you about your account and our services</li>
            <li>To manage our risk, including checking any relevant credit history entity</li>
            <li>To cooperate with enquiries from government and law enforcement bodies and comply with our legal obligations</li>
            <li>To enforce our agreement with you.</li>
          </ul></Typography>

          <Typography variant="h5" component="h2" className={classes.textMarginBottom}>3. Disclosure</Typography>
          <Typography variant="body1" className={classes.textMarginBottom}>We may disclose any information we have about you including your personal information as is reasonably necessary or as is permitted by law, including but not limited to the following:
          <ul>
            <li>our employees, including temp or externally contracted staff</li>
            <li>service providers engaged by us, such as data storage, IT, software management, insurers and financial services</li>
            <li>third parties engaged to perform specific functions within our business,</li>
            <li>government departments</li>
            <li>law enforcement agencies, including the police</li>
            <li>investigators</li>
            <li>liquidators, administrators or other persons appointed to administer your financial affairs</li>
            <li>debt collection services</li>
            <li>agents for any of the above.</li>
          </ul></Typography>
          <Typography variant="body1" className={classes.textMarginBottom}>We may send your information overseas. Some of our service providers or other third parties, including data storage providers, may be located, operate or hold data outside of Australia. When information is sent or disclosed outside of Australia we take reasonable efforts to ensure that appropriate data handling and security arrangements are in place. We utilise service providers who are located in a number of countries outside Australia, including New Zealand, the UK, the USA. Please note that Australian law may not apply to some of these entities in those countries.</Typography>

          <Typography variant="h5" component="h2" className={classes.textMarginBottom}>5. Marketing</Typography>
          <Typography variant="body1" className={classes.textMarginBottom}>We may use your contact details, including email, and telephone numbers, to contact you for marketing purposes. You may withdraw this consent at any time. We may share your information with our related entities. We will not share your contact details with third party enterprises, and will never sell your personal information or contact details. If you wish to opt out of direct marketing, please contact us at <a href="mailto:info@calcumate.co">info@calcumate.co</a>.</Typography>

          <Typography variant="h5" component="h2" className={classes.textMarginBottom}>6. Security</Typography>
          <Typography variant="body1" className={classes.textMarginBottom}>We may keep your information provided to us in both hard form and electronic form. We take steps to secure this from misuse, loss, unauthorised modification, unauthorised disclosure and access. We employ physical security such as alarms, restricted access to hard files, and other security measures including password protected computers and internet firewalls. We take reasonable care when engaging third parties to provide services to us where those service may necessitate the third party handling your information, including data storage, file destruction, enforcement of the agreement in any way and debt collection.</Typography>

          <Typography variant="h5" component="h2" className={classes.textMarginBottom}>7. Accuracy of information we hold about you, your right to access and your right to correct</Typography>
          <Typography variant="body1" className={classes.textMarginBottom}>You may view and correct your personal information by:
          <ul>
            <li>giving us reasonable notice in writing that you wish to view your personal information, and</li>
            <li>paying reasonable costs for the provision of access.</li>
          </ul></Typography>
          <Typography variant="body1" className={classes.textMarginBottom}>If we refuse access, we will provide you with a written reason for the refusal.</Typography>
          <Typography variant="body1" className={classes.textMarginBottom}>To correct inaccurate information we hold about you, you may advise us in writing that your personal information is not correct and provide us with written corrections. We will endeavour to update within a reasonable time.</Typography>

          <Typography variant="h5" component="h2" className={classes.textMarginBottom}>8. Your Rights – how to contact us about your privacy</Typography>
          <Typography variant="body1" className={classes.textMarginBottom}>If you believe we have breached the Privacy Act 1988 or Australian Privacy Principles and wish to complain, please contact us at info@Calcumate.co or PO Box 635 Double Bay NSW 1360.</Typography>
          <Typography variant="body1" className={classes.textMarginBottom}>We will endeavour to resolve your complaint within 21 days. If you do not believe your complaint has been satisfactorily handled or resolved you can lodge a complaint with the Office of the Australian Information Commissioner. For more information visit: <a href="https://www.oaic.gov.au" target="_blank" rel="noopener noreferrer">www.oaic.gov.au</a></Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default PrivacyPolicy
