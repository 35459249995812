import React, {Fragment, useEffect, useState, useContext, useMemo} from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Button, Grid, Typography, Link, withWidth, } from "@material-ui/core"

// Core components

// Other components
import CustomPricingPanelsSlider from "components/CustomPricing/CustomPricingPanelsSlider"
import AdminTransactionalHeader from "../../components/AdminTransactionalHeader/AdminTransactionalHeader"
import { useGlobalState } from "hooks/useGlobalState";
import classNames from 'classnames';
// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
import SelectService from "components/CustomPricing/SelectService"
import { whiteColor } from "assets/jss/material-dashboard-react"

const useStyles = makeStyles(styles)

const Pricing = props => {
  const classes = useStyles();
  const [service, setService] = useState('self-storage');
  const dispatchState = useContext(useGlobalState.Dispatch);
  const updateService = (service) => {
    setService(service);

    if (props.location.pathname !== "/facilities") {
      props.history.push("/pricing/facilities?service="+service);
    }
  }

  useEffect(() => {
    dispatchState({ type: 'root-state', payload: { 
        service
      }
    });
  }, [
    service,
  ]);

  const showContactUsButton = useMemo(() => {
    return props.history?.location?.pathname === '/pricing/facilities' && !['sm', 'xs'].includes(props.width);
  }, [
    props.history.location,
    props.width
  ]);

  const bakButton = () => {
    const path = props.history?.location?.pathname;
    switch (path) {
      case '/pricing/facilities':
        props.history.push('/pricing');
        break;
      // case '/pricing':
      //   props.history.goBack();
      //   break;  
      default:
        props.history.push('/');
        break; 
    }
  }

  return (
    <Fragment>
      <AdminTransactionalHeader
        homeBtn={false}
        backBtn={bakButton}
        logo={true}
        closeBtn={false}
        customClasses={classes.pricingPageTitle}
      />
      {
        showContactUsButton && (
          <Button
            size="small"
            variant="contained"
            component={Link}
            to="/contact-us"
            className={classes.contactUsButtonHeader}
          >TALK TO SALES</Button>
        )
      }

      <Grid
        container
        spacing={0}
        justify="center"
        style={{backgroundColor: whiteColor}}
        className={classNames(classes.cFormContanerNoLimits, classes.cFormContanerNoLimitsPricing)}
      >
        <Grid item xs={12}>
          <Typography variant="h1" className={classNames(classes.headerOneLine, classes.pricingTitle)} data-cy={'main-header'}>Choose your Calcumate plan</Typography>
          <br/>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{
          maxWidth: 1336,
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '100%'
        }}
        >
         {props.location.pathname === "/pricing" &&
            <SelectService 
              selectedService={service}
              setService={updateService}
            />
          }
          {props.location.pathname === "/pricing/facilities" &&
            <CustomPricingPanelsSlider
              selectedService={service}
              setSelectedService={updateService}
            />
          }
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default withWidth()(Pricing)
