import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-react/views/HomePageStyle";
import classNames from "classnames";
import QurazExample from "../../../assets/img/Quraz-Example.webp";
import QurazNoBg from "../../../assets/img/Quraz-no-bg.png";
const useStyles = makeStyles(styles);

export default () => {
  const classes = useStyles();
  return (
    <div>
      <Grid container>
        <Grid xs={12} sm={6} md={6} item className={classNames(classes.whyItWorksSlide1Left, classes.whyItWorksSlide3Left)} style={{backgroundColor: '#DF7523'}}>
          <img
            style={{maxWidth: 581, width: '100%', height: 'auto', maxHeight: 366}}
            className={classNames(classes.whyItWorksSlide1LeftImg,classes.whyItWorksSlide2LeftImg)}
            src={QurazExample}
            alt={'Quraz logo'}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} className={classNames(classes.whyItWorksSlide1Right, classes.whyItWorksSlide2Right)}>
          <Grid style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}} className={classes.whyItWorksSlide2RightWrap}>
            <a href={'https://www.quraz.com/common/sizeguide.aspx'} target={'_blank'} rel="noopener">
              <img
                src={QurazNoBg}
                className={classNames(classes.whyItWorksSlide1RightLogo, classes.whyItWorksSlide12RightLogo)}
                alt={'Quraze logo'}
              />
            </a>
            <Grid>
              <Typography className={classNames(classes.whyItWorksSlide1RightText, classes.whyItWorksSlide2RightText)}>                Japan’s largest indoor self-storage operator, Quraz has over 38,000 units and uses Calcumate to correctly guide new customers towards the unit size that matches their needs.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

