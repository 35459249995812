/*eslint-disable*/
import React, {useContext, Fragment, useMemo} from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"

// core components
import { useGlobalState } from "hooks/useGlobalState"

// Other components
import AdminTransactionalHeader from "components/AdminTransactionalHeader/AdminTransactionalHeader"
import CustomPricingPanel from "components/CustomPricing/CustomPricingPanels"
import usePrice from "../../hooks/usePrice";
// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles)

const AdminMySubscription = props => {
  const classes = useStyles();
  let state = useContext(useGlobalState.State);

  const price = useMemo(() => {
    let selectedPlan = [
      ...state.calcumatePlans,
      ...state.calcumateCustomPlans,
      ...state.calcumatePlansOld,
    ].filter(plan => {
      return plan.sidUSD.includes(state?.originPricingPlan) ||
        plan.sidAUD.includes(state?.originPricingPlan) || 
        plan.sidGBP.includes(state?.originPricingPlan) || 
        plan.sidEUR.includes(state?.originPricingPlan);
    });

    if (!state.originPlanPriceRec) {
      window.location.replace('/');
    }

    if (!selectedPlan.length)
      return 'FREE';

    const selectedPrice = usePrice({
      plan: selectedPlan?.[0], 
      currency: state.originCurrency, 
      type: state.originPlanPriceRec.indexOf('yr') > -1,
      number: state.serviceNumber,
    });

    const currencyDetails = state.availableCurrencies.find(currency => currency.currency === state.originCurrency);

    if (typeof selectedPrice === 'number')
      return `${currencyDetails.sign}${selectedPrice}${state.originPlanPriceRec}`
    return selectedPrice;
  }, [
    state.currentPlanPrice, 
    state.currentPlan,
  ]);

  return (
    <Fragment>
      <AdminTransactionalHeader
        homeBtn={false}
        backBtn={false}
        logo={false}
        closeBtn={() => props?.history?.location?.state?.builder ? props.history.goBack() : props.history.push('/admin/my-account')}
      />
      <Grid container spacing={0} justify="center" className={classes.cFormContanerNoLimits} style={{maxWidth: 1344}}>
        <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
          <Typography variant="overline">My Subscription</Typography>
          <Typography variant="h1" className={classes.headerTwoLine}>{state.currentPlanLabel} {price}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} className={classes.standardForm} style={{
          maxWidth: 1200,
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '100%'}}
        >
          <CustomPricingPanel />
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default AdminMySubscription
