import React, {useContext, useEffect, useMemo, useState} from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import {
  Grid,
  Button,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Fab,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Popover,
  Switch, 
  Select,
} from "@material-ui/core"
import EditRounded from '@material-ui/icons/EditRounded'
import DeleteForeverRounded from '@material-ui/icons/DeleteForeverRounded';
import HelpRounded from "@material-ui/icons/HelpRounded";
import Alert from '@material-ui/lab/Alert';
// Core components
import { useGlobalState } from "hooks/useGlobalState"

// Other components
import { saveIntegration } from '../../../helpers/SaveIntegration';
import {mapPlansLimitations} from '../../../variables/InitState';

// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import PayButton from "../../FreeVersion/Button";
import classNames from "classnames";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import DisabledFieldPlaceholder from "../../FreeVersion/DisabledFieldPlaceholder";
import FormControl from "@material-ui/core/FormControl";
import {
  volumeDisplayText,
  volume,
  round
} from '../../../helpers/common';
import { grayColor } from "assets/jss/material-dashboard-react";

const useStyles = makeStyles(styles)

const UnitsStep = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const [openDialog, setOpenDialog] = useState(false) //local state
  const [deleteItem, setDeleteItem] = useState(null) //local state
  const [unitPop, setUnitPop] = useState(null);
  const [multipleResultsPopUp, setMultipleResultsPopUp] = useState(false);

  const restrictions = useMemo(() => mapPlansLimitations?.[state.currentPlan] || [], []);
  const { theRef, updating, setUpdating } = props
  const cleanState = {
    ID: 0,
    unitName: "",
    unitWidth: "",
    unitHeight: "",
    unitDepth: ""
  }

  /* eslint-disable */
  useEffect(() => {
    dispatchState({ type: 'integration-data', payload: { integrationStepperStep: 1 } }) // Set stepper step number 2
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
  }, []);
  /* eslint-enable */

  const measurementSystemIndex =
    state.integrationData.integrationMeasurement === "m" ? 0 : 1;

  const editUnit = (ID) => () => {
    const unit = state.integrationData.integrationUnits[ID];

    const name = Array.isArray(unit.unitName)
      ? unit.unitName[measurementSystemIndex]
      : unit.unitName;
    const width = Array.isArray(unit.unitWidth)
      ? unit.unitWidth[measurementSystemIndex]
      : unit.unitWidth;
    const depth = Array.isArray(unit.unitDepth)
      ? unit.unitDepth[measurementSystemIndex]
      : unit.unitDepth;
    const height = Array.isArray(unit.unitHeight)
      ? unit.unitHeight[measurementSystemIndex]
      : unit.unitHeight;

    dispatchState({
      type: "integration-data",
      payload: {
        integrationIsNewHelper: false,
        integrationSecPanel: true,
        integrationEditing: ID,
        integrationNewUnit: {
          ...unit,
          unitName: name,
          unitWidth: width,
          unitDepth: depth,
          unitHeight: height,
        },
      },
    });
  };

  const createUnit = () => () => {
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: true,
        integrationIsNewHelper: true,
        integrationNewUnit: { ...cleanState, ID: state.integrationData.integrationUnits.length }
      }
    })
  }

  const handleDisableMultipleResults = async () => {
    setUpdating(true);
    dispatchState({type: 'integration-data', payload: {integrationDisableMultipleResults: !state.integrationData.integrationDisableMultipleResults}});
    await saveIntegration({ ...state, integrationData: { ...state.integrationData, integrationDisableMultipleResults: !state.integrationData.integrationDisableMultipleResults }}, false, dispatchState);
    setUpdating(false);
  }

  const handleOpenDialog = index => () => {
    setOpenDialog(true);
    setDeleteItem(index);
  }

  const deleteUnit = () => async () => {
    setOpenDialog(false) //Close modal
    setUpdating(true);
    const update = (await import("immutability-helper")).default;
    let arrayToChange = state.integrationData.integrationUnits
    // Save new
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: false,
        integrationUnits: update(arrayToChange, { $splice: [[deleteItem, 1]] }),
        integrationNewUnit: { ...cleanState }
      }
    });

    await saveIntegration({ ...state, integrationData: { ...state.integrationData, integrationSecPanel: false, integrationUnits: update(arrayToChange, { $splice: [[deleteItem, 1]] }), integrationNewUnit: { ...cleanState } } }, false, dispatchState);
    setUpdating(false);
  }

  const handleAmountMultipleResult = async (value) => {
    setUpdating(true);
    // Save new
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationAmountMultipleUnit: value
      }
    });

    await saveIntegration({ ...state, integrationData: { ...state.integrationData, integrationAmountMultipleUnit: value }}, false, dispatchState);
    setUpdating(false);
  }

  const maxNumberOfUnits = 35;

  return (
    <form noValidate autoComplete="off" className={classes.standardForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h1">Units <HelpRounded style={{ verticalAlign: "sub", fontSize: "1.3rem" }} onClick={(event) => { setUnitPop(event.currentTarget) }} /></Typography>
          <Popover
            id="domainURL"
            open={Boolean(unitPop)}
            anchorEl={unitPop}
            onClose={() => { setUnitPop(null) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography variant="body2" className={classes.blueText}>
              {state.integrationData.integrationStorageType === "mobile-storage" || state.integrationData.integrationStorageType === "portable-storage" ? (
                <span>
                  For mobile storage integrations, please note you can add 1
                  unit only. This is because the software will calculate
                  differently to determine how many of the storage units are
                  required to fit the furniture list.
                </span>
              ) : (
                <span>
                  For self-storage integrations, please note you can add
                  anywhere from 1 to 35 units. You will be unable to progress
                  without a unit and you will be unable to add units if you have
                  35 units. The software will always calculate to the smallest
                  possible unit that fits the furniture list.
                </span>
              )}
            </Typography>
          </Popover>
        </Grid>
        {/* <Grid item xs={6} style={{textAlign:"right"}}>
          <Typography variant="overline" style={{textTransform:"initial"}}>{state.integrationData.integrationUnits.length} of 5 units</Typography>
        </Grid> */}
        <Grid item xs={12}>
          <Divider />
        </Grid>
          <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Typography variant="h6" component="h4">
              <span
                style={{ color: 'rgba(0,0,0,.87)', fontWight: 500, fontSize: 14}}
                className={classNames({[classes.disabled]: !restrictions.includes('integrationDisableMultipleResults')})}
              >
               Recommend multiple unit sizes?
              </span>
              <HelpRounded
                style={{ verticalAlign: "sub", fontSize: "1.3rem", marginLeft: 3 }}
                onClick={(event) => { setMultipleResultsPopUp(event.currentTarget) }}
              />
            </Typography>
            <Popover
              id="domainURL"
              open={Boolean(multipleResultsPopUp)}
              anchorEl={multipleResultsPopUp}
              onClose={() => { setMultipleResultsPopUp(null) }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography variant="body2" className={classes.blueText}>
                We’ll recommend the smallest unit size for their items, but you can also display the next size(s) up.
              </Typography>
            </Popover>
            <Grid item>
              {
                !restrictions.includes('integrationDisableMultipleResults') ?
                  <PayButton setUpdating={props.setUpdating} feature={'integrationDisableMultipleResults'} updating={updating} /> :
                  (
                    <Switch
                      checked={!state.integrationData.integrationDisableMultipleResults}
                      onChange={handleDisableMultipleResults}
                      color="primary"
                      name="disable-presets"
                      inputProps={{ 'aria-label': 'disable presets' }}
                    />
                  )
              }
            </Grid>
          </Grid>
        {
          !state.integrationData.integrationDisableMultipleResults && (
            <Grid item xs={12}>
              <FormControl fullWidth className={{[classes.disabledFieldInput]: !restrictions.includes('integrationAmountMultipleUnit')}}>
                <InputLabel htmlFor="p-integration-language">Number of units</InputLabel>
                <Select
                  labelId="result-amount-select-outlined-label"
                  id="result-amount-select-outlined-label"
                  value={!restrictions.includes('integrationAmountMultipleUnit') ? ' ' : state.integrationData.integrationAmountMultipleUnit || 3}
                  onChange={(event) => handleAmountMultipleResult(event.target.value)}
                  label="Unit sizes amount"
                  name={'Unit sizes amount'}
                  variant="outlined"
                  style={{width: '100%'}}
                  disabled={!restrictions.includes('integrationAmountMultipleUnit')}
                >
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                </Select>
                { !restrictions.includes('integrationAmountMultipleUnit') && <DisabledFieldPlaceholder feature={'integrationAmountMultipleUnit'} updating={false} /> }
              </FormControl>
            </Grid>
          )
        }
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table className={classes.integrationTable} aria-label="Unit setup">
              <TableBody>
                {state.integrationData.integrationUnits.length ? state.integrationData.integrationUnits.map((prop, index) => {
                  const width = Array.isArray(prop.unitWidth)
                    ? prop.unitWidth[measurementSystemIndex]
                    : prop.unitWidth;
                  const depth = Array.isArray(prop.unitDepth)
                    ? prop.unitDepth[measurementSystemIndex]
                    : prop.unitDepth;
                  const height = Array.isArray(prop.unitHeight)
                    ? prop.unitHeight[measurementSystemIndex]
                    : prop.unitHeight;
                  const unitVolume = volumeDisplayText(state.integrationData.integrationMeasurement === 'm', volume({
                    width,
                    height,
                    depth,
                  }));
                  return (
                    <TableRow key={index}>
                      {/* <TableCell align="left">
                        <Typography variant="subtitle2" component="p">ID:{prop.ID}</Typography>
                      </TableCell> */}
                      <TableCell align="left" style={{padding: '16px 16px 16px 0', maxWidth:340}}>
                        <Typography variant="subtitle2" component="p">
                          {Array.isArray(prop.unitName)
                            ? prop.unitName[measurementSystemIndex]
                            : prop.unitName}
                        </Typography>
                        <Typography variant="subtitle2" component="p" style={{
                            display: 'flex',
                            fontSize: 12,
                            color: grayColor[0]
                          }}
                          className="ignore-translation"
                        >
                          <span>Volume: {unitVolume}</span>
                          <span className="ignore-translation"><sup>3</sup>,&nbsp;</span>
                          <span>Length: {round(Number(depth))}{state.integrationData.integrationMeasurement},&nbsp;</span>
                          <span>Width: {round(Number(width))}{state.integrationData.integrationMeasurement},&nbsp;</span>
                          <span>Height: {round(Number(height))}{state.integrationData.integrationMeasurement}</span>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Edit unit" aria-label="Edit unit">
                          <Fab
                            color="primary"
                            aria-label="Edit unit"
                            size="small"
                            disabled={updating}
                            onClick={editUnit(index)}
                          >
                            <EditRounded />
                          </Fab>
                        </Tooltip>
                        <Tooltip title="Delete unit" aria-label="Delete unit">
                          <Fab
                            color="primary"
                            aria-label="Delete unit"
                            size="small"
                            onClick={handleOpenDialog(index)}
                            className={classes.deleteIconColour}
                          >
                            <DeleteForeverRounded />
                          </Fab>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                }) : <TableRow><TableCell align="center"><Alert severity="error">Please add a unit to continue. </Alert></TableCell></TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            className={classes.buttonSpacing}
            color="secondary"
            size="medium"
            fullWidth
            onClick={createUnit()}
            disabled={state.integrationData.integrationUnits.length === maxNumberOfUnits}
          >
            Add unit
          </Button>
          {
            state.integrationData.integrationShowUnitsNote && (
              <Typography align={'center'}>Note: You can see the furniture & item dimensions on the next step</Typography>
            )
          }
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            className={classes.buttonSpacing}
            color="primary"
            size="medium"
            fullWidth
            onClick={() => {
                dispatchState({
                type: 'integration-data',
                payload: {
                  integrationThirdPanel: true,
                  integrationSecPanel: true,
                  closeAllPanels: true
                }})
            }}
          >
            Import unit sizes
          </Button>
        </Grid>
      </Grid>
      {/* Closing dialog */}
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to delete?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Unit will be deleted
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={() => { setOpenDialog(false) }} color="primary">No</Button>
          <Button size="large" onClick={deleteUnit()} color="primary">Yes</Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}

export default UnitsStep
