import React, {useContext, useMemo} from 'react';
import {useGlobalState} from "../hooks/useGlobalState";

export default () => {
  const state = useContext(useGlobalState.State);

  const isIntegrationDataChanged = useMemo(() => {
    const integration = state?.signedInUser?.data?.user?.integrations?.[state?.integrationDataOptions?.selectedIntegration];
    if (!integration) return true;
    return state?.integrationData?.integrationName !== integration.name ||
      state?.integrationData?.integrationCtaURL !== integration.cta ||
      state?.integrationData?.integrationDomain !== integration.domain
  }, [state.integrationData]);

  return isIntegrationDataChanged;
}
