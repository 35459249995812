import React, { useContext } from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import ExitToApp from "@material-ui/icons/ExitToAppRounded"
import Drawer from "@material-ui/core/Drawer"
import Hidden from "@material-ui/core/Hidden"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Icon from "@material-ui/core/Icon"

// core components
import { useGlobalState } from "hooks/useGlobalState"
import classNames from "classnames"
import PropTypes from "prop-types"
import { NavLink, Link } from "react-router-dom"
// styles
import styles from "assets/jss/material-dashboard-react/components/adminSidebarStyle"

const useStyles = makeStyles(styles)

const AdminSidebar = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false
  }
  const { logo, routes } = props
  let loopCounter = 0
  let links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        let isSeparator = prop.name === "Support" ? true : false
        let listItemClasses

        if (!prop.inAdminNav) return false // Skip non admin routes

        listItemClasses = classNames({
          [" " + classes.blue]: activeRoute(prop.path)
        })

        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.path)
        })

        loopCounter++

        return (
          <NavLink
            to={prop.path}
            className={classNames({ [classes.isSeparator]: isSeparator }, classes.item)}
            activeClassName="active"
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === "string" ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses)}
                >
                  {prop.icon}
                </Icon>
              ) : (
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                  />
                )}
              <ListItemText
                primary={prop.name}
                className={classNames(classes.itemText, whiteFontClasses)}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
      <NavLink
        to="/login"
        className={classes.item}
        key={loopCounter + 1}
        onClick={() => { dispatchState({ type: 'root-state', payload: { isLoggedIn: false, signedInUser: { token: '', cukey: '' } } }) }}
      >
        <ListItem button className={classes.itemLink}>
          <ExitToApp className={classNames(classes.ExitToApp, classes.itemIcon)} />
          <ListItemText
            primary="Logout"
            className={classes.itemText}
            disableTypography={true}
          />
        </ListItem>
      </NavLink>
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <Link
        to="/admin/my-calcumate"
        className={classes.logoLink}
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
      </Link>
    </div>
  )
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {links}
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
        </Drawer>
      </Hidden>
    </div>
  )
}

AdminSidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  logo: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
}

export default AdminSidebar