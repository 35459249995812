import {Button, Grid, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import classNames from 'classnames'
import styles from "../../../assets/jss/material-dashboard-react/components/CustomPricingStyle";
import Letter from '../../../assets/img/letter.svg';

const useStyles = makeStyles(styles)

const HelpSection = props => {
  const classes = useStyles();

  return (
    <Grid 
      container
      alignItems={'center'}
      justifyContent="center"
      id={'pricing-help-section'}
      direction="column"
      style={{marginTop: 38, marginBottom:30}}
    >
      <Grid>
        <Typography className={classNames(classes.helpTopHead)}>Have some<br/> questions first?</Typography>
      </Grid>
      <Grid>
        <Button
          size="small"
          variant="contained"
          component={Link}
          to="/contact-us"
          className={classes.contactUsButton}
          id={'contactUsButton'}
        >
          TALK TO SALES
        </Button>
      </Grid>
    </Grid>
  )
}

export default HelpSection
