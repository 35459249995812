import React from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress } from "@material-ui/core";

//style
import styles from "assets/jss/material-dashboard-react/components/genericCalulatorLoaderStyle";

const useStyles = makeStyles(styles);

const GenericCalculatorLoader = props => {
    const classes = useStyles()
    return (
        <Box className={classes.spinnerWrapper}>
            <Box>  
                <CircularProgress disableShrink />
                <Box>{props.children}</Box>
            </Box>
        </Box>
    )
}

export default GenericCalculatorLoader;