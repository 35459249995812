import { Service, Services } from "helpers/types";
import React from "react";
import {
  allowedServices
} from '../../components/CustomPricing/SelectService';

import {
  Chip,
  Grid,
  makeStyles
} from '@material-ui/core';
import { primaryColor, primaryColour, whiteColor } from "assets/jss/material-dashboard-react";
import classNames from "classnames";

export default ({
  onChange,
  selectedService,
}: ServiceShipsProps) => {

  const classes = useStyles();

  return (
    <div className={classes.chips}>
      {
        //@ts-ignore
        allowedServices.map((service: Service) => {
          return (
            <Chip
              label={service.label}
              onClick={() => onChange(service.value)}
              variant="outlined"
              className={classNames(classes.chip, {
                [classes.selected]: selectedService === service.value
              })}
            />
          );
        })
      }
    </div>
  );
}

// ============================================================================
// Styles
// ============================================================================

const styles = {
  chips: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    gap: 8,
    marginTop: 19
  },
  chip: {
    borderRadius: 20,
    border: '1px solid #DEDEDE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 42,
    '& .MuiChip-label': {
      color: primaryColor[4],
      fontSize: 14.95,
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      padding: '12px 14px',
    },
    '&:focus': {
      backgroundColor: 'unset',
      boxShadow: 'unset',
    }
  },
  selected: {
    backgroundColor: `${primaryColour[900]} !important`,
    '& .MuiChip-label': {
      color: whiteColor,
    }
  },
}

const useStyles = makeStyles(styles);

// ============================================================================
// Interfaces & Types
// ============================================================================

interface ServiceShipsProps {
  onChange: (value: Services) => void;
  selectedService: Services;
}