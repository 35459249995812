import { primaryColour } from "assets/jss/material-dashboard-react"

const genericCalulatorLoaderStyle = {
  spinnerWrapper: {
    position: "fixed",
    zIndex: "5",
    width: "100vw",
    paddingLeft: "575px",
    height: "100vh",
    right: 0,
    top:0,
    backgroundColor: primaryColour[50],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
  },
}

export default genericCalulatorLoaderStyle;
