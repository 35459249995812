import {
  primaryColour,
  whiteColor
} from "assets/jss/material-dashboard-react.js";

const footerStyle = theme => ({
  footerContainerOne: {
    maxWidth: "100%",
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up("sm")]: {
      maxWidth: 700,
    },
    paddingLeft: 15,
    paddingRight: 15,
    '& > img': {
      maxWidth: '17%',
      maxHeight: 60,
    }
  },
  footerImageWrap: {
    maxWidth: '21%',
    marginLeft: '2%',
    marginRight: '2%',
    [theme.breakpoints.up("sm")]: {
      maxWidth: '25%',
      marginLeft: 'unset',
      marginRight: 'unset',
    },
  },
  startFreeTxt: {
    color: primaryColour[900]
  },
  startFreeTxtWhite: {
    color: whiteColor[900]
  },
  footerContainerTwo: {
    backgroundColor: primaryColour[900],
    color: whiteColor,
    padding: "15px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      borderRadius: '30px 30px 0 0'
    },
    "& img": {
      width: 30,
      height: "auto",
      marginRight: 10,
      verticalAlign: "middle"
    },
    "& > div":{
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        textAlign:"center",
      },
    },
  },
  footer: {
    margin: 0,
    padding: 0
  }
})

export default footerStyle
