const muiThemeProps = {
  // Button props
  MuiButtonBase: {
    disableRipple: true, // No more ripple, on the whole application!
  },
  // Link props
  MuiLink: {
    underline: 'always',
  },
};

export default muiThemeProps;
