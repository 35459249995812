import React, {useEffect, useState, Fragment, useMemo} from "react";

//Material UI
import CircularProgress from "@material-ui/core/CircularProgress";
import {Grid} from '@material-ui/core'
import {getCmsPage, handleNodes, getCmsPost} from '../../helpers/cmsHelpers'

//Components
import Footer from "../../components/Footer/Footer";
import HomeHeader from "../../components/HomeHeader/HomeHeader";
import NotFound from "../NotFound/NotFound";
import '../../assets/css/wordpress.scss';
//Styles
import styles from "assets/jss/material-dashboard-react/views/CmsPageStyles";
import {makeStyles} from "@material-ui/core/styles";
import CustomPricingPanels from "../../components/CustomPricing/CustomPricingPanelsSlider";
import { serviceMenu } from "../../variables/InitState";

const useStyles = makeStyles(styles);

const CmsPage = (props) => {
  const { location } = props;
  const [pageData, setPageData] = useState('');
  const [loading, setLoading] = useState(null);
  const [is404, setIs404] = useState(false);
  const [dynamicContent, setDynamicContent] = useState([]);
  const classes = useStyles();

  const handleSlickSliders = async () => {
    const resetOffsetsBlocks = document.querySelectorAll('.reset-offsets');
    resetOffsetsBlocks.forEach(elem => {
      const wrap = elem.closest('.cms-block-wrap');
      if (wrap) {
        wrap.classList.add('cms-block-wrap-service');
      }
    });

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: window.innerWidth > 601 ? 4 : 1,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: '<button type="button" class="slick-prev slick-arrow"></button>',
      nextArrow: '<button type="button" class="slick-next slick-arrow"></button>',
      breakpoint: () => console.log('breakpoint 1'),
      init: () => console.log('init 1'),
      reInit: () => console.log('reInit 1')
    };
    const pricingSettings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      breakpoint: () => console.log('breakpoint'),
      init: () => console.log('init\n'),
      reInit: () => console.log('reInit')
    }

    if (document.querySelector('.why-it-works-block > .wp-block-group__inner-container')) {
      window.$('.why-it-works-block > .wp-block-group__inner-container').slick(settings);
    }
    if (document.querySelector('.pricing-plans-container') && window.innerWidth < 601 ) {
      window.$('.pricing-plans-container').slick(pricingSettings);
    }
    if (window.innerWidth > 750) {
      const pricingPlansWrap = document.querySelector('.pricing-plans-container');
      if (pricingPlansWrap) {
        const children = pricingPlansWrap.children;
        if (children.length === 2) {
          const divider = document.createElement("div");
          divider.className = 'vertical-divider';
          children[0].parentNode.insertBefore(divider, children[0].nextSibling)
        } else if (children.length === 3) {
          const divider = document.createElement("div");
          divider.className = 'vertical-divider';
          children[0].parentNode.insertBefore(divider, children[0].nextSibling)
          const divider1 = document.createElement("div");
          divider1.className = 'vertical-divider';
          children[0].parentNode.insertBefore(divider1, children[2].nextSibling)
        }
    }
    }
  }

  useEffect(() => {
    try {
      handleSlickSliders();
    } catch (e) {
      console.log('Cms page error', e)
    }
  });

  const mapFunctions = {
    getCmsPage,
    getCmsPost
  }

  useEffect(() => {
    import('html-react-parser').then(({default: parse}) => {
      if (!pageData?.post_content) return null;
      setDynamicContent(parse(pageData.post_content).filter(item => item?.type))
    });
  }, [pageData]);

  useEffect(() => {
    const { pathname } = location;
    const pageName = (pathname || '').replace('/', '')
    setLoading(true)
    const isPost = (pageName.match(/^blog\//) || []).length;
    mapFunctions[isPost ? 'getCmsPost' : 'getCmsPage'](pageName).then(res => {
      if (res.data?.[0]) setPageData(res.data[0]);
      else setIs404(true);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      setIs404(true);
    });
    return () => {}
  }, [location.pathname]);

  if (is404) {
    return <NotFound />
  }

  return (
    <Grid
      style={{ minHeight: '100vh' }}
      container
      direction="column"
      justify="space-between"
    >
      <Fragment>
        <section id={'header'} className={classes.header}>
          <HomeHeader pageYOffset={40} />
        </section>
        {
          loading ? <Grid className={'entry-content'} style={{minHeight: '33vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress /></Grid> : (
            <section id={'cms-content'} className={classes.cmsContent}>
              <div className={'entry-content'} >
                {
                  (
                    pageData || dynamicContent) && dynamicContent.map((elem, index) => handleNodes(elem, index)
                  )
                }
              </div>
            </section>
          )
        }
        {/* { //TODO uncomment after fix UI
          serviceMenu.includes(pageData?.post_name) && (
            <Grid item xs={12} sm={12} md={12} className={classes.standardForm} style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              backgroundColor: 'white',
              padding: 24,
              paddingTop: 34,
              width: '100%'}}
            >
              <CustomPricingPanels />
            </Grid>
          )
        } */}
        <section className={classes.footerSection}>
          <Footer />
        </section>
      </Fragment>
    </Grid>
  )
}

export default CmsPage
