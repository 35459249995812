// https://create-react-app.dev/docs/supported-browsers-features/
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from 'react'

// Core components
import ReactDOM from 'react-dom'
import App from 'App'

Sentry.init({//SENTRY_KEY
  dsn: process.env.REACT_APP_SENTRY_KEY,
  integrations: [
    new Sentry.Replay(),
    new Integrations.BrowserTracing(),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  enabled: process.env.REACT_APP_ENV_MODE === 'prod',
});

ReactDOM.render(<App />, document.getElementById('root'));
