import React, {useContext, useEffect, useRef, useState, useMemo} from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"
import Alert from '@material-ui/lab/Alert';

// core components
import { useGlobalState } from "hooks/useGlobalState"

// Other components
import CustomPricingPanel from "components/CustomPricing/CustomPricingPanel/CustomPricingPanel"
import CustomPricingSwitch from "./CustomPricingPanel/CustomPricingSwitch"
import SelectService from "components/CustomPricing/CustomPricingPanel/components/SelectService";
import ServicesShips from "views/common/ServicesShips";

// Style
import styles from "assets/jss/material-dashboard-react/components/CustomPricingStyle";
import classNames from "classnames";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import Currencies from "./Currencies";
import withWidth from '@material-ui/core/withWidth';

const useStyles = makeStyles(styles)

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: false,
  arrows: false,
  variableWidth: true,
  breakpoint: event => console.log('e', event),
  responsive: [
    {
      breakpoint: 350,
      settings:  {
        variableWidth: false,
        initialSlide: 1
      }
    },
    {
      breakpoint: 600,
      settings:  {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 760,
      settings:  {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 900,
      settings:  {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
      }
    },
    {
      breakpoint: 1265,
      settings:  {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
      }
    },
    {
      breakpoint: 500000,
      settings:  'unslick'
    },
  ]
};

const CustomPricingPanels = ({
  width,
}) => {
  const classes = useStyles();
  const state = useContext(useGlobalState.State);
  const dispatchState = useContext(useGlobalState.Dispatch);
  // const slider = useRef(null)

  const [selectedService, setSelectedService] = useState(state.service);
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (!selectedService) {
      setSelectedService(urlParams.get('service') && allowedServices.includes(urlParams.get('service')) ? 
      urlParams.get('service') : 
      'self-storage');
    }
  }, []);

  const allPlans = useMemo(() => {
    if (!['container-storage', 'self-storage', 'kiosks', 'portable-storage', 'movers'].includes(selectedService)) {
      return state.calcumatePlans.filter(plan => ['Contact-us'].includes(plan.thePlan));
    }
    return state.calcumatePlans.filter(plan => {
      if (plan.service) {
        return plan.service === selectedService;
      }
      return true;
    });
  }, [
    selectedService,
    state.currency,
    state.cmPlanPayRec,
  ]);

  useEffect(() => {
    dispatchState({ type: 'root-state', payload: { 
        service: selectedService
      }
    });
  }, [
    selectedService
  ]);

  const basicPlans = [
    process.env.REACT_APP_STRIPE_BASIC_PLAN,
    process.env.REACT_APP_STRIPE_BASIC_PRICE,
    process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY,
  ]

  return (
    <div id={'pricing-plan-screen'}>
      {
        state?.signedInUser?.data?.user &&
          !state?.signedInUser?.data?.user?.stripeAccount?.subscriptionId &&
          !basicPlans.includes(state?.signedInUser?.data?.user?.lastChosenSubscription) ? (
          <Alert style={{maxWidth: 300, marginLeft: 'auto', marginRight: 'auto', marginBottom: 30, marginTop: 0}} severity="info">
            Payment will not be taken until your calculator is ready and your 30-day free trial has finished.
          </Alert>
        )  : null
      }
      {
        ['xs', 'sm'].includes(width) ? 
          (
            <SelectService
              onChange={setSelectedService}
              selectedService={selectedService}
            />
          ) :
          (
            <ServicesShips
              onChange={setSelectedService}
              selectedService={selectedService}
            />
          )
      }
      <Grid
        container
        justify={'center'}
        alignItems={'flex-start'}
        style={{marginBottom: 61}}
        className={classNames(classes.pricingPlanWrap, 'pricing-plans-container')}
        // ref={slider}
        data-cy={'pricing-plan-list'}
      >
        {allPlans.map((value, index) => {
          return (
            <Grid item key={index} style={{height: 'auto',}}>
              <CustomPricingPanel
                service={selectedService}
                index={index}
                {...value}
              />
            </Grid>
          )
        })}
      </Grid>
    </div>
  );
}

export default withWidth()(CustomPricingPanels);
