import React, { useContext } from "react";

// core components
import classNames from 'classnames'

// other components
import { Typography, makeStyles, Grid } from "@material-ui/core";
import { useGlobalState } from "hooks/useGlobalState"
import {useHistory} from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
// styles
import styles from "assets/jss/material-dashboard-react/components/adminHeadingStyle";

const useStyles = makeStyles(styles)

const AdminHeading = props => {
  const classes = useStyles();
  const { heading, overline, results, isMain } = props;
  const history = useHistory();
  // load the global state
  let state = useContext(useGlobalState.State);
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classNames(classes.headerMargin, { [classes.extraMargin]: !isMain })}
    >

      <Grid item>
        {state.showInactiveMessage ?
          <Alert style={{ marginBottom: 30 }} severity="error" sty>
            You have cancelled your Calcumate subscription. Select a new subscription to access your calculator <a href="#" onClick={() => history.replace('/admin/my-account/my-subscription')}>here</a>
          </Alert>
          : ''
        }
        <Typography variant="overline">{overline}</Typography>
        <Typography data-cy={'admin-panel__header'} variant="h1">{heading}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="overline" className={classes.results}>{results}</Typography>
      </Grid>
    </Grid>
  );
}

export default AdminHeading
