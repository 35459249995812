import React, { useContext } from "react";

// @material-ui/core
import {
  Typography,
  makeStyles,
  Fab,
  TextField,
  Grid,
  Tooltip,
} from "@material-ui/core";
import FullscreenRounded from "@material-ui/icons/FullscreenRounded";
import FullscreenExitRounded from "@material-ui/icons/FullscreenExitRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
// core components

// other components
import { useGlobalState } from "hooks/useGlobalState";

// styles
import styles from "assets/jss/material-dashboard-react/components/integrationHeadingStyle";

const useStyles = makeStyles(styles);

const IntegrationHeading = () => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch);
  const state = useContext(useGlobalState.State);

  const editHeading = (event) => {
    dispatchState({
      type: "integration-data",
      payload: { integrationName: event.target.value },
    });
  };

  const setFullScreen = () => {
    dispatchState({
      type: "integration-data-options",
      payload: {
        integrationPanelsFS: !state.integrationDataOptions.integrationPanelsFS,
      },
    });
  };

  return (
    <div className={classes.inHeadingWrapper}>
      <Grid container spacing={0} direction="row" justify="space-between">
        <Grid item xs={12}>
          <Typography variant="overline">New calculator</Typography>
        </Grid>
      </Grid>

      { !state.integrationDataOptions.updatingItems ? (
        <div className={classes.inputWrapper}>
          <TextField
            id="integration-name"
            aria-describedby="integration-name"
            onChange={editHeading}
            value={state.integrationData.integrationName}
            className={classes.modInput}
            inputProps={{ maxLength: 25 }}
            autoFocus
            helperText={
              !state.integrationData.integrationName &&
              "Integration name cannot be blank"
            }
            error={!state.integrationData.integrationName}
          />
        </div>
      ) : (
        <CircularProgress />
      )}

      <div className={classes.panelOneController}>
        <Tooltip title="Show/Hide panels" aria-label="Show/Hide panels">
          <Fab
            color="primary"
            aria-label="Hide panels"
            size="large"
            onClick={setFullScreen}
          >
            {
              !state.integrationDataOptions.integrationPanelsFS ? <FullscreenRounded /> :  <FullscreenExitRounded />
            }
          </Fab>
        </Tooltip>
      </div>
    </div>
  );
};

export default IntegrationHeading;
