import React, {useContext, useMemo} from 'react';
import {Button, Grid, Typography,} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-react/layouts/integrationStyle";
import {Link} from "react-router-dom";
import { mapPlansLimitations } from '../../variables/InitState';
import useIsIntegrationChanges from "../../helpers/useIsIntegrationChanges";
import {saveIntegration} from "../../helpers/SaveIntegration";
import {useGlobalState} from "../../hooks/useGlobalState";

const useStyles = makeStyles(styles)

const findPlan = feature => {
  if (!feature) return null
  let acceptablePlan;
  for (let i = 0; i < Object.keys(mapPlansLimitations).length; i++) {
    const planName = Object.keys(mapPlansLimitations)[i];
    const features = mapPlansLimitations[planName];
    if (features.includes(feature)) {
      acceptablePlan = planName;
      break;
    }
  }
  return acceptablePlan;
}

const BuyButton = props => {
  const classes = useStyles();
  const state = useContext(useGlobalState.State);
  const dispatchState = useContext(useGlobalState.Dispatch)
  let history = useHistory();

  const planName = useMemo(() => findPlan(props.feature), []);

  const isIntegrationDataChanged = useIsIntegrationChanges();

  const redirectToSubscription = async e => {
    e.preventDefault();
    if (isIntegrationDataChanged) {
      props.setUpdating && props.setUpdating(true);
      try {
        await saveIntegration(state, false, dispatchState);
      } catch (e) {
        console.log('Saving error:', e)
      }
      props.setUpdating && props.setUpdating(false);
    }
    if (planName === 'Custom')
      return history.push("/contact-us?int", {builder: true});

    if (findPlan) {
      return history.push(`/admin/my-account/my-subscription/modify?plan=${planName}&backLink=${history.location.pathname}${history.location.search}`, {builder: true});
    }
    history.push("/admin/my-account/my-subscription", {builder: true});
  }

  const ButtonWithIcon = ()=><Button onClick={redirectToSubscription} component={Link} to="/admin/my-account/my-subscription?builder" variant="contained" size="small" color="primary" style={{marginLeft: 10, pointerEvents: props.updating ? 'none' : 'auto'}}>{
    props.updating ?
      <CircularProgress color='inherit' size={15} style={{fontSize: 15, marginRight: 5}} /> :
      (<>
        <ShoppingCartRoundedIcon style={{fontSize: 15, marginRight: 5}} /><span style={{textTransform: 'capitalize'}}>{planName || 'Add'}</span>
      </>)
  }</Button>
  if (props.label) {
    return <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}><span className={classes.disabled}>{props.label}</span> <ButtonWithIcon /></Typography>
  }
  return <ButtonWithIcon/>
}

export default BuyButton;
