import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import TBExample from "../../../assets/img/TB-Example.png";
import SlideLogo from "../../../assets/img/taxibox-logo.svg";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-react/views/HomePageStyle";
import classNames from "classnames";
const useStyles = makeStyles(styles);

export default () => {
  const classes = useStyles();
  return (
    <div>
      <Grid container>
        <Grid xs={12} sm={6} md={6} item className={classes.whyItWorksSlide1Left}>
          <img
            style={{maxWidth: 580, width: '100%', height: 'auto'}}
            className={classes.whyItWorksSlide1LeftImg}
            src={TBExample}
            alt={'Taxibox logo'}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} className={classNames(classes.whyItWorksSlide1Right, classes.whyItWorksSlide2Right)}>
          <Grid
            style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}
            className={classes.whyItWorksSlide2RightWrap}>
              <a
                href={'https://www.taxibox.com.au/faq/storage-calculator/'}
                target={'_blank'}
                rel="noopener">
                <img
                  src={SlideLogo}
                  className={classNames(classes.whyItWorksSlide1RightLogo, classes.whyItWorksSlide12RightLogo)}
                  alt={'Taxibox logo'}
                />
              </a>
            <Grid style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
              <Grid>
                <Typography className={classes.whyItWorksSlide1RightText}>131% increase in quote page visits</Typography>
                <Typography className={classes.whyItWorksSlide1RightText}>100s of added leads each month</Typography>
                <Typography className={classes.whyItWorksSlide1RightText}>1 in 3 customers use the calculator</Typography>
                <Typography className={classes.whyItWorksSlide1RightText}>Loved by sales team members</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
