import React, {useContext, createRef, useEffect, useState} from "react"
import { useHistory } from 'react-router-dom';
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { Fab, Slide } from "@material-ui/core";
import CloseRounded from '@material-ui/icons/CloseRounded';
import { loadAll } from '../../helpers/loadAll';

// Core components
import classNames from 'classnames';
import { useGlobalState } from "hooks/useGlobalState";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// Other components
import IntegrationPreview from "views/IntegrationCreation/IntegrationPreview";
import OptionStepAdvanced from "views/IntegrationCreation/steps/OptionStepAdvanced";
import OptionStep from "views/IntegrationCreation/steps/OptionStep";
import UnitsStep from "views/IntegrationCreation/steps/UnitsStep";
import UnitsStepAdvanced from "views/IntegrationCreation/steps/UnitsStepAdvanced";
import CategoriesStep from "views/IntegrationCreation/steps/CategoriesStep";
import CategoriesStepAdvanced from "views/IntegrationCreation/steps/CategoriesStepAdvanced";
import PresetsStep from "views/IntegrationCreation/steps/PresetsStep";
import PresetsStepAdvanced from "views/IntegrationCreation/steps/PresetsStepAdvanced";
import DesignStep from "views/IntegrationCreation/steps/DesignStep";
import ConfirmStep from "views/IntegrationCreation/steps/ConfirmStep";
import ConfirmedStep from "views/IntegrationCreation/steps/ConfirmedStep";
import PaymentStep from "views/IntegrationCreation/steps/PaymentStep";
import IntegrationHeading from "components/IntegrationHeading/IntegrationHeading";
import IntegrationStepper from "views/IntegrationCreation/IntegrationStepper";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle";
import "perfect-scrollbar/css/perfect-scrollbar.css";

/* eslint-disable */
let panelOneScroll
/* eslint-enable */

const useStyles = makeStyles(styles)

const IntegrationCreation = props => {
  const classes = useStyles();
  let history = useHistory();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const sidePanelOne = createRef(); // ref to help us initialize PerfectScrollbar on windows devices
  const sidePanelTwo = createRef(); // ref to help us initialize PerfectScrollbar on windows devices
  const [updating, setUpdating] = useState(false);
  const updatingProps = {updating, setUpdating}
  const panelOneRender = (section) => {
    switch (props.history.location.search) {
      case "?step=units":
        return section === "primary" ? <UnitsStep theRef={sidePanelOne} {...updatingProps} /> : <UnitsStepAdvanced {...updatingProps} theRef={sidePanelTwo} />
      case "?step=categories":
        return section === "primary" ? <CategoriesStep theRef={sidePanelOne} {...updatingProps} /> : <CategoriesStepAdvanced theRef={sidePanelTwo} />
      case "?step=presets":
        return section === "primary" ? <PresetsStep {...updatingProps} theRef={sidePanelOne} /> : <PresetsStepAdvanced {...updatingProps} theRef={sidePanelTwo} />
      case "?step=design":
        return section === "primary" ? <DesignStep theRef={sidePanelOne} {...updatingProps}/> : ""
      case "?step=details":
        return section === "primary" ? <PaymentStep theRef={sidePanelOne} {...updatingProps} /> : ""
      case "?step=confirm":
        return section === "primary" ? <ConfirmStep theRef={sidePanelOne} {...updatingProps}  /> : ""
      case "?step=confirmed":
        return section === "primary" ? <ConfirmedStep theRef={sidePanelOne} /> : ""
      default:
        return section === "primary" ? <OptionStep theRef={sidePanelOne} {...updatingProps} /> : <OptionStepAdvanced {...updatingProps} theRef={sidePanelTwo} />
    }
  }

  // initialize and destroy the PerfectScrollbar plugin
  /* eslint-disable */
  useEffect(() => {

    if (state.isLoggedIn && state.signedInUser.token !== '') {
      // load the items and translations from the database and set them in the state for the integration
      // it is required to set the item names according to the language selected.
      // the selected set of items and categories will be saved in the user's record, along with the translations
      loadAll(state, dispatchState);
    } else {
      history.replace('/login');
      window.location.reload();
    }
    let sidePanelOneCurrent = sidePanelOne.current;
    import("perfect-scrollbar").then(({default: PerfectScrollbar}) => {
      panelOneScroll = new PerfectScrollbar(sidePanelOneCurrent)
    })
  }, []);
  /* eslint-enable */

  const handleClose = () => {
    dispatchState({
      type: 'root-state',
      payload: {
        message: null
      }
    })
  }

  return (
    <div className={classes.integrationWrapper}>
      <Snackbar
        open={!!state.message}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
          zIndex: 10
        }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={state?.message?.type}>
          {state?.message?.text}
        </Alert>
      </Snackbar>
      {/* Primary panel */}
      <div className={classNames(classes.panelOne, { [classes.panelOneHide]: state.integrationDataOptions.integrationPanelsFS })}>
        <IntegrationHeading />
        <div className={classes.panelOneWrapper} ref={sidePanelOne}>
          {panelOneRender("primary")}
        </div>
      </div>

      {/* Secondary panel */}
      <Slide in={state.integrationData.integrationSecPanel} direction="right" timeout={500}>
        <div className={classes.panelTwo} ref={sidePanelTwo}>
          <Fab
            color="primary"
            aria-label="Edit"
            size="medium"
            className={classes.closeFab}
            onClick={() => { state.integrationData.integrationThirdPanel ?
              dispatchState({
                type: "integration-data",
                payload: state.integrationData.closeAllPanels ? {
                  integrationThirdPanel: false,
                  closeAllPanels: false,
                  integrationSecPanel: false
                }  : {
                  integrationThirdPanel: false,
                  closeAllPanels: false
                }
              }) :
              dispatchState({ type: "integration-data", payload: { integrationSecPanel: false, closeAllPanels: false } }) }}
          >
            <CloseRounded />
          </Fab>
          <div className={classes.panelTwoWrapper}>
            <div className={classes.contentTable}>
              {panelOneRender("secondary")}
            </div>
          </div>
        </div>
      </Slide>

      {/* Stepper */}
      <IntegrationStepper
        updating={updating}
        setUpdating={setUpdating}
        urlParam={props.history.location.search}
      />

      {/* Integration preview */}
      <div className={classes.integrationIndex}>
        <IntegrationPreview />
      </div>
    </div>
  )
}

// ============================================================================
// Helpers
// ============================================================================

const getTranslations = (translations, itemName) => {
  if (!translations) {
    return {};
  }

  // search each translation and check for the item name provided
  let translated = {};
  translations.forEach((translation) => {
    Object.entries(translation.items)
      .filter((item) => item[0] === itemName)
      .forEach((item) => {
        translated[translation.language] = item[1];
      });
  });

  return translated;
};

const categoriesOrderMap = new Map();
categoriesOrderMap.set("Living Room", 0);
categoriesOrderMap.set("Bedroom", 1);
categoriesOrderMap.set("Kitchen", 2);
categoriesOrderMap.set("Other", 3);

export default IntegrationCreation
