import {Button, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
//Styles
import styles from "assets/jss/material-dashboard-react/views/HomePageStyle";
import classNames from "classnames";

const useStyles = makeStyles(styles);
export default props => {
  const classes = useStyles();
  return (
    <section className={classNames(classes.startForFree, {[classes.resetOffsets]: props.resetOffsets})}>
      <Typography className={classes.startForFreeSimpleHeading} mb={30}>Build Your Own Size Calculator</Typography>
      <Typography className={classes.startForFreeDiveder}/>
      <Typography component={'h1'} variant="subtitle2" className={classes.startFreeTxt}>
        No Commitments. Cancel Anytime.
      </Typography>
      <Button
        component={Link}
        to="/pricing"
        variant="contained"
        color="primary"
        size="large"
        style={{
          backgroundColor: '#FFFFFF',
          color: '#00437C'
        }}
      >
        Sign up free
      </Button>
    </section>
  )
}
