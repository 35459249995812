import React, {useContext, useMemo, useState} from "react";

// @material-ui/core components
import { Typography, Card, Tooltip, Grid, makeStyles } from "@material-ui/core";
import AddRounded from "@material-ui/icons/AddRounded";
import RemoveRounded from "@material-ui/icons/RemoveRounded";
import CheckCircleOutlineRounded from "@material-ui/icons/CheckCircleOutlineRounded";
import EditRounded from "@material-ui/icons/EditRounded";

// core components
import { useGlobalState } from "hooks/useGlobalState";
import classNames from "classnames";

// other components
import CustomNameDialog from "./CustomNameDialog";

// styles
import styles from "assets/jss/material-dashboard-react/components/adminIntegrationItemStyle";
import { whiteColor } from "assets/jss/material-dashboard-react";
import {mapPlansLimitations} from "../../variables/InitState";

const useStyles = makeStyles(styles);

const AdminIntegrationItemQty = ({ prop }) => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch);
  const state = useContext(useGlobalState.State);
  const [openDialog, setOpenDialog] = useState(false); //local state
  const restrictions = useMemo(() => mapPlansLimitations?.[state.currentPlan] || [], []);

  const {
    itemLibrary,
    integrationNewPreset,
    integrationActiveLanguage,
    integrationMeasurement,
    integrationMeasurement2
  } = state.integrationData;
  const isMetric = state.integrationData.integrationMeasurement === 'm';
  const itemLibraryEntry = itemLibrary.find((item) => item.id === prop.id);

  const qty =
    integrationNewPreset.qtyAssigned[
      integrationNewPreset.itemsAssigned.indexOf(prop.ID)
      ];

  const isActiveItem = integrationNewPreset.itemsAssigned.includes(prop.ID);

  const handleAddItem = async () => {
    const position = integrationNewPreset.itemsAssigned.indexOf(prop.ID);
    const update = (await import("immutability-helper")).default;
    if (isActiveItem) {
      dispatchState({
        type: "integration-data",
        payload: {
          integrationNewPreset: update(integrationNewPreset, {
            qtyAssigned: { [position]: { $set: qty + 1 } },
          }),
        },
      });
    } else {
      if (integrationNewPreset.items) {
        integrationNewPreset.items.push({id: itemLibraryEntry.id})
      }
      dispatchState({
        type: "integration-data",
        payload: {
          integrationNewPreset: update(integrationNewPreset, {
            qtyAssigned: { $push: [1] },
            itemsAssigned: { $push: [prop.ID] },
          }),
        },
      });
    }
  };

  const handleRemoveItem = async () => {
    const position = integrationNewPreset.itemsAssigned.indexOf(prop.ID);
    const update = (await import("immutability-helper")).default;
    if (isActiveItem) {
      if (qty > 1) {
        dispatchState({
          type: "integration-data",
          payload: {
            integrationNewPreset: update(integrationNewPreset, {
              qtyAssigned: { [position]: { $set: qty - 1 } },
            }),
          },
        });
      } else if (qty === 1) {
        if (integrationNewPreset.items) {
          const index = integrationNewPreset.items.findIndex(elem => elem.id === prop.id)
          if (index !== -1 && qty === 1) {
            integrationNewPreset.items.splice(index, 1)
          }
        }
        dispatchState({
          type: "integration-data",
          payload: {
            integrationNewPreset: update(integrationNewPreset, {
              qtyAssigned: { $splice: [[position, 1]] },
              itemsAssigned: { $splice: [[position, 1]] },
            }),
          },
        });
      }
    }
  };

  // I believe there is a bug in the below. If getItemName is swapped for a
  // variable the item's name will not display properly. This may be due to
  // state being mutated up the tree
  const getItemName = () => {
    if (prop.customName) {
      return prop.customName;
    } else {
      switch (integrationActiveLanguage) {
        case "en":
          return prop.defaultName;
        default:
          return prop[integrationActiveLanguage];
      }
    }
  };
  
  const weightText = useMemo(() => {
    if (!prop.weight) return null
    const isPlural = prop.weight[isMetric ? 0 : 1] > 1;
    return `${prop.weight[isMetric ? 0 : 1]}${isMetric ? 'kg' : 'lb'}${isPlural ? 's' : ''}`
  }, [prop.weight, isMetric]);

  return (
    <Card
      className={classNames(classes.itemCard, {
        [classes.cardActive]: isActiveItem,
      })}
    >
      <Tooltip title="Customise item name" aria-label="Customise item name">
        <EditRounded
          onClick={() => {
            setOpenDialog(true);
          }}
          className={classes.cardEdit}
          style={{position: 'absolute'}}
        />
      </Tooltip>

      <div className={classes.itemCardAction}>
        {isActiveItem && (
          <CheckCircleOutlineRounded className={classes.itemCheckedIcon} style={{position: 'absolute'}} />
        )}

        <img
          src={prop.itemThumbnail}
          className={classes.itemCardImg}
          alt=""
          role="presentation"
        />

        <Typography variant="subtitle2" align="center">
          {getItemName()}
        </Typography>
        <Typography variant="subtitle2" align="center">
          {integrationMeasurement === "m"
            ? prop.dimension[0]
            : prop.dimension[1]}{" "}
          {integrationMeasurement}
          <sup>3</sup>
          {
            prop.weight && (
              <>
                | {`${weightText}`}
              </>
            )
          }
        </Typography>
        <Typography
          variant="caption"
          align="center"
          className={classes.itemSize}
        >
          (H{" "}
          {integrationMeasurement === "m"
            ? prop.h[0] + integrationMeasurement2[0]
            : prop.h[1] + integrationMeasurement2[1]}{" "}
          W{" "}
          {integrationMeasurement === "m"
            ? prop.w[0] + integrationMeasurement2[0]
            : prop.w[1] + integrationMeasurement2[1]}{" "}
          D{" "}
          {integrationMeasurement === "m"
            ? prop.d[0] + integrationMeasurement2[0]
            : prop.d[1] + integrationMeasurement2[1]}
          )
        </Typography>
      </div>

      <div className={classes.qtyControl}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ display: "flex" }}>
            <RemoveRounded
              className={classes.controlBtn}
              onClick={handleRemoveItem}
              disabled={!restrictions.includes('presetChange')}
            />
          </Grid>

          <Grid item style={{ display: "flex" }}>
            <Typography variant="subtitle1" style={{ color: whiteColor }}>
              {qty ? qty : 0}
            </Typography>
          </Grid>

          <Grid item style={{ display: "flex" }}>
            <AddRounded
              className={classes.controlBtn}
              onClick={handleAddItem}
              disabled={!restrictions.includes('presetChange')}
            />
          </Grid>
        </Grid>
      </div>

      <CustomNameDialog
        key={prop.id}
        isOpen={openDialog}
        defaultCustomName={itemLibraryEntry?.customName || ""}
        defaultSize={itemLibraryEntry?.scale || null}
        itemThumbnail={prop.itemThumbnail}
        itemLibraryEntryIndex={itemLibrary.indexOf(itemLibraryEntry)}
        state={state}
        weight={prop.weight}
        onClose={() => setOpenDialog(false)}
        dispatchState={dispatchState}
        defaultWeight={itemLibraryEntry.scaleWeight || null}
        elem={prop}
      />
    </Card>
  );
};

export default AdminIntegrationItemQty;
