import React from 'react'
import {Button, DialogContentText, Tooltip, Grid} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-react/layouts/integrationStyle";
import {Link} from "react-router-dom";
const useStyles = makeStyles(styles);

const Dialog = props => {
  const classes = useStyles();
  let history = useHistory();
  const redirectToSubscription = e => {
    e.preventDefault();
    history.push("/admin/my-account/my-subscription", {builder: true});
  }
  return (
    <Grid
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.paidBlockWrap}
    >
      <Grid
        className={classes.paidBlock}
      >
        <DialogContentText id="alert-dialog-title" style={{fontSize: 18, color: 'black', textAlign: 'center'}}>Start a subscription to<br/> access this feature</DialogContentText>
        <DialogContentText id="alert-dialog-description"  style={{fontSize: 14}}>
          30-Day free trial included. Cancel anytime.
        </DialogContentText>
        <Tooltip title="Start a subscription to access this feature" aria-label="Start a subscription to access this feature">
          <Button onClick={redirectToSubscription} component={Link} to="/admin/my-account/my-subscription" style={{pointerEvents: 'auto'}} variant="contained" size="small" color="primary">UPGRADE NOW</Button>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default Dialog;
