import React from 'react'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { BlockPicker } from 'react-color'

const styles = {
  picker:{
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    "& + .block-picker": {
      margin:"0 auto !important",
      left:"0 !important",
      right:"0 !important",
      boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.25) !important"
    },
    "& + .block-picker div:nth-child(2)": {
      height:"50px !important"
    }
  },
  pickerWrapper: {
    position: "realtive"
  }
}
const useStyles = makeStyles(styles);

const ColourPickerDialog = ({
  value,
  onClick,
  onChange
}) => {
  const classes = useStyles();
  return(
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', zIndex: '2', width:"100%" }}>
        <div
          className={classes.picker}
          onClick={onClick}
        />
        <BlockPicker
          color={value}
          colors={['#ffffff', '#F53C29', '#000000', '#EA175F', '#9C22B1', '#6638B8', '#3D50B6', '#1796F5', '#16BCD5','#0E9688', '#48AF4B', '#89C342', '#CCDB2A', '#FFEA31', '#FFBF2B', '#FE9527', '#FE5121', '#785446', '#9D9D9D', '#5E7C8B']}
          onChange={
            (color)=>{
              onChange(color.hex)
            }
          }
        />
      </div>
    </div>
  )
}

export default ColourPickerDialog
