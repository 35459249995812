import React from "react"
import { withRouter } from 'react-router-dom'
// @material-ui/core components
import { Grid, Typography, Box, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

// Core components
import { Link } from "react-router-dom";

// Other components
import AdminTransactionalHeader from "../../components/AdminTransactionalHeader/AdminTransactionalHeader"

// Styles
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles);

const Transactional = props => {
  const classes = useStyles()

  return (
    <Grid container spacing={0} className={classes.pWrapper} alignItems="stretch">
      <Grid item xs={12} sm={12} className={classes.pContainer}>
        <AdminTransactionalHeader
          homeBtn={() => props.history.push("/")}
          backBtn={false}
          logo={true}
          closeBtn={false}
        />
        <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
          <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
            <Typography variant="h1" className={classes.headerOneLine}>Page not found</Typography>
            <Box style={{textAlign:"center", padding:"40px 0"}}>
              <Typography variant="h5" component="p" style={{paddingBottom:40}}>Something is not quite right here based on our calculations, please go back to the home page and start again.</Typography>
              <Button size="large" variant="contained" component={Link} to="/" color="primary">BACK HOME</Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withRouter(Transactional)
