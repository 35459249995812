import { primaryColour } from "assets/jss/material-dashboard-react"

const muiTooltipStyle = {
  MuiTooltip: {
    tooltip: {
      backgroundColor: primaryColour[900],
      fontSize: 12
    }
  }
}

export default muiTooltipStyle
