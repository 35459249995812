import {blueColor, primaryColour} from "assets/jss/material-dashboard-react";
import bgImg from "assets/img/hero-bg-texture.svg";
import bgImgMob from "assets/img/hero-bg-texture-mob.png";
import boxImg from "assets/img/self.webp";
import boxImgRemovals from "assets/img/removals.png";
import boxImgRemovalsMobile from "assets/img/removals-mobile.png";
import elipseShine from "assets/img/shines.svg";
import { whiteColor } from "assets/jss/material-dashboard-react";

const homePageStyle = theme => ({
  videoWrap: {
   width: '100vw',
    height: '100vh',
    position: 'relative',
    //padding: 44
  },
  whyItWorksHeader: {
    color: blueColor,
    fontSize: 28,
    textAlign: 'left',
    marginBottom: 5,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 21,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  whyItWorksWrap: {
    width: '100%',
    margin: '0 auto',
  },
  whyItWorksHead: {
    backgroundColor: blueColor,
    borderRadius: '30px 30px 0 0',
    paddingTop: '35px!important',
    paddingBottom: '30px!important'
  },
  whyItWorksFooterWrap: {
    borderRadius: '0px 0px 30px 30px',
    paddingTop: '35px!important',
    paddingBottom: '45px!important',
    position: 'relative',
    top: -1
  },
  whyItWorksFooterImages: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: 20,
    [theme.breakpoints.down(990)]: {
      justifyContent: 'space-between',
    },
    '& > img': {
      marginLeft: 20,
      marginRight: 20,
      width: 'unset',
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
      maxHeight: 48,
      height: '100%',
      maxWidth: '11%',
      [theme.breakpoints.down(1340)]: {
        maxWidth: '9%',
        maxHeight: 35,
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: 10,
      },
      [theme.breakpoints.down(990)]: {
        marginLeft: 10,
        marginRight: 10
      },
      [theme.breakpoints.down(770)]: {
        marginLeft: 2,
        marginRight: 2
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: 10,
        marginLeft: 10,
        marginRight: 10,
        maxWidth: '8%',
        '&:first-child': {
          marginLeft: 0,
        },
        '&:last-child': {
          marginRight: 0,
        },
      },
      [theme.breakpoints.down(450)]: {
        marginLeft: 3,
        marginRight: 3,
      }
    }
  },
  whyItWorksSlide: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: 313,
    marginLeft: 15,
    marginRight: 15
  },
  whyItWorksSlideImage: {
    maxWidth: 240,
    height: '100%',
    width: '100%',
    [theme.breakpoints.down("md")]: {
      maxWidth: 220,
      marginBottom: 29
    },
  },
  whyItWorksSliderWrap: {
    marginBottom: 33,
    [theme.breakpoints.up("sm")]: {
      marginBottom: 82,
    },
  },
  whyItWorksSlideContainer: {
    flexWrap: 'wrap',
    flexDirection: 'column',
    [theme.breakpoints.up("md")]: {
      flexWrap: 'nowrap',
      flexDirection: 'row',
    },
  },
  whyItWorksSlide1Left: {
    backgroundColor: '#FEE624',
    padding: 44,
    //borderRadius: '0 0 0 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down("md")]: {
      //paddingLeft: 61,
      //paddingRight: 61,
    },
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
    }
  },
  whyItWorksSlide2Left: {
    background: '#FBC81A 0% 0% no-repeat padding-box'
  },
  whyItWorksSlide3Left: {
    background: '#0062A4 0% 0% no-repeat padding-box'
  },
  whyItWorksSlide4Left: {
    background: '#C80E2E 0% 0% no-repeat padding-box',
  },
  whyItWorksSlide1LeftImg: {
    [theme.breakpoints.up("md")]: {
      marginBottom: '15%',
      marginTop: 'calc(15% + 24px)',
      maxWidth: 818,
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: '10%',
      marginTop: 'calc(10% + 24px)',
    }
  },
  whyItWorksSlide2LeftImg: {
    [theme.breakpoints.up("md")]: {
      marginBottom: '15%',
      marginTop: 'calc(15% + 24px)',
      maxWidth: 818,
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: '10%',
      marginTop: 'calc(10% + 24px)',
    }
  },
  whyItWorksSlide1LeftImage: {
    maxWidth: 580,
    width: '100%',
    height: 'auto',
    [theme.breakpoints.down("md")]: {
      borderRadius: 0,
    },
  },
  whyItWorksSlide1Right: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#FFFFFF',
    //borderRadius: '0 0 30px 0',
    [theme.breakpoints.down("md")]: {
      paddingTop: 28,
      paddingBottom: 28,
      //borderRadius: '0 0 30px 0',
    },
    [theme.breakpoints.down("xs")]: {
      //borderRadius: '0 0 30px 30px',
    },
  },
  whyItWorksSlide2Right: {
    paddingLeft: '5%',
    paddingRight: '5%',
  },
  whyItWorksSlide2RightWrap: {
    width: '100%',
    [theme.breakpoints.down("md")]: {
      height: 259
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: 'center!important'
    }
  },
  whyItWorksSlide1RightText: {
    fontSize: 18,
    color: '#707070',
    textAlign: 'left',
    marginBottom: 18,
    [theme.breakpoints.up("md")]: {
      fontSize: 28,
      marginBottom: 18
    },
  },
  whyItWorksSlide2RightText: {
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
  },
  whyItWorksSlide3RightText: {
    // [theme.breakpoints.up("md")]: {
    //   paddingTop: 15
    // },
  },
  whyItWorksSlide1RightLogo: {
    alignItems: 'flex-start!important',
    [theme.breakpoints.up("lg")]: {
      marginBottom: 60,
      alignSelf: 'flex-start',
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: 40,
      alignSelf: 'flex-start',
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: 'center!important'
    }
  },
  whyItWorksSlide12RightLogo: {
    width: '100%',
    [theme.breakpoints.up("lg")]: {
      marginBottom: 50,
      alignSelf: 'flex-start',
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: 30,
      alignSelf: 'flex-start',
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: '5px!important',
    },
  },
  mainHeaderBlue: {
    fontSize: 42,
    fontcolor: blueColor,
    textAlign: 'center',
    fontWeight: 500
  },
  videoModalStyles: {
    paddingTop: 100,
    height: '100%',
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 80,
      paddingRight: 80,
    },
  },
  videoCloseButton: {
    position: 'absolute',
    top: 15,
    backgroundColor: whiteColor,
    right: 15
  },
  videoButton: {
    display: 'flex',
    flexWrap: "nowrap",
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 'unset',
      marginRight: 'auto'
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 20
    },
  },
  heroBanner: {
    background: "linear-gradient(220deg, #005695, #5E33A2, #00437C, #0098CC)",
    backgroundSize: "600% 600%",
    //height: "100vh",
    position: "relative",
    animation: "backgroundAmination 20s linear infinite",
    overflow: "hidden",
    height: 737,
    paddingTop: 130,
    [theme.breakpoints.down("xs")]: {
      height: 565,
      paddingTop: 170,
    },
    "&::before": {
      content: `" "`,
      display: "block",
      position: "absolute",
      height: '52%',
      width: "100%",
      bottom: "0",
      backgroundImage: "url(" + bgImgMob + ")",
      backgroundSize: "100% auto",
      backgroundRepeat: "no-repeat",
      backgroundPosition: 'bottom',
      [theme.breakpoints.up("sm")]: {
        backgroundImage: "url(" + bgImg + ")",
        height: "50%",
        backgroundSize: "3000px auto",
        backgroundPosition: "center top",
      },
      [theme.breakpoints.down("xs")]: {
        bottom: -1
      },
    },
    // '&::after': {
    //   content: `" "`,
    //   display: "block",
    //   position: "absolute",
    //   background: `transparent url("${BannerBackground}") repeat center top`
    // }
  },
  mainSection: {
    maxWidth: 750,
    width: "100%",
    height: "100%",
    position: "relative",
    margin:"0 auto"
  },
  heroContent: {
    maxWidth: 992,
    width: "100%",
    height: "100%",
    position: "relative",
    margin: "0 auto",
    textAlign: "center",
    alignItems: "flex-start",
    padding: "30% 0 0 0",
    boxSizing: "border-box",
    marginTop: -65,
    [theme.breakpoints.up("sm")]: {
      textAlign: "initial",
      alignItems: "center",
      paddingTop: 0,
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 0 0 0",
    },
    "& img": {
      width: "100%",
    },
    "&::before": {
      content: `" "`,
      display: "block",
      position: "absolute",
      left: 0,
      right:0,
      margin: "0 auto",
      bottom: "8%",
      height: "280px",
      width: "250px",
      backgroundImage: "url(" + boxImg + ")",
      backgroundSize: "100% auto",
      backgroundPosition: "center 70%",
      backgroundRepeat: "no-repeat",
      zIndex: 2,
      [theme.breakpoints.down("sm")]: {
        bottom: "-1%",
      },
      [theme.breakpoints.up("sm")]: {
        backgroundImage: "url(" + boxImg + ")",
        width: "500px",
        height: "100%",
        backgroundSize: "500px auto",
        backgroundPosition: "center 50%",
        left: "50%",
        right: "initial",
        bottom: "initial"
      },
    },
    "&::after": {
        content: `" "`,
        display: "block",
        position: "absolute",
        //right: "0",
        left: '14%',
        bottom: '14%',
        height: "100%",
        width: "100%",
        backgroundImage: "url(" + elipseShine + ")",
        backgroundSize: "100% auto",
        backgroundPosition: "center 50%",
        backgroundRepeat: "no-repeat",
        zIndex: 1,
        mixBlendMode: "overlay",
        [theme.breakpoints.down("xs")]: {
          top: 0,
          left: 0,
          bottom: 'unset',
          backgroundPosition: "center bottom",
        }
    },
  },
  removalsHelpSectionWrap: {
    margin: 20,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      marginRight: 0
    }
  },
  heroContentRemovals: {
    "&::before": {
      [theme.breakpoints.down("xs")]: {
        backgroundImage: "url(" + boxImgRemovalsMobile + ")",
        width: '100%',
        maxWidth: 375,
        height: 287,
        bottom: '5%'
      },
      [theme.breakpoints.down(400)]: {
        bottom: '0%'
      },
      [theme.breakpoints.up("sm")]: {
        backgroundImage: "url(" + boxImgRemovals + ")",
        left: '52%',
        bottom: 19,
      },
      // [theme.breakpoints.down("xs")]: {
      //   bottom: "-1%",
      //   left: '13%'
      // },
    },
  },
  mainHeader: {
    color: whiteColor,
    fontSize: 42,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      fontSize: 48,
    },
  },
  mainTypography: {
    color: '#fff',
    marginBottom: 30,
    [theme.breakpoints.down("xs")]: {
      fontSize: '2.2rem',
      marginBottom: 15
    }
  },
  mainSubHeading: {
    color: whiteColor,
    lineHeight: "130%",
    fontSize: 18,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      fontSize: 20,
      marginBottom: 25
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 15
    }
  },
  mb1: {
    marginBottom: 10
  },
  features: {
    marginBottom: 70
  },
  caseStudy: {
    paddingTop: 40,
    paddingBottom: 0,
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 15,
    marginTop: 0,
    backgroundColor: whiteColor,
    borderRadius: 30,
    [theme.breakpoints.up("sm")]: {
      paddingTop: 18,
      paddingBottom: 70,
      marginLeft: 15,
      marginRight: 15,
      marginBottom: 25,
    },
  },
  caseStudyRemovals: {
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      marginRight: 0,
    }
  },
  whoItWorkFor: {
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 15,
    marginTop: 0,
    //borderRadius: 30,
    [theme.breakpoints.down("sm")]: {
      //borderRadius: '30px',
      marginBottom: 15,
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: 25,
    }
  },
  circleFeature: {
    backgroundColor:primaryColour[50],
    borderRadius: "50%",
    width: 140,
    height: 140,
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      width: 163,
      height: 163,
    },
  },
  featureMain: {
    fontSize: 38,
    fontWeight: 600,
    color: primaryColour[900],
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: 48,
    },
    "& sup": {
      fontSize: 22,
      [theme.breakpoints.up("sm")]: {
        fontSize: 28,
      },
    }
  },
  featureSub: {
    textTransform: "initial",
    letterSpacing: 0,
    textAlign: "center",
    lineHeight: 1,
    color: primaryColour[500]
  },
  homeSubHeaderBlue: {
    color: blueColor,
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 500
  },
  homeSubHeaderWhite: {
    color: whiteColor,
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 500
  },
  homeSubHeaderGrey: {
    color: '#000000',
    opacity: .6,
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 500
  },
  demo: {
    borderRadius: 30,
    paddingTop: 40,
    paddingBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    minHeight: 903,
    position: 'relative',
    "& .MuiTypography-h1": {
      [theme.breakpoints.down("xs")]: {
        color: primaryColour[900] + "!important",
      },
    },
    [theme.breakpoints.up("sm")]: {
      // marginLeft: 15,
      // marginRight: 15,
      //backgroundColor: primaryColour[800],
      //paddingTop: 187,
      //top: -235,
      position: 'relative',
    },
  },
  integration: {
    marginTop: 15,
    minHeight: 200,
    textAlign: "center",
    overflow: "hidden",
    // [theme.breakpoints.up("sm")]: {
    //   backgroundColor: primaryColour[100],
    // },
  },
  startFreeTxt: {
    textAlign: "center",
    color: whiteColor,
    marginBottom: 20
  },
  startFreeSlider: {
    maxWidth: 888,
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 750,
    }
  },
  helpSectionWrapRemovals: {
    margin: 20,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      marginRight: 0
    }
  },
  startForFreeDivederRemovals: {
    marginTop: 0,
    marginBottom: 7,
    [theme.breakpoints.down("xs")]: {
      marginBottom: '21px!important'
    }
  },
  startFreeFeaturesWrap: {
    paddingLeft: 17,
    paddingBottom: 0,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 10,
    }
  },
  startFreeFeatures: {
    fontSize: 18,
    fontWeight: 'normal',
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    }
  },
  startForFree: {
    paddingTop: 70,
    paddingBottom: 70,
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 15,
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 0,
    backgroundColor: blueColor,
    borderRadius: 30,
    textAlign: "center"
  },
  resetOffsets: {
    marginLeft: 'unset',
    marginRight: 'unset'
  },
  startForFreeRemovals: {
    backgroundColor: '#F2E6FF',
    paddingTop: 38,
    marginBottom: 0,
    marginLeft: 20,
    marginRight: 20,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      marginRight: 0
    }
  },
  startForFreeSimpleHeading: {
    fontSize: 42,
    color: whiteColor,
    textAlign: "center"
  },
  startForFreeDiveder: {
    backgroundColor: '#1F88AB',
    width: 30,
    height: 2,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 14,
    marginBottom: 15
  },
  startForFreeDivederVertical: {
    backgroundColor: '#1F88AB',
    width: 2,
    height: 176,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 54,
    marginBottom: 0,
    [theme.breakpoints.down("xs")]: {
      display: 'none'
    }
  },
  removalsPlanWrap: {
    maxWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down("xs")]: {
      margin: 'auto'
    }
  },
  imgResponsive: {
    maxWidth: "100%",
    height: "auto",
  },
  howCWorksMainWrap: {
    display: 'block',
    backgroundColor: 'white',
    marginLeft: 15,
    paddingTop: 40,
    marginRight: 15,
    marginBottom: 25,
    marginTop: 25,
    paddingBottom: 70,
    borderRadius: 30,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 25,
      paddingRight: 15,
      paddingLeft: 15
    }
  },
  howCalcumateWorksWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 'auto',
    width: '100%',
    maxWidth: 1420,
    paddingLeft: 30,
    paddingRight: 30,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 15
    }
  },
  howCWorksMainTitle: {
    margin: 'auto',
    width: '100%',
    [theme.breakpoints.down("xs")]: {
      fontSize: 24
    }
  },
  howCWorksImage: {
    maxWidth: 330,
    width: '100%',
    margin: 'auto',
    display: 'block',
  },
  howCWorksTitle: {
    fontSize: 38,
    color: primaryColour[800],
    textAlign: 'center',
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      marginTop: 10,
    },
  },
  howCWorksText: {
    fontSize: 24,
    color: '#3c4858',
    textAlign: 'center',
    [theme.breakpoints.down("sm")]: {
      fontSize: 16
    },
  },
  howCWorksChevronWrap: {
    position: 'relative',
    width: '30%'
  },
  howCWorksChevron: {
    position: 'absolute',
    top: '50%',
    right: -75,
    fontSize: 120,
    color: '#EBF1F3',
    marginTop: -60,
    [theme.breakpoints.down("md")]: {
      fontSize: 100,
      marginTop: -50,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 60,
      right: -46,
      marginTop: -30,
    },
    [theme.breakpoints.down("xs")]: {
      display: 'none'
    }
  },
  howCWorksSlideWrap: {
    maxWidth: 400,
    [theme.breakpoints.down("xs")]: {
      maxWidth: '100%'
    }
  },
})

export default homePageStyle
