import kebabCase from 'lodash.kebabcase';

export const handleUnits = (units, measurementSystemIndex, domain) => (units.map((unit, index) => {
    const name = Array.isArray(unit.unitName)
      ? unit.unitName[measurementSystemIndex]
      : unit.unitName;
    const width = Array.isArray(unit.unitWidth)
      ? unit.unitWidth[measurementSystemIndex]
      : unit.unitWidth;
    const depth = Array.isArray(unit.unitDepth)
      ? unit.unitDepth[measurementSystemIndex]
      : unit.unitDepth;
    const height = Array.isArray(unit.unitHeight)
      ? unit.unitHeight[measurementSystemIndex]
      : unit.unitHeight;

    return {
      ...unit,
      unitName: name,
      unitWidth: width,
      unitDepth: depth,
      unitHeight: height,
      // prettier-ignore
      ID: Number.isInteger(unit.ID) ? `${index}-${domain}-${kebabCase(encodeURIComponent(name))}` : unit.ID
    };
  }));

export const compareUnits = (origin, custom) => {
  let result = true;
  if (!custom.length) return result;
  if (origin.length !== custom.length) return false;

  for (let i = 0; i < custom.length; i++) {
    for (let prop in custom[i]) {
      if (custom[i][prop] !== origin[i][prop]) result = false;
    }
    if (!result) break;
  }
  return result;
}
