// @noflow

import React, { useContext } from 'react';
import { CardElement, useElements, useStripe, ElementsConsumer } from '@stripe/react-stripe-js';
import { Grid, Fab } from "@material-ui/core";
import { useGlobalState } from "hooks/useGlobalState"
import EditRounded from '@material-ui/icons/EditRounded'
//import '../styles/common.css';
// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.

const CheckoutForm = props => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatchState = useContext(useGlobalState.Dispatch);
  let state = useContext(useGlobalState.State);
  const {
    paymentDetails
  } = props;
  const handleCardDetailsChange = async e => {
    elements.getElement('card');

    if (e.complete) {
      await createPaymentMethod(e);
      return props.handleCardDetailsChange(e);
    }
    else if (e.error) {
      // show validation to customer
      props.handleCardDetailsChange(e);
    }
  }

  const createPaymentMethod = async (event) => {

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    const billing_details = {
      address: {
        city: paymentDetails?.city,
        country: paymentDetails?.country,
        postal_code: paymentDetails?.postal_code,
        line1: paymentDetails?.address,
      },
      //email: paymentDetails?.email,
      phone: paymentDetails?.phone,
      name: paymentDetails?.firstName && paymentDetails?.lastName ? 
        `${paymentDetails?.firstName} ${paymentDetails?.lastName}`: 
        null,
    }
    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details,
    });


    if (error) {
      console.error(error);
      let obj = {
        billing: {
          paymentMethod: null,
          invalidCard: true
        }
      }
      dispatchState({ type: 'reg-state', payload: { userData: obj } });
    } else {
      // save the payment method in the state
      if (state.isLoggedIn && state.signedInUser.token !== '') {
        props.handleNewPaymentMethod(paymentMethod.id);
      }
      let obj = {
        billing: {
          paymentMethod: paymentMethod,
          invalidCard: false
        }
      }
      dispatchState({ type: 'reg-state', payload: { userData: obj } });



    }
  };
  return (
    <div
      style={
        {
          border: '1px solid rgba(0, 0, 0, 0.23)',
          borderRadius: '4px',
          padding: '18.5px 14px'
        }
      }
    >
      {state.userData.billing.paymentMethod ?
        <Grid container spacing={1}>
          <Grid item xs={12} sm={9} md={9}>Card ending: {state.userData.billing.paymentMethod.card.last4}</Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Fab
              color="primary"
              aria-label="Change plan"
              size="small"
              style={{ float: 'right', margin: '-5px -5px -10px 0' }}
              onClick={() => dispatchState({ type: 'reg-state', payload: { userData: { billing: { paymentMethod: null } } } })}
            >
              <EditRounded />
            </Fab>
          </Grid>
        </Grid> :
        <div>
          <CardElement
            onChange={handleCardDetailsChange}
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />

        </div>
      }
    </div>


  );
};

const InjectedCheckoutForm = props => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CheckoutForm 
          elements={elements} 
          stripe={stripe} 
          handleCardDetailsChange={props.handleCardDetailsChange} 
          handleNewPaymentMethod={props.handleNewPaymentMethod} 
          paymentDetails={props.paymentDetails}
        />
      )}
    </ElementsConsumer>
  );
};



const App = props => {
  let state = useContext(useGlobalState.State);
  return (
    <div>
      <InjectedCheckoutForm 
        handleCardDetailsChange={props.handleCardDetailsChange} 
        handleNewPaymentMethod={props.handleNewPaymentMethod} 
        paymentDetails={props.paymentDetails}
      />
      {state.userData.billing.invalidCard ? <div style={{ color: '#9e2146', fontSize: 14, paddingTop: 5 }}>Invalid card number</div> : ''}
    </div>
  );
};

export default App;
