import React, {useContext, useMemo, useState} from "react";

// @material-ui/core components
import {
  Typography,
  Card,
  CardActionArea,
  Tooltip,
  makeStyles,
  Grid
} from "@material-ui/core";
import RadioButtonUncheckedRounded from "@material-ui/icons/RadioButtonUncheckedRounded";
import CheckCircleOutlineRounded from "@material-ui/icons/CheckCircleOutlineRounded";
import EditRounded from "@material-ui/icons/EditRounded";

// core components
import { useGlobalState } from "hooks/useGlobalState";
import classNames from "classnames";

// other components
import CustomNameDialog from "./CustomNameDialog";

// styles
import styles from "assets/jss/material-dashboard-react/components/adminIntegrationItemStyle";

const useStyles = makeStyles(styles);

const AdminIntegrationItem = ({ prop, children }) => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch);
  const state = useContext(useGlobalState.State);
  const [openDialog, setOpenDialog] = useState(false); //local state

  const {
    itemLibrary,
    integrationNewCategory,
    integrationActiveLanguage,
    integrationMeasurement,
    integrationMeasurement2,
  } = state.integrationData;

  const isMetric = state.integrationData.integrationMeasurement === 'm';

  const itemLibraryEntry = itemLibrary.find((item) => item.id === prop.id);

  const isActiveItem = integrationNewCategory.itemsAssigned.includes(prop.ID);

  const handleSelectItem = () => {
    if (isActiveItem) {
      if (integrationNewCategory.items) {
        const index = integrationNewCategory.items.findIndex(elem => elem.id === prop.id)
        if (index !== -1) {
          integrationNewCategory.items.splice(index, 1)
        }
        integrationNewCategory.itemsAssigned.splice(integrationNewCategory.itemsAssigned.indexOf(prop.ID), 1)
      }
      dispatchState({
        type: "integration-data",
        payload: {
          integrationNewCategory: integrationNewCategory,
        },
      });
    } else {
      if (integrationNewCategory.items) {
        integrationNewCategory.items.push({id: prop.id, sort: integrationNewCategory.items.length })
      } else {
        integrationNewCategory.items = [{id: prop.id, sort: 0}];
      }
      integrationNewCategory.itemsAssigned.push(prop.ID)
      dispatchState({
        type: "integration-data",
        payload: {
          integrationNewCategory: integrationNewCategory,
        }
      });
    }
  };

  // I believe there is a bug in the below. If getItemName is swapped for a
  // variable the item's name will not display properly. This may be due to
  // state being mutated up the tree
  const getItemName = () => {
    if (prop.customName) {
      return prop.customName;
    } else {
      switch (integrationActiveLanguage) {
        case "en":
          return prop.defaultName;
        default:
          return prop[integrationActiveLanguage];
      }
    }
  };
  const weightText = useMemo(() => {
    if (!prop.weight) return null
    const isPlural = prop.weight[isMetric ? 0 : 1] > 1;
    return `${prop.weight[isMetric ? 0 : 1]}${isMetric ? 'kg' : 'lb'}${isPlural ? 's' : ''}`
  }, [prop.weight, isMetric]);

  return (
    <Card
      className={classNames(classes.itemCard, {
        [classes.cardActive]: isActiveItem,
      })}
    >
      <Grid container style={{padding: 10}} justify={'space-between'} alignItems={'center'}>
        {isActiveItem ? children : null}
        <Tooltip style={{marginLeft: 5}} title="Customise item name" aria-label="Customise item name">
          <EditRounded
            onClick={() => {
              setOpenDialog(true);
            }}
            className={classes.cardEdit}
          />
        </Tooltip>

        {isActiveItem ? (
          <CheckCircleOutlineRounded onClick={handleSelectItem} className={classes.itemCheckedIcon} />
        ) : (
          <RadioButtonUncheckedRounded onClick={handleSelectItem} className={classes.itemCheckedIcon} />
        )}
      </Grid>
      <CardActionArea
        onClick={() => setOpenDialog(true)}
        className={classes.itemCardAction}
      >
        <img
          src={prop.itemThumbnail}
          className={classes.itemCardImg}
          alt=""
          role="presentation"
        />

        <Typography variant="subtitle2" align="center">
          {getItemName()}
        </Typography>
        <Typography variant="subtitle2" align="center">
          {integrationMeasurement === "m"
            ? prop.dimension[0]
            : prop.dimension[1]}{" "}
          {integrationMeasurement}
          <sup>3</sup>
          {
            prop.weight && (
              <>
                | {`${weightText}`}
              </>
            )
          }
        </Typography>
        <Typography
          variant="caption"
          align="center"
          className={classes.itemSize}
        >
          (H{" "}
          {integrationMeasurement === "m"
            ? prop.h[0] + integrationMeasurement2[0]
            : prop.h[1] + integrationMeasurement2[1]}{" "}
          W{" "}
          {integrationMeasurement === "m"
            ? prop.w[0] + integrationMeasurement2[0]
            : prop.w[1] + integrationMeasurement2[1]}{" "}
          D{" "}
          {integrationMeasurement === "m"
            ? prop.d[0] + integrationMeasurement2[0]
            : prop.d[1] + integrationMeasurement2[1]}
          )
        </Typography>
      </CardActionArea>

      <CustomNameDialog
        key={prop.id}
        isOpen={openDialog}
        defaultCustomName={itemLibraryEntry?.customName || ""}
        defaultSize={itemLibraryEntry.scale || null}
        itemThumbnail={prop.itemThumbnail}
        weight={prop.weight}
        itemLibraryEntryIndex={itemLibrary.indexOf(itemLibraryEntry)}
        state={state}
        onClose={() => setOpenDialog(false)}
        dispatchState={dispatchState}
        defaultWeight={itemLibraryEntry.scaleWeight || null}
        elem={prop}
      />
    </Card>
  );
};

export default React.memo(AdminIntegrationItem);
