/*eslint-disable*/
import React, {useContext, useState, useEffect, Fragment, useMemo} from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Button, Typography, Divider } from "@material-ui/core"

// core components
import { useLocation, useHistory } from "react-router-dom";
import axios from 'axios';
import { useGlobalState } from "hooks/useGlobalState"
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
// Other components
import AdminTransactionalHeader from "components/AdminTransactionalHeader/AdminTransactionalHeader"
import PaymentSelected from "components/PaymentSelected/PaymentSelected"
import NewPaymentComponent from '../AdminPaymentMethod/NewPaymentComponent';
import isEmpty from 'lodash.isempty';
import { allowedStorageTypes } from "../../helpers/validation";
import usePrice from "../../hooks/usePrice";
// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles);


// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const AdminMySubscriptionUpdate = props => {
  const classes = useStyles();
  let query = useQuery();
  let history = useHistory();
  const dispatchState = useContext(useGlobalState.Dispatch);
  let state = useContext(useGlobalState.State);
  const [updating, setUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState('initial');
  const [updateError, setUpdateError] = useState('');
  //Local state
  let [values, setValues] = useState({
    paymentMod: false
  });

  const selectedServiceDetails = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let service = 'self-storage';
    let number = 1;
  
    for (const key of urlParams.keys()) {
      if (allowedStorageTypes.includes(key)) {
        service = key;
        number = urlParams.get(key) || 1;
      }
    }
    return {
      service,
      number,
    }
  }, []);
  
  const setToModCard = mode => () => {
    setValues({ paymentMod: mode })
  };
  const backLink = query.get("backLink");

  const getUpgradingPlan = () => {
    let selectedPlan = state.calcumatePlans.filter(plan => {
      if (plan.service) {
        return plan.service === selectedServiceDetails.service && plan.thePlan.toLowerCase() === (query.get("plan") || '').toLowerCase();
      }
      return plan.thePlan.toLowerCase() === (query.get("plan") || '').toLowerCase();
    });
    return selectedPlan[0];
  };

  const price = usePrice({
    plan: getUpgradingPlan(), 
    currency: state.currency, 
    type: state.cmPlanPayRec,
    number: selectedServiceDetails.number,
    service: selectedServiceDetails.service,
  });

  const handleSupscriptionUpgradeClick = async () => {
    // send the request to the API to change the subscription
    setUpdating(true);
    const newPlan = getUpgradingPlan();
    // prepare the object to send to the API
    const sids = newPlan[`sid${state.currency}`];
    let requestObj = { 
      userData: state.signedInUser.data.user, 
      type: 'change', 
      newSubscription: sids[state.cmPlanPayRec ? 0 : 1],
      serviceNumber: selectedServiceDetails.number, 
      service: selectedServiceDetails.service,
    };

    // send the request to the API
    await axios.post(
      process.env.REACT_APP_API_URL + 'changesubscription/',
      requestObj,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.signedInUser.token } }
    ).then(function (response) {


      if (response.status && response.status === 200 && response.data) {
        setUpdateSuccess(response.data.status ? 'true' : 'false');
        setUpdateError(!response.data.status ? (response.data.error.type ? response.data.error.raw.message : response.data.error) : '');
        if (response.data.status) {
          // if the response was a success, update the user details in the state
          const sids = newPlan[`sid${state.currency}`];
          dispatchState({
            type: 'root-state',
            payload: {
              showInactiveMessage: false,
              currentPlan: newPlan.thePlan,
              originCurrency: state.currency,
              currentPlanPrice: price,
              service: selectedServiceDetails.service,
              serviceNumber: selectedServiceDetails.number,
              currentPlanPriceRec: state.cmPlanPayRec ? '/yr' : '/mo',
              originPlanPriceRec: state.cmPlanPayRec ? '/yr' : '/mo',
              originPricingPlan: sids[state.cmPlanPayRec ? 0 : 1],
              originServiceNumber: state.serviceNumber,
              signedInUser: {
                ...state.signedInUser,
                data: {
                  ...state.signedInUser.data,
                  user: {
                    ...state.signedInUser.data.user,
                    active: true,
                    service: selectedServiceDetails.service,
                    serviceNumber: selectedServiceDetails.number,
                    stripeAccount: {
                      ...state.signedInUser.data.user.stripeAccount,
                      subscriptionId: response.data.newSubscriptionId ? response.data.newSubscriptionId : state.signedInUser.data.user.stripeAccount.subscriptionId 
                    },
                    lastChosenSubscription: sids[state.cmPlanPayRec ? 0 : 1]
                  }
                }
              }
            }
          });
          setUpdateSuccess('true');
          // redirect the user to the admin panel after a timeout
          setTimeout(() => {
            history.push("/admin/my-account");
          }, 1500);

        } else {
          setUpdating(false);
          setUpdateSuccess('false');
        }
      } else {
        setUpdating(false);
        setUpdateSuccess('false');
      }

    }).catch(function (error) {
      setUpdating(false);
      setUpdateSuccess('false');
      console.error(error);
    });


  }
  
  const cost = useMemo(() => {
    const currencyDetails = state.availableCurrencies.find(currency => currency.currency === state.currency);
    const sign = state.showSign ? currencyDetails.sign : '';
    if (typeof price === 'number')
      return `${state.currency}${sign}${price}`;
    return price;
  }, [])


  const triggerButtons = () => {
    if (values.paymentMod) {
      return (
        <NewPaymentComponent setToModCard={mode => setValues({ paymentMod: mode })} update={state.currentPlan.toLowerCase()} />
      )
    } else {
      return (
        <div>
          <PaymentSelected
            cardIcon={state.signedInUser?.data?.card?.brand} // visa, mastercard, amex
            cardDigits={state.signedInUser?.data?.card?.last4digits}
            expDate={state.signedInUser?.data?.card?.expiry}
            marginTopBottom={20}
            editBtn={setToModCard(true)}
          />
          <Divider style={{ marginTop: 30, marginBottom: 20 }} />
          <Grid container spacing={0} direction="row" justify="space-between" alignItems="center" className={classes.planSelected}>
            <Grid item><Typography variant="h6">Amount payable</Typography></Grid>
            <Grid item><Typography variant="h6">{cost}</Typography></Grid>
          </Grid>
          <Grid container spacing={0} className={classes.planSelected}>
            <Grid item sm={12} md={12} lg={12}>
              <Button
                variant="contained"
                // component={Link}
                // to={'/admin/my-account/my-subscription/confirmed'}
                className={classes.buttonSpacing}
                color="primary"
                size="large"
                style={{ marginTop: 30 }}
                onClick={handleSupscriptionUpgradeClick}
                disabled={updating || !state.signedInUser?.data?.card?.last4digits}
                fullWidth>
                {updating ? <CircularProgress color='inherit' size={15} /> : 'Change my plan'}
              </Button>
            </Grid>
          </Grid>
        </div>
      )
    }
  }

  const redirect = () => {
    history.replace('/login');
    window.location.reload();
  };

  /* eslint-disable */
  useEffect(() => {
    if (!state.isLoggedIn || state.signedInUser.token === '') {
      history.replace('/login');
      window.location.reload();
    }

  }, []);

  const currencyDetails = state.availableCurrencies.find(currency => currency.currency === state.currency);

  return (
    <Fragment>
      <AdminTransactionalHeader
        homeBtn={false}
        backBtn={() => props.history.push(backLink || "/admin/my-account/my-subscription")}
        logo={false}
        closeBtn={false}
      />
      <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
        <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
          <Typography variant="overline">Subscription purchase</Typography>
          {updateSuccess === 'true' ?
            <div className={classes.notification}>
              <br />
              <Alert severity="success">Update succesful!</Alert>

            </div>
            : ''}
          {updateSuccess === 'false' ?
            <div className={classes.notification}>
              <br />
              <Alert severity="error">{updateError !== '' && !isEmpty(updateError) ? updateError : 'Something went wrong. Please try again.'}</Alert>

            </div>
            : ''}
          {
            query.get("plan").length ?
              <Typography variant="h1" className={classes.headerTwoLine}>{getUpgradingPlan()?.thePlanLabel || getUpgradingPlan()?.thePlan} {currencyDetails.sign}{price}{state.cmPlanPayRec ? '/yr' : '/mo'}</Typography>
              : <Typography variant="h1" className={classes.headerTwoLine}>{state.currentPlan} {currencyDetails.sign}{state.currentPlanPrice}{state.currentPlanPriceRec}</Typography>
          }


          {state.isLoggedIn && state.signedInUser.token !== '' ? triggerButtons() : redirect()}

        </Grid>
      </Grid>
    </Fragment>

  );
}

export default AdminMySubscriptionUpdate
