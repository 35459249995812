import { primaryColour, whiteColor, grayColor } from "assets/jss/material-dashboard-react";

const integrationHeadingStyle = theme => ({
  modInput: {
    "& .Mui-disabled": {
      color: primaryColour[700],
    },
    "& .MuiInput-root": {
      color: primaryColour[900],
      fontSize: "2.635rem",
      fontFamily: "futura-pt, sans-serif",
      fontWeight: 600,
      lineHeight: 1.167
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.20)"
    }
  },
  inputWrapper: {
    position: "relative",
    lineHeight: 1,
    "& .MuiInputBase-input": {
      paddingRight: 50,
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 10
    }
  },
  inputFab: {
    position: "absolute",
    right: 10,
    top: 8,
    pointerEvents: "none"
  },
  inHeadingWrapper: {
    position: "relative",
    paddingRight: 30,
    paddingLeft: 20
  },
  panelOneController: {
    position: "absolute",
    top: 17,
    right: -50,
    backgroundColor: whiteColor,
    padding: 6,
    borderRadius: "50%"
  },
  saveIcon: {
    color: grayColor[3],
    "&:hover": {
      cursor: 'pointer',
      color: grayColor[2],
    }
  }
});

export default integrationHeadingStyle
