import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

const LoaderDots = (props) => {
  return (
    <LoaderWrap>
      <LoaderRunning color={props.primaryColour}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </LoaderRunning>
    </LoaderWrap>
  );
};

export default LoaderDots;

const LoaderWrap = styled.div`
  //padding: 20px;
  text-align: center;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const animation = keyframes`
  0%,
  100%{opacity:1}
  50%{opacity:.5}
`;

const LoaderRunning = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  & > div {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${(props) => (props.color ? props.color : '#c00000')};
    animation: lds-grid 1.2s linear infinite;
    animation-name: ${animation};
  }
  & > div:nth-child(1) {
    top: 6px;
    left: 6px;
    animation-delay: 0s;
    animation-name: ${animation};
  }
  & > div:nth-child(2) {
    top: 6px;
    left: 26px;
    animation-delay: -0.4s;
    animation-name: ${animation};
  }
  & > div:nth-child(3) {
    top: 6px;
    left: 45px;
    animation-delay: -0.8s;
    animation-name: ${animation};
  }
  & > div:nth-child(4) {
    top: 26px;
    left: 6px;
    animation-delay: -0.4s;
    animation-name: ${animation};
  }
  & > div:nth-child(5) {
    top: 26px;
    left: 26px;
    animation-delay: -0.8s;
    animation-name: ${animation};
  }
  & > div:nth-child(6) {
    top: 26px;
    left: 45px;
    animation-delay: -1.2s;
    animation-name: ${animation};
  }
  & > div:nth-child(7) {
    top: 45px;
    left: 6px;
    animation-delay: -0.8s;
    animation-name: ${animation};
  }
  & > div:nth-child(8) {
    top: 45px;
    left: 26px;
    animation-delay: -1.2s;
    animation-name: ${animation};
  }
  & > div:nth-child(9) {
    top: 45px;
    left: 45px;
    animation-delay: -1.6s;
    animation-name: ${animation};
  }
`;
