/*eslint-disable*/
import React, { Fragment } from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

// Other components
import ContactForm from "components/ContactForm/ContactForm"
import AdminTransactionalHeader from "components/AdminTransactionalHeader/AdminTransactionalHeader";

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles)

const AdminSupport = props => {
  const classes = useStyles();
  
  return (
    <Fragment>
      <AdminTransactionalHeader
        homeBtn={false}
        backBtn={false}
        logo={false}
        closeBtn={() => props.history.push("/admin/my-account")}
      />
      <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
        <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
          <Typography variant="overline">Support centre</Typography>
          <Typography variant="h1" className={classes.headerTwoLine}>Contact us</Typography>
          <ContactForm />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default AdminSupport
