import React, {useEffect, useMemo, useState} from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"

import AdminTransactionalHeader from "../../components/AdminTransactionalHeader/AdminTransactionalHeader"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
import {getCmsPost, handleNodes} from "../../helpers/cmsHelpers";
import NotFound from "../NotFound/NotFound";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles(styles)

const Blog = props => {
  const classes = useStyles();
  const [pageData, setPageData] = useState(null);
  const [is404, setIs404] = useState(false);
  const [loading, setLoading] = useState(null);
  const [dynamicContent, setDynamicContent] = useState([]);
  useEffect(() => {
    setLoading(true);
    getCmsPost('blog').then(result => {
      result.data[0] ?
        setPageData(result.data[0]) :
        setIs404(true);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      console.log('error', error);
    });

  }, []);

  useEffect(() => {
    import("html-react-parser").then(({default: parse}) => {
      if (!pageData?.post_content) return null;
      setDynamicContent(parse(pageData.post_content).filter(item => item?.type));
    });
  }, [pageData]);

  if (is404) {
    return <NotFound />
  }

  return (
    <Grid style={{
        minHeight: '100vh', 
        paddingBottom: 30
      }}
    >
      <AdminTransactionalHeader
        homeBtn={() => props.history.push("/")}
        backBtn={false}
        logo={true}
        closeBtn={false}
      />
      <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
        <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
          <Typography variant="h1" className={classes.headerOneLine}>Blog</Typography>
        </Grid>
        {
          loading ? <Grid className={'entry-content'} style={{minHeight: '33vh', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}><CircularProgress /></Grid> : (
            <section id={'cms-content'} className={classes.cmsContent}>
              <div className={'entry-content'} >
                {
                  (pageData || dynamicContent) && dynamicContent.map((elem, index) => handleNodes(elem, index))
                }
              </div>
            </section>
          )
        }
      </Grid>
    </Grid>
  );
}

export default Blog;
