import React, {useContext, useEffect} from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Button, Typography, Divider } from "@material-ui/core"

// Core components
import { useGlobalState } from "hooks/useGlobalState"

// Other components
import AdminPanels from "components/AdminPanels/AdminPanels"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import { Link, useHistory } from "react-router-dom";

import { sendAdminProgressEmail } from '../../../helpers/SendEmail';
import {saveIntegration} from "../../../helpers/SaveIntegration";

const useStyles = makeStyles(styles)

const ConfirmStep = props => {
  const classes = useStyles();
  const history = useHistory();
  const dispatchState = useContext(useGlobalState.Dispatch);
  const state = useContext(useGlobalState.State);
  const { 
    theRef, 
    updating, 
    setUpdating, 
  } = props

  /* eslint-disable */
  useEffect(() => {
    dispatchState({ type: 'integration-data', payload: { integrationStepperStep: 5 } }) // Set stepper step number 6
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
  }, []);
  /* eslint-enable */

  const handleConfirmClick = async () => {
    setUpdating(true);
    if (state.newIntegration || state.integrationData.integrationPublished === false) {
      // send the notification only if this is a new integration
      await sendAdminProgressEmail(3, {
        firstName: state.signedInUser.data.user.firstName,
        lastName: state.signedInUser.data.user.lastName,
        phone: state.signedInUser.data.user.phone,
        email: state.signedInUser.data.user.email,
        company: state.signedInUser.data.user.companyName,
        currency: state.currency,
        domain: state.integrationData.integrationDomain,
        plan: `${state.currentPlan} / ${state.signedInUser?.data?.user?.lastChosenSubscription}`,
        pricing: state.signedInUser?.data?.user?.lastChosenSubscription,
        serviceNumber: state.serviceNumber,
        service: state.service,
        coupon: state?.userData?.billing?.coupon ?
          state.userData.billing.coupon :
          state?.signedInUser?.data?.billing?.coupon
      });
    }

    await saveIntegration({ ...state, signedInUser: {...state.signedInUser, data: {...state.signedInUser.data, user: {...state.signedInUser.data.user, registrationActivity: 'Integration Created'}}}}, false, dispatchState);
    setUpdating(false);
    history.push('/admin/my-calcumate/new?step=confirmed');
  };

  return (
    <form noValidate autoComplete="off" className={classes.standardForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h1">Confirmation</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="h3">Are you happy with the live preview?</Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            className={classes.buttonSpacing2}
            color="primary"
            size="large"
            fullWidth
            component={Link}
            to={'/admin/my-calcumate/new?step=design'}
          >Keep editing</Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            className={classes.buttonSpacing2}
            color="primary"
            size="large"
            fullWidth
            disabled={updating}
            onClick={handleConfirmClick}
          >Yes, publish now</Button>
        </Grid>
        <Grid item xs={12}>
          <AdminPanels
            theHeader={false}
            headerAction={false}
            fabCodeAction={false}
            fabCloneAction={false}
            fabEditAction={false}
            fabDeleteAction={false}
            panelTheme="dark"
          >
            <Typography variant="h5">Need assistance?</Typography>
            <Typography variant="body1">Please <Link to="/admin/support">contact us</Link> if you have questions or display issues and your developer can't fix it quickly. We'll help to fix it (usually free of charge).</Typography>
          </AdminPanels>
        </Grid>
      </Grid>

    </form>
  )
}

export default ConfirmStep
