import { FormControl, InputLabel, MenuItem, Select, makeStyles } from "@material-ui/core";
import React from "react";
import { allowedServices } from '../../SelectService';
import { Services } from "helpers/types";

const styles = (theme: any) => ({
  selectServiceContainer: {
    '& .MuiOutlinedInput-notchedOutline': {
      boxSizing: 'border-box',
      border: '1px solid #D4D4D4',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: 20,
    },
    '& .MuiOutlinedInput-input': {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '17px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      color: '#075695'
    },
    '& .MuiSelect-icon': {
      fontSize: '2rem',
      top: 5,
      color: '#075695'
    },
    '& .MuiSelect-select.MuiSelect-select': {
      backgroundColor: 'unset'
    }
  }
});

export default ({
  selectedService,
  onChange,
}: SelectServiceProps) => {

  const classes = useStyles();

  return (
    <>
      <FormControl className={classes.selectServiceContainer} fullWidth style={{maxWidth: 300}}>
        <Select
          id="p-service"
          value={selectedService}
          variant="outlined"
          MenuProps={{
            className: 'select-service-menu'
          }}
          onChange={(e) => {
            onChange(e.target.value as Services);
          }}
          style={{borderRadius: 20, height: 41}}
        >
          {
            allowedServices.map((service: AllowedService, key: number) => (
            <MenuItem key={key} value={service.value}>{service.label}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </>
  );
}

//@ts-ignore
const useStyles = makeStyles(styles);

interface SelectServiceProps {
  selectedService: Services;
  onChange: (service: Services) => void;
}


export interface AllowedService {
  value: Services,
  label: string;
  img?: any;
}
