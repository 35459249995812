import React, {useContext, useState} from 'react';
import {useGlobalState} from "../../hooks/useGlobalState";
import {Fab, TextField, Tooltip, Grid} from "@material-ui/core";
import SaveAsRoundedIcon from "@material-ui/icons/SaveRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import {saveIntegration} from "../../helpers/SaveIntegration";
import CircularProgress from "@material-ui/core/CircularProgress";

const ExportFiled = props => {
  const dispatchState = useContext(useGlobalState.Dispatch);
  const state = useContext(useGlobalState.State);
  const { label, property, closeEdit } = props;
  const [value, setValue] = useState(state.integrationData[property]);
  const [updating, setUpdating] = useState(false);
  const save = async () => {
    setUpdating(true);
    try {
      dispatchState({
        type: 'integration-data',
        payload: {
          [property]: value
        }
      });
      await saveIntegration({ ...state, integrationData: { ...state.integrationData,  [property]: value}}, false, dispatchState);
    } catch (e) {
      console.log('Saving error:', e);
    }
    setUpdating(false);
    closeEdit();
  }

  return (
    <Grid style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    }}>
      <TextField
        id={`p-${label}`}
        label={label}
        variant="outlined"
        type="text"
        fullWidth
        error={(value && !value.length)}
        onChange={e => setValue(e.target.value)}
        value={value || ''}
        style={{maxWidth: '78%'}}
      />
      <Grid style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
        <Tooltip title="Save field" aria-label="Save field" style={{marginRight: 10}}>
          <Fab
            color="primary"
            aria-label="Save"
            size="small"
            onClick={save}
          >
            { updating ?
              <CircularProgress color='inherit' size={15} /> :
              <SaveAsRoundedIcon style={{fontSize: 20}} />
            }
          </Fab>
        </Tooltip>
        <Tooltip title="Cancel" aria-label="Cancel">
          <Fab
            color="primary"
            aria-label="Cancel"
            size="small"
            onClick={closeEdit}
          >
            <CancelRoundedIcon style={{fontSize: 20}} />
          </Fab>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default ExportFiled;
