import React, { useContext, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { Elements } from '@stripe/react-stripe-js';
import InfoIcon from '@material-ui/icons/InfoRounded';
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, Button, Typography, Select, MenuItem, InputLabel, FormControl, IconButton, Tooltip } from "@material-ui/core";
// Core components

// Other components
import { useGlobalState } from "hooks/useGlobalState";

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
import { Link } from "react-router-dom"
import StripeCard from 'components/Stripe/Card';
const useStyles = makeStyles(styles);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentStep = () => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch);
  const state = useContext(useGlobalState.State);

  const sendConfirmationCode = async () => {
    // send the confirmation code
    const response = await axios.post(
      process.env.REACT_APP_API_URL + 'confirmation',
      {
        email: state.userData.email,
        firstName: state.userData.firstName
      },
      { headers: { 'Content-Type': 'application/json' } }
    )
    if (response.data && response.data.status) {
      dispatchState({ type: 'reg-state', payload: { userData: { confirmationCode: response.data.code } } });
    }
  }

  const handleCardDetailsChange = (e) => {
    let obj = {
      billing: {
        creditCard: {
          valid: false
        }
      }
    }
    if (e.error) {
      obj.billing.creditCard.valid = false;
    }
    if (e.complete) {
      obj.billing.creditCard.valid = true;
    }
    dispatchState({ type: 'reg-state', payload: { userData: obj } });
  }

  const handleChange = e => {
    var obj = { billing: {} };
    obj.billing[e.target.name] = e.target.value;
    dispatchState({ type: 'reg-state', payload: { userData: obj } });
  }

  /* eslint-disable */
  useEffect(() => {
    dispatchState({ type: 'root-state', payload: { signUpStepperStep: 1 } }) // Set stepper step number 2
  }, []);
  /* eslint-enable */
  return (
    <form noValidate autoComplete="off" className={classes.standardForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <p className={classes.ccSubText}>FREE trial for 30 days. Your card won't be charged for the first 30 days.<br />Cancel at any time prior.</p>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2">Payment details</Typography>
        </Grid>
        <Grid item xs={12}>
          {/* <StripeCardsSelection /> */}

          <Elements stripe={stripePromise}><StripeCard handleCardDetailsChange={handleCardDetailsChange} /></Elements>

        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2">Billing Details</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="cm-payment-country-label">Country</InputLabel>
            <Select
              id="cm-payment-country"
              value={state.userData.billing.country}
              variant="outlined"
              label="Country"
              name="country"
              onChange={handleChange}
            >
              {state.accountCurrentCountryList.map((label, key) => (
                <MenuItem key={key} value={label.value}>{label.country}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField id="p-payment-fname" name="firstName" label="First name" variant="outlined" type="text" fullWidth onChange={handleChange} value={state.userData.billing.firstName} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField id="p-payment-lname" name="lastName" label="Last name" variant="outlined" type="text" fullWidth onChange={handleChange} value={state.userData.billing.lastName} />
        </Grid>
        <Grid item xs={12}>
          <TextField id="p-payment-address" name="address" label="Address" variant="outlined" type="text" fullWidth onChange={handleChange} value={state.userData.billing.address} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={11}>
              <TextField id="p-payment-company" name="company" label="Business ID/Tax number" variant="outlined" type="text" fullWidth onChange={handleChange} value={state.userData.billing.company} />
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Your Business ID/Tax number will appear on your invoice for tax and accounting purposes">
                <IconButton aria-label="delete">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
          <Typography variant="body2">You will be charged monthly/annually on the anniversary date of when your free trial period ends. Reminder you can cancel anytime</Typography>
        </Grid> */}
        <Grid item xs={6} sm={6} md={6}>
          <Button
            component={Link}
            to="/sign-up"
            variant="outlined"
            className={classes.buttonSpacing}
            color="primary"
            size="large"
            fullWidth

          >
            Back
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Button
            variant="contained"
            component={Link}
            onClick={sendConfirmationCode}
            to="/sign-up/confirmation"
            className={classes.buttonSpacing}
            color="primary"
            size="large"
            fullWidth
            disabled={(!state.userData.billing.paymentMethod) || state.userData.billing.invalidCard || state.userData.billing.firstName === '' || state.userData.billing.lastName === '' || state.userData.billing.address === '' /*|| state.userData.billing.company === ''*/}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </form >
  )
}

export default PaymentStep
