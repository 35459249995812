import React, { useRef, useState, Fragment, useEffect } from "react";

import { Button } from "@material-ui/core"

const CopyToClipboardBtn = props => {
    const [copySuccess, setCopySuccess] = useState('Copy to clipboard');
    const [textToCopy, setTextToCopy] = useState('');
    useEffect(() => {
        if(props.txtcopy) {
            setTextToCopy(props.txtcopy);
        }
    }, []);
    
    const textAreaRef = useRef(null);
    const copyToClipboard = () => {

        if(!textToCopy) return;
        textAreaRef.current.select();
        document.execCommand('copy');

        setCopySuccess('Code copied');
        setTimeout(()=>{
            setCopySuccess('Copy to clipboard');
        }, 3000);
    };
    
    return (
        <Fragment>
            {document.queryCommandSupported('copy') &&
                <Fragment>
                    <textarea
                        style={{position:"absolute", left:-10000}}
                        ref={textAreaRef}
                        rows={12}
                        value={textToCopy} />
                    <Button variant="contained" color="primary" size="small" onClick={copyToClipboard} fullWidth>{copySuccess}</Button>
                </Fragment>
            }
        </Fragment>
    );
}

export default CopyToClipboardBtn;