import React, {useContext, useEffect, useMemo, useState} from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, TextField, Button, Typography, Divider, FormControl, InputLabel, Select, MenuItem, RadioGroup, FormControlLabel, Radio, Popover, Switch } from "@material-ui/core"
import HelpRounded from "@material-ui/icons/HelpRounded"
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import CloseRounded from '@material-ui/icons/CloseRounded'
import { saveIntegration } from '../../../helpers/SaveIntegration';
import { defaultUnitsHandler } from '../../../variables/InitState';
import CloneDeep from 'lodash.clonedeep';
import { mapPlansLimitations } from '../../../variables/InitState';
// Core components
import { useGlobalState } from "hooks/useGlobalState";
import { useHistory } from "react-router-dom";
import classNames from 'classnames'
import { compareUnits, handleUnits } from "../../../helpers/handleUnits";

// Other components
import {regexp, regexpLocalhost} from '../../../helpers/validation';
import PayButton from '../../FreeVersion/Button';
// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import {getDomain} from "../../../helpers/getDomain";

const useStyles = makeStyles(styles)

const OptionStep = props => {
  const classes = useStyles();
  let history = useHistory();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const { theRef, updating } = props
  const [domainUrlPop, setDomainUrlPop] = useState(null);
  const [ctaUrlPop, setCtaUrlPop] = useState(null);
  const [unitDetailsPop, setUnitDetailsPop] = useState(null);
  const [furnitureDetailsPop, setFurnitureDetailsPop] = useState(null);
  const [showAdditionalDomain, setShowAdditionalDomain] = useState(false);
  const { setUpdating } = props;

  const restrictions = useMemo(() => mapPlansLimitations?.[state.currentPlan] || [], []);

  /* eslint-disable */
  useEffect(() => {

    if (state.isLoggedIn && state.signedInUser.token !== '') {
      const isDomainValid = regexp.test(state.integrationData.integrationDomain) || regexpLocalhost.test(state.integrationData.integrationDomain);
      const isDomainValid2 = regexp.test(state.integrationData.integrationDomain2) || regexpLocalhost.test(state.integrationData.integrationDomain2);
      let isCtaUrlValid = true;
      if (!state.integrationData.integrationCTAType) {
        isCtaUrlValid = regexp.test(state.integrationData.integrationCtaURL) ||
          regexpLocalhost.test(state.integrationData.integrationCtaURL) ||
          new RegExp('^\#{1}.+$').test(state.integrationData.integrationCtaURL);
      }
      dispatchState({
        type: 'integration-data',
        payload: {
          integrationStepperStep: 0,
          domainUrlValid: isDomainValid,
          domainUrlValid2: isDomainValid2,
          ctaUrlValid:
            !restrictions.includes('integrationCtaURL') ?
              true :
              isCtaUrlValid
        }
      }) // Set stepper step number 1
      theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
    } else {
      history.replace('/login');
      window.location.reload();
    }
  }, [state.integrationData.integrationSecPanel, state.integrationData.integrationCTAType]);

  /* eslint-enable */

  const validateURL = (url, type) => {

    if (type === "domain") {
      if (regexp.test(url) || regexpLocalhost.test(url)){
        dispatchState({ type: 'integration-data', payload: { domainUrlValid: true } });
      } else {
        dispatchState({ type: 'integration-data', payload: { domainUrlValid: false } });
      }
      dispatchState({ type: 'integration-data', payload: { integrationDomain: url } });
    }
    if (type === "domain2") {
      if (regexp.test(url) || regexpLocalhost.test(url)){
        dispatchState({ type: 'integration-data', payload: { domainUrlValid2: true } });
      } else {
        dispatchState({ type: 'integration-data', payload: { domainUrlValid2: false } });
      }
      dispatchState({ type: 'integration-data', payload: { integrationDomain2: url } });
    }
    if (type === "cta") {
      // check whether the user is adding a url and not a phone number
      if (!state.integrationData.integrationCTAType) {
        if (regexp.test(url) || regexpLocalhost.test(url) || new RegExp('^\#{1}.+$').test(url)) {
          dispatchState({ type: 'integration-data', payload: { ctaUrlValid: true } });
        } else {
          dispatchState({ type: 'integration-data', payload: { ctaUrlValid: false } });
        }
      } else {
        dispatchState({ type: 'integration-data', payload: { ctaUrlValid: true } });
      }
      dispatchState({ type: 'integration-data', payload: { integrationCtaURL: url } })
    }


  }

  const handleDesignUpdate = async (value) => {
    props.setUpdating(true)
    const defaultUnits = defaultUnitsHandler(value);

    const defaultUnitsOldOne = defaultUnitsHandler(state.integrationData.integrationStorageType);
    const domain = getDomain(state.integrationData.integrationDomain);
    const measurementSystemIndex =
      state.integrationData.integrationMeasurement === "m" ? 0 : 1;

    const integrationUnits = compareUnits(handleUnits(defaultUnitsOldOne, measurementSystemIndex, domain), handleUnits(state.integrationData.integrationUnits, measurementSystemIndex, domain)) ?
      defaultUnits :
      state.integrationData.integrationUnits;

    const newIntegrationData = {...state.integrationData, integrationUnits, integrationStorageType: value};
    const newState = CloneDeep({...state, integrationData: newIntegrationData});
    dispatchState({type: 'integration-data', payload: {...newIntegrationData}});

    try {
      await saveIntegration(newState, false, dispatchState);
    } catch (e) {
      console.log('Saving error: ', e)
    }
    props.setUpdating(false)
  }

  const handleOptionChange = async (key, value) => {
    props.setUpdating(true)
    dispatchState({type: 'integration-data', payload: { [key]: value}});
    const newState = CloneDeep({...state, integrationData: {...state.integrationData, [key]: value}})
    try {
      await saveIntegration(newState, false, dispatchState);
    } catch (e) {
      console.log('Saving error:', e)
    }
    props.setUpdating(false)
  }

  return (
    <form noValidate autoComplete="off" className={classes.standardForm}>
      { !state.integrationDataOptions.updatingItems ?
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h1">Options</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Website URL <HelpRounded style={{ verticalAlign: "sub", fontSize: "1.3rem" }} onClick={(event) => { setDomainUrlPop(event.currentTarget) }} /></Typography>
            <Popover
              id="domainURL"
              open={Boolean(domainUrlPop)}
              anchorEl={domainUrlPop}
              onClose={() => { setDomainUrlPop(null) }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography variant="body2" className={classes.blueText}>
                This is the URL of the website where this integration will be
                loaded. The code will only work on this URL. If you would like
                to run the tool on a test URL please use that initially and
                change it to the live domain once you’re happy with the results.
                Please note each time you change the URL you will need to make
                sure you proceed to step 6 and click “Publish” and follow the
                instructions to insert the code into your website.
              </Typography>
            </Popover>
            <TextField
              id="p-integration-url"
              label="URL"
              variant="outlined"
              type="text"
              helperText="Example: https://www.yourwebsite.com"
              fullWidth
              error={!state.integrationData.domainUrlValid}
              onChange={e => validateURL(e.target.value, "domain")}
              value={state.integrationData.integrationDomain}
              disabled={!restrictions.includes('integrationDomain')}
            />
            {
              (!showAdditionalDomain && !state.integrationData.integrationDomain2) && (
                <Link
                  component="button"
                  variant="body2"
                  style={{marginTop: 5}}
                  onClick={event => {
                    event.preventDefault()
                    setShowAdditionalDomain(true);
                  }}
                >
                  Additional domain (Optional)
                </Link>
              )
            }
          </Grid>
          {
            (showAdditionalDomain || state.integrationData.integrationDomain2) && (
              <Grid item xs={12} className={classNames({[classes.pointerEventNone]: !restrictions.includes('integrationDomain2') })}>
                <TextField
                  id="p-integration-url-additional"
                  label="Additional URL (Optional)"
                  variant="outlined"
                  type="text"
                  helperText="Example: https://dev.yourwebsite.com"
                  fullWidth
                  error={state.integrationData.integrationDomain2 && !state.integrationData.domainUrlValid2}
                  onChange={e => validateURL(e.target.value, "domain2")}
                  value={state.integrationData.integrationDomain2}
                  disabled={!restrictions.includes('integrationDomain2')}
                  InputProps={{
                    endAdornment: <CloseRounded style={{cursor: 'pointer'}} onClick={()=>{
                      dispatchState({ type: 'integration-data', payload: { integrationDomain2: null } });
                    }} />,
                  }}
                />
              </Grid>
            )
          }
          <Grid item xs={12} className={classNames({[classes.pointerEventNone]: !restrictions.includes('integrationActiveLanguage') })}>
            <Divider className={classes.customDividerOne} />
            <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>
              <span className={
                classNames({[classes.disabled]: !restrictions.includes('integrationActiveLanguage')})}>
                Integration language</span>{!restrictions.includes('integrationStorageType') ? <PayButton setUpdating={setUpdating} feature={'integrationStorageType'} updating={updating} /> : null}</Typography>
            {
              restrictions.includes('integrationActiveLanguage') && (
                <>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="p-integration-language">Select language</InputLabel>
                    <Select
                      id="p-integration-language"
                      value={state.integrationData.integrationActiveLanguage}
                      variant="outlined"
                      label="Select language"
                      onChange={(e) => handleOptionChange('integrationActiveLanguage', e.target.value)}
                      disabled={!restrictions.includes('integrationActiveLanguage')}
                    >
                      {state.integrationData.integrationLanguages.map((label, key) => (
                        <MenuItem key={key} value={label.value}>{label.language}</MenuItem>
                      ))}
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                  {state.integrationData.integrationActiveLanguage === "other" && (
                    <Alert severity="error">
                      Hi there. We can add your language in. Please switch back to another language e.g. English to continue preparing your integration for now (you won't be able to proceed without doing this) and send us an email with your language request which we'll add in later. Contact us{" "}
                      <a target="_blank" href="/admin/support">
                        here
                      </a>
                    </Alert>
                  )}
                </>
              )
            }
          </Grid>
          <Grid item xs={12} className={classNames({[classes.pointerEventNone]: !restrictions.includes('integrationStorageType') })}>
            <Divider className={classes.customDividerOne} />
            <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>
              <span className={
                classNames({[classes.disabled]: !restrictions.includes('integrationStorageType')})}>
                Select storage type
              </span> {
              !restrictions.includes('integrationStorageType') ?
                <PayButton feature={'integrationStorageType'} updating={updating} setUpdating={setUpdating} /> :
                null}
            </Typography>

            {
              restrictions.includes('integrationStorageType') && (
                <FormControl component="fieldset" style={{width: "100%"}}>
                  <RadioGroup row aria-label="storage-type" name="storage-type" style={{justifyContent: 'space-between', width: "100%", gap: '10px 0'}}>
                    <FormControlLabel
                      value="self-storage"
                      control={<Radio color="primary" />}
                      label="Self storage"
                      onChange={(e) => { handleDesignUpdate( e.target.value) }}
                      labelPlacement="end"
                      checked={state.integrationData.integrationStorageType === "self-storage"}
                      className={classes.customRadio}
                      style={{minWidth: 'unset'}}
                      disabled={!restrictions.includes('integrationStorageType')}
                    />
                    <FormControlLabel
                      value="mobile-storage"
                      control={<Radio color="primary" />}
                      label="Mobile storage"
                      onChange={(e) => { handleDesignUpdate( e.target.value) }}
                      labelPlacement="end"
                      checked={state.integrationData.integrationStorageType === "mobile-storage"}
                      className={classes.customRadio}
                      style={{minWidth: 'unset'}}
                      disabled={!restrictions.includes('integrationStorageType')}
                    />
                    <FormControlLabel
                      value="container-storage"
                      control={<Radio color="primary" />}
                      label="Container storage"
                      onChange={(e) => { handleDesignUpdate( e.target.value) }}
                      labelPlacement="end"
                      checked={state.integrationData.integrationStorageType === "container-storage"}
                      className={classes.customRadio}
                      style={{minWidth: 'unset'}}
                      disabled={!restrictions.includes('integrationStorageType')}
                    />
                    <FormControlLabel
                      value="removals-storage"
                      control={<Radio color="primary" />}
                      label="Removals"
                      onChange={(e) => { handleDesignUpdate( e.target.value) }}
                      labelPlacement="end"
                      checked={state.integrationData.integrationStorageType === "removals-storage"}
                      className={classes.customRadio}
                      style={{minWidth: 'unset'}}
                      disabled={!restrictions.includes('integrationStorageType')}
                    />
                    <br />
                    <FormControlLabel
                      value="portable-storage"
                      control={<Radio color="primary" />}
                      label="Portable unit"
                      onChange={(e) => { handleDesignUpdate( e.target.value) }}
                      labelPlacement="end"
                      checked={state.integrationData.integrationStorageType === "portable-storage"}
                      className={classes.customRadio}
                      style={{minWidth: 108}}
                      disabled={!restrictions.includes('integrationStorageType')}
                    />
                    <FormControlLabel
                      value="indoor-storage"
                      control={<Radio color="primary" />}
                      label="Indoor storage"
                      onChange={(e) => { handleDesignUpdate( e.target.value) }}
                      labelPlacement="end"
                      checked={state.integrationData.integrationStorageType === "indoor-storage"}
                      className={classes.customRadio}
                      style={{minWidth: 'unset', marginRight: 'auto'}}
                      disabled={!restrictions.includes('integrationStorageType')}
                    />
                  </RadioGroup>
                </FormControl>
              )
            }
          </Grid>
          <Grid item xs={12} className={classNames({[classes.pointerEventNone]: !restrictions.includes('integrationMeasurement') })}>
            <Divider className={classes.customDividerOne} />
            <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}><span className={classNames({[classes.disabled]: !restrictions.includes('integrationMeasurement')})}>Select measurement system</span> {!restrictions.includes('integrationMeasurement') ? <PayButton setUpdating={setUpdating} feature={'integrationMeasurement'} updating={updating} /> : null}</Typography>

            { restrictions.includes('integrationMeasurement') && (
                <FormControl component="fieldset">
                  <RadioGroup row aria-label="measurement-method" name="measurement-method">
                    <FormControlLabel
                      value="m"
                      control={<Radio color="primary" />}
                      label="Metric"
                      onChange={(e) => { handleOptionChange('integrationMeasurement', e.target.value)}}
                      labelPlacement="end"
                      checked={state.integrationData.integrationMeasurement === "m"}
                      className={classes.customRadio}
                      disabled={!restrictions.includes('integrationMeasurement')}
                    />
                    <FormControlLabel
                      value="ft"
                      control={<Radio color="primary" />}
                      label="Imperial"
                      onChange={(e) => { handleOptionChange('integrationMeasurement', e.target.value) }}
                      labelPlacement="end"
                      checked={state.integrationData.integrationMeasurement === "ft"}
                      className={classes.customRadio}
                      disabled={!restrictions.includes('integrationMeasurement')}
                    />
                  </RadioGroup>
                </FormControl>
              )
            }
          </Grid>
          <Grid item xs={12} className={classNames({[classes.pointerEventNone]: !restrictions.includes('showResultUnitDetails') })}>
            <Divider className={classes.customDividerOne} />
            <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}><span className={classNames({[classes.disabled]: !restrictions.includes('showResultUnitDetails')})}>Measurements and Dials</span> {!restrictions.includes('showResultUnitDetails') ? <PayButton setUpdating={setUpdating} feature={'showResultUnitDetails'} updating={updating} /> : null}</Typography>
            {
              restrictions.includes('showResultUnitDetails') && (
                <>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="subtitle2">Display unit volume and space used by items <HelpRounded style={{ verticalAlign: "sub", fontSize: "1.3rem", pointerEvents: 'auto' }} onClick={(event) => { setUnitDetailsPop(event.currentTarget) }} /></Typography>
                      <Popover
                        id="displayUnit"
                        open={Boolean(unitDetailsPop)}
                        anchorEl={unitDetailsPop}
                        onClose={() => { setUnitDetailsPop(null) }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <Typography variant="body2" className={classes.blueText}>
                          These additional measurements will display after the calculation has been completed.
                        </Typography>
                      </Popover>
                    </Grid>
                    <Grid item>
                      {
                        !restrictions.includes('showResultUnitDetails') ?
                          <PayButton feature={'showResultUnitDetails'} updating={updating} setUpdating={setUpdating} /> :
                          (
                            <Switch
                              checked={state.integrationData.showResultUnitDetails}
                              onChange={() => handleOptionChange('showResultUnitDetails', !state.integrationData.showResultUnitDetails)}
                              color="primary"
                              name="cta-type"
                              inputProps={{ 'aria-label': 'CTA type' }}
                              disabled={!restrictions.includes('showResultUnitDetails')}
                            />
                        )
                      }
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="subtitle2">Display total weight of items</Typography>
                    </Grid>
                    <Grid item>
                      {
                        !restrictions.includes('integrationShowItemWeight') ?
                          <PayButton setUpdating={setUpdating} updating={updating} feature={'integrationShowItemWeight'} /> :
                          (
                            <Switch
                              checked={state.integrationData.integrationShowItemWeight}
                              onChange={() => handleOptionChange('integrationShowItemWeight', !state.integrationData.integrationShowItemWeight)}
                              color="primary"
                              name="show-weight"
                              inputProps={{ 'aria-label': 'Show weight' }}
                              disabled={!restrictions.includes('integrationShowItemWeight')}
                            />
                          )
                      }
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="subtitle2">Display furniture item dimensions <HelpRounded style={{ verticalAlign: "sub", fontSize: "1.3rem", pointerEvents: 'auto' }} onClick={(event) => { setFurnitureDetailsPop(event.currentTarget) }} /></Typography>
                      <Popover
                        id="displayUnit"
                        open={Boolean(furnitureDetailsPop)}
                        anchorEl={furnitureDetailsPop}
                        onClose={() => { setFurnitureDetailsPop(null) }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <Typography variant="body2" className={classes.blueText}>
                          Choose to display the depth, width and height of the items added.
                        </Typography>
                      </Popover>
                    </Grid>
                    <Grid item>
                      {
                        !restrictions.includes('hideResultUnitDetails') ? <PayButton setUpdating={setUpdating} feature={'hideResultUnitDetails'} updating={updating} /> : (
                          <Switch
                            checked={!state.integrationData.hideResultUnitDetails}
                            onChange={() => handleOptionChange('hideResultUnitDetails', !state.integrationData.hideResultUnitDetails)}
                            color="primary"
                            name="cta-type"
                            inputProps={{ 'aria-label': 'CTA type' }}
                            disabled={!restrictions.includes('hideResultUnitDetails')}
                          />
                        )
                      }
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="subtitle2">Allow the user to play, pause and re-play the stacking animation</Typography>
                    </Grid>
                    <Grid item>
                      {
                        !restrictions.includes('integrationAllowAnimationControl') ?
                          <PayButton setUpdating={setUpdating} updating={updating} feature={'integrationAllowAnimationControl'} /> :
                          (
                            <Switch
                              checked={state.integrationData.integrationAllowAnimationControl}
                              onChange={() => handleOptionChange('integrationAllowAnimationControl', !state.integrationData.integrationAllowAnimationControl)}
                              color="primary"
                              name="allow-animation"
                              inputProps={{ 'aria-label': 'Allow animation' }}
                              disabled={!restrictions.includes('integrationAllowAnimationControl')}
                            />
                        )
                      }
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="subtitle2">Allow the user to copy selected items</Typography>
                    </Grid>
                    <Grid item>
                      {
                        !restrictions.includes('integrationAllowCopy') ?
                          <PayButton setUpdating={setUpdating} updating={updating} feature={'integrationAllowCopy'} /> :
                          (
                            <Switch
                              checked={state.integrationData.integrationAllowCopy}
                              onChange={() => handleOptionChange('integrationAllowCopy', !state.integrationData.integrationAllowCopy)}
                              color="primary"
                              name="allow-copy"
                              inputProps={{ 'aria-label': 'Allow copy' }}
                              disabled={!restrictions.includes('integrationAllowCopy')}
                            />
                          )
                      }
                    </Grid>
                  </Grid>
                </>
              )
            }
          </Grid>
          <Grid item xs={12} className={classNames({[classes.pointerEventNone]: !restrictions.includes('integrationCtaURL') })}>
            <Divider className={classes.customDividerOne} />
            <Typography
              variant="subtitle1"
              component="h2"
              className={classes.subHeadOne}
            >
              <span
                className={
                  classNames({[classes.disabled]: !restrictions.includes('integrationCtaURL')})
                }>
                Call to action URL
              </span> {
              restrictions.includes('integrationCtaURL') &&
              <HelpRounded
                style={{ verticalAlign: "sub", fontSize: "1.3rem", pointerEvents: 'auto' }}
                onClick={(event) => { setCtaUrlPop(event.currentTarget) }} />} {!restrictions.includes('integrationCtaURL') ? <PayButton setUpdating={setUpdating} feature={'integrationCtaURL'} updating={updating} /> : null}</Typography>
            <Popover
              id="ctaPopover"
              open={Boolean(ctaUrlPop)}
              anchorEl={ctaUrlPop}
              onClose={() => { setCtaUrlPop(false) }}
              style={{pointerEvents: 'auto'}}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography variant="body2" style={{zIndex: 1}} className={classes.blueText}>Link on the button displayed on the calculation result.</Typography>
            </Popover>
            {
              restrictions.includes('integrationCtaURL') && (
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <TextField
                      id="p-integration-cta-url"
                      label={state.integrationData.integrationCTAType ? "Phone number" : "URL"}
                      variant="outlined"
                      type="text"
                      error={!state.integrationData.ctaUrlValid}
                      helperText={state.integrationData.integrationCTAType ? "Example: 1234 567 899" : "Example: https://yourwebsite.com/book-now"}
                      onChange={e => validateURL(e.target.value, "cta")}
                      value={state.integrationData.integrationCtaURL}
                      fullWidth
                      disabled={!restrictions.includes('integrationCtaURL')}
                    />
                  </Grid>
                </Grid>
              )
            }
          </Grid>
            {
              restrictions.includes('optionAdvancedSettings') && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    className={classes.buttonSpacing}
                    color="secondary"
                    size="medium"
                    fullWidth
                    disabled={
                      !restrictions.includes('optionAdvancedSettings') ||
                      !state.integrationData.ctaUrlValid
                    }
                    onClick={() => { dispatchState({ type: 'integration-data', payload: { integrationSecPanel: true } }) }}
                  >Show advanced settings</Button>
                </Grid>
              )
            }
        </Grid>
        : null}
    </form>
  )
}

export default OptionStep
