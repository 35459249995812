import {
  drawerWidth,
  transition,
  container,
  primaryColour
} from "assets/jss/material-dashboard-react.js"

const adminStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  integrationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    flexWrap: 'nowrap',
    paddingBottom: 0,
    marginBottom: 20,
    [theme.breakpoints.up("xs")]: {
      height: 'calc(100vh - 350px)',
    },
    [theme.breakpoints.up("md")]: {
      height: 'calc(100vh - 315px)',
    },
  },
  content: {
    marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 133px)",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingTop: 0,
      paddingBottom: 30,
      paddingLeft: "6%",
      paddingRight: "6%"
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: 0,
      paddingBottom: 30,
      paddingLeft: "8%",
      paddingRight: "8%"
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: 0,
      paddingBottom: 30,
      paddingLeft: "10%",
      paddingRight: "10%"
    },
  },
  panelHelpBar: {
    position: "relative",
    bottom: 0,
    left: 0,
    right: 0,
    margin: "0px 0px",
    [theme.breakpoints.up("xs")]: {
      position: "relative",
      // marginLeft: "3%",
      // marginRight: "3%",
    },
    [theme.breakpoints.up("md")]: {
      position: "relative",
      // marginLeft: 0,
      // marginRight: 0,
    },
    // [theme.breakpoints.up("md")]: {
    //   marginLeft: "6%",
    //   marginRight: "6%"
    // },
    // [theme.breakpoints.up("lg")]: {
    //   marginLeft: "8%",
    //   marginRight: "8%"
    // },
    // [theme.breakpoints.up("xs")]: {
    //   marginLeft: "10%",
    //   marginRight: "10%"
    // },
  },
  container,
  map: {
    marginTop: 70
  },
  addNewIntegration: {
    marginTop: 10,
    marginBottom: 10
  },
  tableMarginTop: {
    marginTop: 10,
    marginBottom: 10
  },
  userBlock: {
    position: "relative",
    "& .MuiTypography-root" :{
      paddingLeft: 60,
    },
    "& .MuiSvgIcon-root": {
      position: "absolute",
      color: primaryColour[800],
      width: 50,
      height: 50
    }
  },
  paymentBlock: {
    position: "relative",
    "& img": {
      position: "absolute",
      width: 50,
      height: 50,
    },
    "& .MuiTypography-body2" :{
      paddingLeft: 60,
    },
  },
  customH6: {
    color: primaryColour[800],
    fontSize:"1rem"
  }
})

export default adminStyle
