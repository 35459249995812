import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import SimpleHeading from "../SimpleHeading/SimpleHeading";
import Slider from "react-slick";
import Relax from '../../assets/img/Relax.webp';
import Build from '../../assets/img/Build.webp';
import Copy from '../../assets/img/Copy.webp';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// Styles
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-react/views/HomePageStyle";
import classNames from 'classnames';
const useStyles = makeStyles(styles);

const HowCalcumateWorks = () => {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 8000,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings:  {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1

        }
      },
      {
        breakpoint: 500000,
        settings:  'unslick'
      },
    ]
  };

  const Slide = ({img, title, text}) => (
    <Grid className={classes.howCWorksSlideWrap}>
      <img className={classNames(classes.howCWorksImage, "lazyload")} data-src={img} alt={title} />
      <Typography className={classes.howCWorksTitle}>{title}</Typography>
      <Typography className={classes.howCWorksText}>{text}</Typography>
    </Grid>
  )

  return (
    <section className={classes.howCWorksMainWrap} id={'how-calcumate-works-slider'}>
      <Grid
        container
      >
        <SimpleHeading style={{margin: 'auto', width: '100%'}} mb={25} weight={500}>How Calcumate works</SimpleHeading>
        <Slider {...settings} style={{width: '100%'}} className={classes.howCalcumateWorksWrap}>
          <Grid className={classes.howCWorksChevronWrap}>
            <Slide
              img={Build}
              title={'BUILD'}
              text={'Set your calculator’s branding, items & measurements.'}
            />
            <ChevronRightIcon className={classes.howCWorksChevron}  />
          </Grid>
          <Grid className={classes.howCWorksChevronWrap}>
            <Slide
              img={Copy}
              title={'COPY'}
              text={'Add the calculator code snippet anywhere on your website.'}
            />
            <ChevronRightIcon className={classes.howCWorksChevron} />
          </Grid>
          <Grid className={classes.howCWorksChevronWrap}>
            <Slide
              img={Relax}
              title={'RELAX'}
              text={'Your customers can now learn how much space they need in seconds!'}
            />
          </Grid>
        </Slider>
      </Grid>
    </section>
  )
}

export default HowCalcumateWorks;
