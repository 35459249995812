import React, { Fragment, useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {Grid, Typography, Button, Fab} from "@material-ui/core";
import CloseRounded from '@material-ui/icons/CloseRounded';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

// Core components
import classNames from "classnames";

//Other components
import Footer from "components/Footer/Footer";
import HomeHeader from "components/HomeHeader/HomeHeader";
import SimpleHeading from "components/SimpleHeading/SimpleHeading";
import WhyItWorks from "../../components/WhyItworks";
import WhoItWorkFor from "../../components/WhoItWorkFor";
import StartForFree from '../../components/StartForFree';
import LoaderDots from './LoaderDots';
import { whyItWorks } from '../../variables/InitState';
//Styles
import styles from "assets/jss/material-dashboard-react/views/HomePageStyle";

import Modal from '@material-ui/core/Modal';
import HowCalcumateWorks from "../../components/HowCalcumateWorks";

const useStyles = makeStyles(styles);

const HomePage = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const [screen, setScreen] = useState({});
  const [loadingIntegration, setLoadingIntegration] = useState(false);

  /* eslint-disable */
  useEffect(() => {
    const loadCalculatorScript = () => {
      const script = document.createElement("script");
      setLoadingIntegration(true);
      script.src = process.env.REACT_APP_DEMO_CALCULATOR_SCRIPT_URL;
      script.async = true;
      script.onload = () => setLoadingIntegration(false);
      script.onerror = () => setLoadingIntegration(false);
      // loop through existing children and delete the script if exists
      [...document.body.children]
        .filter(
          (node) =>
            node.src === process.env.REACT_APP_DEMO_CALCULATOR_SCRIPT_URL
        )
        .forEach((node) => node.remove());
      document.body.appendChild(script);
    };

    loadCalculatorScript();
    setScreen({innerWidth: window.innerWidth, innerHeight: window.innerHeight});
    if (location?.href?.includes('autoplay')) {
      setOpen(true)
    }
    return () => {};
  }, []);

  return (
    <Fragment>
      <HomeHeader />
      <section className={classes.heroBanner} id="main-section">
        <Grid
          container
          spacing={5}
          className={classNames(classes.heroContent, classes.mainSection)}
          direction="row"
          justify="flex-start"
          alignItems="center"
          id="hero-content"
        >
          <Grid item xs={12} sm={6}>
            <Typography className={classes.mainTypography} variant={'h1'}>Calcumate Size Guide</Typography>
            <Typography
              className={classes.mainSubHeading}
              variant="body1"
              fontWeight="fontWeightMedium"
            >
              Solving storage’s biggest question:
              <br />
              How much space do I need?
            </Typography>
            <Typography
              className={classes.mainSubHeading}
              variant="body1"
              fontWeight="fontWeightMedium"
            >
              Give your customers accurate
              <br />
              sizing recommendations in seconds.
            </Typography>
            <Button
              variant="contained"
              color="default"
              size="large"
              className={classes.videoButton}
              onClick={(e)=>{
                e.preventDefault();
                setOpen(true);
              }}
            >
              <PlayArrowIcon
                color="primary"
                aria-label="Edit"
                size="small"
                style={{marginRight: 4, fontSize: 14}}
              />
              Watch the video
            </Button>
          </Grid>
        </Grid>
      </section>
      <section className={classes.demo} id="demo-section">
        <SimpleHeading mb={10} weight={500} style={{paddingLeft: 30, paddingRight: 30}}>
          Take Away The Guesswork
        </SimpleHeading>
        <Typography variant={'h2'} component={'h2'} className={classes.homeSubHeaderBlue} style={{paddingLeft: 30, paddingRight: 30}}>Fast, accurate and easy to use, Calcumate fits seamlessly onto your website.</Typography>
        <div className={classes.integration}>
          {
            loadingIntegration && <LoaderDots primaryColour={'#93959E'} />
          }
          <div
            id="calcumate-root"
            data-integration={process.env.REACT_APP_CALCULATOR_DEMO_DOMAIN}
            data-ref={process.env.REACT_APP_CALCULATOR_DEMO_CUID}
            data-int={process.env.REACT_APP_CALCULATOR_DEMO_INT}
          />
        </div>
      </section>
      <HowCalcumateWorks/>
      <WhoItWorkFor />
      <WhyItWorks data={whyItWorks.home} />
      <StartForFree />
      <Footer />
      <Modal
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.videoWrap}

      >
        <div className={classes.videoModalStyles}>
          <Fab
            color="primary"
            aria-label="Edit"
            size="medium"
            className={classes.videoCloseButton}
            onClick={()=>setOpen(false)}
          >
            <CloseRounded
              color="primary"
              aria-label="Edit"
              size="medium"
            />
          </Fab>
          <div style={{position:'relative', height: screen.innerWidth && screen.innerWidth - 80 * .75 < screen.innerHeight ?  screen.innerWidth - 80 * .75 :'75%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/JQ6SICvt7RI"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>

          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default HomePage;
