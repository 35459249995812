import axios from 'axios';

export async function getSignedUploadUrlForUser(data, state) {
  const {id, type} = data
  return await axios.get(process.env.REACT_APP_API_URL+ 'url' + `/${id}/${type}`, {headers: {Authorization: "Bearer " + state.signedInUser.token}})
}

export async function getSignedUploadUrlUnitImage(data, state) {
  const {id, type} = data
  return await axios.get(process.env.REACT_APP_API_URL+ 'url-unit' + `/${id}/${type}`, {headers: {Authorization: "Bearer " + state.signedInUser.token}})
}

export async function uploadIntegrationLogo(url, file) {
  try {
    const buffer = await file.arrayBuffer();
    const response = await axios.put(url, buffer, {headers: {'Content-Type': file.type}})
    return response;
  } catch (e) {
    console.log('e:', e)
  }
}
export async function uploadIntegrationUnitImage(url, file) {
  try {
    const buffer = await file.arrayBuffer();
    const response = await axios.put(url, buffer, {headers: {'Content-Type': file.type}})
    return response;
  } catch (e) {
    console.log('e:', e)
  }
}
