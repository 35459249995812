import { primaryColour } from "assets/jss/material-dashboard-react";
import { whiteColor, blueColor } from "assets/jss/material-dashboard-react";
import AccordionIcon from '../../../img/+icon@2x@2x.png';

const CmsPageStyles = theme => ({
  header: {
    background: "linear-gradient(220deg, #005695, #5E33A2, #00437C, #0098CC)",
    backgroundSize: "600% 600%",
    position: "relative",
    animation: "backgroundAmination 20s linear infinite",
    overflow: "hidden",
    height: 103,
    [theme.breakpoints.up("md")]: {
      height: 112,
    },
  },
  cmsContent: {
    marginBottom: 63,
    marginLeft: 15,
    marginRight: 15,
    //paddingTop: 23,
  },
  mainSection: {
    maxWidth: 750,
    width: "100%",
    height: "100%",
    position: "relative",
    margin:"0 auto"
  },
  images: {
    paddingTop: 40,
    paddingBottom: 40,
    '& img': {
      height: 'auto',
      maxWidth: '90%',
      [theme.breakpoints.up("md")]: {
        marginLeft: 0,
        marginRight: 0,
        maxWidth: 720,
      },
    }
  },
  paragraph: {
    maxWidth: '90%',
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
      marginRight: 0,
      maxWidth: 720,
    },
  },
  mainHeader: {
    color: whiteColor,
    fontSize: 42,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      fontSize: 48,
    },
  },
  mainSubHeading: {
    color: primaryColour[100],
    lineHeight: "130%",
    fontSize: 18,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      fontSize: 20,
    },
  },
  mb1: {
    marginBottom: 10
  },
  features: {
    marginBottom: 70
  },
  caseStudy: {
    paddingTop: 40,
    paddingBottom: 40,
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 15,
    marginTop: 0,
    backgroundColor: whiteColor,
    borderRadius: 30,
    [theme.breakpoints.up("sm")]: {
      paddingTop: 70,
      paddingBottom: 70,
    },
  },
  circleFeature: {
    backgroundColor:primaryColour[50],
    borderRadius: "50%",
    width: 140,
    height: 140,
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      width: 163,
      height: 163,
    },
  },
  featureMain: {
    fontSize: 38,
    fontWeight: 600,
    color: primaryColour[900],
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: 48,
    },
    "& sup": {
      fontSize: 22,
      [theme.breakpoints.up("sm")]: {
        fontSize: 28,
      },
    }
  },
  featureSub: {
    textTransform: "initial",
    letterSpacing: 0,
    textAlign: "center",
    lineHeight: 1,
    color: primaryColour[500]
  },
  demo: {
    borderRadius: 30,
    paddingTop: 40,
    paddingBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    "& .MuiTypography-h1": {
      [theme.breakpoints.down("xs")]: {
        color: primaryColour[900] + "!important",
      },
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: 15,
      marginRight: 15,
      backgroundColor: primaryColour[800],
      paddingTop: 70,
    },
  },
  integration: {
    borderRadius: 30,
    minHeight: 200,
    marginBottom: 15,
    textAlign: "center",
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      backgroundColor: primaryColour[100],
    },
  },
  startFreeTxt: {
    textAlign: "center",
    color: primaryColour[900],
    marginBottom: 20
  },
  startForFree: {
    paddingTop: 70,
    paddingBottom: 70,
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 15,
    marginTop: 0,
    backgroundColor: whiteColor,
    borderRadius: 30,
    textAlign: "center"
  },
  startForFreeRemovals: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  startForFreeCms: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  imgResponsive: {
    maxWidth: "100%",
    height: "auto",
  },
  footerSection: {
    marginTop: 'auto'
  },
  cmsHeader: {
    backgroundColor: '#00437C',
    height: 120
  },
  accordionHead: {
    '& .accordion__button': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
      width: 'unset',
      backgroundColor: '#fff',
    },
    '& .accordion__button:before': {
      width: 30,
      height: 30,
      minWidth: 30,
      minHeight: 30,
      margin: 0,
      backgroundImage: `url(${AccordionIcon})`,
      border: 'unset',
      backgroundPosition: "center",
      borderRadius: '50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      transform: 'rotate(90deg)',
    },
    "& .accordion__button[aria-expanded='true']::before, .accordion__button[aria-selected='true']::before": {
      transform: 'rotate(45deg)'
    },
    '& .eb-accordion-title-wrapper': {
      backgroundColor: 'unset!important',
      marginTop: 'unset!important',
      marginLeft: '12px!important'
    },
  },
  accordionContent: {
    backgroundColor: 'white',
    '& .eb-accordion-content-wrapper': {
      border: 'unset',
      borderWidth: '0!important',
      minHeight: 70,
      paddingRight: 52,
      paddingLeft: 21,
      backgroundColor: 'unset!important',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: "center"
    }
  }
})

export default CmsPageStyles
