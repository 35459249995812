import { 
  calculateProgressivePrice, 
  isPriceProgressive 
} from '../helpers/common';

export const planPrice = ({
  plan, 
  currency,  
  type,
}) => {
  if (!plan) return false;
  let prices = type ? plan?.[currency]?.[0] : plan?.[currency]?.[1];
  if (isPriceProgressive(prices)) {
    return calculateProgressivePrice(prices, 1);
  }
  return prices;
}

export  default ({
  plan, 
  currency, 
  type,
  number,
}) => {
  if (!plan) return false;
  let prices = type ? plan?.[currency]?.[0] : plan?.[currency]?.[1];
  if (isPriceProgressive(prices)) {
    return calculateProgressivePrice(prices, number);
  }
  return prices;
}