/*eslint-disable*/
import React, { useContext, useState, Fragment,useMemo } from "react"
import axios from 'axios';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Button, Typography } from "@material-ui/core"

import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
// core components
import { useGlobalState } from "hooks/useGlobalState"
import { Link, useHistory } from "react-router-dom";
// Other components
import AdminTransactionalHeader from "components/AdminTransactionalHeader/AdminTransactionalHeader"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
import usePrice from "hooks/usePrice";

const useStyles = makeStyles(styles)

const AdminMySubscriptionConfirmed = props => {
  const classes = useStyles();
  let state = useContext(useGlobalState.State);
  const dispatchState = useContext(useGlobalState.Dispatch);
  const [updating, setUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState('initial');
  const [updateError, setUpdateError] = useState('');
  let history = useHistory();

  const handleCancelSubscription = async () => {
    setUpdating(true);
    // send the request to the backend
    // prepare the object to send to the API
    let requestObj = { userData: state.signedInUser.data.user, type: 'cancel' };

    // send the request to the API
    await axios.post(
      process.env.REACT_APP_API_URL + 'changesubscription/',
      requestObj,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.signedInUser.token } }
    ).then(function (response) {


      if (response.status && response.status === 200 && response.data) {
        setUpdateSuccess(response.data.status ? 'true' : 'false');
        setUpdateError(!response.data.status ? (response.data.error.type ? response.data.error.raw.message : response.data.error) : '');
        if (response.data.status) {
          // if the response was a success, update the user details in the state
          dispatchState({ 
            type: 'root-state', 
            payload: { 
              showInactiveMessage: true, 
              currentPlan: "cancelled", 
              currentPlanPrice: 0, 
              currentPlanPriceRec: '', 
              signedInUser: { 
                ...state.signedInUser, 
                data: { 
                  ...state.signedInUser.data,
                  user: { 
                    ...state.signedInUser.data.user, 
                    active: false, 
                    lastChosenSubscription: 'cancel', 
                    stripeAccount: {
                      ...state.signedInUser.data.user.stripeAccount,
                      subscriptionId: null
                    } 
                  } 
                } 
              } 
            } 
          });
          setUpdateSuccess('true');
          // redirect the user to the admin panel after a timeout
          setTimeout(() => {
            history.push("/admin/my-account/my-subscription/cancelled");
          }, 1500);

        } else {
          setUpdating(false);
          setUpdateSuccess('false');
        }
      } else {
        setUpdating(false);
        setUpdateSuccess('false');
      }

    }).catch(function (error) {
      setUpdating(false);
      setUpdateSuccess('false');
      console.error(error);
    });
  };

   const price = useMemo(() => {
     let selectedPlan = state.calcumatePlans.filter(plan => {
       return plan.thePlan === state.currentPlan;
     });
     if (!selectedPlan.length)
       return 'FREE';

     const currnentPrice = usePrice({
      plan: selectedPlan?.[0], 
      currency: state.currency, 
      type: state.cmPlanPayRec,
      number: state.serviceNumber,
     });
     const currencyDetails = state.availableCurrencies.find(currency => currency.currency === state.currency);
 
     if (typeof currnentPrice === 'number')
       return `${currencyDetails.sign}${currnentPrice}${state.currentPlanPriceRec}`
     return currnentPrice;
   }, [state.currentPlanPriceRec, state.currentPlanPrice, state.currentPlan]);
  
  return (
    <Fragment>
      <AdminTransactionalHeader
        homeBtn={false}
        backBtn={() => props.history.push("/admin/my-account/my-subscription")}
        logo={false}
        closeBtn={false}
      />
      <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
        <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
          <Typography variant="overline">Subscription Cancellation</Typography>
          <Typography variant="h1" className={classes.headerTwoLine}>{state.currentPlanLabel} {price}</Typography>
          {updateSuccess === 'true' ?
            <div className={classes.notification}>
              <br />
              <Alert severity="success">Cancellation succesful!</Alert>

            </div>
            : ''}
          {updateSuccess === 'false' ?
            <div className={classes.notification}>
              <br />
              <Alert severity="error">Something went wrong. Please try again.</Alert>

            </div>
            : ''}
          <Typography variant="h5" component="h2" style={{ margin: "40px 0" }}>Are you sure you want to cancel your current subscription?</Typography>
          <Grid container spacing={3} justify="center">
            <Grid item xs={6} sm={6} md={6}>
              <Button
                component={Link}
                to="/admin/my-account/my-subscription"
                variant="outlined"
                className={classes.buttonSpacing}
                color="primary"
                size="large"
                disabled={updating}
                fullWidth
              >
                No
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Button
                variant="contained"
                className={classes.buttonSpacing}
                color="primary"
                size="large"
                disabled={updating}
                onClick={handleCancelSubscription}
                fullWidth
              >{updating ? <CircularProgress color='inherit' size={15} /> : 'Yes'}

              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default AdminMySubscriptionConfirmed
