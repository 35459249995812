import React from "react"
import HttpsRedirect from 'react-https-redirect';
// @material-ui/core
import { ThemeProvider, createGenerateClassName, StylesProvider } from "@material-ui/styles"
import { createTheme } from '@material-ui/core/styles';

// Core components
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

// Other components
import themeStyle from "assets/jss/material-dashboard-react/themeStyle"
import Home from "layouts/Home"
import HomeRemovals from "layouts/HomeRemovals"
import CmsPage from "./views/CmsPage";
import routes from "./routes";
import "assets/css/material-dashboard-react.css?v=1.8.0"
import { Dashboard } from "WrapperApp"

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout><Component {...props} /></Layout>
  )} />
)

const generateClassName = createGenerateClassName({
  productionPrefix: 'webStyles',
  //disableGlobal: true
});

const App = (props) => {
  // const hist = createBrowserHistory()
  const theme = createTheme(themeStyle);

  return (
    <Dashboard>
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          <HttpsRedirect disabled={process.env.REACT_APP_DISABLE_HTTPS_REDIRECT === "true"}>
          <div className='header-wrap weglot-wrap' />
            <Router>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/removals" component={HomeRemovals} />
                {routes.map((prop, key) => {
                  return (
                    <AppRoute
                      exact
                      path={prop.path}
                      component={prop.component}
                      layout={prop.layout}
                      key={key}
                    />
                  );
                })}
                <Route component={CmsPage} status={404} />
              </Switch>
            </Router>
          </HttpsRedirect>
        </ThemeProvider>
      </StylesProvider>
    </Dashboard>
  )
}

export default App
