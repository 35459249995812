import React, {useContext, useEffect, useMemo} from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import {Grid, TextField, Button, Typography, Divider, Fab} from "@material-ui/core"

// Core components
import { useGlobalState } from "hooks/useGlobalState"
// import classNames from 'classnames'

// Other components
import AdminIntegrationItemQty from "components/AdminIntegrationItem/AdminIntegrationItemQty";
import { saveIntegration } from '../../../helpers/SaveIntegration';

// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import {mapPlansLimitations} from "../../../variables/InitState";
import ColourPickerField from "../../../components/ColourPickerField/ColourPickerField";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";

const useStyles = makeStyles(styles)

const PresetsStepAdvanced = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const restrictions = useMemo(() => mapPlansLimitations?.[state.currentPlan] || [], []);
  const { theRef } = props
  const cleanState = {
    ID: 0,
    preName: "",
    itemsAssigned: [],
    qtyAssigned: [],
    backgroundColor: "",
    colorText: ""
  }

  const saveNewPre = () => async () => {
    // Save new
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: false,
        integrationPresets: [...state.integrationData.integrationPresets, state.integrationData.integrationNewPreset],
        integrationNewPreset: { ...cleanState }
      }
    });

    await saveIntegration({ ...state, integrationData: { ...state.integrationData, integrationSecPanel: false, integrationPresets: [...state.integrationData.integrationPresets, state.integrationData.integrationNewPreset], integrationNewPreset: { ...cleanState } } }, false, dispatchState);
  }

  const savePre = () => async () => {
    const i = state.integrationData.integrationEditing
    let arrayToChange = state.integrationData.integrationPresets
    //Save new
    const update = (await import("immutability-helper")).default;
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: false,
        integrationPresets: update(arrayToChange, { [i]: { $set: state.integrationData.integrationNewPreset } }),
        integrationNewPreset: { ...cleanState }
      }
    });

    await saveIntegration({ ...state, integrationData: { ...state.integrationData, integrationSecPanel: false, integrationPresets: update(arrayToChange, { [i]: { $set: state.integrationData.integrationNewPreset } }), integrationNewPreset: { ...cleanState } } }, false, dispatchState);
  }

  /* eslint-disable */
  useEffect(() => {
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
  }, []);
  /* eslint-enable */

  return (
    <Grid spacing={3} container>
      <Fab
        color="primary"
        aria-label="Save"
        size="medium"
        className={classes.closeFab}
        style={{position: 'fixed', right: 100}}
        onClick={state.integrationData.integrationIsNewHelper ? saveNewPre() : savePre()}
        disabled={
          state.integrationData.integrationNewPreset?.preName === "" ||
          state.integrationData.integrationNewPreset?.itemsAssigned?.length === 0 ||
          state.integrationData.integrationNewPreset?.qtyAssigned?.length === 0
        }
      >
        <SaveRoundedIcon />
      </Fab>
      <Grid item xs={12}>
        <Typography variant="h3" component="h2">{state.integrationData.integrationIsNewHelper ? "New preset" : "Edit preset"}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.customDividerOne} style={{ marginBottom: 20 }} />
        <TextField
          id="p-integration-preset-name"
          label="Preset name"
          variant="outlined"
          type="text"
          value={state.integrationData.integrationNewPreset.preName}
          onChange={(e) => { dispatchState({ type: 'integration-data', payload: { integrationNewPreset: { ...state.integrationData.integrationNewPreset, preName: e.target.value } } }) }}
          fullWidth
          inputProps={{ maxLength: 15 }}
          helperText={state.integrationData.integrationNewPreset.preName ? `${state.integrationData.integrationNewPreset.preName.length} / 15 character limit` : 'Preset name is required'}
          error={!state.integrationData.integrationNewPreset.preName}
          disabled={!restrictions.includes('preName')}
        />
      </Grid>
      <Grid item xs={12} className={classes.colorsWrap}>
        <Divider className={classes.customDividerOne} />
        <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Label colours</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <ColourPickerField
              value={state.integrationData.integrationNewPreset.colorText}
              onChange={color => {
                dispatchState({
                  type: 'integration-data',
                  payload: {
                    integrationNewPreset: { ...state.integrationData.integrationNewPreset, colorText: color  } } }) }}
              id="p-text-color"
              label="Text colour"
            />
          </Grid>
          <Grid item xs={6}>
            <ColourPickerField
              value={state.integrationData.integrationNewPreset.backgroundColor}
              onChange={color => {
                dispatchState({
                  type: 'integration-data',
                  payload: {
                    integrationNewPreset: { ...state.integrationData.integrationNewPreset, backgroundColor: color  } } }) }}
              id="p-background-color"
              label="Background colour"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Assign items to your preset</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {state.integrationData.itemLibrary.map((item) => (
            <Grid item xs={4} xl={3} key={item.ID}>
              <AdminIntegrationItemQty prop={item} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.buttonSpacing}
          color="primary"
          size="large"
          fullWidth
          disabled={state.integrationData.integrationNewPreset?.preName === "" || state.integrationData.integrationNewPreset?.itemsAssigned?.length === 0 || state.integrationData.integrationNewPreset?.qtyAssigned?.length === 0}
          onClick={state.integrationData.integrationIsNewHelper ? saveNewPre() : savePre()}
        >Save{state.integrationData.integrationIsNewHelper ? " new " : " "}preset</Button>
      </Grid>
    </Grid>
  )
}

export default PresetsStepAdvanced
