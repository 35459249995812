import axios from "axios";

export async function deleteIntegrationLogo(state) {
  const key = state.integrationData.integrationLogo
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + state.signedInUser.token,
  };
  return await axios.delete(`${process.env.REACT_APP_API_URL}logo/${key}`, {headers})
}

export async function deleteCustomUnitImage(state) {
  const key = state.integrationData.integrationLogo
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + state.signedInUser.token,
  };
  return await axios.delete(`${process.env.REACT_APP_API_URL}initial-image/${key}`, {headers})
}
