import React, { Fragment } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

// Core components

// Other components
import CustomPricingPanel from "components/CustomPricing/CustomPricingPanels";
import AdminTransactionalHeader from "../../components/AdminTransactionalHeader/AdminTransactionalHeader";

// Styles
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle";

const useStyles = makeStyles(styles);

const CalculatorComplete = (props) => {
  const classes = useStyles();

  return (
    <Fragment>
      <AdminTransactionalHeader
        homeBtn={() => props.history.push("/")}
        backBtn={false}
        logo={true}
        closeBtn={false}
      />

      <Grid
        container
        spacing={0}
        justify="center"
        className={classes.cFormContaner}
      >
        <Grid item xs={12} sm={7}>
          <Typography variant="h1" className={classes.headerOneLine}>
            Hello and thanks for playing
          </Typography>

          <Typography
            variant="subtitle1"
            component="p"
            className={classes.subTitleTwo}
            align="center"
          >
            <strong>
              Get started below with your own custom version of Calcumate on
              your website.
            </strong>
            <br />
            No commitments, cancel at any time and you won't be charged until
            after your free month.
          </Typography>
        </Grid>

        <Grid item xs={12} style={{
          maxWidth: 1200,
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '100%'}}>
          <CustomPricingPanel />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CalculatorComplete;
