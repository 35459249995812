import React, {useContext, useEffect, useMemo} from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, TextField, Button, Typography, Divider, InputAdornment } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

// Core components
import { useGlobalState } from "hooks/useGlobalState"

// Other components
import { saveIntegration } from '../../../helpers/SaveIntegration';
import { regexp, selfType} from '../../../helpers/validation';
import DisabledFieldPlaceholder from "../../FreeVersion/DisabledFieldPlaceholder";
// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import {mapPlansLimitations} from "../../../variables/InitState";
import ImportUnits from "./ImportUnits";

const useStyles = makeStyles(styles)

const UnitsStepAdvanced = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State);
  const [open, setOpen] = React.useState(false);
  const { theRef, setUpdating, updating } = props;
  const restrictions = useMemo(() => mapPlansLimitations?.[state.currentPlan] || [], []);

  const cleanState = {
    ID: 0,
    unitName: "",
    unitWidth: "",
    unitHeight: "",
    unitDepth: ""
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const saveNewUnit = async (panel) => {
    // Save new
    setUpdating(true);
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: !!panel,
        integrationUnits: [...state.integrationData.integrationUnits, state.integrationData.integrationNewUnit],
      }
    })

    await saveIntegration({ ...state, integrationData: { ...state.integrationData, integrationSecPanel: false, integrationUnits: [...state.integrationData.integrationUnits, state.integrationData.integrationNewUnit], integrationNewUnit: { ...cleanState } } }, false, dispatchState);
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationNewUnit: { ...cleanState }
      }
    });
    setUpdating(false);
  }

  const saveUnit = async (panel = false) => {
    const i = state.integrationData.integrationEditing
    let arrayToChange = state.integrationData.integrationUnits
    const update = (await import("immutability-helper")).default;
    //Save new
    setUpdating(true);
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: !!panel,
        integrationUnits: update(arrayToChange, { [i]: { $set: state.integrationData.integrationNewUnit } }),
      }
    });

    await saveIntegration({ ...state, integrationData: { ...state.integrationData, integrationSecPanel: false, integrationUnits: update(arrayToChange, { [i]: { $set: state.integrationData.integrationNewUnit } }), integrationNewUnit: { ...cleanState } } }, false, dispatchState);
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationNewUnit: { ...cleanState }
      }
    });
    setUpdating(false);
  }

  const handleSaveAndCreate = async ()=>{
    state.integrationData.integrationIsNewHelper ?
      await saveNewUnit(true) :
      await saveUnit(true);
    setOpen(true);
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: true,
        integrationIsNewHelper: true,
        integrationNewUnit: { ...cleanState, ID: state.integrationData.integrationUnits.length }
      }
    })
  }

  /* eslint-disable */
  useEffect(() => {
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
  }, []);
  /* eslint-enable */
 

  if (state.integrationData.integrationThirdPanel) {
    return (
      <ImportUnits
        setUpdating={setUpdating}
        updating={updating}
      />
    )
  }

  return (
    <Grid spacing={3} container>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Unit saved. Start adding a new unit
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant="h3" component="h2">{state.integrationData.integrationIsNewHelper ? "New unit" : "Edit unit"}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.customDividerOne} style={{ marginBottom: 20 }} />
        <TextField
          id="p-integration-unit-name"
          label="Unit name"
          variant="outlined"
          type="text"
          value={state.integrationData.integrationNewUnit.unitName}
          onChange={(e) => { dispatchState({ type: 'integration-data', payload: { integrationNewUnit: { ...state.integrationData.integrationNewUnit, unitName: e.target.value } } }) }}
          fullWidth
          inputProps={{ maxLength: 30 }}
          helperText={state.integrationData.integrationNewUnit.unitName ? `${state.integrationData.integrationNewUnit.unitName.length} / 30 character limit` : 'Unit name is required'}
          error={!state.integrationData.integrationNewUnit.unitName}
          disabled={!restrictions.includes('unitName')}
        />
      </Grid>
      <Grid item xs={12} className={{[classes.disabledFieldInput]: !restrictions.includes('additional')}}>
        <TextField
          id="p-integration-unit-additional"
          label="Additional (Optional)"
          variant="outlined"
          type="text"
          value={!restrictions.includes('additional') ? ' ' : state.integrationData.integrationNewUnit.additional || ''}
          onChange={(e) => { dispatchState({ type: 'integration-data', payload: { integrationNewUnit: { ...state.integrationData.integrationNewUnit, additional: e.target.value } } }) }}
          fullWidth
          inputProps={{ maxLength: 130 }}
          disabled={!restrictions.includes('additional')}
          helperText={state.integrationData.integrationNewUnit.additional ? `${state.integrationData.integrationNewUnit.additional.length} / 130 character limit` : null}
        />
        {
          !restrictions.includes('additional') && <DisabledFieldPlaceholder feature={'additional'} updating={props.updating} />
        }
      </Grid>
      <Grid item xs={12} className={{[classes.disabledFieldInput]: !restrictions.includes('additional')}}>
        <TextField
          id="p-integration-unit-m-sub-header"
          label="Multiple sub header (Optional)"
          variant="outlined"
          type="text"
          value={!restrictions.includes('additional') ? ' ' : state.integrationData.integrationNewUnit.mSubHeader || ''}
          onChange={(e) => { dispatchState({ type: 'integration-data', payload: { integrationNewUnit: { ...state.integrationData.integrationNewUnit, mSubHeader: e.target.value } } }) }}
          fullWidth
          inputProps={{ maxLength: 130 }}
          disabled={!restrictions.includes('additional')}
          helperText={state.integrationData.integrationNewUnit.mSubHeader ? `${state.integrationData.integrationNewUnit.mSubHeader.length} / 130 character limit` : null}
        />
        {
          !restrictions.includes('additional') && <DisabledFieldPlaceholder feature={'additional'} updating={props.updating} />
        }
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="p-integration-unit-width"
          label="Unit width"
          variant="outlined"
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">{state.integrationData.integrationMeasurement} </InputAdornment>,
          }}
          value={state.integrationData.integrationNewUnit.unitWidth}
          onChange={(e) => { dispatchState({ type: 'integration-data', payload: { integrationNewUnit: { ...state.integrationData.integrationNewUnit, unitWidth: e.target.value } } }) }}
          fullWidth
          helperText={!state.integrationData.integrationNewUnit.unitWidth && 'Unit width is required'}
          error={!state.integrationData.integrationNewUnit.unitWidth}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="p-integration-unit-depth"
          label="Unit depth"
          variant="outlined"
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">{state.integrationData.integrationMeasurement}</InputAdornment>,
          }}
          value={state.integrationData.integrationNewUnit.unitDepth}
          onChange={(e) => { dispatchState({ type: 'integration-data', payload: { integrationNewUnit: { ...state.integrationData.integrationNewUnit, unitDepth: e.target.value } } }) }}
          fullWidth
          helperText={!state.integrationData.integrationNewUnit.unitDepth && 'Unit depth is required'}
          error={!state.integrationData.integrationNewUnit.unitDepth}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="p-integration-unit-height"
          label="Unit height"
          variant="outlined"
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">{state.integrationData.integrationMeasurement}</InputAdornment>,
          }}
          value={state.integrationData.integrationNewUnit.unitHeight}
          onChange={(e) => { dispatchState({ type: 'integration-data', payload: { integrationNewUnit: { ...state.integrationData.integrationNewUnit, unitHeight: e.target.value } } }) }}
          fullWidth
          helperText={!state.integrationData.integrationNewUnit.unitHeight && 'Unit height is required'}
          error={!state.integrationData.integrationNewUnit.unitHeight}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="p-integration-unit-weight-limit"
          label="Maximum weight limit (Optional)"
          variant="outlined"
          type="number"
          value={state.integrationData.integrationNewUnit.weightLimit || ''}
          onChange={(e) => { dispatchState({ type: 'integration-data', payload: { integrationNewUnit: { ...state.integrationData.integrationNewUnit, weightLimit: e.target.value } } }) }}
          fullWidth
          disabled={!restrictions.includes('weightLimit')}
          InputProps={{
            endAdornment: <span>{state.integrationData.integrationMeasurement === 'm' ? 'Kg(s)' : 'Lb(s)'}</span>,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="p-integration-unit-url-reserve"
          label="URL to reserve (Optional)"
          variant="outlined"
          type="text"
          value={state.integrationData.integrationNewUnit.unitReserve || ''}
          onChange={(e) => { dispatchState({ type: 'integration-data', payload: { integrationNewUnit: { ...state.integrationData.integrationNewUnit, unitReserve: e.target.value } } }) }}
          fullWidth
          disabled={!restrictions.includes('unitReserve')}
          error={state.integrationData.integrationNewUnit.unitReserve && !regexp.test(state.integrationData.integrationNewUnit.unitReserve)}
          helperText={(state.integrationData.integrationNewUnit.unitReserve && !regexp.test(state.integrationData.integrationNewUnit.unitReserve)) && 'Incorrect url'}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.buttonSpacing}
          color="primary"
          size="large"
          fullWidth
          disabled={state.integrationData.integrationNewUnit.unitName === "" || state.integrationData.integrationNewUnit.unitWidth === "" || state.integrationData.integrationNewUnit.unitHeight === "" || state.integrationData.integrationNewUnit.unitDepth === "" || updating}
          onClick={state.integrationData.integrationIsNewHelper ? () => saveNewUnit() : () => saveUnit()}
        >{updating && !selfType.includes(state.integrationData.integrationStorageType) ? <CircularProgress color='inherit' size={15} /> : `Save${state.integrationData.integrationIsNewHelper ? " new " : " "}unit`}</Button>
        {
          selfType.includes(state.integrationData.integrationStorageType) && (
            <Button
              variant="contained"
              className={classes.buttonSpacing}
              color="secondary"
              size="large"
              fullWidth
              disabled={state.integrationData.integrationNewUnit.unitName === "" || state.integrationData.integrationNewUnit.unitWidth === "" || state.integrationData.integrationNewUnit.unitHeight === "" || state.integrationData.integrationNewUnit.unitDepth === "" || updating}
              onClick={handleSaveAndCreate}
            >{updating ? <CircularProgress color='inherit' size={15} /> : 'Save and create new unit'}</Button>
          )
        }
      </Grid>
    </Grid>
  )
}

export default UnitsStepAdvanced
