import React, {useContext, useCallback} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useGlobalState} from "../../hooks/useGlobalState";
import styles from "assets/jss/material-dashboard-react/components/CustomPricingStyle";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Usa from '../../assets/img/usa.svg';
import Eur from '../../assets/img/EUR-Flag.png';
import Gbp from '../../assets/img/GBP-Flag.png';
import Aud from '../../assets/img/AUD-Flag.png';

const useStyles = makeStyles(styles);


const Currencies = () => {
  const classes = useStyles();
  let state = useContext(useGlobalState.State);
  const dispatchState = useContext(useGlobalState.Dispatch);
  const {currency} = state;

  // const showMessage = useMemo(() => state.cmPlanPayRec, [state.cmPlanPayRec]);

  const setCurrency = useCallback(currency => {
    
    let selectedPlan = [
      ...state.calcumatePlans, 
      ...state.calcumateCustomPlans, 
      ...state.calcumatePlansOld,
    ].filter(plan => {
      return plan.thePlan === state.currentPlan;
    });

    const sids = selectedPlan?.[0]?.[`sid${currency}`];
    const thePrices = selectedPlan?.[0]?.[state.currency];
    const price = state.cmPlanPayRec ?
      thePrices[0] :
      thePrices[1];
    let payload = {
      currency,
      currentPlanPrice: price
    }
    if (state?.signedInUser?.data) {
      payload = {
        ...payload,
        signedInUser: {
          ...state.signedInUser,
          data: {
            ...state.signedInUser.data,
            user: {
              ...state.signedInUser.data.user,
              lastChosenSubscription: sids[(state.currentPlanPriceRec.indexOf('yr') > -1 ? 1 : 0)]
            },
          }
        }
      }
    }
    dispatchState({
      type: 'root-state',
      payload
    });
  }, [state.currentPlan]);

  const mapFlags = {
    'AUD': Aud,
    'GBP': Gbp,
    'EUR': Eur,
    'USD': Usa
  }

  return (
    <div className={classes.rootCurrency}>
      <Select
        labelId="currency-simple-select"
        id="currency-simple-select"
        value={currency}
        onChange={event => setCurrency(event.target.value)}
        data-cy={'select--currency'}
        MenuProps={{
          'data-cy': 'currency-simple-select-menu'
        }}
      >
        {
          state.availableCurrencies.map((item, index) => (
            <MenuItem value={item.currency}>
              <img
                src={mapFlags[item.currency]}
                width={19.5}
                height={19.5}
                style={{marginRight: 6}}
              />
              <span data-cy={'currency'}>{`${item.currency}`}</span>
            </MenuItem>
          ))
        }
      </Select>
      {/*<p style={{*/}
      {/*  visibility: showMessage ? 'visible' : 'hidden',*/}
      {/*  height: showMessage ? 'auto' : 0,*/}
      {/*  margin:  showMessage ? '10px auto 0px auto' : 0*/}
      {/*}} className={classes.noteCurrency}>Please Note: Your subscription will be taken in USD</p>*/}
      <p style={{
        visibility: 'visible',
        height: 'auto',
        //margin:  showMessage ? '10px auto 0px auto' : 0
      }} className={classes.discountNote}>Get 10% off yearly plans</p>
    </div>
  )
}

export default Currencies;
