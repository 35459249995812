/*eslint-disable*/
import React, { useContext, Fragment } from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"

// core components
import { useGlobalState } from "hooks/useGlobalState"

// Other components
import AdminTransactionalHeader from "components/AdminTransactionalHeader/AdminTransactionalHeader"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles)

const AdminMySubscriptionCancelled = props => {
  const classes = useStyles();
  return (
    <Fragment>
      <AdminTransactionalHeader
        homeBtn={false}
        backBtn={false}
        logo={false}
        closeBtn={() => props.history.push("/admin/my-account")}
      />
      <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
        <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
          <Typography variant="overline">Subscription Cancellation</Typography>
          <Typography variant="h1" className={classes.headerTwoLine}>Subscription cancelled</Typography>
          <Typography variant="h5" component="h2" style={{ margin: "40px 0" }}>You have successfully cancelled your subscription</Typography>
          <Typography variant="subtitle2">Your customised integration will no longer function after today. You can re-subscribe at any time.</Typography>
        </Grid>
      </Grid>
    </Fragment>

  );
}

export default AdminMySubscriptionCancelled
