import React, { useEffect } from "react"

// @material-ui/core components
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { withRouter } from 'react-router-dom'

// Styles
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles);

const Transactional = props => {
  const classes = useStyles()
  // let currentPath = props.children.props.location.pathname
  const { children } =  props

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);

  useEffect(() => {
    document.querySelector('#root').classList.add('admin-transaction-layout');
    return () => document.querySelector('#root').classList.remove('admin-transaction-layout');
  }, []);

  return (
    <Grid container spacing={0} className={
      props.location.pathname === '/pricing' ?
      classes.pWrapperPricing :
      classes.pWrapper
    } alignItems="stretch">
      <Grid item xs={12} sm={12} className={classes.pContainer}>
        {children}
      </Grid>
    </Grid>
  );
}

export default withRouter(Transactional)
