import { Grid } from "@material-ui/core";
import { AllowedService } from './CustomPricingPanel/components/SelectService';
import React from "react";
import ServiceCard from "./CustomPricingPanel/components/ServiceCard";
import SelfStorage from '../../assets/img/services/self-storage.svg';
import Container from '../../assets/img/services/container.svg';
import Movers from '../../assets/img/services/movers.svg';
import Mobile from '../../assets/img/services/mobile.svg';
import Kiosks from '../../assets/img/services/kiosks.svg';
import CallCenter from '../../assets/img/services/call-centers.svg';
import LogisticsShipping from '../../assets/img/services/logistics-&-shipping.svg';
import Other from '../../assets/img/services/other.svg';
import { Services } from "helpers/types";

export const allowedServices: AllowedService[] = [
  {
    value: 'self-storage',
    label: 'Self-storage',
    img: SelfStorage
  },
  {
    value:'container-storage', 
    label: 'Container-storage',
    img: Container,
  },
  {
    value:'movers', 
    label: 'Movers',
    img: Movers,
  },
  {
    value:'portable-storage', 
    label: 'Portable-storage',
    img: Mobile,
  },
  {
    value:'kiosks', 
    label: 'Kiosks',
    img: Kiosks,
  },
  {
    value:'call-centers', 
    label: 'Call Centers',
    img: CallCenter
  },
  {
    value: 'logistics-shipping',
    label: 'Logistics & Shipping',
    img: LogisticsShipping,
  },
  {
    value: 'other',
    label: 'Other',
    img: Other,
  }
]


export default ({
  selectedService,
  setService,
}: SelectServiceProps) => {
  return (
    <Grid style={{
      maxWidth: 868, 
      margin: 'auto', 
      display: 'flex', 
      gap: 16,
      flexWrap: 'wrap'
    }}>
      {
        allowedServices.map((service: AllowedService) => {
          return (
            <ServiceCard 
              service={service}    
              setService={setService}     
              selectedService={selectedService}
            />
          );
        })
      }
    </Grid>
  );
}

interface SelectServiceProps {
  selectedService: Services;
  setService: (service: Services) => void;
}