import React, {useContext, useState} from "react";
//Material Ui
import {useGlobalState} from "../../../hooks/useGlobalState";
import {Grid, Button, Typography, Divider, Fab} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CloseRounded from "@material-ui/icons/CloseRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
//Styles
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import {makeStyles} from "@material-ui/core/styles";
//Other component
import {useDropzone} from "react-dropzone";
import BulkImportUnits from '../../../assets/downloads/Bulk-Import-Units.xlsx';
import {saveIntegration} from "../../../helpers/SaveIntegration";
import {regexp} from "../../../helpers/validation";

const useStyles = makeStyles(styles)

export default props => {
  const fileReader = new FileReader();
  const state = useContext(useGlobalState.State)
  const dispatchState = useContext(useGlobalState.Dispatch)
  const [rows, setRows] = useState(null);
  const {updating, setUpdating} = props;
  const classes = useStyles();
  const {getRootProps, getInputProps} = useDropzone({
    onDrop: acceptedFiles => {
      if (acceptedFiles[0]) {
        const file = acceptedFiles[0];
        const handler = mapHandlers?.[file.type];
        if (handler) {
          handler(file)
        }
      }
    },
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv',
    maxFiles: 1
  })

  const xlsxHandler = (file) => {
    import('../../../helpers/readExelFile').then(({default: readXlsx}) => {
      readXlsx(file).then((rows) => {
        setRows(rows)
      })
    })
  }

  const handleAndSaveUnits = async () => {
    let onlyUnits = [...rows]
    onlyUnits.splice(0, 1);
    const newUnits = [];
    onlyUnits.forEach((row, index) => {
      let depth = `${row[2]}`.replace(',', '.');
      let width = `${row[3]}`.replace(',', '.');
      let height = `${row[4]}`.replace(',', '.');

      if (isNaN(depth) || isNaN(width) || isNaN(height) || !row[0] || (row[5] && !regexp.test(row[5]))) {
        return
      }
      let unit = {
        ID: index,
        unitDepth: depth,
        unitHeight: height,
        unitName: row[0],
        unitWidth: width,
        unitReserve: row[5],
        additional: row[1]
      }
      newUnits.push(unit)
    });
    if (newUnits.length) {
      setUpdating(true);
      await saveIntegration({
        ...state,
        integrationData: {
          ...state.integrationData,
          integrationUnits: newUnits,
        }}, false, dispatchState);

      dispatchState({
        type: 'root-state',
        payload: {
          message: {
            type: 'success',
            text: 'Unit saved!'
          }
        }
      });
      dispatchState({
        type: 'integration-data',
        payload: {
          integrationUnits: newUnits,
          closeAllPanels: false,
          integrationThirdPanel: false,
          integrationSecPanel: false
        }
      })
      setUpdating(false);
      return
    }
    dispatchState({
      type: 'root-state',
      payload: {
        message: {
          type: 'info',
          text: 'Items not found!'
        }
      }
    });
  }

  const csvHandler = (file) => {
    fileReader.onload = function (event) {
      let csvRows = event.target.result;
      csvRows = csvRows.split('\n');
      if (csvRows.length) {
        const newRows = csvRows.map(row => row.split(','))
        setRows(newRows)
      }
    };
    fileReader.readAsText(file);
  }

  const mapHandlers = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': xlsxHandler,
    'text/csv': csvHandler
  }

  const handleClose = () => {

  }

  const LoadedUnits = () => {
    return (
      <Grid style={{
        maxHeight: 350,
        overflow: 'auto'
      }}>
        <Fab
          color="primary"
          aria-label="Edit"
          size="small"
          style={{
            position:'absolute',
            right: 11,
            top: 11
          }}
          onClick={() => {
            setRows(null)
          }}
        >
          <CloseRounded />
        </Fab>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {
              rows.map((row, index) => {
                if (index === 0) {
                  return (
                    <TableHead>
                      <TableRow>
                        {
                          row.map(cell => <TableCell>{cell}</TableCell>)
                        }
                      </TableRow>
                    </TableHead>
                  )
                }
                return (
                  <TableBody>
                    <TableRow
                      key={index}
                      style={
                        isNaN(`${row[2]}`.replace(',', '.')) ||
                        isNaN(`${row[3]}`.replace(',', '.')) ||
                        isNaN(`${row[4]}`.replace(',', '.')) || !row[0] || (row[5] && !regexp.test(row[5])) ? {border: '1px solid red'} : {}
                      }
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {
                        row.map(cell => <TableCell>{cell}</TableCell>)
                      }
                    </TableRow>
                  </TableBody>
                )
              })
            }
          </Table>
        </TableContainer>
      </Grid>
    )
  }

  return (
    <Grid spacing={3} container>
      <Grid item xs={12}>
        <Typography variant="h1" component="h2" style={{fontWeight: 'normal'}}>Import unit sizes</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.customDividerOne} style={{ marginBottom: 20 }} />
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'}>Start by downloading this <a href={BulkImportUnits} target={'_blank'}>template file</a> and adding all the information for your unit or truck sizes. Any invalid values won’t be included in your calculator.</Typography>
      </Grid>
      <Grid item xs={12}>
        <div
           style={{
             display: 'flex',
             borderColor: 'rgba(0, 0, 0, 0.12)',
             borderWidth: 1,
             borderStyle: 'solid',
             padding: 25,
             borderRadius: 12,
             position: 'relative',
           }}
           className={{[classes.uploadUnits]: rows && rows.length}}
        >
          {
            rows && rows.length ? (
                <LoadedUnits />
              ) : (
              <div {...getRootProps()} style={{cursor: 'pointer'}}>
                <input {...getInputProps()} />
                <div><p>Drag and drop csv/xlsx file here, or <b><u>select file</u></b></p></div>
              </div>
            )
          }
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography component={'span'}>Note: This upload will replace all existing unit or truck sizes currently saved.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.buttonSpacing}
          color="primary"
          size="large"
          fullWidth
          disabled={!rows}
          onClick={handleAndSaveUnits}
        >{updating ? <CircularProgress color='inherit' size={15} /> : 'SAVE UNITS'}</Button>
      </Grid>
    </Grid>
  );
}
