import React from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Fab} from "@material-ui/core"
import NavigateBeforeRounded from '@material-ui/icons/NavigateBeforeRounded'
import CloseRounded from '@material-ui/icons/CloseRounded'
import HomeRounded from '@material-ui/icons/HomeRounded'
import classNames from 'classnames';
// Core components
import { Link } from "react-router-dom"

// Other components

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
import elipseShine from "assets/img/cm-isotype-dark.svg"

const useStyles = makeStyles(styles)

const AdminTransactionalHeader = props => {
  const classes = useStyles()
  const { homeBtn, backBtn, closeBtn, logo } = props

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classNames(classes.adminTHeader, props.customClasses)}
    >
      <Grid item sm={1}>
        {homeBtn &&
          <Fab
            color="primary"
            aria-label="Edit"
            size="medium"
            onClick={homeBtn}
            data-cy={'home-button'}
          >
            <HomeRounded />
          </Fab>
        }
        {backBtn &&
          <Fab
            color="primary"
            aria-label="Edit"
            size="medium"
            onClick={backBtn}
          >
            <NavigateBeforeRounded />
          </Fab>
        }
      </Grid>
      <Grid item style={{textAlign:"center", minWidth:50}}>
      {logo &&
        <Link to="/"><img src={elipseShine} className={classes.smallMainLogo} alt="Calcumate"></img></Link>
      }
      </Grid>
      <Grid item sm={1} style={{textAlign:"right", minWidth:50}}>
        {closeBtn &&
          <Fab
            color="primary"
            aria-label="Edit"
            size="medium"
            onClick={closeBtn}
          >
            <CloseRounded />
          </Fab>
        }
      </Grid>
    </Grid>
  )
}

export default AdminTransactionalHeader
