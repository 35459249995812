import React, { Fragment } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"

// Core components
import { Switch, Route, Redirect } from "react-router-dom";

// Other components
import RecoveryForm from "./Steps/RecoveryForm"
import AdminTransactionalHeader from "../../components/AdminTransactionalHeader/AdminTransactionalHeader"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
const useStyles = makeStyles(styles)

const PassRecovery = props => {
  const classes = useStyles()

  return (
    <Fragment>
      <AdminTransactionalHeader
        homeBtn={props.history.length <= 2 ? () => { props.history.push("/") } : false}
        backBtn={props.history.length > 2 ? () => { props.history.goBack() } : false}
        logo={true}
        closeBtn={false}
      />
      <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
        <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
          <Typography variant="h1" className={classes.headerOneLine}>Password recovery</Typography>
          <Switch>
            <Route exact path="/password-request" component={RecoveryForm} />
            {/* <Route path="/password-request/requested" component={RecoveryRequested} />
            <Route path="/password-request/reset" component={RecoveryReset} /> */}
            <Redirect from="/password-request" to="/password-request" />
          </Switch>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default PassRecovery
