import React, { useState, Fragment } from "react"
import { useHistory } from 'react-router-dom';

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography, TextField, Button } from "@material-ui/core"
import Alert from '@material-ui/lab/Alert';

// Core components
import { Link } from "react-router-dom"
import PasswordInput from "components/PasswordInput/PasswordInput";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

// Other components

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
const useStyles = makeStyles(styles)

const RecoveryForm = props => {
  let history = useHistory();
  const classes = useStyles();
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [recoveryValid, setRecoveryValid] = useState(true);
  const [emailSent, setEmailSent] = useState(false);
  const [invalidRequest, setInvalidRequest] = useState(false);
  const [submittingUserData, setSubmittingUserData] = useState(false);

  let passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
  const handlePasswordChange = password => {
    setPasswordInvalid(!passwordRegex.test(password));
    setPassword(password);
  };

  const handleCodeChange = e => {
    setCode(e.target.value);
  };

  let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const handleEmailChange = e => {
    if (emailRegex.test(e.target.value)) {
      setEmailValid(true);
      setEmail(e.target.value);
    } else {
      setEmailValid(false);
    }
  };

  const handlePasswordRecoveryClick = () => {
    setSubmittingUserData(true);
    axios.post(
      process.env.REACT_APP_API_URL + 'passwordreset',
      {
        email: email
      },
      { headers: { 'Content-Type': 'application/json' } }
    ).then(function (response) {
      if (response.status && response.status === 200) {
        setEmailSent(true);
      } else {
        setInvalidRequest(true);
      }
      setSubmittingUserData(false);
    })
      .catch(function (error) {
        setEmailSent(false);
        setSubmittingUserData(false);
        setInvalidRequest(true);
      });

  }

  const handlePasswordChangeClick = () => {
    setSubmittingUserData(true);
    axios.post(
      process.env.REACT_APP_API_URL + 'passwordconfirm',
      {
        email: email,
        verificationCode: code,
        newPassword: password
      },
      { headers: { 'Content-Type': 'application/json' } }
    ).then(function (response) {
      if (response.status && response.status === 200) {
        setRecoveryValid(true);
        // redirect to the login page
        history.replace('/login/?recover=1');
        window.location.reload();
      } else {
        setRecoveryValid(false);
      }
      setSubmittingUserData(false);
    })
      .catch(function (error) {
        setSubmittingUserData(false);
        setRecoveryValid(false);
      });

  }

  return (

    <Fragment>
      {!emailSent ?
        <form noValidate autoComplete="off" className={classes.standardForm}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1" align="center">Please enter your email address. </Typography>
            </Grid>
            <Grid item xs={12}>
              {invalidRequest ?
                <div className={classes.notification}>

                  <Alert severity="error">Email address doesn't exist or internal error. Please try again.</Alert>
                  <br />
                </div>
                : ''}
              <TextField id="p-login-email" label="Email" variant="outlined" type="email" onChange={handleEmailChange} error={email !== '' && !emailValid} fullWidth />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Button component={Link} to="/login" variant="outlined" className={classes.buttonSpacing} color="primary" size="large" fullWidth>Back</Button>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Button
                component={Link}
                variant="contained"
                disabled={submittingUserData || email === '' || !emailValid}
                className={classes.buttonSpacing}
                color="primary"
                size="large"
                onClick={handlePasswordRecoveryClick}
                fullWidth> {submittingUserData ? <CircularProgress color='inherit' size={15} /> : 'Send'}</Button>
            </Grid>
          </Grid>
        </form>
        :
        <form noValidate autoComplete="off" className={classes.standardForm}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1" align="center">Please enter the verification code we emailed and your new password. </Typography>
            </Grid>
            <Grid item xs={12}>
              {!recoveryValid ?
                <div className={classes.notification}>

                  <Alert severity="error">Password recovery was not successful. Please try again. </Alert>
                  <br />
                </div>
                : ''}
              <TextField id="p-pwrecovery-code" label="Code" variant="outlined" value={code} type="text" onChange={handleCodeChange} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput uniqueLabel="New Password" setPassword={password => handlePasswordChange(password)} showError={passwordInvalid} validationRequired={true} />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Button
                component={Link}
                variant="contained"
                className={classes.buttonSpacing}
                color="primary"
                size="large"
                disabled={password === '' || code === '' || submittingUserData || passwordInvalid}
                onClick={handlePasswordChangeClick}
                fullWidth>{submittingUserData ? <CircularProgress color='inherit' size={15} /> : 'Change Password'}</Button>
            </Grid>
          </Grid>
        </form>
      }
    </Fragment>

  )
}

export default RecoveryForm
