import {getDomain} from "./getDomain";

export const generateCode = (state, key) => (`<div
  id="calcumate-root"
  data-integration="${getDomain(state.signedInUser?.data?.user?.integrations?.[key]?.domain || '')}"
  data-integration-2="${getDomain(state.signedInUser?.data?.user?.integrations?.[key]?.domain2 || '')}"
  data-ref="${state?.adminMode?.cuid || state.signedInUser.cukey}"
  data-int="${state.signedInUser?.data?.user?.integrations?.[key]?.id}"
></div>
<script defer src="${process.env.REACT_APP_CALCULATOR_SCRIPT_URL}"></script>`
)
