/*eslint-disable*/
import React, { useContext, Fragment } from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from "@material-ui/core"

// core components
import { useGlobalState } from "hooks/useGlobalState"

// Other components
import AdminTransactionalHeader from "components/AdminTransactionalHeader/AdminTransactionalHeader"

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles)

const AdminMySubscriptionConfirmed = props => {
  const classes = useStyles();
  let state = useContext(useGlobalState.State)
  
  return (
    <Fragment>
      <AdminTransactionalHeader
        homeBtn={false}
        backBtn={false}
        logo={false}
        closeBtn={() => props.history.push("/admin/my-account")}
      />
      <Grid container spacing={0} justify="center" className={classes.cFormContaner}>
        <Grid item xs={12} sm={12} md={12} className={classes.cWidthLimiter}>
          <Typography variant="overline">Subscription Purchase</Typography>
          <Typography variant="h1" className={classes.headerTwoLine}>{state.currentPlan} ${state.currentPlanPrice}{state.currentPlanPriceRec}</Typography>
          <Typography variant="h5" component="h2" style={{margin:"40px 0"}}>You have successfully upgraded your subscription</Typography>
          <TableContainer component={Paper} className={classes.tableMarginTop}>
            <Table aria-label="Purchase details">
              <TableBody>
                <TableRow key={0}>
                  <TableCell align="left" scope="row">Transaction number: </TableCell>
                  <TableCell align="left"><strong>#1234567890</strong></TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell align="left" scope="row">Amount billed</TableCell>
                  <TableCell align="left"><strong>USD$1.540</strong></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default AdminMySubscriptionConfirmed
