import React, { useContext, useEffect, useState } from "react"
import { useGlobalState } from "hooks/useGlobalState";
import axios from 'axios';
// @material-ui/core
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Grid, TextField, Button } from "@material-ui/core"
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"

const useStyles = makeStyles(styles)

const ContactForm = () => {
  const classes = useStyles();
  let history = useHistory();

  let state = useContext(useGlobalState.State);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [updating, setUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState('initial');
  const [updateError, setUpdateError] = useState('');
  const [phone, setPhone] = useState('');
  /* eslint-disable */
  useEffect(() => {

    if (state.isLoggedIn && state.signedInUser.token !== '') {
      // set global state values to the local state
      setName(state.signedInUser.data.user.firstName + ' ' + state.signedInUser.data.user.lastName);
      setEmail(state.signedInUser.data.user.email);
    }

  }, []);
  const handleInputChange = e => {
    if (e.target.id === "p-contact-name") {
      setName(e.target.value);
    }
    if (e.target.id === "p-contact-email") {
      setEmail(e.target.value);
    }
    if (e.target.id === "p-contact-message") {
      setMessage(e.target.value);
    }
    if (e.target.id === "p-contact-phone") {
      setPhone(e.target.value);
    }
  };

  const handleFormSubmit = async () => {
    setUpdating(true);
    await axios.post(
      process.env.REACT_APP_API_URL + 'contact/',
      {
        phone, 
        name, 
        email,
        message,
        service: '-',
        serviceNumber: '-',
        existingCustomer: state.signedInUser.token && state.signedInUser.token !== ''
      },
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.signedInUser.token } }
    ).then(function (response) {
      setUpdating(false);

      if (response.status && response.status === 200 && response.data) {
        setUpdateSuccess(response.data.status ? 'true' : 'false');
        setUpdateError(!response.data.status ? response.data.error : '');
      } else {
        setUpdateSuccess('false');
      }

      if (response.data.status) {
        // redirect the user to the admin panel after a timeout
        if (state.signedInUser.token && state.signedInUser.token) {
          setTimeout(() => {
            if (history.location.search === '?int') {
              return history.goBack();
            }
            history.push("/admin/my-account");
          }, 1500);
        } else {
          if (history.location.pathname === "/sign-up/confirmation")
            return history.push('/');
        }
      }

    }).catch(function (error) {
      setUpdating(false);
      setUpdateSuccess('false');
      console.error(error);
    });
  }

  return (
    <form noValidate autoComplete="off" className={classes.standardForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1">Don't be a stranger. Fill out the form and we'll be in touch as soon as possible.</Typography>
          {updateSuccess === 'true' ?
            <div className={classes.notification}>
              <br />
              <Alert severity="success">We received your request and will get back to you soon!</Alert>

            </div>
            : ''}
          {updateSuccess === 'false' ?
            <div className={classes.notification}>
              <br />
              <Alert severity="error">{updateError !== '' ? updateError : 'Something went wrong. Please try again.'}</Alert>

            </div>
            : ''}
        </Grid>
        <Grid item xs={12}>
          <TextField id="p-contact-name" label="Name" variant="outlined" type="text" fullWidth value={name} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField id="p-contact-email" label="Email" variant="outlined" type="email" fullWidth value={email} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField id="p-contact-phone" name="phone" label="Phone number" variant="outlined" type="phone" fullWidth onChange={handleInputChange} value={phone} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="p-contact-message"
            label="Message"
            multiline
            rows="5"
            variant="outlined"
            fullWidth
            value={message} onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            className={classes.buttonSpacing}
            color="primary"

            size="large"
            onClick={handleFormSubmit}
            disabled={name === '' || email === '' || message === ''}
            fullWidth>{updating ? <CircularProgress color='inherit' size={15} /> : 'Send it!'}</Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default ContactForm
