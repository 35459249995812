import React, {useState} from "react";
import Slider from "react-slick";

//Styles
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import styles from "../../assets/jss/material-dashboard-react/views/HomePageStyle";
import "../../assets/css/custom.scss";

//Components
import SimpleHeading from "../SimpleHeading/SimpleHeading";
import withWidth from '@material-ui/core/withWidth';

//Material UI
import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";
const useStyles = makeStyles(styles);

const WhyItWorks = props => {
  const [slideToShow, setSlideToShow] = useState(4);
  const classes = useStyles();
  const {data} =  props;
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slideToShow,
    slidesToScroll: 1,
    arrows: true,
    onReInit: () => {
      if (window.innerWidth <= 600) {
        setSlideToShow(1)
      } else {
        setSlideToShow(4)
      }
    }
  };

  return (
    <section className={classNames(classes.caseStudy, {[classes.caseStudyRemovals]: props.removals})} id={'why-it-works'}>
      <Grid
        container
        spacing={4}
        className={classes.whyItWorksWrap}
        alignItems="center"
      >
        <Grid item xs={12} md={12} style={{marginBottom:  64}}>
          <SimpleHeading mb={10} weight={500}>{data.mainHeader}</SimpleHeading>
          <Typography className={classes.homeSubHeaderGrey}>{data.subHeader}</Typography>
        </Grid>
        <Slider {...settings} style={{width: '100%'}}>
          {
            data.slides.map((slide, key) => (
              <div key={key} className={classes.whyItWorksSliderWrap}>
                <Grid container alignItems={'center'} justify={'center'} className={classes.whyItWorksSlideContainer}>
                  <Grid sm={12} md={4} lg={5} item>
                    <img
                      data-src={require(`../../assets/img/${slide.image}`)}
                      className={classNames(classes.whyItWorksSlideImage, 'lazyload')}
                      alt={slide.header}
                    />
                  </Grid>
                  <Grid sm={12} md={8} lg={7} item className={classes.whyItWorksSlide}>
                    <Typography variant="h4" component={'h1'} className={classes.whyItWorksHeader}>
                      {slide.header}
                    </Typography>
                    <Typography variant="body1">
                      {
                        slide.subHeader
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            ))
          }
        </Slider>
      </Grid>
    </section>
  )
}

export default withWidth()(WhyItWorks);
