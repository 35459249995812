import React, {Fragment, useContext, useEffect, useState} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {Grid, Typography, Button, Fab, List, ListItem, ListItemIcon, ListItemText, Paper} from "@material-ui/core";
import CloseRounded from '@material-ui/icons/CloseRounded';
import Slider from "react-slick";
// Core components
import { Link } from "react-router-dom";
import classNames from "classnames";

//Other components
import Footer from "components/Footer/Footer";
import HomeHeader from "components/HomeHeader/HomeHeader";
import SimpleHeading from "components/SimpleHeading/SimpleHeading";
import WhyItWorks from "../../components/WhyItworks";
import LoaderDots from './LoaderDots';
import AnchorLink from "react-anchor-link-smooth-scroll";
import CustomPricingHelpSection from "../../components/CustomPricing/CustomPricingPanel/CustomPricingHelpSection";
//Styles
import styles from "assets/jss/material-dashboard-react/views/HomePageStyle";
import stylesPricing from "assets/jss/material-dashboard-react/components/CustomPricingStyle"
import Modal from '@material-ui/core/Modal';
import InitState, {whyItWorks, removalsPlans} from "../../variables/InitState";
import CheckCircleRounded from "@material-ui/icons/CheckCircleRounded";
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import {useGlobalState} from "../../hooks/useGlobalState";
import {primaryColour, secondaryColour} from "assets/jss/material-dashboard-react"

const useStyles = makeStyles(styles);
const useStylesPricing = makeStyles(stylesPricing);

const HomePage = () => {
  const classes = useStyles();
  const classesPricing = useStylesPricing()
  const [open, setOpen] = useState(false)
  const [screen, setScreen] = useState({});
  const [loadingIntegration, setLoadingIntegration] = useState(false);
  let state = useContext(useGlobalState.State);
  const [mobile, setMobile] = useState(true);

  useEffect(() => {
    const handleScroll = () => window.innerWidth >= 600 ? setMobile(false) : setMobile( true)
    handleScroll()
    window.addEventListener('resize', handleScroll);
    return () => window.removeEventListener('resize', handleScroll)
  },[]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const SlideComponent  = props => mobile ? <Slider {...props}>{props.children}</Slider> : <Grid {...props}>{props.children}</Grid>;

  /* eslint-disable */
  useEffect(() => {
    const loadCalculatorScript = () => {
      const script = document.createElement("script");
      setLoadingIntegration(true);
      script.src = process.env.REACT_APP_CALCULATOR_SCRIPT_URL;
      script.async = false;
      script.onload = () => setLoadingIntegration(false);
      script.onerror = () => setLoadingIntegration(false);
      // loop through existing children and delete the script if exists
      [...document.body.children]
        .filter(
          (node) =>
            node.src === process.env.REACT_APP_DEMO_CALCULATOR_SCRIPT_URL
        )
        .forEach((node) => node.remove());
      document.body.appendChild(script);
    };

    loadCalculatorScript();
    setScreen({innerWidth: window.innerWidth, innerHeight: window.innerHeight});
    if (location?.href?.includes('autoplay')) {
      setOpen(true)
    }
    return () => {};
  }, []);

  return (
    <Fragment>
      <HomeHeader pageYOffset={60} />
      <section className={classes.heroBanner} id="main-section">
        <Grid
          container
          spacing={5}
          className={classNames(classes.heroContent, classes.mainSection, classes.heroContentRemovals)}
          direction="row"
          justify="flex-start"
          alignItems="center"
          id="hero-content"
        >
          <Grid item xs={12} sm={6}>
            <Typography
              className={classes.mainSubHeading}
              variant="body1"
              fontWeight="fontWeightMedium"
            >
              <Typography fontWeight="fontWeightMedium" style={{ fontSize: 28, marginBottom: 25}}>Calcumate - Movers Calculator</Typography>
              Say goodbye to inaccurate quoting and
              <br />
              hello to more customers!
            </Typography>
            <Typography
              className={classes.mainSubHeading}
              variant="body1"
              fontWeight="fontWeightMedium"
            >
              Our 3D calculator now gives you the
              <br />
              list of items they want you to move.
            </Typography>
            <AnchorLink
              offset="100"
              href="#demo-section"
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                color="default"
                size="large"
                className={classes.videoButton}
              >
                GET STARTED
              </Button>
            </AnchorLink>
          </Grid>
        </Grid>
      </section>
      <section className={classes.demo} id="demo-section">
        <SimpleHeading mb={10} weight={500} style={{paddingLeft: 30, paddingRight: 30}}>
          More jobs, happier customers
        </SimpleHeading>
        <Typography variant={'h3'} component={'h3'} className={classes.homeSubHeaderBlue} style={{paddingLeft: 30, paddingRight: 30}}>Show them what they need, fast. Try it yourself:</Typography>
        <div className={classes.integration}>
          {
            loadingIntegration && <LoaderDots primaryColour={'#93959E'} />
          }
          <div
            id="calcumate-root"
            data-integration={process.env.REACT_APP_CALCULATOR_DEMO_REMOVALS_DOMAIN}
            data-ref={process.env.REACT_APP_CALCULATOR_DEMO_REMOVALS_CUID}
            data-int={process.env.REACT_APP_CALCULATOR_DEMO_REMOVALS_INT}
          />
        </div>
      </section>
      <WhyItWorks removals={true} data={whyItWorks.removals}/>
      <section id={'start-for-free-removals'} className={classNames(classes.startForFree, classes.startForFreeRemovals)} >
        <Typography className={classes.startForFreeSimpleHeading} mb={30} style={{lineHeight: 1, color: primaryColour[900], marginBottom: 28, fontWeight: 500}}>Select the options that suit your needs</Typography>
        <Typography className={classNames(classes.startForFreeDiveder, classes.startForFreeDivederRemovals)}/>
        <SlideComponent {...settings} style={{width: '100%'}} container justify={'space-between'} alignItems={'center'} wrap={'nowrap'} className={classes.startFreeSlider}>
          {
            removalsPlans.map((plan, index) => {
              const originPlan = InitState.calcumatePlans.find(elem => elem.thePlan === plan.name);
              if (!originPlan) return;
              return (
                <>
                  <Grid key={index} item className={classes.removalsPlanWrap}>
                    <Paper
                      key={index}
                      elevation={2}
                      className={classNames(classesPricing.pricingPaperRemovals)}
                    >
                      <Typography variant="overline">{originPlan.thePlan}</Typography>
                      <Typography className={classesPricing.priceFont} variant="h2" style={{fontWeight: 400}}>
                        {originPlan.thePrice[1] > 0 ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 41}}>${originPlan.thePrice[1]} <span style={{fontSize: 14, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', marginLeft: 3}}><span>USD</span><span>Monthly</span></span></div> : <span style={{color: secondaryColour[600], fontSize: 41}}>FREE</span> }
                      </Typography>
                    </Paper>
                    <List dense className={classesPricing.priceList} style={{marginLeft: 'unset', marginRight: 'unset', maxWidth: '100%'}}>
                      {plan.features.map((prop, key) => {
                        return (
                          <ListItem key={key} disableGutters style={{marginBottom: 14, visibility: prop.checked ? 'visible' : 'hidden'}}>
                            <ListItemIcon>
                              {prop.checked ? <CheckCircleRounded
                                style={{color: '#1CB2E6', fontSize: 21}}
                              /> : <RadioButtonUncheckedRoundedIcon
                                style={{color: '#1CB2E6', fontSize: 21}}
                              />}
                            </ListItemIcon>
                            <ListItemText variant="caption" id={`price-list-${key}`} className={classes.startFreeFeaturesWrap}>
                              <strong className={classes.startFreeFeatures}>{prop.text}</strong>
                            </ListItemText>
                          </ListItem>
                        )
                      })}
                    </List>
                    <Button
                      component={Link}
                      to={state.signUpPlanUrl + "?plan=" + originPlan.thePlan.toLowerCase()}
                      variant="contained"
                      color={plan.color}
                      size="small"
                      fullWidth
                    >
                      Get {originPlan.thePlan}
                    </Button>
                  </Grid>
                  {index === 0 && (<Typography className={classes.startForFreeDivederVertical}/>)}
                </>
              )
            })
          }
        </SlideComponent>
      </section>
      <section className={classes.removalsHelpSectionWrap} style={{marginTop: 20}} id={'removals-page'}>
        <CustomPricingHelpSection removals={true} />
      </section>
      <Footer hideCompanies={true} removals={true} />
      <Modal
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.videoWrap}

      >
        <div className={classes.videoModalStyles}>
          <Fab
            color="primary"
            aria-label="Edit"
            size="medium"
            className={classes.videoCloseButton}
            onClick={()=>setOpen(false)}
          >
            <CloseRounded
              color="primary"
              aria-label="Edit"
              size="medium"
            />
          </Fab>
          <div style={{position:'relative', height: screen.innerWidth && screen.innerWidth - 80 * .75 < screen.innerHeight ?  screen.innerWidth - 80 * .75 :'75%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/JQ6SICvt7RI"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>

          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default HomePage;
