import React from 'react';
import {Grid} from "@material-ui/core";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import AdminIntegrationItem from "../../../components/AdminIntegrationItem/AdminIntegrationItem";
import ReorderRoundedIcon from "@material-ui/icons/ReorderRounded";

const SortableWrap = SortableContainer(({ children,  ID}) => <Grid container spacing={3} >{children}</Grid>);

const SortableItem = SortableElement(({ prop, index, children }) => (
  <Grid item xs={6} sm={4} xl={3} className={'item-element-d-n-d'}>
    <AdminIntegrationItem prop={prop} key={index} ><DragHandle /></AdminIntegrationItem>
  </Grid>
));

const DragHandle = SortableHandle(() => <ReorderRoundedIcon style={{fontSize: 25, zIndex: 100, color: '#00437C', cursor: 'grab'}} />);


const SortableList = props => {
  const { onSortEnd, items } = props;

  return (
    <SortableWrap
      onSortEnd={onSortEnd}
      axis={'xy'}
      lockToContainerEdges={true}
      useDragHandle
      lockOffset={["0%", "0%"]}
    >
      {
        items.map((item, index) => {
          return <SortableItem key={`item-${index}`} index={index} prop={item}><DragHandle/></SortableItem>
        })
      }
    </SortableWrap>
  )
}

export default React.memo(SortableList);
