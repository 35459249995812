import { useState, useEffect } from "react";
import {validateCoupon, couponRegexp} from "../helpers/validation"; 
import {handleError} from "../helpers/SaveIntegration";

const useCouponValidation = (coupon, token, setUpdatingGlobal) => {

  const [checkCoupon, setCheckCoupon] = useState(false)
  const [message, setMessage] = useState(null);
  const [couponIsValid, setCouponIsValid] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (!checkCoupon) return;
    const timeOut = setTimeout(async ()=> {
      const isValid = coupon.match(couponRegexp);

      if (isValid?.length === 1) {
        try {
          setUpdating(true);
          if (setUpdatingGlobal) setUpdatingGlobal(true);
          const res = await validateCoupon(coupon, token);
          if (res.data.res) {
            setMessage({type: 'success', msg: 'success'});
            setCouponIsValid(true);
            setUpdating(false);
            if (setUpdatingGlobal) setUpdatingGlobal(false);
            return
          } else {
            setMessage(null);
            setCouponIsValid(false);
          }
          setMessage({type: 'info', msg: 'no'});
        } catch (e) {
          setMessage({type: 'error', msg: 'error'});
          console.log('Coupon error:', e)
          handleError(e);
        }
        setUpdating(false);
        if (setUpdatingGlobal) setUpdatingGlobal(false);
      } else {
        setMessage(null);
      }
      setCouponIsValid(false);
    }, 1000);

    return () => clearTimeout(timeOut);
  }, [coupon]);

  return [
    setCheckCoupon,
    message,
    setMessage,
    couponIsValid,
    updating
  ];

};

export default useCouponValidation;
