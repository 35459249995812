import axios from "axios";
import InitState, {defaultSorting} from "../variables/InitState";
import {isFreePlan, freeItems} from "./validation";
import {preparePresets, prepareCategories} from './common';

export const loadAll = async (state, dispatchState) => {

  dispatchState({
    type: "integration-data-options",
    payload: {
      updatingItems: true,
    },
  });
  const isSubscriptionFree = isFreePlan(state);
  const uuid = (await import('uuid')).default;
  await axios.post(
    process.env.REACT_APP_API_URL + 'itemsntranslations',
    { "cukey": state?.adminMode?.cuid || state.signedInUser.cukey },
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.signedInUser.token } }
  ).then(function (response) {
    if (response.status && response.status === 200 && response.data) {
      // set the items in the state
      // prepare the items
      const selectedIntegration = state.integrationDataOptions?.selectedIntegration ? state.integrationDataOptions.selectedIntegration : 0
      const integration = state.signedInUser.data.user.integrations?.[selectedIntegration];
      let categories = [];
      const allItems = isSubscriptionFree ? response.data.items.filter(item => freeItems.includes(item.id)) : response.data.items;
      // Create categories from items data
      allItems.forEach((item, index) => {
        const category = categories.find((category) => category.catName === item.category);
        if (category) {
          // check whether the item is added to the category, if not add it.
          const isItemInCategory = category.itemsAssigned.includes(index);
          //console.log('item', item)
          if (!isItemInCategory) {
            category.itemsAssigned.push(index);
            category.items.push({id: item.id, sort: defaultSorting?.[item.category]?.[item.id] || 5000})
          }
        } else if (item.category) {
          // this is a new category
          const newCategory = {
            ID: categories.length,
            catName: item.category,
            itemsAssigned: [index],
            items: [{id: item.id, sort: defaultSorting?.[item.category]?.[item.id] || 5000}]
          };
          categories.push(newCategory);
        }
      });

      // Reorder the categories
      categories = categories.map((category, index) => {
        category.items = category.items.sort((a,b) => a.sort - b.sort)
        const ID = categoriesOrderMap.get(category.catName);
        return {
          ...category,
          ID,
          sort: ID
        }
      });

      categories = categories.filter(category =>category.ID !== undefined).sort((a, b) => a.sort - b.sort);

      let preparedItems = response.data.items.map((item, index) => {
        // check for translations
        let tranlationsForItem = getTranslations(response.data.translations, item.product);

        let customNameEntry;
        let customSize;
        let customWeight;
        if (integration) {
          customNameEntry = (integration.itemLibraryCustomNames || []).find(
            (customNameItem) => customNameItem.id === item.id
          );
          customSize = (integration.itemLibrarySizes || []).find(
            customSize => customSize.id === item.id
          );
          customWeight = (integration.itemLibraryWeight || []).find(customWeight => customWeight.id === item.id)
        }

        const dimension = [
          (calculateSize(item.width, customSize?.scale) * calculateSize(item.depth, customSize?.scale) * calculateSize(item.height, customSize?.scale) / 1000000).toFixed(2),
          (calculateSize(item.width, customSize?.scale) * calculateSize(item.depth, customSize?.scale) * calculateSize(item.height, customSize?.scale) / 28317).toFixed(2)]

        return {
          ID: index,
          defaultName: item.product,
          de: tranlationsForItem.de ? tranlationsForItem.de : item.product,
          es: tranlationsForItem.es ? tranlationsForItem.es : item.product,
          ja: tranlationsForItem.ja ? tranlationsForItem.ja : item.product,
          no: tranlationsForItem.no ? tranlationsForItem.no : item.product,
          da: tranlationsForItem.da ? tranlationsForItem.da : item.product,
          fr: tranlationsForItem.fr ? tranlationsForItem.fr : item.product,
          ar: tranlationsForItem.ar ? tranlationsForItem.ar : item.product,
          nl: tranlationsForItem.nl ? tranlationsForItem.nl : item.product,
          he: tranlationsForItem.he ? tranlationsForItem.he : item.product,
          lt: tranlationsForItem.lt ? tranlationsForItem.lt : item.product,
          pl: tranlationsForItem.pl ? tranlationsForItem.pl : item.product,
          pt: tranlationsForItem.pt ? tranlationsForItem.pt : item.product,
          ro: tranlationsForItem.ro ? tranlationsForItem.ro : item.product,
          ch: tranlationsForItem.ch ? tranlationsForItem.ch : item.product,
          sw: tranlationsForItem.sw ? tranlationsForItem.sw : item.product,
          fi: tranlationsForItem.fi ? tranlationsForItem.fi : item.product,
          vi: tranlationsForItem.vi ? tranlationsForItem.vi : item.product,
          ko: tranlationsForItem.ko ? tranlationsForItem.ko : item.product,
          hu: tranlationsForItem.hu ? tranlationsForItem.hu : item.product,
          customName: customNameEntry?.customName || '',
          itemThumbnail: "https://s3.calcumate.co/images/slider-furniture-icons/" + item.thumbnail,
          dimension, //m3,ft3
          w: [parseFloat(calculateSize(item.width, customSize?.scale).toFixed(1)),  (calculateSize(item.width, customSize?.scale) / 2.54).toFixed(0)],
          h: [parseFloat(calculateSize(item.height, customSize?.scale).toFixed(1)), (calculateSize(item.height, customSize?.scale) / 2.54).toFixed(0)],
          d: [parseFloat(calculateSize(item.depth, customSize?.scale).toFixed(1)), (calculateSize(item.depth, customSize?.scale) / 2.54).toFixed(0)],
          group: [item.category],
          width: item.width,
          height: item.height,
          depth: item.depth,
          weight: [
            item.weight ?
              calculateSize(Number(item.weight), customWeight?.scale).toFixed(1) :
              calculateSize(calculateWeight(dimension[0]), customWeight?.scale).toFixed(1),
            item.weight ?
              calculateSize(Number(item.weight) * 2.205, customWeight?.scale).toFixed(0) :
              calculateSize(calculateWeightLb(dimension[1]), customWeight?.scale).toFixed(0)
          ],
          id: item.id,
          order: item.order,
          product: item.product,
          restrictions: item.restrictions,
          cssId: item.cssId,
          thumbnail: item.thumbnail,
          scale: customSize?.scale || null,
          scaleWeight: customWeight?.scale || null,
        };
      });

      // if there is an integration already done, merge it with the state
      if (integration) {
        const integrationData = {
          integrationName: integration.name,
          integrationActiveBgStyle: integration.backgroundStyle,
          integrationActiveFont: integration.font,
          integrationActiveLanguage: integration.language,
          integrationButtonColour: integration.buttonColour,
          integrationButtonTextColour: integration.buttonTextColour,
          integrationCTALabel: integration.ctaLabel,
          integrationCTATarget: integration.ctaTarget,
          integrationCTAType: integration.ctaType === 'phoneNo',
          integrationCtaURL: integration.cta,
          integrationIncQty: integration.ctaShouldIncludeUnitQuantity,
          integrationIncID: integration.ctaShouldIncludeUnitID,
          integrationDomain: integration.domain,
          integrationDomain2: integration.domain2,
          integrationGradientOne: integration.gradientOne,
          integrationGradientTwo: integration.gradientTwo,
          integrationMeasurement: integration.measurement,
          integrationPrimaryColour: integration.primaryColour,
          integrationSecondaryColour: integration.secondaryColour,
          integrationStorageType: integration.storageType,
          integrationUnits: integration.units,
          integrationPresets: integration.presets,
          integrationCategories: integration.categories,
          integrationLogo: integration.integrationLogo,
          bucketDomain: integration.bucketDomain,
          showResultUnitDetails: integration.showResultUnitDetails,
          hideResultUnitDetails: integration.hideResultUnitDetails,
          showLoadingPlanButton: integration.showLoadingPlanButton,
          integrationLoadingCustomerEmail: integration.loadingCustomerEmail,
          integrationUrl: integration.integrationUrl,
          integrationUrlValid: integration.integrationUrlValid,
          integrationSecondaryText: integration.integrationSecondaryText,
          integrationPrimaryText: integration.integrationPrimaryText,
          integrationDisablePresets: integration.disablePresets,
          integrationDisableMultipleResults: integration.disableMultipleResults,
          integrationLoadDetails: integration.loadDetails,
          integrationLoadDetailsActive: integration.loadDetailsActive,
          integrationLoadDetailsEmail: integration.loadDetailsEmail,
          id: integration.id,
          integrationPublished: integration.published,
          integrationAllowAnimationControl: integration.allowAnimationControl,
          integrationLoadGenerationForm: integration.loadGenerationForm,
          integrationLoadingPlanFields: integration.loadingPlanFields,
          integrationPresetsLabel: integration.presetsLabel,
          integrationPresetColor: integration.presetColor,
          integrationPresetBackground: integration.presetBackground,
          integrationLoadDetailsEmails: integration.loadDetailsEmails,
          integrationLoadingCustomerEmails: integration.loadingCustomerEmails,
          integrationShowItemWeight: integration.showItemWeight,
          integrationDisplaySearch: integration.displaySearch,
          integrationCopy: integration.copy,
          integrationUnitImage: integration.unitImage,
          integrationCustomUnitImageEnabled: integration.customUnitImageEnabled,
          integrationCTARedirection: integration.CTARedirection,
          integrationAddCustomItems: integration.addCustomItems,
          integrationItemsListMode: integration.itemsListMode,
          integrationAmountMultipleUnit: integration.amountMultipleUnit,
          integrationAllowCopy: integration.allowCopy,
          integrationPresetMenuPosition: integration.presetMenuPosition,
          integrationPermanentCustom: integration.permanentCustom,
          integrationSelectedItemsIntoURL: integration.selectedItemsIntoURL,
          integrationHideCopyright: integration.hideCopyright,
          integrationCategoryButtonBorderColor: integration.categoryButtonBorderColor,
          integrationIncUsedVolume: integration.ctaShouldIncludeUnitUsedVolume,
          integrationIncVolume: integration.ctaShouldIncludeUnitVolume,
          integrationCtaShouldIncludeUnitQuantity: integration.ctaShouldIncIDQuantityMultiple,
          integrationItemTextColour: integration.itemTextColour,
          integrationPrimaryTextColor: integration.primaryTextColor,
        };

        if ('showUnitsNote' in integration) {
          integrationData.integrationShowUnitsNote = integration.showUnitsNote
        }
        integrationData.integrationCategories = prepareCategories({...state, integrationData: { itemLibrary: preparedItems, ...integrationData}});
        integrationData.integrationPresets = preparePresets({...state, integrationData: { itemLibrary: preparedItems, ...integrationData}});
        dispatchState({ type: 'integration-data', payload: { 
          itemLibrary: preparedItems, 
          ...integrationData,
          integrationItemsLoaded: true, 
        } });
        
      } else {
        const initState = {...InitState.integrationData};
        if (isSubscriptionFree) {
          initState.integrationActiveFont = "My website font";
        }

        dispatchState({ type: 'integration-data', payload: {
            ...initState,
            itemLibrary: preparedItems,
            integrationCategories: categories,
            id: uuid.v4(),
            integrationDomain2: '',
            integrationDomain: '',
            integrationLogo: '',
            hideResultUnitDetails: true,
            integrationPublished: false,
            integrationShowUnitsNote: true,
            integrationPresetsLabel: null,
            integrationDisableMultipleResults: true,
            integrationItemsLoaded: true,
          } });
      }
    }
  })
    .catch(function (error) {
      console.error(error);

    });

  dispatchState({
    type: "integration-data-options",
    payload: {
      updatingItems: false,
    },
  });
}

const categoriesOrderMap = new Map();
categoriesOrderMap.set("Living Room", 0);
categoriesOrderMap.set("Bedroom", 1);
categoriesOrderMap.set("Kitchen", 2);
categoriesOrderMap.set("Other", 3);
//categoriesOrderMap.set("Outdoor", 4);
//categoriesOrderMap.set("Office", 5);

const getTranslations = (translations, itemName) => {
  if (!translations) {
    return {};
  }

  // search each translation and check for the item name provided
  let translated = {};
  translations.forEach((translation) => {
    Object.entries(translation.items)
      .filter((item) => item[0] === itemName)
      .forEach((item) => {
        translated[translation.language] = item[1];
      });
  });

  return translated;
};

const calculateSize = (value, scale) => {
  return scale ? value + (value * (scale / 100)) : value;
}

export const calculateWeight = cu => ((cu /0.028) * 2.7);
export const calculateWeightLb = cu => (cu * 6.5);
