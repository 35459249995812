import axios from 'axios';
import Video from "../views/CmsPage/components/Video";
import Code from "../views/CmsPage/components/Code";
import Accordion from "../views/CmsPage/components/Accordion";
import React, {useMemo} from "react";

export async function cmsHelpers() {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + 'page',
    { headers: { 'Content-Type': 'application/json' } }
  )
  return response && response.data;

}

export async function getCmsPage(name) {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + `page/${name}`,
    { headers: { 'Content-Type': 'application/json' } }
  )
  return response && response.data;

}

export async function getCmsPost(name) {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + `post/${name.replace(/^blog\//, '')}`,
    { headers: { 'Content-Type': 'application/json' } }
  )
  return response && response.data;

}

export async function getCmsPosts() {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + `post`,
    { headers: { 'Content-Type': 'application/json' } }
  )
  return response && response.data;

}


export const BlockWrap = props => {
  const classes = useMemo(() => {
    switch (window.location.pathname) {
      case '/blog':
        return 'cms-block-wrap blog-page-icons-wrap';
      case '/container-storage':
      case '/self-storage':
      case '/mobile-storage':
      case '/movers':
        return 'cms-block-wrap-service cms-block-wrap';
      default:
        return 'cms-block-wrap'
    }
  }, [props]);
  return <div className={classes}>{props.children}</div>
}

export const handleNodes = (elem, index) => {
  const key = Math.random().toFixed(5);
  if (elem?.props?.className && elem?.props?.className.includes('is-type-video')) {
    return <BlockWrap key={key}><Video key={key} elem={elem} index={index}/></BlockWrap>
  } else if ((elem?.props?.className && elem?.props?.className.includes('wp-block-code') && elem.type === 'pre') || elem.type === 'script') {
    return <Code key={key} elem={elem} index={index}/>
  } else if (elem.type === 'div' && elem.props.id === 'calcumate-root') {
    return elem;
  } else if (elem?.props?.className && elem?.props?.className.includes('wp-block-block-accordion')) {
    return <Accordion elem={elem}/>
  } else {
    return <BlockWrap key={key}>{elem}</BlockWrap>
  }
}
