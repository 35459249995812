import React, { useContext, useEffect } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Grid, Switch } from "@material-ui/core"

// core components
import classNames from "classnames"

// Other components
import { useGlobalState } from "hooks/useGlobalState"

// Style
import styles from "../../../assets/jss/material-dashboard-react/components/CustomPricingStyle";

const useStyles = makeStyles(styles)

const CustomPricingSwitch = () => {
  const classes = useStyles()
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)

  const updateStatus = () => {
    let selectedPlan = [
      ...state.calcumatePlans, 
      ...state.calcumateCustomPlans,
      ...state.calcumatePlansOld,
    ].filter(plan => {
      return plan.thePlan === state.currentPlan;
    });
    const sids = selectedPlan[0][`sid${state.currency}`];
    const thePrices = selectedPlan?.[0]?.[state.currency];
    const cmPlanPayRec = state.cmPlanPayRec ? false : true;
    const price = cmPlanPayRec ?
      thePrices[0] :
      thePrices[1];
    let payload = {
      cmPlanPayRec,
      currentPlanPriceRec: (state.currentPlanPriceRec.indexOf('yr') > -1 ? '/mo' : '/yr'),
      currentPlanPrice: price
    }
    if (state?.signedInUser?.data) {
      payload = {
        ...payload,
        signedInUser: {
          ...state.signedInUser,
          data: {
            ...state.signedInUser.data,
            user: {
              ...state.signedInUser.data.user,
              lastChosenSubscription: sids[(state.currentPlanPriceRec.indexOf('yr') > -1 ? 1 : 0)]
            },
          }
        }
      }
    }

    dispatchState({
      type: 'root-state',
      payload
    })
  }

  /* eslint-disable */
  useEffect(() => {

    if (state.currentPlanPriceRec !== '' && state.isLoggedIn) {
      dispatchState({ type: 'root-state', payload: { cmPlanPayRec: state.currentPlanPriceRec.indexOf('yr') > -1 } });
    }

  }, []);

  return (
    <Grid container direction="row" justify="center" alignItems="center" className="billing-period-wrap">
      <Grid item>
        <Typography
          variant="subtitle1"
          style={{fontSize: '.75rem'}}
          className={classNames(classes.PricingWidgetTextColor, { [classes.PricingWidgetTextColorActive]: !state.cmPlanPayRec })}
          onClick={updateStatus}>
          Monthly
        </Typography>
      </Grid>
      <Grid item>
        <Switch data-cy={'switch--cm-plan-pay-rec'} color="primary" value="Yearly" className={classes.pricingSwitch} checked={state.cmPlanPayRec} onClick={updateStatus} />
      </Grid>
      <Grid item>
        <Typography
          style={{fontSize: '.75rem'}}
          variant="subtitle1"
          className={classNames(classes.PricingWidgetTextColor, { [classes.PricingWidgetTextColorActive]: state.cmPlanPayRec })}
          onClick={updateStatus}>
          Yearly
        </Typography>
      </Grid>
    </Grid>
  )
}

export default CustomPricingSwitch
