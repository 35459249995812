import { useState } from "react";
import Icon from '@material-ui/core/Icon';
import { Button, ButtonGroup, Grid, TextField, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { blackColor, primaryColor, primaryColour, whiteColor } from "assets/jss/material-dashboard-react";
import classNames from 'classnames';
import Plus from '../../assets/img/plus.svg';
import Minus from '../../assets/img/minus.svg';

export default ({
  number, 
  setNumber,
  hideLabel,
}: CounterProps) => {

  const classes = useStyles();
  const updateCounter = (action: Action) => {
    const counter = number;
    if (action === 'increase') return setNumber(Number(counter) + 1);
    setNumber((counter - 1 <= 1) ? 1 : counter - 1);
  }
  
  const setValue = (value: string) => {
    if (!Number.isNaN(parseInt(value))) {
      return setNumber(value ? parseInt(value) : 0);
    }
    setNumber(0);  
  }

  return (
    <>
      <Grid className={classes.wrap} container alignItems="center">
        {
          !hideLabel && (
            <Grid>
              <Typography className={classes.label}>Number of facilities</Typography>
            </Grid>
          )
        }
        <Grid className={classes.controlWrap}>
          <span 
            onClick={() => updateCounter('decrease')} 
            className={classNames('decrease', classes.decrease, classes.icon, {
              [classes.disabled]: number === 1 || !number
            })}
          />
          <Grid className={classes.counter}>
            <TextField
              value={number}
              onChange={(ev) => setValue(ev.target.value)}
              type="text"
            />
          </Grid>
          <span onClick={() => updateCounter('increase')} className={classNames(classes.increase, classes.icon)} />
        </Grid>
      </Grid>
    </>
  );
}

// ============================================================================
// Styles
// ============================================================================
//@ts-ignore
const styles = theme => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    gap: 27,
    [theme.breakpoints.between(743.95, 1240)]: {
      gap: 11,
    }
  },
  label: {
    color: blackColor,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    [theme.breakpoints.down("sm")]: {
      fontSize: '.7rem'
    },
    [theme.breakpoints.between(743.95, 1240)]: {
      fontSize: 13
    }
  },
  counter: {
    fontSize: 20,
    fontWeight: 400,
    minWidth: 40,
    [theme.breakpoints.between(743.95, 1240)]: {
      minWidth: 23,
    },
    textAlign: 'center',
    '& input': {
      maxWidth: 40,
      textAlign: 'center',
      [theme.breakpoints.between(743.95, 1240)]: {
        maxWidth: 23,
        fontSize: 13,
        padding: '7px 0'
      }
    },
    '& .MuiInput-underline:after':{
      content: 'none'
    },
    '& .MuiInput-underline:before':{
      content: 'none'
    }
  },
  decrease: {
    backgroundImage: 'url("' + Minus + '")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  increase: {
    backgroundColor: '#2BB0DE',
    color: whiteColor + '!important',
    backgroundImage: 'url("' + Plus + '")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  icon: {
    width: 18,
    height: 18,
    borderRadius: 32,
    border: '2px solid #2BB0DE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    cursor: 'pointer',
    [theme.breakpoints.between(743.95, 1240)]: {
      width: 13,
      height: 13,
    }
  },
  controlWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  disabled: {
    opacity: .5,
    cursor: 'not-allowed'
  }
});
//@ts-ignore
const useStyles = makeStyles(styles);

interface CounterProps {
  number: number;
  setNumber: (number: number) => void;
  hideLabel?: boolean;
}

type Action = 'increase' | 'decrease';