import axios from 'axios';

export async function sendAdminProgressEmail(step, data) {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + 'notifyadmin',
    {
      step: step,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      company: data.company && data.company !== "" ? data.company : 'N/A',
      domain: data.domain && data.domain !== "" ? data.domain : 'N/A',
      plan: data.plan ? data.plan : 'N/A',
      pricing: data.pricing,
      currency: data.currency,
      coupon: data?.coupon,
      serviceNumber: data?.serviceNumber,
      service: data?.service,
    },
    { headers: { 'Content-Type': 'application/json' } }
  )
  return response && response.data;

}
