export default [
  {
    category: "Subscription & Reporting",
    name: "Annual Subscription",
    plans: ['Complete'],
    value: ['10% OFF'],
    noUnderline: true,
    tooltip: 'Save money by subscribing annually, giving you uninterrupted access to all features for a full year at a discounted rate'
  },
  {
    category: "Subscription & Reporting",
    name: "Integration code",
    plans: ['Complete', 'Limited'],
    value: [],
    tooltip: 'Easily integrate our service with your existing website using our provided code snippet'
  },
  {
    category: "Subscription & Reporting",
    name: "Inventory items",
    plans: ['Complete', 'Limited'],
    value: ['130+', '28'],
    noUnderline: true,
    tooltip: 'Offer an inventory of over 130 pre-saved items, ready to use for a quick and easy setup'
  },
  {
    category: "Subscription & Reporting",
    name: "All languages",
    plans: ['Complete', 'Limited'],
    value: [],
    tooltip: 'Access and create the calculator in any language, making it suitable for global teams and diverse user bases'
  },
  {
    category: "Subscription & Reporting",
    name: "All storage types",
    plans: ['Complete', 'Limited'],
    value: [],
    tooltip: 'Available for Self-storage, Container-storage, Portable-storage, Removals, Kiosks, Call-Centers, Logistics and Shipping, and more'
  },
  {
    category: "Subscription & Reporting",
    name: "Usage Reporting",
    plans: ['Complete'],
    value: [],
    tooltip: 'Access detailed reports on your usage statistics to analyze and optimize performance'
  },
  {
    category: "Subscription & Reporting",
    name: "Unlimited Calculators",
    plans: ['Complete'],
    value: [],
    tooltip: 'Add as many calculators as you need, without any limitations on the number of calculations for a fee'
  },
  {
    category: "Settings",
    name: "Metric measurement system",
    plans: ['Complete', 'Limited'],
    value: [],
    tooltip: 'Switch to the metric system for measurements in meters'
  },
  {
    category: "Settings",
    name: "Imperial measurement system",
    plans: ['Complete', 'Limited'],
    value: [],
    tooltip: 'Switch to the imperial system for measurements in feet'
  },
  {
    category: "Settings",
    name: "Display unit volume and space used by items",
    plans: ['Complete',],
    value: [],
    tooltip: 'View the volume and the amount of space occupied by each item in the unit'
  },
  {
    category: "Settings",
    name: "Display total weight of items",
    plans: ['Complete',],
    value: [],
    tooltip: 'See the combined weight of all selected items'
  },
  {
    category: "Settings",
    name: "Display furniture item dimensions",
    plans: ['Complete',],
    value: [],
    tooltip: 'Show the precise dimensions of each furniture item for accurate planning'
  },
  {
    category: "Settings",
    name: "Allow the user to play, pause and re-play the stacking animation",
    plans: ['Complete',],
    value: [],
    tooltip: 'Control the stacking animation with play, pause, and replay options'
  },
  {
    category: "Settings",
    name: "Allow the user to copy selected items",
    plans: ['Complete',],
    value: [],
    tooltip: 'Easily duplicate selected items to streamline your layout process'
  },
  {
    category: "Settings",
    name: "CTA URL",
    plans: ['Complete',],
    value: [],
    tooltip: 'Direct users to a specified URL when they click the call-to-action button (i.e. contact form or booking page).'
  },
  {
    category: "Units",
    name: "Add custom size units",
    plans: ['Complete', 'Limited'],
    value: [],
    tooltip: 'Create custom unit sizes tailored to each facility'
  },
  {
    category: "Units",
    name: "Import unit sizes",
    plans: ['Complete', 'Limited'],
    value: [],
    tooltip: 'Easily import predefined unit sizes to quickly configure the calculator'
  },
  {
    category: "Units",
    name: "Recommend multiple unit sizes",
    plans: ['Complete'],
    value: [],
    tooltip: 'Allow users to see more than one recommend unit sizes'
  },
  {
    category: "Units",
    name: "Add additional subheading",
    plans: ['Complete'],
    value: [],
    tooltip: 'Add a description for each unit, like how much prices start from'
  },
  {
    category: "Units",
    name: "Add multiple sub header",
    plans: ['Complete'],
    value: [],
    tooltip: 'Support multiple subheaders to further detail unit size recommendations'
  },
  {
    category: "Units",
    name: "Add CTA action on each unit size",
    plans: ['Complete'],
    value: [],
    tooltip: 'Encourage users to select a recommended unit size by adding a CTA button to each option'
  },
  {
    category: "Advanced Settings",
    name: "Use phone number instead of URL",
    plans: ['Complete'],
    value: [],
    tooltip: 'Allow users to contact you directly by clicking the CTA, which will dial a phone number instead of opening a URL'
  },
  {
    category: "Advanced Settings",
    name: "Ability to disable CTA",
    plans: ['Complete'],
    value: [],
    tooltip: "Optionally disable the call-to-action button if you don't want to prompt users to take action"
  },
  {
    category: "Advanced Settings",
    name: "Allow the user to bookmark their result",
    plans: ['Complete'],
    value: [],
    tooltip: 'Enable users to save and revisit their results by bookmarking them in their browser'
  },
  {
    category: "Advanced Settings",
    name: "Request lead details from user",
    plans: ['Complete'],
    value: [],
    tooltip: 'Prompt users to enter their contact details before accessing specific features or content'
  },
  {
    category: "Advanced Settings",
    name: "Include unit quantity",
    plans: ['Complete'],
    value: [],
    tooltip: 'Include the total quantity of units selected as a parameter in the CTA URL'
  },
  {
    category: "Advanced Settings",
    name: "Include unit ID",
    plans: ['Complete'],
    value: [],
    tooltip: 'Include the unique identifier for each unit as a parameter in the CTA URL'
  },
  {
    category: "Advanced Settings",
    name: "Include unit volume",
    plans: ['Complete'],
    value: [],
    tooltip: 'Add the total volume of the unit as a parameter in the CTA URL'
  },
  {
    category: "Advanced Settings",
    name: "Include unit used space",
    plans: ['Complete'],
    value: [],
    tooltip: 'Add the space used within the unit as a parameter in the CTA URL'
  },
  {
    category: "Advanced Settings",
    name: "Modify CTA Label",
    plans: ['Complete'],
    value: [],
    tooltip: 'Customize the text label on the CTA button to better fit your action'
  },
  {
    category: "Advanced Settings",
    name: "CTA Open in new tab options",
    plans: ['Complete'],
    value: [],
    tooltip: 'Enable the CTA button to open the next step in a new tab for easy navigation'
  },
  {
    category: "Categories",
    name: "Display search function in Categories",
    plans: ['Complete'],
    value: [],
    tooltip: 'Allow users to quickly find items or categories with a search function'
  },
  {
    category: "Categories",
    name: "Add custom items",
    plans: ['Complete'],
    value: [],
    tooltip: 'Enable the addition of custom items to categories for a personalized inventory'
  },
  {
    category: "Categories",
    name: "Display item mode (horizontal vs popup)",
    plans: ['Complete'],
    value: [],
    tooltip: 'Choose between horizontal or popup display modes for item presentation'
  },
  {
    category: "Categories",
    name: "Add and modify categories",
    plans: ['Complete'],
    value: [],
    tooltip: 'Create and customize categories to organize your items more effectively'
  },
  {
    category: "Categories",
    name: "Change item size by 10/20/30%",
    plans: ['Complete'],
    value: [],
    tooltip: 'Adjust item sizes in increments of 10%, 20%, or 30% to fit your layout'
  },
  {
    category: "Categories",
    name: "Change item weight by 20/30%",
    plans: ['Complete'],
    value: [],
    tooltip: 'Modify item weights by 20% or 30% to accommodate different scenarios'
  },
  {
    category: "Presets",
    name: "Define, name, and display up to five pre-programmed lists of categories and their items when your calculator loads (e.g., 1 Bedroom, 2 Bedroom, etc.)",
    plans: ['Complete'],
    value: [],
    tooltip: 'Display up to 5 preset categories for quick access to relevant options (e.g., 1 Bedroom, 2 Bedroom, etc.)'
  },
  {
    category: "Design",
    name: "Custom Branding",
    plans: ['Complete'],
    value: [],
    tooltip: 'Customize the platform with your brand’s identity'
  },
  {
    category: "Design",
    name: "Font selector",
    plans: ['Complete'],
    value: [],
    tooltip: "Choose from a variety of fonts to match your brand's typography"
  },
  {
    category: "Design",
    name: "Intro primary text",
    plans: ['Complete'],
    value: [],
    tooltip: 'Customize the primary text displayed on your introductory screens'
  },
  {
    category: "Design",
    name: "Intro secondary text",
    plans: ['Complete'],
    value: [],
    tooltip: 'Edit the secondary text on your intro screens to convey additional information'
  },
  {
    category: "Design",
    name: "Upload logo to unit",
    plans: ['Complete'],
    value: [],
    tooltip: "Personalize your units by uploading your company's logo"
  },
  {
    category: "Design",
    name: "Upload unit image",
    plans: ['Complete'],
    value: [],
    tooltip: 'Personalize your units by uploading a different unit image'
  },
  {
    category: "Design",
    name: "Primary colour",
    plans: ['Complete'],
    value: [],
    tooltip: 'Set the primary color for your interface to match your brand identity'
  },
  {
    category: "Design",
    name: "Secondary colour",
    plans: ['Complete'],
    value: [],
    tooltip: 'Choose a secondary color that complements your primary color for a cohesive look'
  },
  {
    category: "Design",
    name: "Unit colour",
    plans: ['Complete'],
    value: [],
    tooltip: 'Select a specific color for your units to make them stand out'
  },
  {
    category: "Design",
    name: "Solid",
    plans: ['Complete'],
    value: [],
    tooltip: 'Apply a solid color as your background for a clean and simple look'
  },
  {
    category: "Design",
    name: "Gradient",
    plans: ['Complete'],
    value: [],
    tooltip: 'Use a gradient background to add depth and visual interest'
  },
  {
    category: "Design",
    name: "Transparent",
    plans: ['Complete'],
    value: [],
    tooltip: 'Make the background transparent to blend with the underlying content'
  },
  {
    category: "Design",
    name: "Show Isometric Grid",
    plans: ['Complete'],
    value: [],
    tooltip: 'Display an isometric grid for precise placement and alignment of items'
  },
  {
    category: "Design",
    name: "Button colour",
    plans: ['Complete'],
    value: [],
    tooltip: 'Set the color of your buttons to match your branding or theme'
  },
  {
    category: "Design",
    name: "Button text colour",
    plans: ['Complete'],
    value: [],
    tooltip: 'Choose the text color for your buttons to ensure readability and contrast'
  },
  {
    category: "Design",
    name: "Category button border colour",
    plans: ['Complete'],
    value: [],
    tooltip: 'Define the border color for category buttons to enhance visibility and design'
  },
];

export const plans = ['Limited', 'Complete'];