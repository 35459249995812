/*eslint-disable*/
import React from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { primaryColour } from "assets/jss/material-dashboard-react"
import { Typography } from "@material-ui/core"
import { whiteColor } from "assets/jss/material-dashboard-react"

// core components

const styles = theme => ({
  headerOneLine: {
    textAlign: "center",
    lineHeight: "100%",
    [theme.breakpoints.up("sm")]: {
      lineHeight: "130%",
    },
    "&::after": {
      content: `" "`,
      display: "block",
      width: 30,
      height: 2,
      backgroundColor: primaryColour[500],
      margin: "12px auto 0 auto"
    }
  }
})
const useStyles = makeStyles(styles)

const SimpleHeading = props => {
  const classes = useStyles()
  const { children, mb, theme, weight, style } = props
  const themeNew = theme === "light" ? whiteColor : primaryColour[900]
  const fontWeight = weight ? weight : 600
  return (
    <Typography variant="h1" className={classes.headerOneLine} style={{marginBottom: mb, color: themeNew, fontWeight, ...style}}>{children}</Typography>
  );
}

export default SimpleHeading
